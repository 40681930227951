import { updateUnapprovedRestaurant } from 'Adapters/APIs/Admin/dashboard';
import { updateEmailRequest, updateUser } from 'Adapters/APIs/Authentication';
import { generateDescription } from 'Adapters/APIs/Restaurant/Profile';
import { getAllCategories, getAllCuisine } from 'Adapters/APIs/restaurants';
import Svgs from 'Assets/svgs';
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import Cookies from 'js-cookie';
import { useMemo, useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useEditProfile = (setIsEdit, editData, successCallback) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();

    // Parse the query string
    const searchParams = new URLSearchParams(location.search);
    const activeTab = searchParams.get('activeTab');

    // Check the pathname and query parameter
    const isProfileTabActive = location.pathname === '/restaurant/profile' && activeTab === 'profile';

    const { errors, setErrors, validation, validateMoblieNumber, emailValidation, validateWebsiteUrlWithName } = useValidations()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const user = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    // states
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false)
    const [updateEmail, setUpdateEmail] = useState(false)
    const [OTPLoading, setOTPLoading] = useState(false)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [addCuisine, setAddCuisine] = useState(false)
    const [getCuisineLoader, setGetCuisineLoader] = useState(true)

    const otherOptions = useMemo(() =>
        [
            {
                method_type: "cash",
                label: "Cash/Cheque"
            },
            {
                method_type: "bank",
                label: "Bank"
            },
        ], [])

    const OTHER_PAYMENT_METHODS = [
        { name: 'cash', icon: <Svgs.CashIcon fill={'var(--secondary-color)'} /> },
        { name: 'bank', icon: <Svgs.CardIcon fill={'var(--secondary-color)'} /> },
    ]

    // handle selected image remove here
    const onImageRemove = (id, ind) => {
        const filteredImages = formData?.images?.filter((itm, index) => {
            if (itm?.name) {
                return index !== ind;
            } else {
                return itm?.id !== id;
            }
        });
        let deletedIds = formData?.delete_images || []
        if (id)
            deletedIds?.push(id)

        setFormData({
            ...formData,
            delete_images: deletedIds,
            images: filteredImages,
            image_update: true
        })
    }

    // handle change here
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'images') {
            let images = formData?.images || []
            images?.push(e.target.files[0])
            setFormData({
                ...formData,
                images: images,
                image_update: true
            })
        } else if (name == 'website_link') {
            validateWebsiteUrlWithName(name, value)
            setFormData({
                ...formData,
                website_link: value,
            })
        } else if (name == 'instagram_link') {
            validateWebsiteUrlWithName(name, value)
            setFormData({
                ...formData,
                instagram_link: value,
            })
        } else if (name == 'agreement') {
            setFormData({
                ...formData,
                agreement: e.target.files[0],
            })
        } else if (name == 'trade_license') {
            setFormData({
                ...formData,
                trade_license: e.target.files[0],
            })

        } else if (name == 'phone_number') {
            validateMoblieNumber(name, value);
            setFormData({
                ...formData,
                phone_number: value,
                phone_code: value?.split('-')[0]
            })
        } else if (name == 'mobile_number') {
            validateMoblieNumber(name, value);
            setFormData({
                ...formData,
                mobile_number: value,
                mobile_code: value?.split('-')[0]
            })
        }
        else if (name == 'wa_number') {
            validateMoblieNumber(name, value);
            setFormData({
                ...formData,
                wa_number: value
            })
        } else if (name == 'email') {
            setFormData({
                ...formData,
                [name]: value
            })
            emailValidation(name, value);
        } else if (name == 'edit_access') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        else if (name == 'booking_cancellation_terms') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        else if (name == 'amount_collection') {
            if (value == 'bank') {
                setErrors((prev) => ({ ...prev, [name]: '', amount_collection_description: "" }));
                setFormData({
                    ...formData,
                    [name]: value
                })
            } else {
                setErrors((prev) => ({ ...prev, [name]: '' }));
                setFormData({
                    ...formData,
                    [name]: value,
                    amount_collection_description: ""
                })
            }
        } else if (name == 'amount_collection_description') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: value
            })
        }
        else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // handle submit function
    const handleSubmit = () => {
        let requiredFields = {
            restaurant_name: formData?.restaurant_name,
            email: formData?.email,
            categories: formData?.categories?.length > 0 ? true : false,
            cuisine_id: formData?.cuisine_id,
            phone_number: formData?.phone_number,
            mobile_number: formData?.mobile_number,
            address: formData?.address,
            contact_person: formData?.contact_person,
            amount_collection: formData?.amount_collection,
            amount_collection_description: formData?.amount_collection == 'bank' ? true : formData?.amount_collection_description
        }
        if (formData?.phone_number?.split('-')[1]) {
            delete requiredFields?.mobile_number
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            // Validation for same mobile and phone_number
            if (formData?.phone_number == formData?.mobile_number) {
                setErrors({
                    ...errors,
                    phone_number: "Mobile and phone can't be same",
                    mobile_number: "Mobile and phone can't be same"
                })
            } else {
                let payload = {
                    ...formData,
                    images: formData?.images?.filter(itm => itm?.name)?.length > 0 ? formData?.images?.filter(itm => {
                        if (itm?.name) {
                            return JSON.stringify(itm)
                        }
                    }) : '',
                    wa_code: formData?.wa_number?.split('-')[0],
                    wa_number: formData?.wa_number?.split('-')[1],
                    phone_code: formData?.phone_number?.split('-')[0]?.replace('+', ''),
                    phone_number: formData?.phone_number?.split('-')[1],
                    mobile_code: formData?.mobile_number?.split('-')[0]?.replace('+', ''),
                    mobile_number: formData?.mobile_number?.split('-')[1],
                    trade_license: formData?.trade_license?.name ? formData?.trade_license : '',
                    agreement: formData?.agreement?.name ? formData?.agreement : '',
                    amount_collection: formData?.amount_collection,
                    amount_collection_description: formData?.amount_collection == 'cash' ? formData?.amount_collection_description : null,
                    booking_cancellation_terms: formData?.booking_cancellation_terms ? 1 : 0,
                    description: formData?.description ? formData?.description : "",
                    terms_and_conditions: formData?.terms_and_conditions ? formData?.terms_and_conditions : ""

                }
                const success = (data) => {
                    document.querySelector(".edittopscroll").scrollIntoView();
                    if (editData) {
                        if (successCallback) {
                            successCallback()
                        } else {
                            navigate(-1)
                        }
                    } else {
                        storeDataInLocalStorage('user_data', data)
                    }
                    Cookies.set('getUserProfileData', true)
                    setLoading(false)
                    setIsEdit && setIsEdit(false)
                }
                const fail = (error) => {
                    setLoading(false)
                    setErrors((prev) => ({
                        ...prev,
                        'email': error?.response?.data?.error?.email ? error?.response?.data?.error?.email[0] : false,
                        'mobile_number': error?.response?.data?.error?.mobile_number ? error?.response?.data?.error?.mobile_number[0] : false,
                        'phone_number': error?.response?.data?.error?.phone_number ? error?.response?.data?.error?.phone_number[0] : false
                    }));
                }
                if (!formData?.image_update || user?.restaurant?.images?.length == formData?.images?.length) {
                    delete payload.images
                    delete payload.image_update
                }
                setLoading(true)
                if (editData) {
                    payload = {
                        ...payload,
                        user_id: editData?.user?.id,
                    }
                    dispatch(updateUnapprovedRestaurant(access_token, payload, success, fail))
                } else {
                    dispatch(updateUser(access_token, payload, success, fail))
                }
            }
        } else {
            document.querySelector(".edittopscroll").scrollIntoView();
        }
    };

    // request OTP for email update
    const requestOTP = (type, email, setStep) => {
        const sucess = () => {
            if (!email) {
                setStep && setStep(2)
            } else {
                setStep && setStep(4)
            }
            setOTPLoading(false)
        }
        const fail = () => { setOTPLoading(false) }

        if (type == 'new' && !email) {
            setErrors((prev) => ({ ...prev, 'newEmail': 'This field is required' }));
        }

        if (!errors?.newEmail) {
            setOTPLoading(true)
            dispatch(updateEmailRequest(access_token,
                {
                    type: type,
                    new_email: email ? email : '',
                },
                sucess, fail))
        }
    }

    // request OTP for email update
    const handleGenerateDescription = () => {
        if (!formData?.description) {
            const success = (value) => {
                setFormData((prevFormData) => ({ ...prevFormData, description: value }));
                setErrors((prev) => ({ ...prev, description: '' }));
            }
            const fail = () => { setOTPLoading(false) }
            dispatch(generateDescription(access_token, user?.restaurant?.id, success, fail))
        }
    }

    // setting cusines
    const cuisines = useMemo(() => {
        return cuisinse?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [cuisinse])

    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [categoriesData])

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    // get cuisine
    const getCuisineData = (existingId) => {
        const success = () => {
            if (existingId) {
                setFormData({
                    ...formData,
                    'cuisine_id': existingId
                })
            }
            setAddCuisine(false)
            setGetCuisineLoader(false)
        }
        const fail = () => {
            setGetCuisineLoader(false)
        }
        setGetCuisineLoader(true)
        dispatch(getAllCuisine('', success, fail))
    }

    useEffect(() => {
        if (editData) {
            setFormData({
                restaurant_name: editData?.name,
                images: editData?.images,
                email: editData?.user?.email,
                authorized_manager: editData?.authorized_manager ? editData?.authorized_manager : "",
                price_per_cover: editData?.price_per_cover ? editData?.price_per_cover : "",
                categories: editData?.categories?.map(itm => itm?.id),
                cuisine_id: editData?.cuisine?.id,
                website_link: editData?.website_link ? editData?.website_link : "",
                phone_number: editData?.phone_code + '-' + editData?.phone_number,
                mobile_number: editData?.mobile_code + '-' + editData?.mobile_number,
                wa_number: `${editData?.mobile_code && `${editData?.mobile_code}-`}${user?.wa_number ? user?.wa_number : ''}`,
                address: editData?.address,
                country: editData?.country,
                type: 'restaurant',
                state: editData?.state,
                city: editData?.city,
                description: editData?.description ? editData?.description : "",
                location_coordinates: editData?.location_coordinates,
                trade_license: editData?.trade_license,
                agreement: editData?.agreement,
                amount_collection: editData?.amount_collection,
                amount_collection_description: editData?.amount_collection_description,
                booking_cancellation_terms: editData?.booking_cancellation_terms,
                terms_and_conditions: editData?.restaurant?.terms_and_conditions,
                trn_number: editData?.restaurant?.trn_number,
                contact_person: editData?.restaurant?.contact_person,
                instagram_link: editData?.restaurant?.instagram_link
            })
        } else {
            setFormData({
                restaurant_name: user?.restaurant?.name,
                images: user?.restaurant?.images,
                email: user?.email,
                authorized_manager: user?.restaurant?.authorized_manager,
                price_per_cover: user?.restaurant?.price_per_cover,
                categories: user?.restaurant?.categories?.map(itm => itm?.id),
                cuisine_id: user?.restaurant?.cuisine?.id,
                website_link: user?.restaurant?.website_link,
                phone_number: user?.phone_code + '-' + user?.phone_number,
                mobile_number: user?.mobile_code + '-' + user?.mobile_number,
                wa_number: `${user?.mobile_code && `${user?.mobile_code}-`}${user?.wa_number ? user?.wa_number : ''}`,
                address: user?.address,
                country: user?.country,
                type: 1, // 0 Concirege && 1 Restaurent
                state: user?.state,
                city: user?.city,
                description: user?.restaurant?.description,
                terms_and_conditions: user?.restaurant?.terms_and_conditions,
                location_coordinates: user?.location_coordinates,
                trade_license: user?.restaurant?.trade_license,
                agreement: user?.agreement,
                amount_collection: user?.restaurant?.amount_collection,
                amount_collection_description: user?.restaurant?.amount_collection_description,
                booking_cancellation_terms: user?.restaurant?.booking_cancellation_terms,
                trn_number: user?.restaurant?.trn_number,
                contact_person: user?.restaurant?.contact_person,
                instagram_link: user?.restaurant?.instagram_link
            })
        }
    }, [editData])

    useEffect(() => {
        getCuisineData()
        getCategories()
    }, [])


    return {
        formData, navigate, errors, handleChange, loading, handleSubmit, updateEmail, setUpdateEmail, OTPLoading, setOTPLoading,
        requestOTP, emailValidation, cuisines, onImageRemove, user, otherOptions, OTHER_PAYMENT_METHODS, categories, addCuisine,
        setAddCuisine, getCuisineLoader, getCuisineData, handleGenerateDescription, setErrors, isProfileTabActive
    }
}

export default useEditProfile