import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminFinance from './helper'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Restaurant from 'Utility/Tables/AdminFinanceTable/Restaurant'
import Back from 'Components/Elements/Back'

const AdminPendingRecievables = () => {
    const { financeData, paginationData, loader, currentPage, setCurrentPage } = useAdminFinance()
    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <Back
                    title={'Invoice Report'}
                />
                <div className="!m-0 flex flex-col">
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-lightGrey">
                                    {loader
                                        ? <BookingTableShimer />
                                        :
                                        <Restaurant data={financeData} />
                                    }
                                    {financeData?.length > 0
                                        && <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={financeData?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default AdminPendingRecievables