import { removeFCMToken } from "Adapters/APIs/Authentication";
import { logoutfunction } from "Constants/Functions/Logout";
import { removeTokenFromFCM } from "firebase.config";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useLogoutHelper = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const fcm_token = getDataFromLocalStorage('fcm_token')

    // states
    const [logoutLoader, setLogoutLoader] = useState(false)

    // logout function
    const logoutUser = async (onSuccessCallback) => {
        setLogoutLoader(true)
        const success = () => {
            onSuccessCallback && onSuccessCallback()
            // setLogoutLoader(false)
        }
        await removeTokenFromFCM()
        dispatch(removeFCMToken(access_token, { fcm_token: fcm_token }, success))
        logoutfunction()
    }

    return {
        logoutUser, navigate, logoutLoader
    }
}
export default useLogoutHelper