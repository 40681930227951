import { getAdminSingleRestaurantApiData, updateRestaurantSubscriptionAndCommission, updateRestaurantToogleStatus } from "Adapters/APIs/Admin/restaurant";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

let initialData = {
    admin_percentage: "",
    subscription_charges: ""
}

const useAdminSingleRestaurant = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { restuarant_id } = useParams();

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const restaurant = state?.adminRestaurants?.singleRestaurant
    const restaurantPromotions = state?.adminRestaurants?.singleRestaurantPromotions

    // states
    const [loader, setLoader] = useState(true)
    const [toogleStatus, setToogleStatus] = useState(false)
    const [confirmToogle, setConfirmToogle] = useState(false)
    const [statusLoader, setStatusLoader] = useState(false)
    const [updateSubscriptionLoader, setUpdateSubscriptionLoader] = useState(false)
    const [restaurantSubscriptionData, setRestaurantSubscriptionData] = useState(initialData)
    const [showRatingPopup, setShowRatingPopup] = useState(false)

    // get dashboard cards data
    const getSingleRestaurantData = () => {
        let payload = {
            restaurant_id: restuarant_id
        }
        setLoader(true)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleRestaurantApiData(access_token, payload, success, fail))
    }

    // handle change of subscription data
    const handleSubscriptionDataChange = (event) => {
        const { name, value } = event.target
        setRestaurantSubscriptionData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // update restaurant status
    const changeRestaurantStatus = () => {
        setStatusLoader(true)
        let payload = {
            restaurant_id: restaurant?.id
        }
        const success = () => {
            setToogleStatus(!toogleStatus)
            setStatusLoader(false)
            setLoader(true)
            setConfirmToogle(false)
            getSingleRestaurantData()
        }
        const fail = () => {
            setStatusLoader(false)
        }
        dispatch(updateRestaurantToogleStatus(access_token, payload, success, fail))
    }

    // update restaurant status
    const updateRestaurantSubscriptionData = () => {
        let payload = {
            restaurant_id: restaurant?.id,
            subscription_charges: restaurantSubscriptionData?.subscription_charges,
            admin_percentage: restaurantSubscriptionData?.admin_percentage
        }
        const success = () => {
            setLoader(true)
            setUpdateSubscriptionLoader(false)
            getSingleRestaurantData()
        }
        const fail = () => {
            setUpdateSubscriptionLoader(false)
        }
        let requiredFields = { admin_percentage: restaurantSubscriptionData?.admin_percentage, }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setUpdateSubscriptionLoader(true)
            dispatch(updateRestaurantSubscriptionAndCommission(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        setRestaurantSubscriptionData({
            admin_percentage: Math.floor(restaurant?.admin_percentage),
            subscription_charges: restaurant?.subscription_charges
        })
    }, [restaurant])

    useEffect(() => {
        setToogleStatus(restaurant?.is_active == 1 ? true : false)
    }, [restaurant?.is_active])

    useEffect(() => {
        getSingleRestaurantData()
    }, [])

    return {
        restaurant, navigate, loader, toogleStatus, setToogleStatus, changeRestaurantStatus, confirmToogle, setConfirmToogle, statusLoader,
        restaurantPromotions, handleSubscriptionDataChange, restaurantSubscriptionData, updateRestaurantSubscriptionData,
        updateSubscriptionLoader, showRatingPopup, setShowRatingPopup, errors
    }
}

export default useAdminSingleRestaurant