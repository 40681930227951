import React from "react";
import { useHelper } from "./helper";
import moment from "moment";
import Svgs from "Assets/svgs";
import MobileVerifyInput from "Components/Elements/MobileVerifyInput";
import Button from "Components/Elements/Button";
import { maskEmail } from "Constants/Functions/maskEmail";
import Back from "Components/Elements/Back";

const OTPScreen = ({ step, setStep, setEmailStep, isReset, email, setShow, isUpdate, onResendClick, isNew, addBusiness }) => {
    const { navigate, resendPassswordOTP, otp, inp_1, inp_2, inp_3, inp_4, userEmail, count, isFinished, onChangeHandler, verifyOtp,
        loading, done
    } = useHelper(isReset, email, step, setStep, setEmailStep, isUpdate, isNew, onResendClick, addBusiness);

    return (
        <>
            {isReset ?
                <div className="px-2 flex  w-full">
                    <div className="gap-5 m-auto w-fit flex flex-col">
                        <div className="gap-1 flex flex-col">
                            {!isUpdate &&
                                <Back
                                    onClick={() => {
                                        if (setEmailStep) {
                                            setEmailStep(1)
                                        } else if (setStep) {
                                            setStep && setStep(1)
                                        } else {
                                            navigate(-1)
                                        }
                                    }}
                                    fill={'var(--secondary-color)'}
                                    noPadding={true}
                                    title={'Forgot Password'}
                                    titleClassName={'font-bold text-[1.5rem] text-white'}
                                />
                            }
                            <p className="text-white text-sm">
                                If you can't find the verfication email in your inbox, please check your span or junk folder

                                <a
                                    href={`mailto:${userEmail}`}
                                    className="text-white text-sm mx-auto w-fit text-center mt-6 flex items-center justify-center"
                                >
                                    {/* {maskEmail(userEmail || email)} */}
                                    {userEmail || email}
                                </a>
                            </p>
                        </div>
                        <div className="flex items-center justify-center gap-3 mt-0">
                            <MobileVerifyInput
                                name={"num1"}
                                value={otp.num1}
                                inp_ref={inp_1}
                                onChange={onChangeHandler}
                            />
                            <MobileVerifyInput
                                name={"num2"}
                                inp_ref={inp_2}
                                value={otp.num2}
                                onBackSpace={() => {
                                    if (otp.num2 == "" && inp_1 && inp_1.current) {
                                        inp_1.current.focus();
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value == "") {
                                        if (inp_1 && inp_1.current) {
                                            inp_1.current.focus();
                                        }
                                    }
                                    onChangeHandler(e);
                                }}
                            />
                            <MobileVerifyInput
                                name={"num3"}
                                inp_ref={inp_3}
                                value={otp.num3}
                                onBackSpace={() => {
                                    if (otp.num3 == "" && inp_2 && inp_2.current) {
                                        inp_2.current.focus();
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value == "") {
                                        if (inp_2 && inp_2.current) {
                                            inp_2.current.focus();
                                        }
                                    }
                                    onChangeHandler(e);
                                }}
                            />
                            <MobileVerifyInput
                                name={"num4"}
                                inp_ref={inp_4}
                                value={otp.num4}
                                onBackSpace={() => {
                                    if (otp.num4 == "" && inp_3 && inp_3.current) {
                                        inp_3.current.focus();
                                    }
                                }}
                                onChange={(e) => {
                                    if (e.target.value == "") {
                                        if (inp_3 && inp_3.current) {
                                            inp_3.current.focus();
                                        }
                                    }
                                    onChangeHandler(e);
                                }}
                            />
                        </div>
                        {/* <p className="text-white text-sm">
                            If you can't find the verification email in your inbox, please check your spam or junk folder.
                        </p> */}
                        {count > 0 && !isFinished ? (
                            <p className="text-white text-xs text-center">
                                You can resend code in
                                {" "}
                                <span className="text-secondary font-semibold">{moment.utc(count * 1000).format("mm:ss")}</span>{" "}
                            </p>
                        ) : (
                            <p className="text-center text-xs text-white">
                                Didn't received the code?{" "}
                                <span
                                    onClick={() => {
                                        if (onResendClick) {
                                            onResendClick()
                                            resendPassswordOTP(1)
                                        } else {
                                            resendPassswordOTP()
                                        }
                                    }}
                                    className="underline cursor-pointer text-secondary"
                                >
                                    Resend OTP
                                </span>{" "}
                            </p>
                        )}
                        <Button
                            disabled={
                                !Object.values(otp).every((x) => x !== "") ? true : false
                            }
                            onClick={() => {
                                inp_4.current.focus();
                                verifyOtp();
                            }}
                            loading={loading}
                            className={"mt-0 lg:mt-2"}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
                :
                <main className="w-fulll flex h-[100vh]">
                    {!done ?
                        <>
                            <div className="flex justify-center items-center py-10 flex-1 overflow-auto">
                                <div className="max-w-md w-full flex flex-col space-y-6 px-3">
                                    <div className="flex justify-center">
                                        <Svgs.AuthLogo width={'190'} height={'100'} />
                                    </div>
                                    <div className='bg-darkGrey rounded-3xl p-5'>
                                        <div className="px-4 py-5 flex w-full">
                                            <div className="gap-5 m-auto w-fit flex flex-col">
                                                <div className="gap-1 flex flex-col">
                                                    <div className="py-2 flex items-center gap-3">
                                                        <Svgs.BackIcon className={'cursor-pointer'} fill={'var(--secondary-color)'} stroke={'var(--secondary-color)'} onClick={() => {
                                                            if (setEmailStep) {
                                                                setEmailStep(1)
                                                            } else if (setStep) {
                                                                setStep && setStep(1)
                                                            } else {
                                                                navigate(-1)
                                                            }
                                                        }} />
                                                        <h1 className="font-bold text-[1.5rem] text-white">
                                                            OTP Verification
                                                        </h1>
                                                    </div>
                                                    <p className="text-white w-full text-sm">
                                                        We have sent a verification code at
                                                        <br />
                                                        <a
                                                            href={`mailto:${userEmail}`}
                                                            className="text-white text-sm mx-1 text-center pt-6 flex items-center justify-center"
                                                        >
                                                            {/* {maskEmail(userEmail || email)} */}
                                                            {userEmail || email}
                                                        </a>
                                                    </p>
                                                </div>
                                                <div className="flex items-center justify-center gap-3 ">
                                                    <MobileVerifyInput
                                                        name={"num1"}
                                                        value={otp.num1}
                                                        inp_ref={inp_1}
                                                        onChange={onChangeHandler}
                                                    />
                                                    <MobileVerifyInput
                                                        name={"num2"}
                                                        inp_ref={inp_2}
                                                        value={otp.num2}
                                                        onBackSpace={() => {
                                                            if (otp.num2 == "" && inp_1 && inp_1.current) {
                                                                inp_1.current.focus();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.target.value == "") {
                                                                if (inp_1 && inp_1.current) {
                                                                    inp_1.current.focus();
                                                                }
                                                            }
                                                            onChangeHandler(e);
                                                        }}
                                                    />
                                                    <MobileVerifyInput
                                                        name={"num3"}
                                                        inp_ref={inp_3}
                                                        value={otp.num3}
                                                        onBackSpace={() => {
                                                            if (otp.num3 == "" && inp_2 && inp_2.current) {
                                                                inp_2.current.focus();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.target.value == "") {
                                                                if (inp_2 && inp_2.current) {
                                                                    inp_2.current.focus();
                                                                }
                                                            }
                                                            onChangeHandler(e);
                                                        }}
                                                    />
                                                    <MobileVerifyInput
                                                        name={"num4"}
                                                        inp_ref={inp_4}
                                                        value={otp.num4}
                                                        onBackSpace={() => {
                                                            if (otp.num4 == "" && inp_3 && inp_3.current) {
                                                                inp_3.current.focus();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.target.value == "") {
                                                                if (inp_3 && inp_3.current) {
                                                                    inp_3.current.focus();
                                                                }
                                                            }
                                                            onChangeHandler(e);
                                                        }}
                                                    />
                                                </div>
                                                {/* <p className="text-white text-sm">
                                                                    If you can't find the verification email in your inbox, please check your spam or junk folder.
                                                                </p> */}
                                                {count > 0 && !isFinished ? (
                                                    <p className="text-white text-xs text-center">
                                                        You can resend code in
                                                        {" "}
                                                        <span className="text-secondary font-semibold">{moment.utc(count * 1000).format("mm:ss")}</span>{" "}
                                                    </p>
                                                ) : (
                                                    <p className="text-center text-xs text-white">
                                                        Didn't received the code?{" "}
                                                        <span
                                                            onClick={() => {
                                                                if (onResendClick) {
                                                                    onResendClick()
                                                                    resendPassswordOTP(1)
                                                                } else {
                                                                    resendPassswordOTP()
                                                                }
                                                            }}
                                                            className="underline cursor-pointer text-secondary"
                                                        >
                                                            Resend OTP
                                                        </span>{" "}
                                                    </p>
                                                )}
                                                <Button
                                                    disabled={
                                                        !Object.values(otp).every((x) => x !== "") ? true : false
                                                    }
                                                    onClick={() => {
                                                        inp_4.current.focus();
                                                        verifyOtp();
                                                    }}
                                                    loading={loading}
                                                    className={"mt-0 lg:mt-3"}
                                                >
                                                    Continue
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="md:block hidden flex-1">
                                <div className="w-full h-full flex items-center justify-center bg-[#282828] px-2 overflow-auto">
                                    <img className='h-[460px] ' src="/img/auth/recoverP.png" alt="" />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="flex items-center flex-col justify-center flex-1 h-full w-full overflow-auto">
                                <div className="flex justify-center">
                                    <Svgs.AuthLogo width={'190'} height={'100'} />
                                </div>
                                <div className='flex flex-col space-y-4 justify-center items-center text-center mb-8'>
                                    <Svgs.ConfirmationSvg width={'180'} />
                                    <h3 className='text-3xl text-white font-bold'>Thank you</h3>
                                    <p className='text-white'>Your account is being reviewed and we will notify you once it has been approved</p>
                                    <Button
                                        onClick={() => navigate('/login')}
                                    >
                                        Sign In
                                    </Button>
                                </div>
                                {/* <div className='bg-darkGrey rounded-3xl p-5 mt-6 max-w-md w-full'>
                                                <div className='flex flex-col space-y-3 py-4 '>
                                                    <h3 className='text-3xl text-white font-bold'>Congratulations</h3>
                                                    <p className='text-white pb-7 text-sm'>Your password has been successfully changed.</p>
                                                    <Button
                                                        onClick={() => navigate('/login')}
                                                    >
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </div> */}
                            </div>
                            <div className="md:block hidden flex-1">
                                <div className="w-full h-full flex items-center justify-center bg-[#282828] px-2 overflow-auto">
                                    <img className='h-[460px] ' src="/img/auth/congratulation.png" alt="" />
                                </div>
                            </div>
                            {/* <section className="w-fulll flex h-[100vh]">
                                            <div className="flex items-center justify-center flex-1 h-full w-full">
                                                <div className='flex flex-col space-y-3 py-4 '>
                                                    <h3 className='text-3xl text-white font-bold'>Congratulations</h3>
                                                    <p className='text-white pb-7 text-sm'>Your password has been successfully changed.</p>
                                                    <Button
                                                        onClick={() => navigate('/login')}
                                                    >
                                                        Sign In
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="md:block hidden flex-1">
                                                <div className="w-full h-full flex items-center justify-center bg-[#282828] px-2 overflow-auto">
                                                    <img className='h-[460px] ' src="/img/auth/congratulation.png" alt="" />
                                                </div>
                                            </div>
                                        </section> */}
                        </>
                    }
                </main>
            }

        </>
    );
};

export default OTPScreen;

export const RecaptchaContainer = () => {
    return <div id="recaptcha-container"></div>;
};
