import React from 'react'

const AccordianShimmer = ({ arrayLength }) => {
    const array = arrayLength ? arrayLength : [0, 1, 2, 3]
    return (
        array?.map((app, ind) => {
            return <>
                <div className="w-full mx-auto animate-pulse">
                    <div className={`rounded-lg my-4 bg-[#DFDFDF] border border-[#DFDFDF] h-16`}>
                    </div>
                </div>
            </>
        })
    )
}

export default AccordianShimmer