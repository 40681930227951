import { getWalletData } from "Adapters/APIs/Concierge/Wallet"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

const useMyWallet = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loading, setLoading] = useState(true)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [walletData, setWalletData] = useState()

    // get wallet data
    const getWallet = () => {
        let payload = {
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : ""
        }
        setLoading(true)
        const success = (data) => {
            setWalletData({
                total_booking_commission: data?.total_booking_commission,
                total_referral_commission: data?.total_referral_commission
            })
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        dispatch(getWalletData(access_token, payload, success, fail))
    }

    useEffect(() => {
        getWallet()
    }, [startDate, endDate])

    return {
        navigate, loading, startDate, setStartDate, endDate, setEndDate, walletData
    }

}

export default useMyWallet