import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const Restaurant = ({ data }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white bg-lightGrey capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Restaurant Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Invoice Number
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Invoice Amount
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Due Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Payment Status
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                View Invoice
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="bg-darkGrey border-b border-lightGrey cursor-pointer text-blueGrey">
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {moment(itm?.updated_at).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.user?.restaurant?.name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.number}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {moment(itm?.updated_at).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3 font-semibold whitespace-nowrap">
                                                {itm?.status}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                <div className='px-4 py-2 bg-secondary text-primary font-semibold max-h-[30px] w-fit flex gap-2 items-center rounded-md cursor-pointer'
                                                    onClick={() => { window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/invoice/${itm?.number}`, '_blank') }}>
                                                    View Invoice
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default Restaurant