import React from 'react'
import NotFound from 'Utility/NotFound'

const ConciergeRestaurantAnalytics = ({ data, type }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white bg-lightGrey capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                {`${type == 'concierges' ? "Concierge" : "Restaurant"} name`}
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total booking
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total platform commission
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total concierge commissions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="bg-darkGrey border-b border-lightGrey cursor-pointer text-blueGrey">
                                            <td class="px-6 py-3 whitespace-nowrap">
                                            {itm?.restaurant ? itm?.restaurant?.name : `${itm?.user?.first_name} ${itm?.user?.last_name}`}
                                            </td>
                                            <td class="px-6 py-3">
                                                {itm?.total_bookings}
                                            </td>
                                            <td class="px-6 py-3">
                                                {itm?.total_platform_earning}
                                            </td>
                                            <td class="px-6 py-3">
                                                {itm?.total_manager_earning}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default ConciergeRestaurantAnalytics