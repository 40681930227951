import { BASE_URL, get_restaurant_business, add_restaurant_business } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAllBusinessData, getAllBusinessDataTopbar } from "../../../Redux/Restaurant/Business/Actions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

// Function to show a single error message
const showSingleErrorMessage = (error) => {
    if (error && typeof error === 'object') {
        // Get the first key in the error object
        const firstKey = Object.keys(error)[0];

        if (firstKey && Array.isArray(error[firstKey]) && error[firstKey].length > 0) {
            // Get the first message for the first key
            const message = error[firstKey][0];

            // Show the error message
            toast.error(message, {
                toastId: 'toast' // Using a single toastId to ensure only one toast is shown
            });
        } else {
            // Handle case where error[firstKey] is not an array or is empty
            console.warn('No valid error message found.');
        }
    } else {
        // Handle case where error is not an object or is undefined
        console.warn('Error object is invalid.');
    }
};

// get all business
export const getRestaurantBusiness = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const quer = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_restaurant_business}${quer}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            if(payload?.topbar){
                dispatch(getAllBusinessDataTopbar(data))
            }else{
                dispatch(getAllBusinessData(data))
            }
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

// create new business
export const createNewBusinessAccount = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${add_restaurant_business}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        if (error?.response?.data?.status == 422) {
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else{
            toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
            fail && fail(error)
        }
        return error;
    }
}