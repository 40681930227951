import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { getAllNotifications, getLoginUserData } from 'Adapters/APIs/Authentication';
import useLogin from 'OnBaording/Login/helper';
import { saveDefaultRoute } from 'Utility/indexedDBUtils';
import Cookies from 'js-cookie';
import useLogoutHelper from 'Hooks/useLogoutHelper';
import { playSound } from 'Utility/Howl';

const useAppHelper = () => {
    const { successCallback, failCallback } = useLogin()
    const { logoutUser } = useLogoutHelper()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { getDataFromLocalStorage, storeDataInLocalStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const userData = getDataFromLocalStorage('user_data')
    const userLocation = getDataFromLocalStorage('userLocation')

    // states
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isInternetWorking, setIsInternetWorking] = useState(true);
    const [cookieValue, setCookieValue] = useState(Cookies.get('logoutUser'));
    const [notificationCookieValue, setNotificationCookieValue] = useState(Cookies.get('getNotificationData'));
    const [userCookieValue, setUserCookieValue] = useState(Cookies.get('getUserProfileData'));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // Timer and sound effect logic
    useEffect(() => {
        const checkTimers = () => {
            const timers = JSON.parse(localStorage.getItem('timers')) || [];
            const currentTime = Date.now();

            // Filter the timers, removing any that have passed and playing sound for expired ones
            const updatedTimers = timers.filter((timer) => {
                if (currentTime >= timer.alertTime) {
                    playSound('reservationAlert');  // Play the sound when the timer expires
                    return false;  // Remove the expired timer
                }
                return true;  // Keep the timer if it hasn't expired yet
            });

            if (updatedTimers.length > 0) {
                // Update the timers in localStorage if there are still timers left
                localStorage.setItem('timers', JSON.stringify(updatedTimers));
            } else {
                // Remove the 'timers' key if all timers are done
                localStorage.removeItem('timers');
            }
        };

        // Set up an interval to check every second
        const interval = setInterval(checkTimers, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // update network status on base of internet connection
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        return () => {
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
        };
    }, []);


    useEffect(() => {
        if (!userLocation) {
            // Check if the browser supports Geolocation API
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        storeDataInLocalStorage('userLocation', `${latitude},${longitude}`)
                    },
                    (err) => {
                        console.error(err.message);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        }
    }, []);

    useEffect(() => {
        // check internet is working or not
        if (isOnline) {
            // get user data
            getUserData();
            if (userCookieValue) {
                Cookies.remove('getUserProfileData');
            }
        } else {
            setIsInternetWorking(false);
        }
    }, [isOnline, location.pathname, userCookieValue]);

    useEffect(() => {
        // routes conditions on basis of roles
        if (access_token || localStorage.getItem('access_token')) {
            switch (userData?.role) {
                case 'Super Admin':
                    if (!location.pathname.startsWith('/admin/')) {
                        navigate('/admin/dashboard');
                        saveDefaultRoute('admin');
                    }
                    break;
                case 'User':
                    if (!location.pathname.startsWith('/concierge/')) {
                        navigate('/concierge/dashboard');
                        saveDefaultRoute('concierge');
                    }
                    break;
                case 'Admin':
                    if (!location.pathname.startsWith('/restaurant/')) {
                        navigate('/restaurant/dashboard');
                        saveDefaultRoute('restaurant');
                    }
                    break;
                default:
                    navigate("/login");
            }
        }
    }, [])

    useEffect(() => {
        const pathName = location.pathname;
        if (!pathName.includes('/concierge/restaurant')) {
            // Remove data from sessionStorage
            removeDataFromSessionStorage("filterData")
        }
    }, [location.pathname])

    useEffect(() => {
        // generateTestNotification() // open this for only test notification generation
        // get api for notification on every page change
        if (access_token) {
            getNotifications()
            if (notificationCookieValue) {
                Cookies.remove('getNotificationData');
            }
        }
    }, [access_token, location.pathname, notificationCookieValue])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newNotificationCookieValue = Cookies.get('getNotificationData');
            if (newNotificationCookieValue !== notificationCookieValue) {
                setNotificationCookieValue(newNotificationCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [notificationCookieValue]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCookieValue = Cookies.get('logoutUser');
            if (newCookieValue !== cookieValue) {
                setCookieValue(newCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [cookieValue]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newGetDataCookieValue = Cookies.get('getUserProfileData');
            if (newGetDataCookieValue !== userCookieValue) {
                setUserCookieValue(newGetDataCookieValue);
            }
        }, 1000); // Check every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [userCookieValue]);

    useEffect(() => {
        if (cookieValue) {
            logoutUser();
            Cookies.remove('logoutUser'); // remove the cookie after logout
        }
    }, [cookieValue]);

    // get notification data
    const getNotifications = () => {
        const success = () => { }
        const fail = () => { }
        dispatch(getAllNotifications(access_token, { page: 1 }, success, fail))
    }

    // update network status
    const updateNetworkStatus = () => {
        setIsOnline(navigator.onLine);
    };

    // get user data
    const getUserData = async () => {
        const success = (data) => {
            setIsInternetWorking(true)
            if (data?.user?.is_active == 0 || data?.user?.restaurant?.is_active == 0 || data?.user?.is_deleted == 1) {
                logoutUser();
            }
            successCallback && successCallback(data, true)
        }
        const fail = (error) => {
            if (error?.response?.status != 401) {
                setIsInternetWorking(false)
                failCallback && failCallback(error)
            } else if (error?.response?.status == 401) {
                if (access_token) {
                    logoutUser();
                }
            } else {
                setIsInternetWorking(true)
            }
        }
        dispatch(getLoginUserData(access_token, success, fail))
    };
    return {
        isInternetWorking, isOnline, screenWidth
    }
}

export default useAppHelper