import { getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard"
import { getUserAllBookings, sendBookingbyEmail } from "Adapters/APIs/Concierge/Bookings"
import { CancelRequestBooking, getSingleReservation } from "Adapters/APIs/Restaurant/Bookings"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

export const initialBookingForm = {
    restaurant_id: '',
    guest_name: '',
    total_pax: '',
    date: '',
    time: '',
    occasion: '',
    allergies: '',
    special_note: '',
}

const useBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { errors, setErrors, emailValidation, validation } = useValidations()

    const [searchParams] = useSearchParams();
    const reservation = searchParams.get('reservation');

    const { getDataFromLocalStorage, getDataFromSessionStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const booking_id = getDataFromSessionStorage('booking_id')

    // redux states
    const state = useSelector(state => state)
    const bookings = state?.bookings?.bookings
    const total_earning = state?.bookings?.total_booking
    const upcomming_bookings = state?.bookings?.upcomming_bookings
    const pagination_data = state?.bookings?.booking_pagination_data
    const restaurants = state?.adminDashboard?.dropdown_restaurants

    // states
    const [type, setType] = useState('total')
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [bookingPopup, setBookingPopup] = useState(false)
    const [bookingDetail, setBookingDetail] = useState(false)
    const [filterData, setFilterData] = useState()
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [bookingForm, setBookingForm] = useState(initialBookingForm)
    const [sendMailPopup, setSendMailPopup] = useState(false)
    const [email, setEmail] = useState('')
    const [sendEmailType, setSendEmailType] = useState('bookingByEmail')
    const [emailLoading, setEmailLoading] = useState(false)
    const [singleLoading, setSingleLoading] = useState(false)
    const [modificationLoading, setModificationLoading] = useState(false)

    // get Single Booking detail
    const getSingleBookingDetail = () => {
        const success = (data) => {
            setBookingForm(data)
            setSingleLoading(false)
        }
        const fail = () => {
            setSingleLoading(false)
        }
        setSingleLoading(true)
        dispatch(getSingleReservation(access_token, reservation, success, fail))
    }

    // get Concierge Booking
    const getBooking = () => {
        const success = () => {
            setLoading(false)
            setBookingPopup(false)
        }
        const fail = () => {
            setLoading(false)
        }
        setLoading(true)

        const payload = {
            page: currentPage,
            start_date: startDate,
            end_date: endDate,
            status: filterData?.status,
            restaurant: filterData?.restaurant_id,
        }
        dispatch(getUserAllBookings(access_token, payload, success, fail))
    }

    // Send booking details to email
    const sendBookingByEmail = (id) => {
        const success = () => {
            setEmailLoading(false)
            setSendMailPopup(false)
            setEmail('')
            setSendEmailType("bookingByEmail")
        }
        const fail = () => {
            setEmailLoading(false)
        }
        const payload = {
            email: email,
            booking_id: id,
            sendEmailType: sendEmailType
        }
        const err = validation({ email })
        setErrors(err)
        if (!err?.email) {
            setEmailLoading(true)
            dispatch(sendBookingbyEmail(access_token, payload, success, fail))
        }
    }

    // request modification
    const requestModification = (type, payload) => {
        const success = () => {
            setModificationLoading(false)
            getBooking()
            setBookingDetail(false)
        }
        const fail = () => { setModificationLoading(false) }
        type != 'billing' && setModificationLoading(type)
        type == 'cancel'
            && dispatch(CancelRequestBooking(access_token, payload, success, fail))
    }


    // Booking filtered Data upcomming and toal booking
    const allBookingData = useMemo(() => {
        return type == 'total' ? bookings : type == 'upcomming' ? upcomming_bookings : []
    }, [type, bookings, upcomming_bookings])

    useEffect(() => {
        getBooking()
    }, [currentPage, type, filterData, startDate, endDate])

    useEffect(() => {
        setLoading(true)
    }, [type])

    useEffect(() => {
        setCurrentPage(1)
    }, [type, filterData, startDate, endDate])

    // check for notification redirection
    useEffect(() => {
        if (booking_id && allBookingData?.length > 0) {
            const data = allBookingData?.find(itm => itm?.number == booking_id)
            setBookingForm(data)
            setBookingDetail(true)
            removeDataFromSessionStorage('booking_id')
        }
    }, [booking_id, allBookingData])

    useEffect(() => {
        dispatch(getDropdownRestaurantAPI())
    }, [])

    useEffect(() => {
        if (reservation) {
            setBookingDetail(true)
            getSingleBookingDetail()
            navigate('/concierge/booking', { replace: true });
        }
    }, [])


    return {
        type, setType, loading, currentPage, bookingForm, restaurants, setCurrentPage, bookingPopup, setBookingPopup, allBookingData,
        filterData, setFilterData, startDate, setStartDate, endDate, setEndDate, bookingDetail, setBookingDetail, setBookingForm,
        pagination_data, setErrors, getBooking, sendMailPopup, setSendMailPopup, sendBookingByEmail, email, setEmail, emailLoading,
        errors, emailValidation, total_earning, singleLoading, sendEmailType, setSendEmailType, modificationLoading, setModificationLoading,
        requestModification
    }

}

export default useBookings