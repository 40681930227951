import { GET_ALL_ANNOUNCED_REWARDS, GET_ALL_UPCOMMING_REWARDS } from "./ActionType";

const initialState = {
    announced_rewards: [],
    upcomming_rewards: [],
    ar_pagination: {},
}

const AdminRewardsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
     case GET_ALL_ANNOUNCED_REWARDS:
            return {
                ...state,
                announced_rewards: payload?.rewards,
                ar_pagination: payload?.ar_pagination,
            }
        case GET_ALL_UPCOMMING_REWARDS:
            return {
                ...state,
                upcomming_rewards: payload?.rewards,
            }
        default:
            return {
                ...state,
            };
    }
}

export default AdminRewardsReducer