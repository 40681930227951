import React from 'react'
import useEditProfile from './helper'
import LoginInput from 'Components/Elements/LoginInput'
import PhoneInput from 'Components/Elements/PhoneInput'
import Button from 'Components/Elements/Button'
import ChangeEmailPopup from './ChangeEmailPopup'
import ImageUploader from 'Components/Elements/ImageUploader'
import Dropdown from 'Components/Elements/Dropdown'
import AutoPlaceInput from 'Components/Elements/AutoPlaceInput'
import Textarea from 'Components/Elements/TextArea'
import EditRestaurantSlider from '../Elements/EditRestaurantSlider'
import EditRestaurantProfileShimer from 'Utility/Shimmers/EditRestaurantProfileShimer'
import Back from 'Components/Elements/Back'
import moment from 'moment'
import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import { error_message } from 'Constants/Functions/Variables'
import PaymentCheckboxCard from 'Components/Elements/PaymentCheckboxCard'
import MultiDropdown from 'Components/Elements/MultiDropdown'
import InfoIcon from 'Components/Elements/InfoIcon'
import { CATEGORY_DATA } from 'Constants/Data/TooltipData'
import AddCuisinePopup from 'OnBaording/Signup/RestaurantForm/AddCuisinePopup'
import FloatInput from 'Components/Elements/FloatInput'

const EditRestaurantProfile = ({ setIsEdit, editData, successCallback, loader, hideUpdateEmail, hideBackBtn, showRejectionReasons }) => {
    const {
        formData, errors, handleChange, loading, handleSubmit, updateEmail, setUpdateEmail, OTPLoading, setOTPLoading, requestOTP,
        emailValidation, cuisines, onImageRemove, navigate, user, otherOptions, OTHER_PAYMENT_METHODS, categories, addCuisine,
        setAddCuisine, getCuisineLoader, getCuisineData, handleGenerateDescription, setErrors, isProfileTabActive
    } = useEditProfile(setIsEdit, editData, successCallback)
    return (
        <>
            <div className="pb-6 topscroll">
                {loader ?
                    <EditRestaurantProfileShimer />
                    :
                    <>
                        {hideBackBtn ? "" :
                            <Back
                                title={'Profile'}
                                onClick={() => { setIsEdit ? setIsEdit(false) : navigate(-1) }}
                            />
                        }
                        <div className="pt-5 pb-5 gap-3 flex flex-col">
                            {(user?.rejection_thread?.length > 0 && showRejectionReasons) &&
                                <div className="flex flex-col gap-3 ">
                                    <h1 className='text-base text-white font-semibold'>Rejection Reason</h1>
                                    {user?.rejection_thread?.map((itm, ind) => {
                                        return (
                                            <div className='flex justify-between p-2 gap-4 bg-darkGrey rounded-lg'>
                                                <h1 className='text-sm text-secondary'>{itm?.message}</h1>
                                                <h1 className='text-sm text-blueGrey'>{moment(itm?.created_at).format('DD MMM YYYY')}</h1>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <div className="flex flex-col items-center gap-5 sm:gap-8 w-full">
                                <ImageUploader
                                    customHeight={'w-full h-52'}
                                    title="Upload Restaurant images"
                                    value={''}
                                    id={'image'}
                                    hideImage={true}
                                    description="Browse"
                                    name={'images'}
                                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                                    onChange={handleChange}
                                    mb_file_size={'10'}
                                    error={errors?.images}
                                    errorValidation={formData?.images?.length >= 5 ? true : false}
                                    errorText={"You can't add more than 5 images"}
                                />
                                {formData?.images?.length > 0 &&
                                    <div className='space-y-2 w-full'>
                                        <h1 className='text-blueGrey'>Profile Images</h1>
                                        <EditRestaurantSlider
                                            data={formData?.images}
                                            onChange={handleChange}
                                            onRemove={onImageRemove}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="flex flex-col gap-5">
                                <div className={`flex ${isProfileTabActive ? "lg:flex-row flex-col" : "sm:flex-row flex-col"} w-full justify-between items-center gap-x-3 gap-y-5`}>
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            required={true}
                                            title={'Restaurant Name'}
                                            placeholder={"Enter Restaurant Name"}
                                            value={formData?.restaurant_name}
                                            error={errors?.restaurant_name}
                                            name={'restaurant_name'}
                                            onChange={handleChange}
                                            inputPadding={"px-4 py-[9px]"}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Email Address'}
                                            required={true}
                                            readOnly={true}
                                            value={formData?.email}
                                            error={errors?.email}
                                            name={'email'}
                                            onChange={handleChange}
                                            rightIconClassName={!hideUpdateEmail && ' !bottom- !top-none !py-[9px]'}
                                            rightIcon={!hideUpdateEmail && <>
                                                <Button customPadding="!p-2" customThemeBtn={"flex items-center justify-center bg-lightGrey text-blueGrey !py-2.5 h-full"}
                                                    onClick={() => {
                                                        setUpdateEmail(true)
                                                        setOTPLoading(false)
                                                    }}
                                                >
                                                    Update
                                                </Button>
                                            </>}
                                            placeholder={"Enter your Email Address"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                    </div>
                                </div>
                                <div className="flex sm:flex-row flex-col justify-between items-center gap-x-3 gap-y-5 ">
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Main point of contact'}
                                            value={formData?.authorized_manager}
                                            error={errors?.authorized_manager}
                                            onChange={handleChange}
                                            name={'authorized_manager'}
                                            placeholder={"Enter main point of contact"}
                                            inputPadding={"px-4 py-[9px]"}
                                            required={false}
                                        />
                                    </div>
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Contact Person'}
                                            value={formData?.contact_person}
                                            error={errors?.contact_person}
                                            onChange={handleChange}
                                            name={'contact_person'}
                                            required={true}
                                            placeholder={"Enter Contact Person"}
                                            inputPadding={"px-4 py-[9px]"}
                                        />
                                    </div>
                                </div>
                                <div className='flex-1 w-full'>
                                    <FloatInput
                                        title={'Price Cover'}
                                        value={formData?.price_per_cover}
                                        error={errors?.price_per_cover}
                                        onChange={handleChange}
                                        name={'price_per_cover'}
                                        onlyNumericAllowed={true}
                                        leftIcon={currency}
                                        placeholder={"Enter price cover"}
                                        inputPadding={"pl-20 pr-4 py-[9px]"}
                                        required={false}
                                    />
                                </div>
                                <div>
                                    <MultiDropdown
                                        info_icon={<InfoIcon
                                            text={""}
                                            info={<div className='flex flex-col px-2 space-y-2 max-w-80 overflow-y-auto text-start max-h-96'>
                                                {CATEGORY_DATA?.map((itm, ind) => (
                                                    <div key={ind} className='flex flex-col space-y-1'>
                                                        <h4 className='font-bold text-white'>{itm?.label}:</h4>
                                                        <p className='text-blueGrey'>{itm?.value}</p>
                                                    </div>
                                                ))}
                                            </div>}
                                        />}
                                        title={'Select Categories'}
                                        value={formData?.categories || []}
                                        error={errors?.categories}
                                        onChange={handleChange}
                                        name={'categories'}
                                        required={true}
                                        options={categories}
                                        placeholder={"Select Categories"}
                                    />
                                </div>
                                <div className='flex gap-2 justify-between items-center'>
                                    <div className='flex-1'>
                                        <Dropdown
                                            title={'Select Cuisine'}
                                            value={formData?.cuisine_id}
                                            error={errors?.cuisine_id}
                                            onChange={handleChange}
                                            option={cuisines}
                                            name={'cuisine_id'}
                                            required={true}
                                            placeholder={"Select Cuisine"}
                                        />
                                    </div>
                                    <div className='pt-7'>
                                        <Button
                                            text={<Svgs.BoldPlusIcon width={"45"} height={'40'} />}
                                            onClick={() => {
                                                setAddCuisine(true)
                                            }}
                                            className="w-full !py-0.5 !pl-2 !pr-2.5 bg-[#282828] border-[1.5px] border-lightGrey"
                                        />
                                    </div>
                                </div>
                                <div className="flex sm:flex-row flex-col justify-between items-center gap-x-3 gap-y-5 ">
                                    <div className='flex-1 w-full'>
                                        <PhoneInput
                                            readOnly={false}
                                            value={formData?.phone_number}
                                            error={errors?.phone_number}
                                            name={'phone_number'}
                                            disabled={true}
                                            onChange={handleChange}
                                            title={'Phone Number'}
                                            placeholder={'Enter Phone Number'}
                                            required={false}
                                        />
                                    </div>
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Website Link'}
                                            placeholder={'Enter website link'}
                                            value={formData?.website_link}
                                            error={errors?.website_link}
                                            name={'website_link'}
                                            onChange={handleChange}
                                            inputPadding={"px-4 py-[9px]"}
                                            required={false}
                                        />
                                    </div>
                                </div>
                                <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Instagram Link'}
                                            placeholder={'Enter Instagram link'}
                                            value={formData?.instagram_link}
                                            error={errors?.instagram_link}
                                            name={'instagram_link'}
                                            onChange={handleChange}
                                            inputPadding={"px-4 py-[9px]"}
                                            required={false}
                                        />
                                    </div>
                                <div className="flex sm:flex-row flex-col justify-between items-center gap-x-3 gap-y-5 ">
                                    <div className='flex-1 w-full'>
                                        <PhoneInput
                                            required={false}
                                            readOnly={false}
                                            value={formData?.mobile_number}
                                            error={errors?.mobile_number}
                                            name={'mobile_number'}
                                            onChange={handleChange}
                                            title={'Mobile Number'}
                                            placeholder={'Enter Mobile Number'}
                                        />
                                    </div>
                                    <div className='flex-1 w-full'>
                                        <PhoneInput
                                            readOnly={true}
                                            value={formData?.wa_number}
                                            error={errors?.wa_number}
                                            name={'wa_number'}
                                            onChange={handleChange}
                                            title={'WhatsApp Number'}
                                            placeholder={'Enter WhatsApp Number'}
                                        />
                                    </div>
                                </div>
                                <div className='relative'>
                                    <div className={`text-sm text-blueGrey flex items-center gap-1`}>
                                        <div>Payment Method to EasyRSV</div><span className="leading-[1] text-red-600"> *</span>
                                    </div>
                                    <div className='grid grid-cols-2 gap-5 py-2'>
                                        {otherOptions?.length > 0 ? (
                                            otherOptions?.map((item, index) => {
                                                let lft_icon = OTHER_PAYMENT_METHODS?.find(
                                                    (itm) => itm.name == item.method_type
                                                );
                                                return (
                                                    <PaymentCheckboxCard
                                                        key={index}
                                                        leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[1.25rem] w-[1.25rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} alt='' />}
                                                        checked={item?.method_type == formData?.amount_collection}
                                                        onClick={() => {
                                                            handleChange({
                                                                target: {
                                                                    name: 'amount_collection',
                                                                    value: item?.method_type
                                                                }
                                                            })
                                                        }}
                                                        borderColor={'border-lightGrey'}
                                                        id={item.method_type}
                                                        title={
                                                            <div className='flex items-center gap-2'>
                                                                <p className='font-semibold text-md'>{item.label}</p>
                                                            </div>
                                                        }
                                                    />
                                                )
                                            }))
                                            :
                                            ""
                                        }
                                    </div>
                                    {errors?.amount_collection &&
                                        <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                                            <Svgs.I fill="#eb3b3b" /> {error_message}
                                        </p>
                                    }
                                </div>
                                {formData?.amount_collection == 'cash' &&
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Cash collection date & time'}
                                            placeholder={'Enter your prefered cash collection Date And time'}
                                            value={formData?.amount_collection_description}
                                            error={errors?.amount_collection_description}
                                            name={'amount_collection_description'}
                                            onChange={handleChange}
                                            inputPadding={"px-4 py-[9px]"}
                                            required={true}
                                        />
                                    </div>
                                }
                                <div className="flex sm:flex-row flex-col justify-between items-center gap-x-3 gap-y-5 ">
                                    <div className='flex-1 w-full'>
                                        <AutoPlaceInput
                                            value={formData?.address}
                                            error={errors?.address}
                                            onChange={handleChange}
                                            setErrors={setErrors}
                                        />
                                    </div>
                                    <div className='flex-1 w-full'>
                                        <LoginInput
                                            title={'Tax Registration Number'}
                                            value={formData?.trn_number}
                                            error={errors?.trn_number}
                                            onChange={handleChange}
                                            name={'trn_number'}
                                            placeholder={"Enter tax registration number"}
                                            inputPadding={"px-4 py-[9px]"}
                                            required={false}
                                        />
                                    </div>
                                </div>
                                {/* <CheckBox
                                    checked={formData?.booking_cancellation_terms}
                                    error={errors?.booking_cancellation_terms}
                                    name={'booking_cancellation_terms'}
                                    onChange={handleChange}
                                    required={false}
                                    label={'Credit card required for booking confirmation'}
                                    title={"Booking cancellation Terms"}
                                /> */}
                                <Textarea
                                    title={'Description'}
                                    value={formData?.description}
                                    error={errors?.description}
                                    name={'description'}
                                    onChange={handleChange}
                                    placeholder={"Enter Description"}
                                    contentAboveInput={<div className='flex justify-between gap-4 items-center text-sm bg-lightGrey rounded-t-[10px]'>
                                        <p className='text-blueGrey px-3'>Smart description generator</p>
                                        <div className='bg-secondary rounded-tr-[10px] rounded-bl-[10px] px-1 py-0.5 cursor-pointer'
                                            onClick={handleGenerateDescription}>
                                            Generate
                                        </div>
                                    </div>}
                                    noLimit
                                />

                                <Textarea
                                    title="Booking Policy"
                                    placeholder="Enter booking policy"
                                    parent="w-full"
                                    noLimit={true}
                                    name="terms_and_conditions"
                                    value={formData?.terms_and_conditions}
                                    errorMessage={formData?.terms_and_conditions}
                                    onChange={handleChange}
                                    textAreaClass='resize-y'
                                />

                            </div>
                            <div className="flex flex-col justify-between items-center gap-x-3 gap-y-5 ">
                                <div className={`flex flex-col gap-2 flex-1 w-full`}>
                                    <ImageUploader
                                        id={'agreement'}
                                        onChange={handleChange}
                                        name={'agreement'}
                                        error={errors?.agreement}
                                        value={formData?.agreement}
                                        title={'Upload partnership agreement'}
                                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF"}
                                        heading={'Partnership Agreement'}
                                        hideEdit={(editData?.restaurant?.agreement || user?.restaurant?.agreement) ? true : false}
                                    />
                                </div>
                                <div className={`flex flex-col gap-2 flex-1 w-full`}>
                                    <ImageUploader
                                        id={'trade_license'}
                                        onChange={handleChange}
                                        name={'trade_license'}
                                        error={errors?.trade_license}
                                        value={formData?.trade_license}
                                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF"}
                                        title={'Upload trade license'}
                                        heading={'Trade License'}
                                        hideEdit={(editData?.restaurant?.trade_license || user?.restaurant?.trade_license) ? true : false}
                                    />
                                </div>
                            </div>

                            <div className="md:pt-5 pt-10 flex justify-end">
                                <Button
                                    loading={loading}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </>
                }
            </div>
            <ChangeEmailPopup
                open={updateEmail}
                close={setUpdateEmail}
                email={formData?.email}
                onSuccess={requestOTP}
                btnLoading={OTPLoading}
                errors={errors}
                setIsEdit={setIsEdit}
                emailValidation={emailValidation}
            />

            <AddCuisinePopup
                open={addCuisine}
                close={setAddCuisine}
                getCuisineLoader={getCuisineLoader}
                getCuisineData={getCuisineData}
            />
        </>
    )
}

export default EditRestaurantProfile