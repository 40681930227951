import { postTradeLicense } from "Adapters/APIs/Authentication";
import { createNewBusinessAccount } from "Adapters/APIs/Restaurant/Business";
import { getAllCategories, getAllCuisine } from "Adapters/APIs/restaurants";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify";

const initialState = {
    restaurant_name: '',
    categories: [],
    cuisine_id: '',
    first_name: '',
    authorized_manager: '',
    email: '',
    phone_code: '',
    phone_number: '',
    mobile_code: '',
    mobile_number: '',
    address: '',
    trade_license: "",
    terms_condition: false,
    privacy_policy: false,
};

const useCreateBusiness = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { storeDataInSessionStorage, getDataFromSessionStorage, removeDataFromSessionStorage, getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const { errors, setErrors, validation, validateNumber, emailValidation } = useValidations()

    // states
    const [formData, setFormData] = useState(initialState);
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [addCuisine, setAddCuisine] = useState(false)
    const [getCuisineLoader, setGetCuisineLoader] = useState(true)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)

    // redux states
    const state = useSelector(state => state)
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    const cuisines = useMemo(() => {
        return cuisinse?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [cuisinse])

    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [categoriesData])

    useEffect(() => {
        getCuisineData()
        getCategories()
    }, [])

    useEffect(() => {
        let businessData = getDataFromSessionStorage("businessData");
        if (businessData) {
            setFormData(businessData)
        } else {
            setFormData(initialState)
        }
        setErrors()
        setStep(1)
    }, [])

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'phone_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                mobile_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                phone_number: value,
                phone_code: value?.split('-')[0]?.replace('+', '')
            })
        }
        if (name == 'mobile_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                phone_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                mobile_number: value,
                mobile_code: value?.split('-')[0]?.replace('+', '')
            })
        }

        if (name == 'email') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            emailValidation(name, value);
            setFormData({
                ...formData,
                [name]: value
            })
        }
        if (name == 'trade_license') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.files[0]
            })
        }
        if (name == 'privacy_policy' || name == 'terms_condition') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        if (name != 'phone_number' && name != 'mobile_number' && name != 'email' && name != 'privacy_policy' && name != 'terms_condition'
            && name != 'trade_license') {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // handle submit form
    const handleSubmit = () => {
        let requiredFields = {}
        const { first_name, email, cuisine_id, phone_number, mobile_number, restaurant_name, address, country, categories,
            state, city, authorized_manager, terms_condition, privacy_policy, trade_license
        } = formData

        let payload = { ...formData }
        requiredFields = {
            restaurant_name,
            categories: categories?.length == 0 ? false : true,
            cuisine_id,
            first_name,
            authorized_manager,
            email,
            phone_number: phone_number ? phone_number : mobile_number ? true : false,
            phone_code: phone_number ? phone_number : mobile_number ? true : false,
            mobile_code: mobile_number ? mobile_number : phone_number ? true : false,
            mobile_number: mobile_number ? mobile_number : phone_number ? true : false,
            address, state, country, city, trade_license,
            terms_condition, privacy_policy
        }
        setErrors((prev) => ({
            ...prev,
            ...validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, })
        }));
        let result = validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, });
        if (objectKeyConvertToArray(result)?.length === 0 && !errors?.password_confirmation) {
            const success = (data) => {
                storeDataInSessionStorage('businessData', { ...formData, user_id: data?.id })
                setStep(2)
                // navigate('/otp-verification')
                setLoading(false)
            }
            const fail = (error) => {
                setLoading(false)
                if (error?.response?.data?.status == 422) {
                    if (error?.response?.data?.error?.email || error?.response?.data?.error?.mobile_number) {
                        setErrors((prev) => ({
                            ...prev,
                            'email': error?.response?.data?.error?.email ? error?.response?.data?.error?.email[0] : false,
                            'mobile_number': error?.response?.data?.error?.mobile_number ? error?.response?.data?.error?.mobile_number[0] : false,
                            'phone_number': error?.response?.data?.error?.phone_number ? error?.response?.data?.error?.phone_number[0] : false
                        }));
                        setStep(1)
                    }
                }
            }
            if (payload?.phone_number) {
                payload = {
                    ...payload,
                    phone_number: formData?.phone_number?.split('-')[1]
                }
            }
            if (payload?.mobile_number) {
                payload = {
                    ...payload,
                    mobile_number: formData?.mobile_number?.split('-')[1]
                }
            }
            payload = {
                ...payload,
                // type: role == 'restaurant' ? 1 : "0"
            }
            if (formData?.categories?.length > 0) {
                const formattedCategories = categories.reduce((acc, value, index) => {
                    acc[`categories[${index}]`] = value;
                    return acc;
                }, {});
                payload = {
                    ...payload,
                    ...formattedCategories
                }
            }
            delete payload.categories
            if (payload?.phone_number == payload?.mobile_number) {
                setErrors((prev) => ({
                    ...prev,
                    'mobile_number': "Mobile number and Phone number must be different"
                }));
                toast.error("Mobile number and Phone number must be different", { toastId: "toast" })
            } else {
                submitFuncntion(payload, success, fail)
            }
        }
    };

    const submitFuncntion = (payload, success, fail) => {
        removeDataFromSessionStorage('signupData')
        setLoading(true)
        dispatch(createNewBusinessAccount(access_token, payload, success, fail))
    }
    
    // get cuisine
    const getCuisineData = (existingId) => {
        const success = () => {
            if (existingId) {
                setFormData({
                    ...formData,
                    'cuisine_id': existingId
                })
                setErrors((prev) => ({
                    ...prev,
                    'cuisine_id': '',
                }));
            }
            setAddCuisine(false)
            setGetCuisineLoader(false)
        }
        const fail = () => {
            setGetCuisineLoader(false)
        }
        setGetCuisineLoader(true)
        dispatch(getAllCuisine('', success, fail))
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    return {
        handleSubmit, handleChange, formData, errors, setErrors, cuisines, loading, addCuisine, setAddCuisine, getCuisineLoader,
        getCuisineData, categories, step, setStep

    }
}

export default useCreateBusiness