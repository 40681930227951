import { getAdminRestaurantApiData } from "Adapters/APIs/Admin/restaurant";
import { getAllCategories, getAllCuisine } from "Adapters/APIs/restaurants";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getAdminSingleRestaurantsData } from "../../../Redux/Admin/Restaurants/Action";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

let timeOutId = undefined
const useConciergeRestaurant = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const { getDataFromLocalStorage, storeDataInSessionStorage, removeDataFromSessionStorage, getDataFromSessionStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const categoryFromLocal = getDataFromLocalStorage('category')
    const userLocation = getDataFromLocalStorage('userLocation')
    const filterDataFromSession = getDataFromSessionStorage('filterData')

    // redux states
    const state = useSelector(state => state)
    const allRestaurants = state?.adminRestaurants?.restaurants
    const pagination_data = state?.adminRestaurants?.pagination_data
    const featured = state?.adminRestaurants?.featured_restaurants
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    // states
    const [viewDetail, setViewDetail] = useState(false)
    const [createBookingPopup, setCreateBookingPopup] = useState()
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(true)
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [filterPopup, setFilterPopup] = useState(false)
    const [filterData, setFilterData] = useState()
    const [featureLoader, setFeatureLoader] = useState(true)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [appliedFilter, setAppliedFilter] = useState({})

    const Cuisines = useMemo(() => {
        return cuisinse?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [cuisinse])

    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [categoriesData])

    // on FIlter Change
    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        setFilterData({
            ...filterData,
            [name]: value
        })
    }

    // get dashboard cards data
    const getRestaurants = (type, clear) => {
        let filterQueryData = filterDataFromSession ? filterDataFromSession : filterData
        let payload = {
            page: type == 'featured' ? 1 : type ? type : currentPage,
            search_text: searchText,
            is_featured: type == 'featured' ? 1 : 0,
            category: categoryFromLocal ? categoryFromLocal : !clear ? filterQueryData?.category : '',
            cuisine_id: !clear ? filterQueryData?.cuisine_id : '',
            price_per_cover: !clear ? filterQueryData?.price_per_cover : '',
            user_location: !clear ? filterQueryData?.radius && userLocation : '',
            sort_by: !clear ? filterQueryData?.sort_by : '',
            google_rating: !clear ? filterQueryData?.google_rating : '',
            tripadvisor_rating: !clear ? filterQueryData?.tripadvisor_rating : '',
        }
        delete payload.radius
        if (filterQueryData?.sort_by == 'name') {
            payload = {
                ...payload,
                sort_order: "asc"
            }
        }
        if (filterQueryData?.sort_by == 'distance') {
            payload = {
                ...payload,
                radius: !clear ? filterQueryData?.radius : '',
            }
            delete payload.sort_by
        }

        setFilterPopup(false)
        const success = () => {
            setLoader(false)
            setSecondLoader(false)
            setTimeout(() => {
                removeDataFromSessionStorage('filterData')
            }, 500);
        }
        const featuredSuccess = () => {
            setFeatureLoader(false)
        }
        const fail = () => {
            setFeatureLoader(false)
            setLoader(false)
            setSecondLoader(false)
        }
        if (type != 1 || filterQueryData?.radius || filterQueryData?.sort_by || filterQueryData?.sort_order || filterQueryData?.category || filterQueryData?.cuisine_id || filterQueryData?.price_per_cover
            || filterQueryData?.google_rating || filterQueryData?.tripadvisor_rating) {
            if (currentPage == 1 && type == '1') {
                setLoader(true)
            }
            if (type == 'featured') {
                setFeatureLoader(true)
            }
            if (clear) {
                setAppliedFilter({})
            } else {
                if (type != 'featured') {
                    setAppliedFilter({
                        category: payload?.category,
                        cuisine_id: payload?.cuisine_id,
                        price_per_cover: payload?.price_per_cover,
                        radius: payload?.radius,
                        sort_by: payload?.sort_by,
                        google_rating: payload?.google_rating,
                        tripadvisor_rating: payload?.tripadvisor_rating,
                    })
                }
            }
            dispatch(getAdminRestaurantApiData(access_token, payload, type == 'featured' ? featuredSuccess : success, fail))
        } else {
            setAppliedFilter({})
            setFilterPopup(false)
        }
    }

    const storeRestaurant = (data) => {
        storeDataInSessionStorage('filterData', filterData)
        navigate(`/concierge/restaurant/${data?.id}`)
        dispatch(getAdminSingleRestaurantsData({
            retaurant: data
        }))
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    // getCuisine
    useEffect(() => {
        if (categoryFromLocal) {
            setFilterData((prev) => ({
                ...prev,
                category: categoryFromLocal
            }))
            setTimeout(() => {
                localStorage.removeItem('category')
            }, 300);
        }
        dispatch(getAllCuisine())
        getCategories()
    }, [])

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getRestaurants()
            getRestaurants('featured')
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getRestaurants()
                getRestaurants('featured')
            }, 500);
        }
    }, [searchText, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    useEffect(() => {
        if (filterDataFromSession) {
            setFilterData(filterDataFromSession)
        }
    }, [location.pathname])

    return {
        allRestaurants, navigate, searchText, setSearchText, pagination_data, loader, secondLoader, currentPage, setCurrentPage,
        viewDetail, setViewDetail, createBookingPopup, setCreateBookingPopup, filterData, setFilterData, filterPopup, setFilterPopup,
        onChangeHandler, getRestaurants, Cuisines, storeRestaurant, featured, featureLoader, categories, storeDataInSessionStorage,
        removeDataFromSessionStorage, appliedFilter
    }
}

export default useConciergeRestaurant