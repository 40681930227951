import { getDropdownRestaurantAPI } from 'Adapters/APIs/Admin/dashboard'
import { getAllCategories, getAllCuisine } from 'Adapters/APIs/restaurants'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

let timeOutId = undefined

const useRestaurantFilter = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // redux states
    const state = useSelector(state => state)
    const restaurants = state?.adminDashboard?.dropdown_restaurants
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const userLocation = getDataFromLocalStorage('userLocation')

    // states
    const [loader, setLoader] = useState(true)
    const [searchText, setSearchText] = useState("")
    const [filterPopup, setFilterPopup] = useState(false)
    const [filterData, setFilterData] = useState()
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)

    // on FIlter Change
    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        setFilterData({
            ...filterData,
            [name]: value
        })
    }

    // get dashboard cards data
    const getRestaurants = (type, clear) => {
        let payload = {
            search_text: !clear ? searchText : '',
            category: !clear ? filterData?.category : '',
            cuisine_id: !clear ? filterData?.cuisine_id : '',
            price_per_cover: !clear ? filterData?.price_per_cover : '',
            user_location: !clear ? filterData?.radius && userLocation : '',
            radius: !clear ? filterData?.radius : '',
            google_rating: !clear ? filterData?.google_rating : '',
            tripadvisor_rating: !clear ? filterData?.tripadvisor_rating : '',
            sort_by: !clear ? filterData?.sort_by : '',
        }
        delete payload.radius
        if (filterData?.sort_by == 'name') {
            payload = {
                ...payload,
                sort_order: "asc"
            }
        }
        if (filterData?.sort_by == 'distance') {
            payload = {
                ...payload,
                radius: !clear ? filterData?.radius : '',
            }
            delete payload.sort_by
        }

        setFilterPopup(false)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        if (type != 1 || filterData?.radius || filterData?.sort_by || filterData?.sort_order || filterData?.category || filterData?.cuisine_id || filterData?.price_per_cover
            || filterData?.google_rating || filterData?.tripadvisor_rating) {
            setLoader(true)
            dispatch(getDropdownRestaurantAPI('', payload, success, fail))
        } else {
            setFilterPopup(false)
        }
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }


    useEffect(() => {
        dispatch(getAllCuisine())
        getCategories()
    }, [])

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getRestaurants()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getRestaurants()
            }, 500);
        }
    }, [searchText])


    const Cuisines = useMemo(() => {
        return cuisinse?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [cuisinse])

    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [categoriesData])
    return {
        filterPopup, setFilterPopup, filterData, setFilterData, getRestaurants, Cuisines, loader, onChangeHandler, restaurants, navigate,
        searchText, setSearchText, categories
    }
}

export default useRestaurantFilter