import LabelAvatar from 'Components/Elements/LabelAvatar'
import React from 'react'

const StaffDataShimmer = () => {
    return (
        <div className='flex gap-2 items-center border-b border-lightGrey pb-4 animate-pulse'>
            <LabelAvatar loader={true} />
            <div>
                <div className='h-6 w-20 bg-gray-200 rounded-md'></div>
                <div className='h-6 w-52 bg-gray-200 mt-2 rounded-md'></div>
            </div>
        </div>
    )
}

export default StaffDataShimmer