import React from 'react'

const ViewAllBtn = ({ title,className, onClick }) => {
    return (
        <button className={`${className} rounded-lg md:rounded-[10px] border border-secondary text-secondary text-xs font-semibold py-2 px-6`}
            onClick={onClick && onClick}>
            {title ? title : "View All"}
        </button>
    )
}

export default ViewAllBtn