export const ACCOUNT_TYPE = "account_type";
export const SIGNUP_TYPE = "signup_type";
export const EVERYONE = "everyone";
export const BUSINESS = "business";
export const GMAIL = "gmail";
export const EMAIL = "email";
export const GOOGLE = "google"
export const FACEBOOK = "facebook";
export const PERSONAL_INFORMATION = "personal_information";
export const ACTIVES = "actives";
export const VERIFICATION = "verification";
export const BUSINESS_DETIALS = "business_detail";
export const BUSINESS_TYPES = "business_types";
export const TEAM_SIZE = "team_size";
export const SOFTWARES = "softwares";
export const OPENING_HOURS = "opening_hours";
export const FIND_US = "find_us";
export const error_message = "This field is required";


