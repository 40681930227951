import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import React from 'react'

const UserCard = ({ winner }) => {
    return (
        <div className={`flex flex-col w-full rounded-lg p-4 gap-2 mb-3 justify-between bg-darkGrey`}>
            <h1 className='font-semibold text-white'> {winner?.first_name} {winner?.last_name} </h1>
            <h1 className='text-secondary' > {winner?.business_name} </h1>
            <h1 className='flex gap-3 items-center text-blueGrey'>
                <div><Svgs.PhoneIcon stroke={'var(--secondary-color)'} /></div>
                +{winner?.mobile_code} {winner?.mobile_number}
            </h1>
            <h1 className='flex gap-3 items-center text-blueGrey'>
                <div><Svgs.MailIcon stroke={'var(--secondary-color)'} /></div>
                {winner?.email}
            </h1>
            <hr className='border-lightGrey' />
            <h1 className='text-blueGrey'>Total Booking: <span className='text-secondary'>{winner?.reservations_count}</span> </h1>
            <h1 className='text-blueGrey'>Total Revenue: <span className='text-secondary'>{winner?.reservations_sum_commission} {currency}</span></h1>
            <h1 className='text-blueGrey'>Total Commissions: <span className='text-secondary'>{winner?.reservations_sum_manager_earning} {currency}</span></h1>
        </div>
    )
}

export default UserCard