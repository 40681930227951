import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminUnapprovedConcierge from './helper'
import EditConciergeProfile from 'Pages/Concierges/Profile/EditProfile'

const EditConciergeByAdmin = () => {
    const { concierge, navigate, loader } = useAdminUnapprovedConcierge()

    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <EditConciergeProfile
                    loader={loader}
                    editData={concierge}
                    hideUpdateEmail={true}
                    successCallback={() => { navigate(`/admin/unapproved-concierge/${concierge?.id}`) }}
                />
            </main>
        </DashboardLayout>
    )
}

export default EditConciergeByAdmin