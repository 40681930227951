import { getAnnoncedConciergeReward, getUpcommingConciergeReward } from 'Adapters/APIs/Concierge/Details'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useRewards = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // redux states
    const state = useSelector(state => state)
    const announced_rewards = state?.concierge?.announced_rewards
    const upcomming_rewards = state?.concierge?.upcomming_rewards

    // states
    const [type, setType] = useState('conciergesOfTheMonth')
    const [loading, setLoading] = useState(true)
    const [upcomingLoading, setUpcomingLoading] = useState(true)
    const [winnerDetail, setWinnerDetail] = useState(false)

    // get announce rewards
    const getAnnouncedRewards = () => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        setLoading(true)
        let payload = {}
        dispatch(getAnnoncedConciergeReward(access_token, payload, success, fail))
    }

    // get upcomming rewards
    const getUpcommingRewards = () => {
        const success = () => { setUpcomingLoading(false) }
        const fail = () => { setUpcomingLoading(false) }
        setUpcomingLoading(true)
        let payload = {}
        dispatch(getUpcommingConciergeReward(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAnnouncedRewards()
        getUpcommingRewards()
    }, [])

    return {
        type, setType, loading, announced_rewards, upcomming_rewards, upcomingLoading, navigate, winnerDetail, setWinnerDetail
    }

}

export default useRewards