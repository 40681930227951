import React, { useState } from 'react'
import Svgs from 'Assets/svgs'
import Slider from '../Elements/Slider'
import Button from 'Components/Elements/Button'
import CreateBooking from 'Pages/Concierges/Bookings/CreateBookings'
import { useNavigate } from 'react-router-dom'
import DashboardLayout from 'Components/DashboardLayout'
import useSingleRestaurant from './helper'
import SingleAdminRestaurantDetailShimmer from 'Utility/Shimmers/SingleAdminRestaurantDetailShimmer'
import Back from 'Components/Elements/Back'
import SingleRestaurantRatingPupup from 'Pages/Admin/Restaurants/SingleRestaurant/SingleRestaurantRatingPupup'
import { currency } from 'Adapters/variables'
import RestaurantBusinessHour from 'Pages/Admin/Restaurants/SingleRestaurant/SingleRestaurantRatingPupup/RestaurantBusinessHour'
import { toast } from 'react-toastify'

const RestaurantDetail = () => {
    const { showRatingPopup, setShowRatingPopup, loader, restaurant, showBusinessHour, setShowBusinessHour } = useSingleRestaurant()
    const {
        id, name, address, ratings, average_rating, total_rating, categories, price_per_cover, cuisine, images, terms_and_conditions, description, phone_code,
        phone_number, email, website_link, business_hours, booking_cancellation_terms, mobile_number, mobile_code, instagram_link
    } = restaurant
    const [createBookingPopup, setCreateBookingPopup] = useState()
    const navigate = useNavigate()

    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {loader ?
                        <SingleAdminRestaurantDetailShimmer />
                        : <div className="pt-4 space-y-5">
                            <Back title={name} />
                            {/* <div className='flex justify-between gap-2'>
                                <div className='flex flex-col space-y-2  text-sm'>
                                    <div className="flex gap-2 items-center text-[#b0b0b0]">
                                        <span><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></span>
                                        <span className="font-extralight">{address}</span>
                                    </div>
                                </div>
                            </div> */}
                            <div className='grid grid-cols-1 justify-between items-center gap-4'>
                                <div className='flex flex-col space-y-2'>
                                    {/* <h1 className='text-white'>Profile Images</h1> */}
                                    <Slider data={images} cardClass={"h-60"} />
                                </div>
                            </div>
                            <div className='flex md:flex-row flex-col justify-between gap-4 items-center'>
                                <div className='flex flex-1 gap-4 items-center'>
                                    <div className="bg-darkGrey rounded-full flex flex-col gap-3 items-center justify-between py-3 xs:px-6 px-2 cursor-pointer" onClick={() => {
                                        if (ratings) {
                                            setShowRatingPopup('rating')
                                        } else {
                                            toast.error('No rating Found', { toastId: 'toast' })
                                        }
                                    }}>
                                        <div className="flex gap-2 items-center">
                                            <Svgs.StarIcon />
                                            <h1 className='font-semibold text-blueGrey xs:text-base text-sm'>View Ratings</h1>
                                        </div>
                                    </div>
                                    <div className="bg-darkGrey rounded-full flex flex-col gap-3 items-center justify-between py-3 xs:px-6 px-2 cursor-pointer" onClick={() => { navigate(`/concierge/restaurant-feedback/${id}`) }}>
                                        <div className="flex gap-2 items-center">
                                            {average_rating > 0 && <Svgs.StarIcon />}
                                            <h1 className='font-semibold text-blueGrey xs:text-base text-sm'>{average_rating} ({total_rating ? total_rating : 0} Reviews)</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <Button
                                        onClick={() => {
                                            setShowBusinessHour(!showBusinessHour)
                                        }}
                                        className={'w-full sm:w-fit flex-end bg-lightGrey'}
                                    >
                                        <Svgs.ClockIcon stroke={'var(--blueGrey-color)'} />
                                    </Button>
                                    <Button
                                        customPadding={'!p-2 gap-2'}
                                        onClick={() => { navigate(`/concierge/restaurant-menu/${id}`) }}
                                        title={<>
                                            <Svgs.MenuIcon fill={'var(--primary-color)'} />
                                            Menu
                                        </>}
                                    />
                                </div>
                            </div>
                            <div className='grid lg:grid-cols-4 xs:grid-cols-2 grid-cols-1 gap-4'>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.PhoneIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{phone_number && phone_number != 'null' ? `+${phone_code} ${phone_number}` : mobile_number && mobile_number != 'null' ? `+${mobile_code} ${mobile_number}` : "----"}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.MailIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{email}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.WebGlobe fill={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{website_link ? website_link : "No URL"}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.InstagramLink width={20} height={20} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{instagram_link ? instagram_link : "No URL"}</p>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 gap-3'>
                                <div className='grid grid-cols-1 sm:grid-cols-3'>
                                    <div className="flex flex-col">
                                        <h1 className='text-white'>Price per cover:</h1>
                                        <p className='font-medium text-blueGrey'>{price_per_cover ? `${price_per_cover} ${currency}` : "-----"} </p>
                                    </div>
                                    <div className="flex flex-col">
                                        <h1 className='text-white'>Cuisine:</h1>
                                        <p className='font-medium text-blueGrey'>{cuisine?.name ? cuisine?.name : "-----"}</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <h1 className="text-white">Category:</h1>
                                        <p className="font-medium text-blueGrey break-words">
                                            {categories?.length > 0 ? categories.map((itm, index) => (
                                                <span key={index}>
                                                    {itm?.name}{index !== categories.length - 1 && ", "}
                                                </span>
                                            )) : "-----"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <h1 className='text-white'>Description:</h1>
                                    <p className='text-blueGrey'>{description ? description : "-----"}</p>
                                </div>
                                <div className="flex flex-col">
                                    <h1 className='text-white'>Booking cancellation terms:</h1>
                                    <p className='text-blueGrey'>{booking_cancellation_terms ? "Credit card required for booking" : "Credit card not required for booking"}</p>
                                </div>
                                <div className="flex flex-col">
                                    <h1 className='text-white'>Terms and Conditions:</h1>
                                    <p className='text-blueGrey whitespace-pre-wrap'>{terms_and_conditions ? terms_and_conditions : "-----"}</p>
                                </div>
                            </div>
                            <div className='flex gap-2 justify-end'>
                                <Button
                                    onClick={() => {
                                        setCreateBookingPopup(id)
                                    }}
                                    text={'Book now'}
                                    className={'w-full sm:w-fit flex-end'}
                                />
                            </div>
                        </div>
                    }
                </main>
                {createBookingPopup && <CreateBooking
                    selectRestaurant={createBookingPopup}
                    close={() => setCreateBookingPopup()}
                />}
                {(showRatingPopup == 'rating') &&
                    <SingleRestaurantRatingPupup
                        open={showRatingPopup}
                        close={setShowRatingPopup}
                        ratingData={ratings}
                    />
                }

                <RestaurantBusinessHour
                    open={showBusinessHour}
                    close={setShowBusinessHour}
                    data={business_hours}
                />
            </DashboardLayout>
        </>
    )
}

export default RestaurantDetail