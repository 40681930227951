import React from 'react'

const EditRestaurantProfileShimer = () => {
    return (
        <>
            <div className="flex gap-3 items-center animate-pulse">
                <div className='rounded-full bg-gray-200 h-12 w-12'></div>
                <div className="h-10 w-36 bg-gray-200 rounded-md"></div>
            </div>
            <div className="pt-10 pb-5 gap-3 flex flex-col">
                <div className="flex flex-col items-center gap-5 sm:gap-8 w-full h-56 rounded-md bg-gray-200">
                </div>
                <div className="flex flex-col gap-5">
                    <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col w-full justify-between items-center gap-x-3 gap-y-5 ">
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                        <div className='flex flex-1 w-full !flex-col gap-2'>
                            <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                            <div className='w-full bg-gray-200 h-10 rounded-md'>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-1 w-full !flex-col gap-2'>
                        <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                        <div className='w-full bg-gray-200 h-10 rounded-md'>
                        </div>
                    </div>
                    <div className='flex flex-1 w-full !flex-col gap-2'>
                        <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                        <div className="w-full bg-gray-200 h-32 rounded-md">
                        </div>
                    </div>
                </div>
                <div className="flex sm:flex-row flex-col justify-between items-center gap-x-3 gap-y-5 ">
                    <div className={`flex flex-col gap-2 flex-1 w-full`}>
                        <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                        <div className="w-full bg-gray-200 h-32 rounded-md">
                        </div>
                    </div>
                    <div className={`flex flex-col gap-2 flex-1 w-full`}>
                        <div className="h-6 w-36 bg-gray-200 rounded-md"></div>
                        <div className="w-full bg-gray-200 h-32 rounded-md">
                        </div>
                    </div>
                </div>
                <div className="pt-5 flex justify-end">
                    <div className="h-10 w-24 bg-gray-200 rounded-md"></div>
                </div>
            </div>
        </>
    )
}

export default EditRestaurantProfileShimer