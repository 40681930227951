import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import SupportRequestCardShimmer from 'Utility/Shimmers/SupportRequestCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import moment from 'moment'
import useBoostPayments from './helper'
import PromotionCard from 'Pages/Admin/PromotionRequest/Elements/PromotionCard'
import Back from 'Components/Elements/Back'

const BoostPayments = () => {
    const { data, navigate, paginationData, loader, currentPage, setCurrentPage, startDate, setStartDate, endDate, setEndDate,
        activeTab, setActiveTab, setDataDetail } = useBoostPayments()
    return (
        <DashboardLayout active={'financials'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex justify-between items-center gap-4'>
                    <Back
                        title={'Boost Payments'}
                    />
                    <DateRangePicker
                        startDate={startDate}
                        className="!max-w-none"
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        maxDate={new Date()}
                    />
                </div>
                <div className="border-b my-5">
                    <div className="flex items-center gap-6">
                        <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "approved" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                            onClick={() => {
                                setActiveTab("approved");
                            }}
                        >
                            <h2>Approved</h2>
                        </div>
                        <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "unapproved" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                            onClick={() => {
                                setActiveTab("unapproved");
                            }}
                        >
                            <h2>
                                Pending
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="!m-0">
                    {loader
                        ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 p-4">
                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                return <SupportRequestCardShimmer />
                            })}
                        </div>
                        : data?.length > 0 ?
                            <InfiniteScroll
                                dataLength={data?.length ? data?.length : 10} //This is important field to render the next data
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }}
                                hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                loader={((paginationData?.current_page !== paginationData?.total_pages))
                                    && <div className="flex justify-center items-center my-4">
                                        <PreloaderSm />
                                    </div>}
                            >
                                <div className="!m-0 grid grid-cols-1 sm:grid-cols-2 gap-5">
                                    {data?.map(itm => {
                                        return <PromotionCard
                                            onCardClick={() => { setDataDetail(itm) }}
                                            name={itm?.restaurant?.name}
                                            title={itm?.package?.name}
                                            amount={itm?.package?.price}
                                            featuredDate={`${moment(itm?.featured_from).format('DD MMM YYYY')} - ${moment(itm?.featured_to).format('DD MMM YYYY')}`}
                                            bought_at={moment(itm?.created_at).format('DD MMM YYYY')}
                                            description={itm?.package?.description}
                                            approved={itm?.is_approved == 1 ? true : false}

                                        //imageSrc, showImage, proof, number, onConfirmClick,
                                        // confirmLoader
                                        />
                                    })}
                                </div>
                            </InfiniteScroll>
                            : <NotFound />
                    }
                </div>
            </main>
            {/* {
                dataDetail &&
                <DetailPromotionrequest
                    open={dataDetail}
                    close={setDataDetail}
                    data={dataDetail}
                    successCallback={callDataForFirstTime}
                    id={dataDetail?.package?.id}
                />
            } */}
        </DashboardLayout >
    )
}

export default BoostPayments