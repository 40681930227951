import { getAllPromotion } from "Adapters/APIs/Concierge/Details"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

const usePromotion = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const navigate = useNavigate()

    const promotions = state?.concierge?.promotions
    const pagination_data = state?.concierge?.promotion_pagination

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const restaurant_id = queryParams.get('restaurant_id');

    const access_token = getDataFromLocalStorage('access_token')
    const [currentPage, setCurrentPage] = useState(1)
    const [promotionloading, setPromotionLoading] = useState(true)
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [createBookingPopup, setCreateBookingPopup] = useState(false);

    const getPromotions = () => {
        if (currentPage == 1) {
            setPromotionLoading(true)
        }
        const payload = {
            page: currentPage,
            end_date: endDate,
            start_date: startDate,
            restaurant_id: restaurant_id,
        }
        dispatch(getAllPromotion(access_token, payload, '', '', setPromotionLoading))
    }

    useEffect(() => {
        getPromotions()
    }, [currentPage, restaurant_id, endDate, startDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [restaurant_id, endDate, startDate])

    return {
        promotionloading, promotions, navigate, pagination_data, currentPage, setCurrentPage,
        endDate, setEndDate, startDate, setStartDate, createBookingPopup, setCreateBookingPopup,
    }
}

export default usePromotion