import React from 'react';

function CheckBox({
    parentClass,
    disabled,
    label_text_transform,
    mainLabelClass,
    label_Class,
    name,
    onChange,
    value,
    checked,
    error,
    label,
    colorLabel1,
    colorLabel2,
    className,
    id,
    onClickLabel1,
    MainClassName,
    onClickLabel2,
    required,
    title
}) {
    const unique_id = Math.floor(Math.random() * 100) * Date.now();

    const handleClickLabel1 = (event) => {
        event.stopPropagation(); // Prevents the click event from bubbling up
        if (onClickLabel1) {
            onClickLabel1();
        }
    };

    const handleClickLabel2 = (event) => {
        event.stopPropagation(); // Prevents the click event from bubbling up
        if (onClickLabel2) {
            onClickLabel2();
        }
    };

    return (
        <div className={`${MainClassName} flex flex-col gap-2 `}>

            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}

            <div className={`flex items-center sm:gap-4 gap-2 ${parentClass ? parentClass : ""}`}>
                <input
                    disabled={disabled}
                    id={`${id ? id : unique_id}`}
                    type="checkbox"
                    name={name}
                    onChange={onChange}
                    value={value}
                    checked={checked}
                    className={`${className ? className : `accent-secondary h-[1.3rem] w-[1.3rem]`} ${disabled && "cursor-not-allowed"} `}
                    style={{
                        backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 20 20\'%3e%3cpath fill=\'none\' stroke=\'%23fff\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'2\' d=\'M6 10l3 3l6-6\'/%3e%3c/svg%3e")'
                    }}
                    autoComplete="off"
                />
                <div className={`relative ${mainLabelClass}`} onClick={(e) => e.stopPropagation()}>
                    <p className={`text-sm font-medium cursor-pointer text-blueGrey ${label_Class && label_Class} ${label_text_transform ? label_text_transform : 'normal-case'}`}>
                        {label}
                        <span
                            className='text-secondary font-semibold'
                            onClick={handleClickLabel1}
                        >
                            {colorLabel1}
                        </span>
                        {colorLabel2 && (
                            <span>
                                <span>&</span>
                                <span
                                    className='text-blueGrey'
                                    onClick={handleClickLabel2}
                                >
                                    {colorLabel2}
                                </span>
                            </span>
                        )}
                    </p>
                </div>
                {error && <span className='text-error text-xs'>{error}</span>}
            </div>
        </div>
    );
}

export default CheckBox;
