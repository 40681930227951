import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Image from 'Components/Elements/Image';

export default function Slider({ data, cardClass, slidesPerView }) {
    return (
        <>
            <div>
                {data?.length > 0 ?
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={slidesPerView ? slidesPerView : 1}
                        autoplay={true}
                    >
                        {data?.map((itm, index) => (
                            <SwiperSlide key={index}>
                                <div className={`${cardClass} h-[200px]`}>
                                    <Image className='h-full w-full object-cover rounded-lg' src={itm?.url} alt={index} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper> :
                    <Swiper
                        modules={[Autoplay]}
                        autoplay={true}
                        slidesPerView={1}
                    >
                        <SwiperSlide >
                            <div className={`${cardClass} h-[200px]`}>
                                <Image className='h-full w-full object-cover rounded-lg' src={''} alt="dummy" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                }
            </div>
        </>
    );
}