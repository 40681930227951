import React from 'react'
import { DOTS, usePagination } from './helper';
import Button from 'Components/Elements/Button';

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        showing,
        loading
    } = props;


    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange?.length - 1];

    props.setLastPage && props.setLastPage(paginationRange[paginationRange?.length - 1])

    return (
        <div>
            {/* Pagination  */}
            <nav aria-label="Page navigation example" >
                <div className="flex rounded-b-lg flex-col md:flex-row bg-primary flex-wrap gap-5 items-center md:w-auto w-full md:items-baseline md:justify-between py-6 sm:px-6">
                    {loading ?
                        <div className='bg-gray-500 rounded-md h-4 w-60'></div> 
                        : <p className="text-blueGrey text-sm">{showing}</p>}
                    <div className="flex sm:flex-nowrap flex-wrap items-center gap-2">
                        <Button
                            disabled={currentPage === 1}
                            onClick={onPrevious}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                            <span className="hidden sm:block">Back</span>
                        </Button>

                        <div className="flex items-center gap-2">
                            {paginationRange?.map(pageNumber => {
                                if (pageNumber === DOTS) {
                                    return <div className="pagination-item dots text-blueGrey">&#8230;</div>;
                                }
                                return (
                                    <Button
                                        disabled={pageNumber === currentPage}
                                        onClick={() => onPageChange(pageNumber)}
                                        customThemeBtn={pageNumber === currentPage ? '' : "view-edit-btn"}
                                    >

                                        {pageNumber}
                                        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                                        </span>
                                    </Button>
                                )
                            })
                            }
                        </div>
                        <Button
                            disabled={currentPage === lastPage}
                            onClick={onNext}
                        >
                            <span className="hidden sm:block">Next</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                            </svg>
                        </Button>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Pagination



