import React from 'react'

const LabelAvatar = ({ name, loader }) => {
    return (
        <>
            {loader ?
                <div className={`flex items-center justify-center capitalize w-14 h-14 rounded-full bg-gray-200 text-secondary font-semibold animate-pulse`}>
                </div> :
                <div className={`flex items-center justify-center capitalize w-14 h-14 rounded-full bg-darkGrey text-secondary font-semibold`}>
                    {name ? name?.charAt(0) : "T"}
                </div>
            }
        </>

    )
}

export default LabelAvatar