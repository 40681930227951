import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useSingleRestaurantMenu from './helper'
import Accordion from 'Components/Elements/Accordian'
import ButtonShimmer from 'Utility/Shimmers/ButtonShimmer'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import DownloadMenuPopup from './DownloadMenuPopup'

const SingleRestaurantMenu = ({ active }) => {
    const { menuData, loader, activeTab, setActiveTab, pdfMenuLoader, pdfMenudata, menuPopup, setMenuPopup } = useSingleRestaurantMenu()
    return (
        <DashboardLayout active={active ? active : 'Restaurant'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <div className='flex justify-between items-center gap-4'>
                    <Back
                        title={'Menu'}
                    />
                    {pdfMenuLoader ?
                        <ButtonShimmer />
                        :
                        <Button
                            text={'Pdf Menu'}
                            onClick={() => { setMenuPopup(!menuPopup) }}
                            customPadding={"px-4 py-2"} />
                    }

                </div>
                <div className="!m-0">
                    <div className="border-b mt-2">
                        <div className="flex items-center gap-6">
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "MainMenu" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("MainMenu");
                                }}
                            >
                                <h2>Main Menu</h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "BeverageMenu" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("BeverageMenu");
                                }}
                            >
                                <h2>
                                    Bevearge Menu
                                </h2>
                            </div>
                            <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "DessertMenu" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                onClick={() => {
                                    setActiveTab("DessertMenu");
                                }}
                            >
                                <h2>
                                    Desset Menu
                                </h2>
                            </div>
                        </div>
                    </div>
                    <Accordion
                        items={menuData}
                        showEdit={false}
                        contentClass={'relative'}
                        loader={loader}
                        showBorder={false}
                        type={activeTab}
                        showCardBackground={false}
                    />
                    {(menuData?.length == 0 && !loader) &&
                        <NotFound />}
                </div>
            </main>
            <DownloadMenuPopup
                open={menuPopup}
                close={setMenuPopup}
                data={pdfMenudata}
            />
        </DashboardLayout>
    )
}

export default SingleRestaurantMenu