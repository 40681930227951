import { currency } from 'Adapters/variables'
import React from 'react'

const DashboardCard = ({ amount1, title1, amount2, hideCurrency, headingClassName, title2, amount3, title3, btnText, onAllClick, className, customPadding, text1Class, showViewDetail, hideCurrency2 }) => {
    return (
        <div className={`relative bg-darkGrey p-4 rounded-[10px] cursor-pointer ${className}`} onClick={onAllClick && onAllClick}>
            {showViewDetail && <div className='absolute right-0 top-0 bg-secondary py-2.5 px-4 rounded-bl-md rounded-tr-md text-sm font-bold'>
                {btnText ? btnText : "View Detail"}
            </div>}
            <div className={`flex justify-between w-full text-blueGrey gap-4 ${customPadding ? customPadding : "pt-10 px-4 py-6"}`}>
                <div className={`flex ${headingClassName} flex-col items-center w-11/12 mx-auto gap-1`}>
                    <h4 className="font-semibold text-lg text-secondary">{amount1 || amount1 === 0 ? `${amount1} ${hideCurrency ? "" : currency}` : ""} </h4>
                    <p className={`${text1Class} text-center text-sm`}>{title1}</p>
                </div>
                {(title2 || amount2) &&
                    <>
                        <div className='bg-lightGrey w-0.5' />
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                            <h4 className="font-semibold text-lg text-secondary">{amount2 || amount2 === 0 ? `${amount2} ${hideCurrency2 ? "" : currency}` : ""}</h4>
                            <p className="text-center text-sm">{title2}</p>
                        </div>
                    </>
                }
                {(title3 || amount3) &&
                    <>
                        <div className='bg-lightGrey w-0.5' />
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                            <h4 className="font-semibold text-lg text-secondary">{amount3 || amount3 === 0 ? `${amount3} ${currency}` : ""}</h4>
                            <p className="text-center text-sm">{title3}</p>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

DashboardCard.defaultProps = {
    showViewDetail: true
}

export default DashboardCard