import DashboardLayout from 'Components/DashboardLayout'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import PromotonCard from 'Components/PromotionCard'
import NotFound from 'Utility/NotFound'
import PromotionCardShimmer from 'Utility/Shimmers/PromotionCardShimmer'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import usePromotion from './helper'
import Back from 'Components/Elements/Back'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import CreateBooking from '../Bookings/CreateBookings'

const AllPromotions = () => {
    const { promotionloading, promotions, pagination_data, currentPage, setCurrentPage,
        endDate, setEndDate, startDate, setStartDate, createBookingPopup, setCreateBookingPopup,
    } = usePromotion()
    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64  h-auto pt-20 min-h-screen ">
                <div className="flex justify-between items-center pt-4 space-y-5">
                    <Back title={'Promotions'} />
                    <DateRangePicker
                        startDate={startDate}
                        className="!max-w-none"
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        maxDate={new Date()}
                    />
                </div>
                <div className="!m-0 py-6">

                    {promotionloading
                        ? <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center gap-5">
                            {[0, 1, 2, 3, 4].map(index => (
                                <PromotionCardShimmer key={index} />
                            ))}
                        </div>
                        : promotions?.length > 0
                            ? <InfiniteScroll
                                dataLength={promotions?.length ? promotions?.length : 10} //This is important field to render the next data
                                next={() => {
                                    setCurrentPage(+currentPage + 1)
                                }}
                                hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                loader={
                                    <div className="flex justify-center w-full items-center my-7">
                                        <PreloaderSm />
                                    </div>
                                }
                            >
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                                    {promotions?.map(itm => {
                                        return <PromotonCard
                                            key={itm?.id}
                                            url={itm?.url}
                                            className='h-full'
                                            end_date={itm?.end_date}
                                            discount={itm?.discount}
                                            start_date={itm?.start_date}
                                            description={itm?.description}
                                            restaurantName={itm?.restaurant?.name}
                                            onClick={() => setCreateBookingPopup(itm?.restaurant?.id)}
                                        />
                                    })}
                                </div>
                            </InfiniteScroll>
                            : <NotFound />
                    }
                </div>
                {createBookingPopup && <CreateBooking
                    selectRestaurant={createBookingPopup}
                    close={() => setCreateBookingPopup()}
                />}
            </main>
        </DashboardLayout >
    )
}

export default AllPromotions