import { BASE_URL, get_staff_data, get_wishlist, update_wishlist_staff } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import axios from "axios";
import { toast } from "react-toastify";


// get admin wishlist data
export const getAdminWishlistData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_wishlist}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// get admin staff members data
export const getStaffData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_staff_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// update wishlist staff member
export const updateWishlistStaff = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_wishlist_staff}${payload?.wishlistId}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}