import Button from 'Components/Elements/Button'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminAnnounceReward from './helper'
import NotFound from 'Utility/NotFound'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import UserCard from '../Elements/UserCard'
import UserCardShimmer from '../Elements/UserCardShimmer'

const AnnounceReward = ({ open, close, announceReward, successCallBack }) => {
    const { loader, secondLoader, paginationData, topPerformers, currentPage, setCurrentPage, announeRewardApi, loading
    } = useAdminAnnounceReward({ announceReward, close, successCallBack })
    return (
        <Popup
            open={open}
            close={close}
            heading={"Announce reward"}
            customSizeStyle={'sm:w-[40vw] md:w-[50vw] lg:w-[40vw]'}
        >
            <div className="flex flex-col space-y-5">
                <div className="space-y-3">
                    {topPerformers?.length > 0 &&
                        <h1 className='font-semibold text-white'>Top performer for reward</h1>
                    }
                        {loader
                            ?
                            <>
                                {[0, 1, 2, 3]?.map(itm => {
                                    return <UserCardShimmer />
                                })}
                            </>
                            :
                            topPerformers?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={topPerformers?.length ? topPerformers?.length : 10} //This is important field to render the next data
                                    next={() => {
                                        setCurrentPage(currentPage + 1)
                                    }}
                                    hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                    loader={secondLoader
                                        ? <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div> : ""
                                    }
                                    // height={'20rem'}
                                >
                                    {topPerformers?.map(itm => {
                                        return <UserCard
                                            data={itm}
                                            winner={itm}
                                            isAnnounced={true}
                                        />
                                    })}
                                </InfiniteScroll>
                                : <NotFound />
                        }
                </div>
                {topPerformers?.length > 0 &&
                    <Button
                        title={"Announce Reward"}
                        loading={loading}
                        disabled={loading}
                        onClick={announeRewardApi}
                    />
                }
            </div>
        </Popup>
    )
}

export default AnnounceReward