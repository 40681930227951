import React from 'react'

const ActiveCard = ({ active, onClick, heading, svgIcon }) => {
    return (
        <div className={`p-8 border-2 bg-darkGrey ${active ? "border-secondary" : "border-lightGrey"} cursor-pointer flex flex-1 flex-col justify-center items-center gap-2 rounded-lg`} onClick={onClick && onClick}>
            {svgIcon}
            <p className='font-semibold text-sm text-blueGrey'>{heading}</p>
        </div>
    )
}

export default ActiveCard