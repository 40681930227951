import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const ConciergeBookingTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[800px]">
                    <thead className="text-sm text-white bg-primary border-b border-table capitalize">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Date
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Restaurant
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Guest
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Total Bill
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Total Platform Commission
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap">
                                Concierge Earning
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <tr className="bg-primary border-b border-table cursor-pointer text-blueGrey">
                                        <td className="px-6 py-4">
                                            {itm?.number}
                                        </td>
                                        <th scope="row" className="px-6 py-4 font-semiBold text-blueGrey whitespace-nowrap ">
                                            {moment(itm?.date_time)?.format('YYYY-MM-DD')}
                                        </th>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {itm?.restaurant?.name}
                                        </td>
                                        <td className="px-6 py-4">
                                            <span className="bg-lightGrey p-1 h-6  w-fit min-w-6 rounded-md text-secondary text-sm font-normal flex justify-center items-center">
                                                {itm?.total_pax}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4">
                                            {itm?.amount}
                                        </td>
                                        <td className="px-6 py-4">
                                            {itm?.platform_earning}
                                        </td>
                                        <td className="px-6 py-4">
                                            {itm?.manager_earning}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default ConciergeBookingTable