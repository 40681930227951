import { getAdminSingleRestaurantApiData } from "Adapters/APIs/Admin/restaurant";
import { getAllRestaurents } from "Adapters/APIs/restaurants";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminSingleRestaurantsData } from "../../../../Redux/Admin/Restaurants/Action";

const useSingleRestaurant = () => {
    const dispatch = useDispatch()

    const { restuarant_id } = useParams();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const restaurant = state?.adminRestaurants?.singleRestaurant

    // states
    const [loader, setLoader] = useState(true)
    const [showRatingPopup, setShowRatingPopup] = useState(false)
    const [showBusinessHour, setShowBusinessHour] = useState(false)

    // store restaurant 
    const storeRestaurant = (data) => {
        dispatch(getAdminSingleRestaurantsData({
            retaurant: data
        }))
    }


    // get single restaurants
    const getAllRestaurantsData = () => {
        let payload = {
            restaurant_id: restuarant_id,
            get_single_restaurant: true
        }
        setLoader(true)
        const success = (data) => {
            setLoader(false)
            storeRestaurant(data[0])
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAllRestaurents(access_token, payload, success, fail, setLoader))
    }

    useEffect(() => {
        if (restaurant?.id) {
            setLoader(false)
        } else {
            getAllRestaurantsData()
        }
    }, [restaurant, restuarant_id])

    return {
        showRatingPopup, setShowRatingPopup, loader, restaurant, showBusinessHour, setShowBusinessHour
    }
}

export default useSingleRestaurant