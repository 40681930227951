import { GET_USER_DETAILS, GET_USER_NOTIFICATIONS } from "./ActionType";

export const getUserDetail = (data) => {
    return {
        type: GET_USER_DETAILS,
        payload: data,
    };
};
export const getAllNotificationsAction = (data) => {
    return {
        type: GET_USER_NOTIFICATIONS,
        payload: data,
    };
};