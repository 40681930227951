import React, { useState } from 'react';
import DummyImage from "../../Assets/images/error2.png"
import useCustomLazyLoading from 'Hooks/useCustomLazyLoading';
import ImageShimmer from 'Utility/Shimmers/ImageShimmer';

const Image = ({ src, className, alt, fallback, showDummy, customLoaderHeight, customLoaderClass, loading }) => {
    const imageLoading = useCustomLazyLoading(src)
    const [broken, setBroken] = useState(false);

    const handleImageError = () => {
        setBroken(true);
    };

    return (
        <>
            {!imageLoading || loading ?
                <ImageShimmer customHeight={customLoaderHeight} className={customLoaderClass} />
                : broken || !src ? (
                    <img className={className} src={showDummy ? DummyImage : fallback ? fallback : "/img/logo3.svg"} alt="fallback" />
                ) : (
                    <img src={src} className={className} alt={alt ? alt : "Hotel Conierge"} onError={handleImageError} />
                )}
        </>
    );
};

export default Image;
