import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import Dropdown from 'Components/Elements/Dropdown'
import { BookingAnalyticsTimePeriod } from 'Constants/Data/Categories'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import Concierge from 'Utility/Tables/AdminFinanceTable/Concierge'
import Back from 'Components/Elements/Back'
import useAdminPendingPayables from './helper'

const AdminPendingPayables = () => {
    const { financeData, paginationData, loader, currentPage, setCurrentPage, monthValue, setMonthValue, filter, setFilter
    } = useAdminPendingPayables()
    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex gap-4 justify-between items-center'>
                    <Back
                        title={'Pending Payables'}
                    />
                    <div className="flex flex-wrap gap-3 float-end items-center justify-end">
                        <div>
                            <Dropdown
                                title={''}
                                placeholder={'Select Time period'}
                                option={BookingAnalyticsTimePeriod}
                                onChange={(e) => setFilter(e?.target?.value)}
                                value={filter}
                            />
                        </div>
                        {filter == 'date' &&
                            <ReactMonthPicker
                                onChange={(e) => setMonthValue(e?.target?.value)}
                                value={monthValue}
                                name={'month'}
                                isClearable={true}
                            />
                        }
                    </div>
                </div>
                <div className="!m-0 flex flex-col">
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg  border border-lightGrey">
                                    {loader
                                        ? <BookingTableShimer columns={7} />
                                        :
                                        <Concierge data={financeData} showPayBtn />
                                    }
                                    {financeData?.length > 0
                                        && <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={financeData?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </DashboardLayout>
    )
}

export default AdminPendingPayables