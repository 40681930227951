import { updateOrAddAdminPromotion } from "Adapters/APIs/Admin/promotions";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useAdminCreateEditPromotions = ({ editData, close, successCallBack }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [packageData, setPackageData] = useState({
        name: "",
        description: "",
        duration: "",
        price: "",
        type: "Restaurant",
        status: true,
        image: ""
    })

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == 'reward') {
            setPackageData((prev) => ({
                ...prev,
                [name]: event.target.files[0]
            }))
        } else if (name == 'date') {
            setPackageData((prev) => ({
                ...prev,
                [name]: event.target.startDate
            }))
        } else {
            setPackageData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }

    // update or add promotion/package data
    const updateOrAddPromotionData = () => {
        let payload = {
            id: editData?.id,
            name: packageData?.name,
            description: packageData?.description,
            duration: packageData?.duration,
            price: packageData?.price,
            type: packageData?.type?.toLowerCase(),
            image: packageData?.image == editData?.image ? "" : packageData?.image,
            status: packageData?.status ? 1 : 0
        }
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            close && close(false)
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            name: packageData?.name,
            description: packageData?.description,
            duration: packageData?.duration,
            price: packageData?.price,
        }
        setErrors(validation(requiredData));
        let result = validation(requiredData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoader(true)
            dispatch(updateOrAddAdminPromotion(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        if (editData) {
            setPackageData({
                name: editData?.name,
                description: editData?.description,
                duration: editData?.duration,
                price: editData?.price,
                type: editData?.type,
                status: editData?.status == 1 ? true : false,
                image: editData?.image
            })
        }
    }, [editData])

    return {
        packageData, loader, updateOrAddPromotionData, handleChange, setPackageData, errors
    }
}

export default useAdminCreateEditPromotions