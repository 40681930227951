import { registerUser, updateUser } from 'Adapters/APIs/Authentication';
import Svgs from 'Assets/svgs';
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import React, { useMemo, useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useEditProfile = (setActiveTab) => {

    // states
    const [formData, setFormData] = useState({
        password: '',
        current_password: '',
        new_password_confirmation: ''
    });
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const { errors, setErrors, validation, confirmPasswordValidation, passwordValidation } = useValidations()

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'password') {
            passwordValidation(name, value, formData.new_password_confirmation, 'new_password_confirmation');
            setFormData({
                ...formData,
                [name]: value
            })
        } else if (name == 'new_password_confirmation') {
            confirmPasswordValidation(name, value, formData.password)
            setFormData({
                ...formData,
                [name]: value
            })
        } else {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // handle submit
    const handleSubmit = () => {
        const { password, new_password_confirmation } = formData
        setErrors(validation(formData));
        let result = validation(formData);
        passwordValidation('password', password, new_password_confirmation, 'new_password_confirmation');
        confirmPasswordValidation('new_password_confirmation', new_password_confirmation, password)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)
            const success = (data) => {
                setLoading(false)
                setActiveTab && setActiveTab('profile')
            }
            const fail = () => {
                setLoading(false)
            }
            dispatch(updateUser(access_token, formData, success, fail))
        }
    };

    return { formData, errors, handleChange, handleSubmit, loading }
}

export default useEditProfile