import { getAdminPromotionsData, updateOrAddAdminPromotion } from "Adapters/APIs/Admin/promotions";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAdminPromotions = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const [addEditPromotion, setAddEditPromotion] = useState(false)
    const [editData, setEditData] = useState()

    // get admin promotion data
    const getAdminPromotionData = (noLoader) => {
        !noLoader && setLoader(true)
        let payload = {}
        const success = (data) => {
            setData(data?.packages)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminPromotionsData(access_token, payload, success, fail))
    }

    // update promotion status
    const updateOrAddPromotionData = (data) => {
        let payload = {
            id: data?.id,
            name: data?.name,
            description: data?.description,
            duration: data?.duration,
            price: data?.price,
            type: data?.type?.toLowerCase(),
            status: data?.status == 1 ? 0 : 1,
            showToast: true
        }
        const success = () => {
            getAdminPromotionData()
        }
        const fail = () => {
        }
        dispatch(updateOrAddAdminPromotion(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAdminPromotionData()
    }, [])

    return {
        navigate, loader, data, addEditPromotion, setAddEditPromotion, editData, setEditData, getAdminPromotionData, updateOrAddPromotionData
    }
}

export default useAdminPromotions