import { getAllNotifications, makeExternalNotificationCountZero, readAllNotifications, readSingleNotification } from 'Adapters/APIs/Authentication';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useNotifications = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const notification = state?.user?.notifications
    const pagination = state?.user?.notification_pagination
    const user_unread_count = state?.user?.user_unread_count

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    
    // states
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [markAsReadLoader, setMarkAsReadLoader] = useState(false)

    // get notifications data
    const getNotifications = () => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        if (currentPage == 1)
            setLoading(true)
        dispatch(getAllNotifications(access_token, { page: currentPage }, success, fail))
    }

    // mark all notifications as read
    const markAllAsRead = () => {
        const success = () => {
            setMarkAsReadLoader(false)
            setCurrentPage(1)
            setLoading(true)
            getNotifications()
        }
        const fail = () => {
            setMarkAsReadLoader(false)
        }
        if (user_unread_count > 0) {
            setMarkAsReadLoader(true)
            dispatch(readAllNotifications(access_token, success, fail))
        }
    }

    // mark all notifications as read
    const markNotificationCountZero = () => {
        const success = () => { getNotifications() }
        const fail = () => { }
        dispatch(makeExternalNotificationCountZero(access_token, success, fail))
    }    

    useEffect(() => {
        markNotificationCountZero()
    }, [])

    useEffect(() => {
        if(currentPage!=1)
        getNotifications()
    }, [currentPage])

    return {
        notification, loading, pagination, currentPage, setCurrentPage, markAllAsRead, user_unread_count, markAsReadLoader,
    };

}

export default useNotifications