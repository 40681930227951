import { useState } from "react"
import { useNavigate } from "react-router-dom"

const useRoleSelectionHelper = () => {
    const navigate = useNavigate()

    // states
    const [selectedRole, setSelectedRole] = useState('restaurant')

    // handle submit selected role
    const handleSelectedRole = () => {
        navigate(`/signup/${selectedRole}`)
    }

    return {
        selectedRole, setSelectedRole, handleSelectedRole, navigate
    }
}

export default useRoleSelectionHelper