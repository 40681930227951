import Svgs from 'Assets/svgs'
import ActivitLogsCard from 'Components/ActivitLogsCard'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import Image from 'Components/Elements/Image'
import ImageUploader from 'Components/Elements/ImageUploader'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import Textarea from 'Components/Elements/TextArea'
import ToggleWithText from 'Components/Elements/ToggleWithText'
import { SupportRequestUpdateStatus } from 'Constants/Data/Categories'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import NotFound from 'Utility/NotFound'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const RequestCard = (props) => {
    const { id, number, logs, status, title, category, date, user, note, isDetail, onCardClick, image, threads, btnLoading, onSubmit, loadingThread,
        adminWishlist, address, assignedUser, isConcierge, is_active, onToggleClick, onEditClick, updateStatus, updateStatusValue,
        setUpdateStatusValue, onClickUpdateStatus, updateStatusLoader, addedBy, allowEdit = true,
    } = props

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const userData = getDataFromLocalStorage('user_data')

    const [comment, setComment] = useState('')
    const [error, setError] = useState('')
    const [type, setType] = useState('chat')

    useEffect(() => {
        if (!btnLoading)
            setComment('')
    }, [btnLoading])

    return (
        <div className='flex flex-col gap-4'>
            <div
                className={`${onCardClick && 'cursor-pointer'} bg-darkGrey flex flex-col rounded-xl p-3 sm:p-5 gap-3`}
                onClick={() => {
                    onCardClick && onCardClick()
                }}
            >
                {isConcierge &&
                    <div className="flex">
                        <Image src={image} className='h-52 object-cover w-full rounded-lg' showDummy />
                    </div>
                }
                <div className="flex justify-between items-center">
                    <div class="font-semibold text-secondary mb-1">ID: {number}</div>
                    {!adminWishlist && !isConcierge &&
                        <button className={`${status?.toLowerCase()} flex items-center gap-1 px-4 py-1.5 rounded-lg `}>
                            {status}
                        </button>
                    }
                    {(isConcierge && allowEdit) &&
                        <div className="flex gap-3">
                            <ToggleWithText checked={is_active} onChange={() => onToggleClick(id)} />
                            <div className='flex border p-1.5 rounded-full border-secondary'
                                onClick={() => { onEditClick && onEditClick() }}
                            >
                                <Svgs.EditIcon fill={'var(--secondary-color)'} height={14} width={14} />
                            </div>
                        </div>
                    }
                </div>
                <p class="text-white font-semibold text-lg">{title}</p>
                <div className="flex gap-3 text-blueGrey sm:flex-row flex-col justify-between sm:items-center">
                    {!adminWishlist &&
                        <div className="flex gap-1 items-center">
                            <div><Svgs.ReportIcon stroke={'var(--secondary-color)'} /> </div>
                            {isDetail && <span>Suggestions:</span>}
                            <span className={`text-blueGrey ${isDetail ? 'font-semibold' : ''}`} >{category}</span>
                        </div>
                    }
                    <div className="flex gap-2 items-center">
                        <div><Svgs.CalendarGreen fill={'var(--secondary-color)'} /> </div>
                        {isDetail && <span> Date:</span>}
                        <span className={`text-blueGrey ${isDetail ? 'font-semibold' : ''}`} >{moment(date).format('MMM, DD YYYY')}</span>
                    </div>
                </div>
                {adminWishlist && address ?
                    <>
                        <div className="flex gap-2 items-center">
                            <div><Svgs.LocationIcon stroke={'var(--secondary-color)'} width={20} height={22} /></div>
                            <span className='text-blueGrey'>{address}</span>
                        </div>

                    </> : ""}
                {adminWishlist && addedBy ?
                    <>
                        <div className="flex gap-2 items-center">
                            <div><Svgs.ProfileIcon stroke={'var(--secondary-color)'} /></div>
                            <span className='text-blueGrey'>Added By: {addedBy}</span>
                        </div>

                    </> : ""}
                {adminWishlist && assignedUser ?
                    <>
                        <div className="flex gap-2 items-center">
                            <div><Svgs.ProfileIcon stroke={'var(--secondary-color)'} /></div>
                            <span className='text-blueGrey'>Assigned: {assignedUser}</span>
                        </div>

                    </> : ""}
                {!adminWishlist ? isDetail ?
                    <div className='flex flex-col gap-4 text-blueGrey mt-2'>
                        <div className='flex flex-col gap-2 text-blueGrey'>
                            <h1 className='font-semibold' >Attachment</h1>
                            <Image src={image} className='h-40 object-cover w-full rounded-lg' showDummy={true} />
                            <div className='flex gap-2 bg-lightGrey items-center mt-2 p-3 rounded-xl shadow justify-between'>
                                Requested By :
                                <span className="space-y-3 text-base font-semibold">
                                    {user?.name
                                        ? user?.name
                                        : `
                                    ${user?.first_name ? user?.first_name : ""}
                                    ${user?.last_name ? user?.last_name : ""}
                                    `
                                    }
                                    {/* {`${user?.first_name ? user?.first_name : ""} ${user?.last_name ? user?.last_name : ""}`} */}
                                </span>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <h1 className='font-semibold' >Notes</h1>
                            <p className='text-blueGrey'>{note}</p>
                        </div>
                        {(updateStatus && status != 'Resolved') &&
                            <div className='grid xs:grid-cols-3 grid-cols-1 gap-4'>
                                <div className='col-span-2'>
                                    <Dropdown
                                        title={''}
                                        name={'status'}
                                        placeholder={'Select status'}
                                        onChange={(e) => { setUpdateStatusValue(e.target.value) }}
                                        value={updateStatusValue ? updateStatusValue : status?.toLowerCase()}
                                        option={SupportRequestUpdateStatus}
                                        bgColor={'var(--lightGrey-color)'}
                                    />
                                </div>
                                <Button
                                    title={'Update Status'}
                                    onClick={() => { onClickUpdateStatus && onClickUpdateStatus(id, updateStatusValue ? updateStatusValue : status?.toLowerCase()) }}
                                    loading={updateStatusLoader}
                                    disabled={updateStatusLoader}
                                    customPadding={'!p-2'}
                                />
                            </div>
                        }
                    </div>
                    : <>
                        <hr className='border-lightGrey' />
                        <div className="text-blueGrey flex gap-2 items-center">
                            Requested By:
                            <span className="space-y-3 text-base font-semibold">
                                {user?.restaurant?.name
                                    ? user?.restaurant?.name
                                    : `
                                    ${user?.first_name ? user?.first_name : ""}
                                    ${user?.last_name ? user?.last_name : ""}
                                    `
                                }
                            </span>
                        </div>
                    </>
                    : ""
                }
            </div>
            {isDetail &&
                <>
                    <div className="bg-darkGrey rounded-xl grid grid-cols-2 items-baseline text-blueGrey">
                        <div
                            onClick={() => setType('chat')}
                            className={`${type == 'chat' ? 'bg-secondary text-primary rounded-l-xl' : ''} cursor-pointer font-semibold flex items-center justify-center py-3 px-2`}>
                            <h1>Chat</h1>
                        </div>
                        <div
                            onClick={() => setType('logs')}
                            className={`${type == 'logs' ? 'bg-secondary text-primary rounded-r-xl' : ''} cursor-pointer font-semibold flex items-center justify-center py-3 px-2`}>
                            <h1>Activity Logs</h1>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4 text-blueGrey">
                        {/* Threads */}
                        {type == "chat"
                            ?
                            <>
                                {threads?.length > 0 ?
                                    <>
                                        <div className="flex flex-col gap-3">
                                            {threads?.map(itm => {
                                                const isActive = itm?.sender_id == userData?.id
                                                return (
                                                    <div className={`flex flex-col text-sm gap-1 p-3 max-w-40 rounded-b shadow justify-between ${!isActive ? "text-primary bg-secondary rounded-tl self-end " : "bg-darkGrey rounded-tr self-start"} `}>
                                                        <p>{itm?.message}</p>
                                                        <p className='text-xs'>
                                                            By: {
                                                                isActive
                                                                    ? itm?.restaurant?.name || itm?.sender?.first_name
                                                                    : `${itm?.sender?.first_name} ${itm?.sender?.first_name}`
                                                            }
                                                        </p>
                                                    </div>
                                                )
                                            })}
                                            {loadingThread &&
                                                <div className="flex justify-end my-5">
                                                    <PreloaderSm />
                                                </div>
                                            }
                                        </div>
                                    </>
                                    : <NotFound />
                                }

                                {status == 'Resolved'
                                    ? ""
                                    : <div className="flex flex-col gap-3">
                                        <h1 className='font-semibold' >Reply</h1>
                                        <Textarea
                                            placeholder={'Provide a brief decription'}
                                            rows={4}
                                            value={comment}
                                            error={error}
                                            onChange={(e) => {
                                                setError('')
                                                setComment(e?.target?.value)
                                            }}
                                        />
                                    </div>
                                }

                                <Button
                                    loading={btnLoading}
                                    onClick={() => {
                                        if (status == 'Resolved') {
                                            onSubmit && onSubmit(id, comment, status)
                                        } else {
                                            if (comment)
                                                onSubmit && onSubmit(id, comment)
                                            else
                                                setError("This field is required")
                                        }
                                    }}
                                    customThemeBtn={'theme-btn'} customPadding={'p-3  gap-3'}>
                                    {status == 'Resolved'
                                        ? "Re-open"
                                        : "Comment"
                                    }
                                </Button>
                            </>
                            // Activity logs
                            : type == "logs"
                                ? logs?.length > 0
                                    ? <>
                                        <div className="flex flex-col gap-3">
                                            {logs?.map(itm => {
                                                return (
                                                    <ActivitLogsCard
                                                        data={itm}
                                                    />
                                                )
                                            })}
                                            {loadingThread &&
                                                <div className="flex justify-end my-5">
                                                    <PreloaderSm />
                                                </div>
                                            }
                                        </div>
                                    </>
                                    : <NotFound />
                                : ""
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default RequestCard