import { updateOrAddAdminReward } from "Adapters/APIs/Admin/rewards";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useAdminRewardAddUpdate = ({ editData, close, successCallBack }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [rewardData, setRewardData] = useState({
        reward: "",
        date: "",
        booking_threshold: "",
        status: true
    })

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == 'reward') {
            setRewardData((prev) => ({
                ...prev,
                [name]: event.target.files[0]
            }))
        } else if (name == 'date') {
            setRewardData((prev) => ({
                ...prev,
                [name]: event.target.startDate
            }))
        } else {
            setRewardData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }

    // update or add reward data
    const updateOrAddRewardData = (id) => {
        let payload = {
            id: editData?.id,
            reward: rewardData?.reward == editData?.reward ? "" : rewardData?.reward,
            date: moment(rewardData?.date).format('YYYY-MM-DD'),
            booking_threshold: rewardData?.booking_threshold,
            status: rewardData?.status ? 1 : 0
        }
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            close && close(false)
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            reward: rewardData?.reward,
            date: rewardData?.date,
            booking_threshold: rewardData?.booking_threshold
        }
        setErrors(validation(requiredData));
        let result = validation(requiredData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoader(true)
            dispatch(updateOrAddAdminReward(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        if (editData) {
            setRewardData({
                reward: editData?.reward,
                date: `${editData?.year}-${editData?.month < 10 ? `0${editData?.month}` : editData?.mont}-01`,
                booking_threshold: editData?.booking_threshold,
                status: editData?.status == 1 ? true : false
            })
        }
    }, [editData])

    return {
        rewardData, loader, updateOrAddRewardData, handleChange, setRewardData, errors
    }
}

export default useAdminRewardAddUpdate