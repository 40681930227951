import React from 'react'
import DashboardLayout from '../../../../Components/DashboardLayout'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'
import RewardCard from '../Elements/RewardCard'
import useAnnounceReward from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import moment from 'moment'
import WinnerDetailPopup from '../Elements/WinnerDetailPopup'

const AnnounceRewardDetail = () => {
    const {
        loading, rewards, announceCurrentPage, setAnnounceCurrentPage, secondLoader, ar_pagination, winnerDetail, setWinnerDetail
    } = useAnnounceReward()
    return (
        <>
            <DashboardLayout active={'reward'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="pt-4 space-y-5">
                        <div className="flex items-center gap-3 justify-between">
                            <Back title={'Announce Rewards'} />
                        </div>
                        <div>
                            <div className="rounded-lg md:rounded-[10px] pb-4">
                                {loading
                                    ? <div className={`grid grid-cols-1 w-full gap-5`}>
                                        <div className="flex flex-col w-full gap-2 py-2 rounded-lg">
                                            <div className="bg-gray-500 w-full h-52 rounded-lg" alt="promo" />
                                        </div>
                                    </div>
                                    : rewards?.length > 0
                                        ?
                                        <InfiniteScroll
                                            dataLength={rewards?.length ? rewards?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                setAnnounceCurrentPage(announceCurrentPage + 1)
                                            }}
                                            hasMore={ar_pagination && ar_pagination?.current_page !== ar_pagination?.total_pages}
                                            loader={secondLoader
                                                ? <div className="flex justify-center items-center my-4">
                                                    <PreloaderSm />
                                                </div> : ""
                                            }
                                        >
                                            {rewards?.map(itm => {
                                                return <RewardCard
                                                    image={itm?.reward}
                                                    data={itm}
                                                    date={itm?.date}
                                                    winner={itm?.winner?.user}
                                                    className={"cursor-pointer"}
                                                    text={`Winner for ${moment(itm?.date).format('MMMM YYYY')} is: ${itm?.winner?.user?.first_name ? itm?.winner?.user?.first_name : ""} ${itm?.winner?.user?.last_name ? itm?.winner?.user?.last_name : ""}`}
                                                    onClick={() => {
                                                        setWinnerDetail(itm)
                                                    }}

                                                />
                                            })
                                            }
                                        </InfiniteScroll>
                                        : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>

            {winnerDetail &&
                <WinnerDetailPopup
                    open={winnerDetail}
                    close={setWinnerDetail}
                    data={winnerDetail}
                />
            }
        </>
    )
}

export default AnnounceRewardDetail