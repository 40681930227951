import Button from 'Components/Elements/Button'
import Popup from 'Components/Elements/Popup'
import React from 'react'

const DeletePopup = ({ title, open, loading, close, onDelete, customSize }) => {
    return (
        <>
            <Popup
                open={open}
                close={close}
                removeClose={true}
                removeHeading={true}
                size={customSize ? "" : 'xs'}
                customSizeStyle={customSize ? customSize : ""}
            >
                <div className="flex flex-col gap-5">
                    <h1 className='text-xl font-semibold'>{title}</h1>
                    <div className="flex justify-between gap-4 items-center">
                        <Button className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={() => close(false)} >No</Button>
                        <Button className={'w-full'} loading={loading} onClick={() => onDelete()}>Yes</Button>
                    </div>
                </div>

            </Popup>
        </>
    )
}

export default DeletePopup