import { combineReducers } from "redux";
import UserReducers from "./User/Reducers";
import CuisinesReducer from "./Cuisines/Reducers";
import RestaurantReducer from "./Concierge/Retaurants/Reducers";
import BookingReducder from "./Concierge/Bookings/Reducers";
import AdminDashboardReducer from "./Admin/Dashboard/Reducer";
import AdminRestaurants from "./Admin/Restaurants/Reducer";
import AdminConcierges from "./Admin/Concierges/Reducer";
import ChatReducer from "./Chat/Reducers";
import ConciergeDashBoardReducer from "./Concierge/ConciergeDashboard/Reducers";
import AdminRewardsReducer from "./Admin/Rewards/Reducers";
import MenuCategoryReducer from "./Restaurant/Menu/Reducers";
import CategoriesReducer from "./Categories/Reducers";
import BusinessReducer from "./Business/Reducers";
import InviteFriendReducer from "./InviteFriend/Reducers";
import RestaurantBusinessReducer from "./Restaurant/Business/Reducers";
import RestaurantProfileBoostingReducer from "./Restaurant/ProfileBoosting/Reducers";

const Reducers = combineReducers({
    user: UserReducers,
    adminDashboard: AdminDashboardReducer,
    adminRestaurants: AdminRestaurants,
    adminRewards: AdminRewardsReducer,
    adminConcierges: AdminConcierges,
    cuisines: CuisinesReducer,
    categories: CategoriesReducer,
    retaurant: RestaurantReducer,
    bookings: BookingReducder,
    chat: ChatReducer,
    concierge: ConciergeDashBoardReducer,
    manu: MenuCategoryReducer,
    business: BusinessReducer,
    inviteFriend: InviteFriendReducer,
    restaurantBusiness: RestaurantBusinessReducer,
    restaurantProfileBoosting: RestaurantProfileBoostingReducer
})
export default Reducers;
