import React from 'react'

const RestaurantPaymentCardShimmer = () => {
    return (
        <div className={`bg-darkGrey relative rounded-lg md:rounded-[10px] flex items-center justify-between p-5 animate-pulse`}>
            <div className="flex flex-col gap-3 py-3 items-center justify-between h-full w-full">
                <div className="flex flex-col w-full max-w-xl gap-3">
                    <div className='h-6 w-56 rounded-md bg-gray-500'></div>
                    <div className='h-16 w-full rounded-md bg-gray-500'></div>
                </div>
                <div className='flex flex-col text-blueGrey items-center w-11/12 mx-auto gap-1'>
                    <div className='h-6 w-36 rounded-md bg-gray-500'></div>
                    <div className='h-6 w-56 rounded-md bg-gray-500'></div>
                </div>

                <div className="flex justify-between w-full text-blueGrey gap-2">
                    <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                        <div className='h-6 w-24 rounded-md bg-gray-500'></div>
                        <div className='h-6 w-56 rounded-md bg-gray-500'></div>
                    </div>
                    <div className='bg-lightGrey w-0.5' />
                    <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                        <div className='h-6 w-24 rounded-md bg-gray-500'></div>
                        <div className='h-6 w-56 rounded-md bg-gray-500'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RestaurantPaymentCardShimmer