import React from 'react'

const UnapprovedCardShimmer = ({ index, onCardClick, showImage, conciergeImage, name, status, phoneNumber, businessName, created_at, type }) => {
    return (
        <div className="bg-white restaurant-card-shadow rounded-xl p-4 w-full cursor-pointer animate-pulse">
            <div className='flex gap-2 items-center'>
                {showImage ?
                    <div className='w-14 h-14 rounded-full bg-gray-200'></div>
                    : ""
                }
                <div className='flex gap-2 items-center justify-between flex-1'>
                    <div className="flex flex-1 flex-col space-y-2 mt-5">
                        <div className='bg-gray-200 h-4 w-32 rounded-md'></div>
                        <div className='bg-gray-200 h-4 w-28 rounded-md'></div>
                        <div className='bg-gray-200 h-4 w-32 rounded-md'></div>
                        {showImage ?
                            <div className='bg-gray-200 h-4 w-20 rounded-md'></div>
                            : <div className='bg-gray-200 h-4 w-56 rounded-md'></div>
                        }

                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                        <div className='bg-gray-200 h-8 w-20 rounded-md'></div>
                        <div className='w-8 h-8 rounded-full bg-gray-200'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnapprovedCardShimmer