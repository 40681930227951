import StarsRating from 'Pages/Admin/Restaurants/SingleRestaurant/SingleRestaurantRatingPupup/StarsRating'
import React from 'react'

const AdminSingleRestaurantFeedbackCardShimmer = () => {
    return (
        <div className="bg-darkGrey restaurant-card-shadow rounded-xl w-full animate-pulse">
            <div className='p-4'>
                <div className='flex justify-between items-center gap-2'>
                    <div className='h-4 w-28 bg-gray-200 rounded-md'></div>
                    <StarsRating rating={0} starSize={20} />
                </div>
                <div className='h-4 w-20 bg-gray-200 rounded-md mb-2'></div>
                <div className='h-4 w-full max-w-96 bg-gray-200 rounded-md'></div>
            </div>
            <hr className='border-[1.5px]' />
            <div className='p-4'>
                <div className='grid grid-cols-2 gap-2 space-y-2'>
                    <div className='flex items-center gap-2'>
                        <div className='h-4 w-20 bg-gray-200 rounded-md'></div>
                        <div className='h-4 w-44 bg-gray-300 rounded-md'></div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className='h-4 w-20 bg-gray-200 rounded-md'></div>
                        <div className='h-4 w-44 bg-gray-300 rounded-md'></div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-2 space-y-2'>
                    <div className='flex items-center gap-2'>
                        <div className='h-4 w-20 bg-gray-200 rounded-md'></div>
                        <div className='h-4 w-44 bg-gray-300 rounded-md'></div>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className='h-4 w-20 bg-gray-200 rounded-md'></div>
                        <div className='h-4 w-44 bg-gray-300 rounded-md'></div>
                    </div>
                </div>
                <div className='flex items-center gap-2 mt-1'>
                    <div className='h-4 w-20 bg-gray-200 rounded-md'></div>
                    <div className='h-4 w-44 bg-gray-300 rounded-md'></div>
                </div>
            </div>
        </div>
    )
}

export default AdminSingleRestaurantFeedbackCardShimmer