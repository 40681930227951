import React from 'react'

const Shimmer = ({ className, children }) => {
    return (
        <div className={`${className} animate-pulse`}>
            {children}
        </div>
    )
}

export default Shimmer