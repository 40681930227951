import { currency } from 'Adapters/variables'
import React from 'react'

const AmountCard = ({ amount, title, subTitle, showCurrency }) => {
    return (
        <div className={`bg-darkGrey rounded-lg md:rounded-[10px] min-h-20 flex flex-col items-center justify-between p-5 text-center`}>
            <p className="text-blueGrey">{title}</p>
            {subTitle && <p className="text-blueGrey">{subTitle}</p>}
            <h4 className={`font-semibold text-xl text-secondary`}>{amount ? amount : 0} {showCurrency ? currency : ""}</h4>
        </div>
    )
}

export default AmountCard