import { getDropdownRestaurantAPI } from 'Adapters/APIs/Admin/dashboard'
import { getConciergeBookingAnalytics } from 'Adapters/APIs/Concierge/Bookings'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

let initialFilterState = {
    dropdownValue: '',
    start_date: "",
    end_date: "",
    status: "",
    filter: ""
}

const useBookingAnalytics = () => {
    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // redux states
    const state = useSelector(state => state)
    const booking = state?.bookings?.booking_analytics
    const total_bill_amount = state?.bookings?.total_bill_amount
    const total_manager_earning = state?.bookings?.total_manager_earning
    const total_concierges_recieved = state?.bookings?.total_concierges_recieved
    const restaurants = state?.adminDashboard?.dropdown_restaurants
    const pagination_data = state?.bookings?.ba_pagination

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterPopup, setFilterPopup] = useState(false)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setloading] = useState(true)

    // on apply filter
    const onApplyFilter = () => {
        setFilterPopup(false)
        if (filterUpdate) {
            getBookingAnalytics()
        }
    }

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // get Booking Analytics
    const getBookingAnalytics = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10
        }
        if (!noFilter) {
            payload = {
                ...payload,
                page: currentPage,
                end_date: filterData?.end_date,
                start_date: filterData?.start_date,
                restaurant: filterData?.dropdownValue,
                status: filterData?.status,
                filter: filterData?.filter == 'date' ? '' : filterData?.filter

            }
        }
        setloading(true)
        const success = () => {
            setloading(false)
        }
        const fail = () => {
            setloading(false)
        }
        dispatch(getConciergeBookingAnalytics(access_token, payload, success, fail))
    }

    // on clear filter
    const onClearFilter = () => {
        setFilterPopup(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            getBookingAnalytics(true)
            setFilterData(initialFilterState)
        }
    }

    useEffect(() => {
        getBookingAnalytics()
    }, [currentPage])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    useEffect(() => {
        dispatch(getDropdownRestaurantAPI(access_token))
    }, [])

    return {
        setFilterData, filterData, restaurants, startDate, endDate, setStartDate, setEndDate,
        loading, booking, currentPage, pagination_data, setCurrentPage, total_bill_amount, total_manager_earning,
        filterPopup, setFilterPopup, onApplyFilter, onClearFilter, handleChangeFilter

    }

}

export default useBookingAnalytics