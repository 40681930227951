import { BASE_URL, get_admin_dashboard_detail_url, get_tranaction_url, get_all_promotions_url, get_announced_rewards_url, get_commission_analytics_url, get_unannounced_rewards_url, receive_payment_url } from "Adapters/variables";

import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getTransactionAction } from "../../../Redux/Concierge/ConciergeDashboard/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { getAllDashboardData, getAllPromotionAction, getAnnouncedRewards, getCommissionAnalyticAction, getUpcommingRewards } from "../../../Redux/Concierge/ConciergeDashboard/Actions";

export const getConceirgeTransaction = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_tranaction_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            setLoading && setLoading(false)
            dispatch(getTransactionAction(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const getDashboardDataAPI = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_admin_dashboard_detail_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            setLoading && setLoading(false)
            dispatch(getAllDashboardData(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const getAllPromotion = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_all_promotions_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            setLoading && setLoading(false)
            dispatch(getAllPromotionAction(data))
        }
    } catch (error) {
        fail && fail()
        setLoading && setLoading(false)
        dispatch(getAllPromotionAction({
            promotions: []
        }))
        return error;
    }
}
export const getAnnoncedConciergeReward = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_announced_rewards_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            dispatch(getAnnouncedRewards(data))
            success && success(data)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}
export const getUpcommingConciergeReward = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_unannounced_rewards_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getUpcommingRewards(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}
export const getCommissionAnalytics = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_commission_analytics_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            setLoading && setLoading(false)
            success && success()
            dispatch(getCommissionAnalyticAction(data))
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const paymentReceive = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${receive_payment_url}`, payload, headers);
        if (response?.status == 200) {
            success && success()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}