import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import RestaurantForm from 'OnBaording/Signup/RestaurantForm'
import React from 'react'
import useCreateBusiness from './helper'
import OTPScreen from 'OnBaording/OTPVerification/OTPScreen'

const CreateUpdateBusiness = () => {
    const {
        handleSubmit, handleChange, formData, errors, setErrors, cuisines, loading, addCuisine, setAddCuisine, getCuisineLoader,
        getCuisineData, categories, step, setStep
    } = useCreateBusiness()
    return (
        <DashboardLayout active={'business'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 auto min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 w-full justify-between">
                    <div className="pt-4 space-y-5 w-full">
                        <div className="flex xs:flex-row flex-col justify-between xs:items-center">
                            <Back
                                title={'Create Business'} />
                        </div>
                    </div>
                </div>
                <div className='py-6'>
                    {step == 1 ?
                        <RestaurantForm
                            handleChange={handleChange}
                            formData={formData}
                            cuisines={cuisines}
                            errors={errors}
                            setErrors={setErrors}
                            handleSubmit={handleSubmit}
                            loading={loading}
                            addCuisine={addCuisine}
                            setAddCuisine={setAddCuisine}
                            getCuisineLoader={getCuisineLoader}
                            getCuisineData={getCuisineData}
                            categories={categories}
                            addBusiness={true}
                        />
                        : <OTPScreen step={step} setStep={setStep} email={formData?.email} />
                    }
                </div>
            </main>
        </DashboardLayout>
    )
}

export default CreateUpdateBusiness