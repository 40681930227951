import { GET_ALL_ANNOUNCED_REWARDS, GET_ALL_PROMTIONS, GET_ALL_UPCOMMING_REWARDS, GET_COMMISSION_ANALYTICS, GET_DASHBOARD_DATA, GET_SUPPORT_REQUEST, GET_TRANSACTION, GET_WISHLIST, GET_WISHLIST_DETAIL } from "./ActionType";
const initialState = {
    transaction: [],
    transaction_pagination: {},
    support_requests: [],
    supp_req_pagination: {},
    wishlists: [],
    wishlist_pagination: {},
    promotions: [],
    promotion_pagination: {},
    announced_rewards: [],
    upcomming_rewards: [],
    ar_pagination: {},
    dashbaord_data: {},
    commission_analytics: [],
    recent_transfers: [],
    ca_pagination: {},
    commission_stats: {}
}

const ConciergeDashBoardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TRANSACTION:
            return {
                ...state,
                transaction: payload.transactions,
                transaction_pagination: payload?.pagination_data?.meta,
            }
        case GET_SUPPORT_REQUEST:
            let update_request;
            if (payload?.pagination_data?.meta?.current_page !== 1) {
                update_request = [...state?.support_requests, ...payload?.requests];
            } else {
                update_request = payload?.requests;
            }
            return {
                ...state,
                support_requests: update_request,
                supp_req_pagination: payload?.pagination_data?.meta
            }

        case GET_WISHLIST:
            let wishlist_data;
            if (payload?.pagination_data?.meta?.current_page !== 1) {
                wishlist_data = [...state?.wishlists, ...payload?.wishlist];
            } else {
                wishlist_data = payload?.wishlist;
            }
            return {
                ...state,
                wishlists: wishlist_data,
                wishlist_pagination: payload?.pagination_data?.meta
            }

        case GET_WISHLIST_DETAIL:
            return {
                ...state,
                wishlist_detail: payload,
            }

        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashbaord_data: payload,
            }
        case GET_ALL_PROMTIONS:
            let updatedPromotion;
            if (payload?.promotions?.length == 0) {
                updatedPromotion = []
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                updatedPromotion = [...state?.promotions, ...payload?.promotions];
            } else {
                updatedPromotion = payload?.promotions;
            }
            return {
                ...state,
                promotions: updatedPromotion,
                promotion_pagination: payload?.pagination_data?.meta
            }
        case GET_ALL_ANNOUNCED_REWARDS:
            return {
                ...state,
                announced_rewards: payload?.rewards,
                ar_pagination: payload?.ar_pagination,
            }
        case GET_ALL_UPCOMMING_REWARDS:
            return {
                ...state,
                upcomming_rewards: payload?.rewards,
                ur_pagination: payload?.ur_pagination,
            }
        case GET_COMMISSION_ANALYTICS:
            return {
                ...state,
                commission_analytics: payload.reservations,
                ca_pagination: payload?.pagination_data?.meta,
                commission_stats: {
                    total_earning: payload?.total_earning,
                    total_bill: payload?.total_bill,
                    my_earning: payload?.my_earning,
                    total_pax: payload?.total_pax,
                },
                recent_transfers: payload?.recent_transfers
            }
        default:
            return {
                ...state,
            };
    }
}

export default ConciergeDashBoardReducer