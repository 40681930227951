import { getAdminSingleConciergeBookingsData, getAdminSingleConciergeBookingsUserData, payConciergeRemainingDues } from "Adapters/APIs/Admin/concierge";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const useSingleConciergeBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user_id } = useParams();

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [bookingLoader, setBookingLoader] = useState(true)
    const [conceirgeData, setConciergeData] = useState({})
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [monthValue, setMonthValue] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [bookingData, setBookingData] = useState([])
    const [paginationData, setPaginationData] = useState({})
    const [conciergePay, setConciergePay] = useState(false)
    const [conciergePayLoader, setConciergePayLoader] = useState(false)
    const [payClicked, setPayClicked] = useState(false)
    const [paidProof, setPaidProof] = useState()
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [totalManagerEarnings, setTotalManagerEarnings] = useState(0);

    // handle change month filter
    const handleChangeMonth = (e) => {
        const { startDate, endDate, value } = e.target
        setMonthValue(value)
        setStartDate(startDate)
        setEndDate(endDate)
    }

    // on prrof handle change
    const onProofChangeHandler = (e) => {
        const { name, files } = e?.target
        setPaidProof(files[0])
        setErrors({ ...errors, [name]: '' })
    }

    // get concierge bookings user data
    const getConciergeBookingsUserData = () => {
        let payload = {
            user_id: user_id,
            start_date: startDate,
            end_date: endDate
        }
        setLoader(true)
        const success = (res) => {
            setConciergeData(res)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleConciergeBookingsUserData(access_token, payload, success, fail))
    }

    // pay concierge remaining dues
    const payConciergeDues = () => {
        let payload = {
            paid_proof: paidProof,
            paid_amount: totalManagerEarnings,
            reservationIds: selectedBookings?.map(itm => itm?.id)
        }
        const success = (res) => {
            setConciergePayLoader(false)
            setConciergePay(false)
            setPayClicked(false)
            setBookingLoader(true)
            setLoader(true)
            getConciergeBookings()
            getConciergeBookingsUserData()
        }
        const fail = () => {
            setConciergePayLoader(false)
        }
        let requiredFields = { paidProof: paidProof }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setConciergePayLoader(true)
            dispatch(payConciergeRemainingDues(access_token, payload, success, fail))
        }
    }

    // get concierge bookings
    const getConciergeBookings = () => {
        let payload = {
            user_id: user_id,
            page: currentPage,
            per_page: 10
        }
        setBookingLoader(true)
        const success = (res) => {
            setPaginationData(res?.pagination_data?.meta)
            setBookingData(res?.reservations)
            setBookingLoader(false)
        }
        const fail = () => {
            setBookingLoader(false)
        }
        dispatch(getAdminSingleConciergeBookingsData(access_token, payload, success, fail))
    }

    // handle booking click function
    const handleBookingClick = (booking) => {
        const isSelected = selectedBookings?.find((b) => b?.number === booking?.number);

        if (isSelected) {
            const newSelectedBookings = selectedBookings?.filter((b) => b?.number !== booking?.number);
            setSelectedBookings(newSelectedBookings);
            setTotalManagerEarnings(newSelectedBookings?.reduce((acc, b) => acc + (+b?.manager_earning || 0), 0));
        } else {
            const newSelectedBookings = [...selectedBookings, booking];
            setSelectedBookings(newSelectedBookings);
            setTotalManagerEarnings(newSelectedBookings?.reduce((acc, b) => acc + (+b?.manager_earning || 0), 0));
        }
    };

    // handle select all bookings or not
    const handleSelectAllBookings = () => {
        if (selectedBookings?.length == conceirgeData?.bookings?.length) {
            setSelectedBookings([])
            setTotalManagerEarnings(0)
        } else {
            setSelectedBookings(conceirgeData?.bookings);
            const initialTotal = conceirgeData?.bookings?.reduce((acc, booking) => acc + (+booking?.manager_earning || 0), 0);
            setTotalManagerEarnings(initialTotal);
        }
    }

    // Initially select all bookings
    useEffect(() => {
        if (conceirgeData?.bookings?.length > 0) {
            setSelectedBookings(conceirgeData?.bookings);
            const initialTotal = conceirgeData?.bookings?.reduce((acc, booking) => acc + (+booking?.manager_earning || 0), 0);
            setTotalManagerEarnings(initialTotal);
        }
    }, [conceirgeData?.bookings]);

    useEffect(() => {
        setBookingLoader(true)
        getConciergeBookings()
    }, [currentPage])

    useEffect(() => {
        setLoader(true)
        getConciergeBookingsUserData()
    }, [startDate, endDate])

    return {
        navigate, conceirgeData, loader, handleChangeMonth, monthValue, bookingLoader, paginationData, bookingData, setCurrentPage, currentPage,
        user_id, startDate, endDate, conciergePay, setConciergePay, payConciergeDues, payClicked, setPayClicked, paidProof, onProofChangeHandler,
        conciergePayLoader, errors, handleBookingClick, selectedBookings, totalManagerEarnings, handleSelectAllBookings
    }
}

export default useSingleConciergeBookings