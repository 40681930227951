import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import React from 'react'

const PromotionCard = ({ name, title, amount, bought_at, imageSrc, showDetail, description, approved, proof, number, onConfirmClick,
    confirmLoader, featuredDate, onCardClick
}) => {
    return (
        <div className={`flex flex-col w-full rounded-lg p-4 gap-2 justify-between bg-darkGrey ${onCardClick && 'cursor-pointer'}`} onClick={onCardClick && onCardClick}>
            {showDetail ?
                <Image src={imageSrc} showDummy={true} className="w-full h-52 object-cover border border-lightGrey rounded-lg" />
                : ""}
            <h1 className='font-semibold text-white'>{name}</h1>
            <div className='flex justify-between items-center gap-4'>
                <h1 className='font-medium text-secondary'>{title} </h1>
                <h1 className='font-semibold text-blueGrey'>{amount} {currency}</h1>
            </div>
            <div className='flex gap-2 items-center'><Svgs.CalendarGreen fill={'var(--secondary-color)'}  /> <span className='font-normal text-blueGrey'> {featuredDate}</span></div>
            <h1 className='font-semibold text-secondary'>Bought at: <span className='font-normal text-blueGrey'>{bought_at}</span></h1>
            <p className='text-blueGrey'>{description}</p>
            <hr className='border-lightGrey' />
            <div>
                <button className={`${approved ? 'approve' : 'not-approved'} flex items-center gap-1 px-4 py-1.5 rounded-lg `}>
                    {
                        approved
                            ? <>
                                <Svgs.Tick stroke={'white'} />
                                Approved
                            </>
                            : <>
                                Not Approved
                            </>
                    }
                </button>
            </div>
            {
                showDetail &&
                <>
                    <div className='flex flex-col space-y-2'>
                        <h1 className='text-blueGrey'>Amount transfer proof</h1>
                        <Image className="w-full h-52 object-cover border border-lightGrey rounded-lg" src={proof} showDummy={true} />
                    </div>
                    <div className={`${!approved ? "flex justify-between items-center gap-4" : "w-full"}`}>
                        {!approved &&
                            <Button
                                customThemeBtn={'view-edit-btn'}
                                text={'Confirm'}
                                className={'w-full'}
                                onClick={onConfirmClick}
                                loading={confirmLoader}
                                disabled={confirmLoader}
                            />
                        }
                        <Button
                            text={'View Invoice'}
                            className={'w-full'}
                            onClick={() => { window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/purchase-invoice/${number}`, '_blank') }}
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default PromotionCard