import Button from 'Components/Elements/Button'
import DateInput from 'Components/Elements/DateInput'
import Dropdown from 'Components/Elements/Dropdown'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import { BookingAnalyticsStatus } from 'Constants/Data/Categories'
import React, { useEffect, useState } from 'react'

const BookingFilter = ({ open, close, onClearFilter, onApplyFilter, filterData, setFilterData, handleChangeFilter, dropdownOptions }) => {
    // states
    const [startDate, setStartDate] = useState(filterData?.start_date)
    const [endDate, setEndDate] = useState(filterData?.end_date)

    useEffect(() => {
        if (startDate) {
            setEndDate()
            setFilterData((prev) => ({
                ...prev,
                start_date: startDate,
                end_date: ''
            }))
        }
    }, [startDate])

    useEffect(() => {
        setFilterData((prev) => ({
            ...prev,
            end_date: endDate
        }))
    }, [endDate])

    // handle on clear filters
    const handleOnClearFilters = () => {
        onClearFilter && onClearFilter()
        setStartDate()
        setEndDate()
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Filter by'}
        >
            <div className='flex flex-col space-y-4'>
                <Dropdown
                    title={'Select Status'}
                    placeholder={'Select Status'}
                    name={'status'}
                    option={BookingAnalyticsStatus?.slice(0, 5)}
                    onChange={handleChangeFilter}
                    value={filterData?.status}
                />
                <Dropdown
                    title={'Select Concierge'}
                    placeholder={'Select Concierge'}
                    required={false}
                    name="user_id"
                    onChange={handleChangeFilter}
                    value={filterData?.user_id}
                    inputClass="block w-full"
                    option={[
                        { "label": "All Concierges", "value": "" },
                        ...dropdownOptions?.map(itm => {
                            return {
                                value: itm?.id,
                                label: itm?.first_name
                            }
                        })]}
                />
                <LoginInput
                    title={'Guest'}
                    placeholder={'Enter guesst name'}
                    onChange={handleChangeFilter}
                    name={'search_text'}
                    value={filterData?.search_text}
                    inputPadding={"py-[9px] px-3"}
                />
                <div className='flex md:flex-row flex-col gap-2 items-center justify-between'>
                    <div className='w-full flex-1'>
                        <DateInput
                            title={'Select Start Date'}
                            name={'startDate'}
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value) }}
                            placeholder={'Select Start Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"flex-1"}
                            required={false}
                        />
                    </div>
                    <div className='w-full flex-1'>
                        <DateInput
                            title={'Select End Date'}
                            name={'endDate'}
                            value={endDate}
                            onChange={(e) => { setEndDate(e.target.value) }}
                            placeholder={'Select End Date'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"flex-1"}
                            min={startDate}
                            required={false}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} />
                    <Button text={'Apply'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default BookingFilter