import { getStaffData, updateWishlistStaff } from "Adapters/APIs/Admin/wishlist";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useAdminWishlistUpdate = ({ wishlistDetail, successCallBack, close }) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [staffData, setStaffData] = useState([])

    // update wishlist staff member
    const updateWishListStaffMember = (id) => {
        let payload = {
            wishlistId: wishlistDetail?.id,
            assigned_user_id: id,
        }
        const success = () => {
            setLoader(true)
            successCallBack && successCallBack()
            close && close(false)
        }
        const fail = () => {
        }
        dispatch(updateWishlistStaff(access_token, payload, success, fail))
    }

    // get staff api data
    const getStaffApi = () => {
        let payload = {
            page: currentPage,
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (data) => {
            let updatedData = []
            if (data?.members?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...staffData || [], ...data?.members];
                } else {
                    updatedData = data?.members;
                }
            }
            setStaffData(updatedData)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getStaffData(access_token, payload, success, fail))
    }

    useEffect(() => {
        getStaffApi()
    }, [currentPage])

    return {
        paginationData, loader, secondLoader, currentPage, setCurrentPage, staffData, updateWishListStaffMember
    }
}

export default useAdminWishlistUpdate