import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminSingleRestaurant from './helper'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import moment from 'moment'
import Image from 'Components/Elements/Image'
import LoginInput from 'Components/Elements/LoginInput'
import { currency } from 'Adapters/variables'
import SingleAdminRestaurantDetailShimmer from 'Utility/Shimmers/SingleAdminRestaurantDetailShimmer'
import Popup from 'Components/Elements/Popup'
import Textarea from 'Components/Elements/TextArea'
import Back from 'Components/Elements/Back'

const AdminUnapprovedRestaurant = () => {
    const { restaurant, restaurantSubscriptionData, navigate, loader, errors, updateRestaurantSubscriptionData, updateSubscriptionLoader,
        handleSubscriptionDataChange, setRejectPopup, rejectPopup, rejectLoader, rejectReason, setRejectReason, handleRejectSubmission,
        setErrors
    } = useAdminSingleRestaurant()

    const { id, name, address, average_monthly_bookings, verified_at, trade_license, mobile_code, mobile_number, phone_code, phone_number,
        email, website_link, user } = restaurant

    const { admin_percentage, subscription_charges } = restaurantSubscriptionData

    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {loader ?
                        <SingleAdminRestaurantDetailShimmer hideSliderShimmer />
                        :
                        <div className="pt-4 space-y-5">
                            <div className='flex justify-between items-center'>
                                <Back
                                    title={name} />
                                <div>
                                    <Button
                                        customPadding={'!p-2'}
                                        title={'Edit Profile'}
                                        onClick={() => { navigate(`/admin/edit-restaurant/${id}`) }}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col space-y-2 justify-between'>
                                <div className="flex gap-2 items-center text-blueGrey">
                                    <span><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></span>
                                    <span className="font-extralight">{address}</span>
                                </div>
                                <div className="flex gap-2 items-center text-blueGrey">
                                    <span><Svgs.ClockIcon stroke={'var(--secondary-color)'} width={14} height={18} /></span>
                                    <span className="font-extralight">Average Monthly Booking: {average_monthly_bookings}</span>
                                </div>
                                <div className="flex gap-2 items-center text-blueGrey">
                                    <span><Svgs.ClockIcon stroke={'var(--secondary-color)'} width={14} height={18} /></span>
                                    <span className="font-extralight">Joining at: {moment(verified_at).format('DD-MM-YYYY')}</span>
                                </div>
                            </div>
                            <div className='grid lg:grid-cols-4 xs:grid-cols-2 grid-cols-1 gap-4'>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.PhoneIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm'>{`+${mobile_code} ${mobile_number}`}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.PhoneIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm'>{`+${phone_code} ${phone_number}`}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.MailIcon stroke={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{email}</p>
                                </div>
                                <div className='rounded-lg min-h-28 flex flex-col items-center justify-center space-y-2 py-5 bg-darkGrey'>
                                    <Svgs.WebGlobe fill={'var(--secondary-color)'} />
                                    <p className='text-blueGrey text-sm line-clamp-2'>{website_link ? website_link : "No URL"}</p>
                                </div>
                            </div>
                            {user?.rejection_messages?.length > 0 &&
                                <div className="flex flex-col gap-3 ">
                                    <h1 className='text-base text-white font-semibold'>Rejection Reason</h1>
                                    {user?.rejection_messages?.map((itm, ind) => {
                                        return (
                                            <div className='flex justify-between p-2 gap-4 bg-darkGrey rounded-lg'>
                                                <h1 className='text-sm text-secondary'>{itm?.message}</h1>
                                                <h1 className='text-sm text-blueGrey'>{moment(itm?.created_at).format('DD MMM YYYY')}</h1>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                <div className="flex flex-col gap-3 ">
                                    <h1 className='text-base text-white font-semibold'>Trade Licence</h1>
                                    <Image src={trade_license} className={'h-52 w-full object-cover view-edit-btn p-2 bg-darkGrey rounded-lg'} />
                                </div>
                                <div className="flex flex-col gap-3 ">
                                    <h1 className='text-base text-white font-semibold'>Add Commission Structure</h1>
                                    <div className={` bg-darkGrey rounded-lg flex flex-col space-y-2 items-start justify-between p-5`}>
                                        <div className='flex gap-2 items-center'>
                                            <Svgs.GroupBuilding />
                                            <h1 className='font-medium text-white text-[16px]'>{name}</h1>
                                        </div>
                                        <LoginInput
                                            title="Monthly Subscription"
                                            leftIcon={currency}
                                            parentClass={'w-full'}
                                            required={false}
                                            value={subscription_charges}
                                            name={"subscription_charges"}
                                            onChange={handleSubscriptionDataChange}
                                            placeholder={'Enter monthly subscription'}
                                            inputClass={'bg-lightGrey'}
                                            iconStyle={'border-blueGrey min-w-16'}
                                        />
                                        <LoginInput
                                            title="Commission Percentage"
                                            leftIcon={'%'}
                                            parentClass={'w-full'}
                                            value={admin_percentage}
                                            name={"admin_percentage"}
                                            onChange={handleSubscriptionDataChange}
                                            placeholder={'Enter commission percentage'}
                                            onlyNumericAllowed={true}
                                            error={errors?.admin_percentage}
                                            inputClass={'bg-lightGrey'}
                                            iconStyle={'border-blueGrey min-w-16'}
                                        />
                                        <div className='grid xs:grid-cols-2 grid-cols-1 gap-4 text items-center w-full pt-3'>
                                            <Button
                                                text={'Reject Submission'}
                                                className={"w-full !font-normal"}
                                                customPadding={"px-5 py-2"}
                                                onClick={() => {
                                                    setRejectPopup(true)
                                                }}
                                            />
                                            <Button
                                                text={'Accept Submission'}
                                                className={"w-full !font-normal"}
                                                customPadding={"px-5 py-2"}
                                                loading={updateSubscriptionLoader}
                                                disabled={updateSubscriptionLoader}
                                                onClick={updateRestaurantSubscriptionData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>

                <Popup
                    open={rejectPopup}
                    close={setRejectPopup}
                    customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                    heading={'Provide reason for rejection'}
                >
                    <div className='flex flex-col space-y-4'>
                        <Textarea
                            title={'Reason'}
                            value={rejectReason}
                            error={errors?.rejectReason}
                            name={'rejectReason'}
                            placeholder={"Provide a brief reason"}
                            required={true}
                            onChange={(e) => {
                                setRejectReason(e.target.value);
                                setErrors((prev) => ({
                                    ...prev,
                                    rejectReason: ''
                                }))
                            }}
                        />
                        <Button text={'Submit'} className={'w-full'} onClick={handleRejectSubmission} loading={rejectLoader} disabled={rejectLoader} />
                    </div>
                </Popup>
            </DashboardLayout>
        </>
    )
}

export default AdminUnapprovedRestaurant