import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import moment from 'moment'
import React from 'react'

const PaymentCard = ({ onCardClick, date, number, className, id, amount }) => {
    return (
        <>
            <div
                className={`${className} flex col-span-2 max-w-fit border rounded-xl border-darkGrey bg-darkGrey p-3 cursor-pointer`}
                onClick={() => onCardClick && onCardClick()}
            >
                <div className="grid grid-cols-7 sm:grid-cols-2 items-center gap-3 w-full justify-end">
                    <div className="flex flex-col col-span-4 sm:col-span-1 justify-between gap-3">
                        <h1 className='font-semibold text-white'>Most Recent Transfer</h1>
                        <div className="flex gap-5">
                            <p className='text-sm text-blueGrey'>{moment(date).format('DD-MM-YYYY')} </p>
                            <p className='text-sm text-secondary'>ID - {number} </p>
                        </div>
                    </div>
                    <div className="flex gap-3 col-span-3 sm:col-span-1 items-center justify-end w-full">
                        <div className='bg-lightGrey p-2 rounded-full cursor-pointer'
                            onClick={(e) => {
                                e.stopPropagation()
                                window.open(`${process.env.REACT_APP_BACKEND_BASEURL}pdf/reciept?number=${number}`, '_blank')
                            }}
                        >
                            <Svgs.CashIcon fill={'var(--secondary-color)'} />
                        </div>
                        <div className='p-2 font-semibold text-primary bg-secondary rounded-lg'>
                            {amount} {currency}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default PaymentCard