import { getAnnoncedConciergeReward } from 'Adapters/APIs/Concierge/Details'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useAnnounceReward = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // redux states
    const state = useSelector(state => state)
    const ar_pagination = state?.concierge?.ar_pagination

    // states
    const [loading, setLoading] = useState(true)
    const [announceCurrentPage, setAnnounceCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)
    const [rewards, setRewards] = useState([])
    const [winnerDetail, setWinnerDetail] = useState(false)

    // get announce rewards
    const getAnnouncedRewards = () => {
        const success = (data) => {
            let updatedData = []
            if (data?.rewards?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...rewards || [], ...data?.rewards];
                } else {
                    updatedData = data?.rewards;
                }
            }
            setRewards(updatedData)
            setLoading(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoading(false)
            setSecondLoader(false)
        }
        setLoading(true)
        let payload = {
            page: announceCurrentPage,
            per_page: 10
        }
        dispatch(getAnnoncedConciergeReward(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAnnouncedRewards()
    }, [announceCurrentPage])

    return {
        loading, rewards, announceCurrentPage, setAnnounceCurrentPage, secondLoader, ar_pagination, winnerDetail, setWinnerDetail
    }

}

export default useAnnounceReward