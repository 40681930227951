import React, { useEffect, useRef, useState } from 'react'
import Svgs from 'Assets/svgs';
import { DIAL_CODES } from 'Constants/Data/DialCodes';
import { country_code_url } from 'Adapters/variables';
import FlagDropdown from './FlagDropdown';

const PhoneInput = ({ onEnterSubmit, title, placeholder, isCapitalized, name, value, onChange, edit, onBlur, onFocus, error, required }) => {
    const dial_code_ref = useRef()

    const [currentLocationCode, setCurrentLocationCode] = useState('+971')
    const [code, setCode] = useState('+971');
    const [number, setNumber] = useState('');
    const [focus, setFocus] = useState(false);

    const parsedPlaceholder = isCapitalized ? placeholder : placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    useEffect(() => {
        const arrValue = value?.split("-");
        if (value && arrValue[1] != '' && arrValue[0]) {
            setCode((arrValue[0] && arrValue[0] !== 'null') ? arrValue[0] : "+971")
            setNumber(arrValue[1])
        } else if (value && !arrValue[1] && arrValue[0]) {
            setCode((arrValue[0] && arrValue[0] !== 'null') ? arrValue[0] : "+971")
            setNumber('')
        } else {
            setCode(currentLocationCode ? currentLocationCode : '+971')
            setNumber('')
        }
    }, [edit, value, currentLocationCode])

    useEffect(() => {
        // get_user_location()
    }, [])

    const HandleKeyPress = (e) => {
        // Allow navigation keys and control keys (copy, paste, select, etc.)
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab',
            'Control', 'Meta', 'Shift', 'Home', 'End', 'Enter'
        ];

        // regular expression pattern for only numeric characters
        const onlyNumericCharsAllowed = /[a-zA-Z=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,.?~\\-]/g;
        // Check your condition here to disallow special characters
        if (!allowedKeys.includes(e.key) && !/[0-9]/.test(e.key)) {
            // If input doesn't match, prevent the default behavior (e.g., typing the invalid character)
            // e.preventDefault();
        }

        // Execute submit action on Enter key
        if (e.key === 'Enter') {
            onEnterSubmit();
        }
    };

    // handle change function
    const handleChange = (codeValue, numberValue) => {
        onChange && onChange(
            {
                target: {
                    value: `${codeValue && `${codeValue}-`}${numberValue ? numberValue : ''}`,
                    name: name ? name : 'mobile_number'
                }
            }
        )
    }

    // get user location
    const get_user_location = () => {
        fetch(country_code_url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
            })
            .then(result => {
                let my_country = DIAL_CODES.find(cd => cd.Code.toLowerCase() == result?.country_code?.toLowerCase())
                if (edit) {
                    if ((value && value?.split('-')[1] == '') || !value) {
                        if (my_country) {
                            setCurrentLocationCode(my_country?.label)
                        }
                        else {
                            setCurrentLocationCode("+971")
                        }
                    } else {
                        if (my_country) {
                            setCurrentLocationCode(my_country?.label)
                        }
                        else {
                            setCurrentLocationCode("+971")
                        }
                    }
                } else {
                    if (!edit) {
                        if (my_country) {
                            setCurrentLocationCode(my_country?.label)
                        }
                        else {
                            setCurrentLocationCode("+971")
                        }
                    }
                }
            })
            .catch(err => {
                setCurrentLocationCode("+971")
            })
    }

    return (
        <div className='flex flex-col gap-2 relative'>
            {title && (
                <div className={`${title == '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div className={`flex ${error ? " border-[1.5px] border-error" : focus ? 'border-[1.5px] border-secondary' : "border border-lightGrey"} rounded-[10px] ease-in transition-all bg-darkGrey text-base`}>
                <div className='w-[110px] rounded-l-[10px] bg-lightGrey pl-1.5 remove-indicator-dropdown flex items-center overflow-hidden' id='remove-indicator-dropdown'>
                    <FlagDropdown
                        title={''}
                        showTitle={false}
                        value={code}
                        placeholder=''
                        options={[
                            ...DIAL_CODES.map((cd) => {
                                return {
                                    label: <div className='flex items-center gap-1.5'>
                                        <img src={`https://flagcdn.com/${cd?.Code?.toLowerCase()}.svg`} className='h-[1rem] w-[1.5rem] object-cover' alt={cd?.Code} />
                                        {cd.label}
                                    </div>,
                                    code: cd?.label,
                                    value: cd?.value,
                                }
                            })]}
                        onChange={(e) => {
                            setCode(e.target.value)
                            handleChange(e.target.value, number)
                        }}
                        className='border-0 flex-1 h-full min-w-[11ch]'
                        noBorder={true}
                        borderRadius={'20px'}
                    />
                </div>
                <input
                    onKeyDown={HandleKeyPress}
                    disabled={!code}
                    type="number"
                    placeholder={parsedPlaceholder}
                    name={name}
                    value={number}
                    onFocus={(e) => {
                        setFocus(true)
                        onFocus && onFocus()
                    }}
                    onBlur={(e) => {
                        setFocus(false);
                        onBlur && onBlur(e);
                    }}
                    // autoComplete="off"
                    onChange={(e) => {
                        if (e.target.value == '') {
                            if (dial_code_ref && dial_code_ref.current) {
                                dial_code_ref.current.focus()
                            }
                        }
                        setNumber((e.target.value).replace(/[^0-9]/g, ''))
                        handleChange(code, (e.target.value).replace(/[^0-9]/g, ''))
                    }}
                    className={`px-4 py-[0.5rem] w-full bg-darkGrey rounded-tr-[10px] rounded-br-[10px] outline-none focus:!outline-none focus:!border-none text-sm disabled:bg-gray-800 disabled:cursor-not-allowed`}
                />
            </div>
            {error &&
                <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" />
                    {error}
                </p>
            }
        </div>
    )
}

PhoneInput.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    edit: false,
    onEnterSubmit: () => { }
}

export default PhoneInput