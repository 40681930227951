import { SOCKET_BASE_URL } from "Adapters/variables";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

// Initialize the socket connection
const socket = io(SOCKET_BASE_URL);

// Create context for the app
const AppContext = React.createContext();

const SocketAppProvider = ({ children }) => {
  const dispatch = useDispatch();

  // Fetch local storage data
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const user_data = getDataFromLocalStorage('user_data');
  const access_token = getDataFromLocalStorage('access_token');

  // Redux states
  const redux = useSelector((state) => state);


  // get default route data
  function getUpdatedDefaultRoute() {
    const defaultRoute = localStorage.getItem("defaultRoute");

    if (defaultRoute) {
      // Remove leading slashes and split by '/'
      const segments = defaultRoute.replace(/^\/+/, '').split('/');
      // Check if the second segment is available (after '/dashboard')
      return segments[1] || ''; // Get the segment immediately after '/dashboard'
    }

    // Return a default value if no valid route is found
    return '';
  }


  useEffect(() => {
    // Socket channel constants
    const SOCKET_CHANNEL = {
      TEST: "user-channel.1:TEST",
      CONNECT: "connect",
      NOTIFICATIONEVENT: `notification-channel.${user_data?.id}:NotificationEvent`,
    };

    // Request notification permission
    const requestNotificationPermission = () => {
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.log("Notification permission denied.");
          }
        });
      }
    };

    // Function to show browser notification
    const showBrowserNotification = (heading, payload) => {
      if (Notification.permission === "granted") {
        Cookies.set('getNotificationData', true);

        // Extract notification data
        const notificationData = payload;
        const notificationType = notificationData.type;
        const notificationBody = JSON.parse(notificationData?.body);
        let clickActionUrl = '/';

        const updatedDefaultRoute = getUpdatedDefaultRoute();

        switch (notificationType) {
          case 'reservation_alert':
          case 'reservation_update_alert':
          case 'reservation_cancelled':
          case 'reservation_modified':
          case 'reservation_modified_confirmed':
          case 'reservation_confirmed':
          case 'reservation_no_show':
          case 'restaurant_request_alert':
          case 'bill_alert':
            clickActionUrl = `/${updatedDefaultRoute}/booking/?reservation=${notificationBody?.notification?.notification_data}`;
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/booking`)  && (!JSON.parse(sessionStorage.getItem('create_booking')) && updatedDefaultRoute != 'restaurant')) {
              window.location.href = `/${updatedDefaultRoute}/booking/?reservation=${notificationBody?.notification?.notification_data}`;
            }
            break;
          case 'commission_paid_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/commission`)) {
              window.location.href = `/${updatedDefaultRoute}/commission`
            }
            clickActionUrl = `/${updatedDefaultRoute}/commission`;
            break;
          case 'commission_confirmation_alert':
            if (window.location.pathname.includes(`/${updatedDefaultRoute}/payment`)) {
              window.location.href = `/${updatedDefaultRoute}/payment`
            }
            clickActionUrl = `/${updatedDefaultRoute}/payment`;
            break;

          case 'user_deactivate_alert':
            Cookies.set('logoutUser', true);
            return;
          default:
            clickActionUrl = '/';
        }

        if (notificationType !== "user_deactivate_alert") {
          // Create a notification
          const notification = new Notification(heading?.title, {
            body: heading?.body,
            icon: "https://conciergebooking.tijarah.ae/er-logo.svg", // Optional: Add a custom icon here
          });

          // Add a click event listener
          notification.onclick = (event) => {
            event.preventDefault(); // Prevent the browser from focusing the Notification's tab

            // Safari-specific workaround
            setTimeout(() => {
              if (window.open) {
                // Try opening a new window
                const win = window.open(clickActionUrl, '_blank');
                if (win) {
                  win.focus();
                } else {
                  // If window.open is blocked, fallback to window location
                  window.location.href = clickActionUrl;
                }
              } else {
                // Fallback to changing the current window's location
                window.location.href = clickActionUrl;
              }
            }, 100); // Small timeout to bypass Safari's restrictions
          };
        }
      }
    };


    // Request permission for notifications when the component mounts
    requestNotificationPermission();

    // Handle socket connection event
    socket.on(SOCKET_CHANNEL.CONNECT, () => {
      if (!socket.connected) {
        console.log(socket.connected, "Socket not connected");
      } else {
        console.log(socket.connected, "Socket connected");
      }
    });

    // Test Channel for socket
    socket.on(SOCKET_CHANNEL.TEST, (data) => {
      console.log(data, "Test Channel");
    });

    if (user_data?.id) {
      // Handle Notifications
      socket.on(SOCKET_CHANNEL.NOTIFICATIONEVENT, (data) => {
        console.log("🚀 ~ socket.on ~ Notification event data:", data);

        // Generate a browser notification when a notification event is received
        showBrowserNotification(data?.message?.notification?.body?.notification, data?.message?.notification?.body?.data);

        // Dispatch notification-related actions if needed
        // dispatch(getUserNotifications(access_token));
      });
    }

    // Clean up the event listeners on unmount
    return () => {
      socket.off(SOCKET_CHANNEL.CONNECT);
      socket.off(SOCKET_CHANNEL.NOTIFICATIONEVENT); // Unsubscribe from notification event
    };
  }, [user_data?.id]);

  return (
    <AppContext.Provider value={{ ...redux, socket }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to access context
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, SocketAppProvider };


// import React, { useState, useContext, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import io from "socket.io-client";
// import { SOCKET_BASE_URL } from "Adapters/variables";
// import Cookies from "js-cookie";

// // Initialize the socket connection
// const socket = io(SOCKET_BASE_URL);

// // Create context for the app
// const AppContext = React.createContext();

// const SocketAppProvider = ({ children }) => {
//   const dispatch = useDispatch();
//   const redux = useSelector((state) => state);

//   // State to manage in-app notification display
//   const [notification, setNotification] = useState(null);

//   // Function to request notification permission
//   const requestNotificationPermission = () => {
//     if ("Notification" in window) {
//       Notification.requestPermission().then((permission) => {
//         console.log(`Notification permission: ${permission}`);
//       });
//     }
//   };

//   // Function to handle showing in-app notifications
//   const showInAppNotification = (heading, payload) => {
//     setNotification({
//       title: heading?.title,
//       body: heading?.body,
//       data: payload,
//     });

//     // Hide notification after 5 seconds
//     setTimeout(() => setNotification(null), 5000);
//   };

//   // Socket handling, request permission, and notification logic
//   useEffect(() => {
//     const user_data = JSON.parse(localStorage.getItem('user_data'));
//     const SOCKET_CHANNEL = {
//       CONNECT: "connect",
//       NOTIFICATIONEVENT: `notification-channel.${user_data?.id}:NotificationEvent`,
//     };

//     // Request notification permissions on component mount
//     requestNotificationPermission();

//     // Handle socket connection status
//     socket.on(SOCKET_CHANNEL.CONNECT, () => {
//       console.log(socket.connected ? "Socket connected" : "Socket not connected");
//     });

//     if (user_data?.id) {
//       // Listen for notification events from the socket
//       socket.on(SOCKET_CHANNEL.NOTIFICATIONEVENT, (data) => {
//         console.log("Notification event data:", data);

//         // Show an in-app notification
//         showInAppNotification(
//           data?.message?.notification?.body?.notification,
//           data?.message?.notification?.body?.data
//         );
//       });
//     }

//     // Clean up the event listeners on unmount
//     return () => {
//       socket.off(SOCKET_CHANNEL.CONNECT);
//       socket.off(SOCKET_CHANNEL.NOTIFICATIONEVENT);
//     };
//   }, []);

//   return (
//     <AppContext.Provider value={{ ...redux, socket }}>
//       {children}
//       {notification && (
//         <div className="custom-notification">
//           <h3>{notification.title}</h3>
//           <p>{notification.body}</p>
//         </div>
//       )}
//     </AppContext.Provider>
//   );
// };

// // Custom hook to access context
// export const useGlobalContext = () => {
//   return useContext(AppContext);
// };

// export { AppContext, SocketAppProvider };
