import React from 'react'

const AdminRestaurantCardShimmer = ({ showImages, hideInfo, bookBtn, hideStatus }) => {
    return (
        <div className="bg-darkGrey shadow-lg rounded-xl p-4 w-full animate-pulse">
            {showImages &&
                <div className='flex justify-between gap-4'>
                    <div className='h-28 w-32 rounded-lg bg-gray-500'></div>
                    <div className='h-28 w-32 rounded-lg bg-gray-500'></div>
                    <div className='h-28 w-32 rounded-lg bg-gray-500'></div>
                    <div className='h-28 w-32 rounded-lg bg-gray-500'></div>
                </div>
            }
            <div className='flex items-center gap-2'>
                {!showImages &&
                    <div className="flex items-center justify-center h-[4rem] w-[4rem] overflow-hidden rounded-full bg-gray-500 ">
                    </div>
                }
                <div className="flex flex-1 flex-col space-y-2 mt-5">
                    <div className='flex justify-between'>
                        <div className='h-6 w-36 rounded-lg bg-gray-500'></div>
                        {!hideStatus && <div className='h-6 w-20 rounded-lg bg-gray-500'></div>}
                    </div>
                    <div className='h-6 w-32 rounded-lg bg-gray-500'></div>
                    <div className='h-6 w-3/4 rounded-lg bg-gray-500'></div>

                </div>
            </div>
            {!hideInfo &&
                <div className="flex items-center justify-center mt-4">
                    <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                        <div className='h-6 w-8 rounded-lg bg-gray-500'></div>
                        <div className='h-6 w-20 rounded-lg bg-gray-500'></div>
                        <div className='h-6 w-8 rounded-lg bg-gray-500'></div>
                    </div>
                    <div className='border-[1.5px] mx-auto border-blueGrey h-16'></div>
                    <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                        <div className='h-6 w-8 rounded-lg bg-gray-500'></div>
                        <div className='h-6 w-20 rounded-lg bg-gray-500'></div>
                        <div className='h-6 w-8 rounded-lg bg-gray-500'></div>
                    </div>
                    <div className='border-[1.5px] mx-auto border-blueGrey h-16'></div>
                    <div className="flex flex-1 flex-col justify-center items-center text-center space-y-2">
                        <div className='h-6 w-8 rounded-lg bg-gray-500'></div>
                        <div className='h-6 w-20 rounded-lg bg-gray-500'></div>
                        <div className='h-6 w-8 rounded-lg bg-gray-500'></div>
                    </div>
                </div>
            }
            {bookBtn
                ? <div className="flex items-center justify-end gap-4 mt-4 ">
                    <div className='h-12 w-full flex-end rounded-lg bg-gray-500 max-w-32'></div>
                </div>
                : <div className="flex items-center justify-between gap-4 mt-4">
                    <div className='h-12 w-full rounded-lg bg-gray-500'></div>
                    <div className='h-12 w-full rounded-lg bg-gray-500'></div>
                </div>
            }
        </div>
    )
}

export default AdminRestaurantCardShimmer