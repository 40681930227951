import React from 'react'

const WishlistShimmer = () => {
    return (
        <div className='flex flex-col gap-4 p-3 animate-pulse bg-darkGrey rounded-lg'>
            <div class="bg-gray-300 mb-1 h-5 w-24 rounded"></div>
            <div class="bg-gray-300 h-5 w-32 rounded"></div>
            <div class="bg-gray-300 h-5 w-24 rounded"></div>
            <div class="bg-gray-300 h-5 w-56 rounded"></div>
        </div>
    )
}

export default WishlistShimmer