import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import moment from 'moment'
import React from 'react'

const Card = ({ index, onCardClick, showImage, conciergeImage, name, status, phoneNumber, businessName, created_at, type }) => {
    return (
        <div className="bg-darkGrey rounded-xl p-4 w-full cursor-pointer" key={index} onClick={() => { onCardClick && onCardClick() }}>
            <div className='flex gap-2 items-center'>
                {showImage ?
                    conciergeImage ?
                        <div className="flex items-center justify-center h-[3.5rem] w-[3.5rem] overflow-hidden rounded-full bg-gray-200 ">
                            <Image src={conciergeImage} className="w-full h-full object-cover" />
                        </div>
                        :
                        <Svgs.UserIcon2 width={'56'} height={'56'} />
                    : ""
                }
                <div className='flex gap-2 items-center justify-between flex-1'>
                    <div className="flex flex-1 flex-col space-y-2 mt-5">
                        <h1 className='font-semibold text-blueGrey'>{name}</h1>
                        <div className="flex items-start gap-2 text-blueGrey mt-1 text-sm">
                            <span className='font-medium'>Created at: {created_at ? moment(created_at).format('DD-MM-YYYY') : ""}</span>
                        </div>
                        <div className="flex gap-2 items-center text-sm text-blueGrey">
                            <span><Svgs.PhoneIcon stroke={'var(--secondary-color)'} /></span>
                            <span className="">{phoneNumber}</span>
                        </div>
                        <div className="flex items-start gap-2 mt-1 text-sm text-blueGrey">
                            <span> {type == 'concierge' ? <Svgs.BuildingIcon fill={'var(--secondary-color)'} /> : <Svgs.LocationIcon stroke={'var(--secondary-color)'} />}</span>
                            <span className=''>{businessName}</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2 items-center'>
                        {
                            status == 0 ?
                                <div className='flex justify-between items-center gap-2 bg-[#1D8D70] px-3 py-2 rounded-lg'>
                                    <p className='text-xs text-white font-semibold'>Pending</p>
                                </div> :
                                <div className='flex justify-between items-center gap-2 bg-[#C00E0E] px-3 py-2 rounded-lg'>
                                    <p className='text-xs text-white font-semibold'>Rejected</p>
                                </div>
                        }
                        <div className='p-1'>
                            <Svgs.ArrowNext2 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card