import Button from 'Components/Elements/Button'
import ImageUploader from 'Components/Elements/ImageUploader'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminCreateEditPromotions from './helper'
import FloatInput from 'Components/Elements/FloatInput'
import Dropdown from 'Components/Elements/Dropdown'
import Textarea from 'Components/Elements/TextArea'

const AdminCreateEditPromotions = ({ open, close, editData, successCallBack }) => {
    const { packageData, loader, updateOrAddPromotionData, handleChange, errors
    } = useAdminCreateEditPromotions({ editData, close, successCallBack })
    return (
        <Popup
            open={open}
            close={close}
            heading={editData ? "Edit promotion" : "Add promotion"}
            customSizeStyle={'sm:w-[40vw] md:w-[50vw] lg:w-[40vw]'}
        >
            <div className="flex flex-col space-y-5">
                <div>
                    <Dropdown
                        required={true}
                        title={'Select Type'}
                        placeholder={'Select Type'}
                        value={packageData?.type}
                        name={'type'}
                        onChange={handleChange}
                        option={[
                            { label: 'Restaurant', value: 'Restaurant' },
                            { label: 'Promotion', value: 'Promotion' }
                        ]}
                    />
                </div>
                <LoginInput
                    required={true}
                    title={'Package Name'}
                    placeholder={'Package Name'}
                    value={packageData?.name}
                    name={'name'}
                    onChange={handleChange}
                    error={errors?.name}
                />
                <LoginInput
                    required={true}
                    title={'Days'}
                    placeholder={'Days'}
                    onlyNumericAllowed={true}
                    value={packageData?.duration}
                    name={'duration'}
                    onChange={handleChange}
                    error={errors?.duration}
                />
                <FloatInput
                    required={true}
                    title={'Price'}
                    placeholder={'Price'}
                    value={packageData?.price}
                    name={'price'}
                    onChange={handleChange}
                    error={errors?.price}
                />
                <ImageUploader
                    title={'Upload your promotion image'}
                    value={packageData?.image}
                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF"}
                    multiple={false}
                    id={'CreateEditReward'}
                    mb_file_size={8}
                    onChange={handleChange}
                    error={errors?.image}
                    name={'image'}
                    customHeight={"h-[180px]"}
                    showDummy={true}
                />
                <Textarea
                    placeholder={'Description'}
                    required={true}
                    title={"Description"}
                    limit={500}
                    rows={8}
                    name={'description'}
                    value={packageData?.description}
                    error={errors?.description}
                    onChange={handleChange}
                />
                <Button
                    title={editData ? "Save" : "Add"}
                    loading={loader}
                    disabled={loader}
                    onClick={updateOrAddPromotionData}
                />
            </div>
        </Popup>
    )
}

export default AdminCreateEditPromotions