import React from 'react'
import DashboardLayout from '../../../Components/DashboardLayout'
import RewardCard from '../../../Components/RewardCard'
import RewardsShimmer from 'Utility/Shimmers/RewardsShimmer'
import ReactMonthPicker from 'Components/Elements/ReactMonthPicker'
import useRewards from './helper'
import NotFound from 'Utility/NotFound'
import Button from 'Components/Elements/Button'
import CreateEditReward from './CreateEditReward'
import AnnounceReward from './AnnounceReward'
import Back from 'Components/Elements/Back'

const AdminRewards = () => {
    const {
        navigate, type, setType, loading, rewards, monthValue, setMonthValue, addEditReward, setAddEditReward, editData, setEditData,
        getRewardDataForFirstTime, announceReward, setAnnounceReward, handleRedirectionAnnounceProject
    } = useRewards()
    return (
        <>
            <DashboardLayout active={'reward'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="pt-4 space-y-5">
                        <div className="flex items-center gap-3 justify-between">
                            <Back
                                title={'Rewards'}
                            />
                            <div className="flex justify-end my-2 gap-4">
                                <Button
                                    text={'Create Reward'}
                                    onClick={() => { setAddEditReward(true) }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="border-b flex items-baseline gap-5 text-blueGrey">
                                <div className="flex items-baseline gap-5 text-blueGrey">
                                    <p onClick={() => setType('announced')} className={`${type == 'announced' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Announced &nbsp;
                                    </p>
                                    <p onClick={() => setType('upcomming')} className={`${type == 'upcomming' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Upcoming &nbsp;
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-end my-4">
                                <ReactMonthPicker
                                    onChange={(e) => setMonthValue(e?.target?.value)}
                                    value={monthValue}
                                    name={'month'}
                                    isClearable={true}
                                />
                            </div>
                            <div className="pb-6">
                                <div className="rounded-lg md:rounded-[10px] border border-[#ECECEC]">
                                    {loading
                                        ? <div className={`p-4 grid grid-cols-1 ${type == "announced" ? "xl:grid-cols-2" : 'md:grid-cols-2'} w-full gap-5`}>
                                            <RewardsShimmer isAnnounced={type == "announced"} />
                                        </div>
                                        : rewards?.length > 0
                                            ?
                                            <div className={`p-4 grid grid-cols-1 ${type == "announced" ? "xl:grid-cols-2" : 'md:grid-cols-2'} w-full gap-5`}>
                                                {rewards?.map(itm => {
                                                    return <RewardCard
                                                        image={itm?.reward}
                                                        data={itm}
                                                        date={itm?.date}
                                                        winner={itm?.winner?.user}
                                                        isAnnounced={type == "announced"}
                                                        allowEditAndAnnounce={type == "upcomming"}
                                                        onEditClick={() => { setEditData(itm); setAddEditReward(true); }}
                                                        onAnnounceClick={() => { handleRedirectionAnnounceProject(itm) }}
                                                    />
                                                })
                                                }
                                            </div>
                                            : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>

            {addEditReward &&
                <CreateEditReward
                    open={addEditReward}
                    close={() => {
                        setEditData()
                        setAddEditReward(false)
                    }}
                    editData={editData}
                    successCallBack={getRewardDataForFirstTime}
                />}

            {announceReward &&
                <AnnounceReward
                    open={announceReward}
                    close={() => {
                        setAnnounceReward()
                    }}
                    announceReward={announceReward}
                    successCallBack={getRewardDataForFirstTime}
                />}
        </>
    )
}

export default AdminRewards