import Svgs from 'Assets/svgs'
import React from 'react'
import NotFound from 'Utility/NotFound'
import useRestaurantMenu from './helper'
import Accordion from 'Components/Elements/Accordian'
import Button from 'Components/Elements/Button'
import AddMenuPopup from './AddMenu'
import DeletePopup from './DeleteVerificationPopup'
import ContentCard from 'Pages/Admin/Restaurants/SingleRestaurant/Menu/ContentCard'
import ButtonDropdown from 'Components/Elements/ButtonDropdown'
import ButtonShimmer from 'Utility/Shimmers/ButtonShimmer'
import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import { copyToClipBoard } from 'Constants/Functions/CopyToClipBoard'

const RestaurantMenu = () => {
    const {
        manu_category, loader, deleteMainMenu, type, setType, onSuccess, addPopup, setAddPopup, deletePopup, setDeletePopup, loading,
        pdfMenu, pdfMenuLoader, deletePDF, pDFDeleteLoader, pdfData, deleteConfirmation, setDeleteConfirmation
    } = useRestaurantMenu()

    return (
        <DashboardLayout active={'menu'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 auto min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 w-full justify-between">
                    <div className="pt-4 space-y-5 w-full">
                        <div className="flex xs:flex-row flex-col justify-between xs:items-center">
                            <Back
                                title={'Menu'} />
                            <div className='flex justify-end gap-2'>
                                {/* {(pdfMenuLoader && !pDFDeleteLoader)
                                    ? <div className="flex justify-end">
                                        <ButtonShimmer customHeightWidth={'h-14 w-32 flex items-center !self-end'} />
                                    </div>
                                    :
                                    (pdfMenu?.url || pdfMenu?.beverage || pdfMenu?.dessert) &&
                                    <ButtonDropdown
                                        pdfURL={pdfMenu}
                                        title={'PDF Menu'}
                                        onEditClick={setAddPopup}
                                        deletePDF={deletePDF}
                                        pDFDeleteLoader={pDFDeleteLoader}
                                        pdfMenuLoader={pdfMenuLoader}
                                    />
                                } */}
                                <Button title={'Add Menu'} onClick={() => setAddPopup(true)} />
                            </div>
                        </div>
                        <div className="border-b flex w-full items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setType('main')} className={`${type == 'main' ? 'activePage' : ''} cursor-pointer font-semibold sm:text-base text-sm pb-2 flex`}>Main Menu  &nbsp;
                            </p>
                            <p onClick={() => setType('beverage')} className={`${type == 'beverage' ? 'activePage' : ''} cursor-pointer font-semibold sm:text-base text-sm pb-2 flex`}>Beverage Menu &nbsp;
                            </p>
                            <p onClick={() => setType('desert')} className={`${type == 'desert' ? 'activePage' : ''} cursor-pointer font-semibold sm:text-base text-sm pb-2 flex`}>Desert Menu &nbsp;
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pt-6 pb-2 flex-col flex space-y-2">
                    <h2 className='text-white text-xl'>Pdf Menu</h2>
                    {(pdfMenuLoader && !pDFDeleteLoader) ? 
                    <div className='bg-gray-500 rounded-md w-full h-14'></div>
                    : pdfData ?
                        <div className={`px-4 flex justify-between gap-3 bg-darkGrey text-blueGrey items-center p-2 rounded-xl shadow attach-file-btn`}>
                            <div className="flex items-center gap-4 line-clamp-1">
                                <div><Svgs.FileIcon /></div>
                                <div className='break-words'>{pdfData}</div>
                            </div>
                            <div className='flex justify-between items-center gap-2'>
                                <div className='cursor-pointer pt-2' onClick={() => {
                                    const pdfWindow = window.open(pdfData, '_blank'); // Opens the PDF in a new tab
                                    if (pdfWindow) {
                                        pdfWindow.focus(); // Focus on the new tab
                                    }
                                }}>
                                    <Svgs.EyeHide className={"h-6 w-6"} />
                                </div>
                                <div className='cursor-pointer' onClick={() => { setDeleteConfirmation(!deleteConfirmation) }}>
                                    <Svgs.DeleteIcon stroke={'var(--secondary-color)'} />
                                </div>
                            </div>
                        </div> : <NotFound />
                    }
                </div>
                <div className="pb-6 pt-2">
                    <h2 className='text-white text-xl'>Manual Menu</h2>
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            <Accordion
                                type={type}
                                items={manu_category?.map((itm, ind) => ({
                                    title: itm?.name,
                                    content: itm?.items?.map((obj, ind) => {
                                        return <ContentCard
                                            data={obj}
                                            index={ind}
                                            className={'flex justify-end self-end'}
                                            showDelete={true}
                                            onDeleteClick={(id) => setDeletePopup({ id: id, type: true })}
                                            heading={obj?.name}
                                            description={obj?.description}
                                            price={obj?.price}
                                            discount={obj?.discount}
                                        />
                                    }),
                                    id: itm?.id,
                                    name: itm?.name,
                                    restaurant_id: itm?.restaurant_id,
                                    status: itm?.status,
                                    type: itm?.type,
                                }))}
                                showEdit={true}
                                showBorder={false}
                                showDelete={true}
                                onDeleteClick={(id) => setDeletePopup({ id: id })}
                                onEditClick={(itm) => setAddPopup(itm)}
                                contentClass={'relative'}
                                loader={loader}
                            />
                            {(manu_category?.length == 0 && !loader) &&
                                <div className="p-4 flex flex-col items-center justify-center">
                                    <NotFound />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <AddMenuPopup
                    open={addPopup}
                    close={setAddPopup}
                    onSuccessCallback={onSuccess}
                    type={type == 'main' ? 'main_menu' : type == "beverage" ? "beverage_menu" : "dessert_menu"}
                />

                {/* Delete Popup */}
                    <DeletePopup
                        open={deletePopup && deletePopup?.id}
                        close={setDeletePopup}
                        title={<div className='text-white'>
                            {deletePopup?.type
                                ? 'Are you sure you want to delete the menu item?'
                                : 'Are you sure you want to delete the menu?'}
                        </div>
                        }
                        onDelete={deleteMainMenu}
                        loading={loading}
                    />
                {/* Delete PDF */}
                <DeletePopup
                    open={deleteConfirmation}
                    close={setDeleteConfirmation}
                    title={<div className='flex flex-col space-y-4 mx-auto items-center justify-center pb-4 text-center'>
                        <h2 className='text-white'>Are you sure you want to Delete?</h2>
                    </div>}
                    loading={pDFDeleteLoader}
                    onDelete={deletePDF}
                />
            </main>
        </DashboardLayout>
    )
}

export default RestaurantMenu