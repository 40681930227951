import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './date-picker.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import configureStore from './Redux/store';
import { Provider } from 'react-redux';

// Font awsome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { PopupManagerProvider } from 'Hooks/usePopupManager';
import { SocketAppProvider } from 'Context';

library.add(fas, fab, far);

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore();

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <SocketAppProvider>
    <Router>
      <PopupManagerProvider>
        <App />
      </PopupManagerProvider>
    </Router>
    </SocketAppProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
