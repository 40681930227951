import React from 'react'
import { currency } from 'Adapters/variables'

const AdminDashBoardCards = ({ className, amount, amountClassName, titleClassName, btnClass, title, btnColor, svgIcon, showCurrency, onClick, showSvg, noMinHeight, flexClass }) => {
    return (
        <>
            <div className={`cursor-pointer bg-darkGrey rounded-lg md:rounded-[10px] ${noMinHeight ? '' : "min-h-28"} flex flex-col gap-3 ${flexClass ? flexClass : "items-start"} justify-between p-5`} onClick={onClick}>
                {showSvg &&
                    <div className={`${btnClass} min-h-10`}>
                        <div className={`${btnColor} rounded-full min-h-10 min-w-10 h-10 w-10 bg-[#1D8D70] flex justify-center items-center`}>
                            {svgIcon}
                        </div>
                    </div>
                }
                <div className={`${className}`}>
                    <h4 className={`${amountClassName} font-semibold text-secondary text-xl`}>{amount || amount === 0 ? `${amount} ${showCurrency ? currency : ""}` : ""}</h4>
                    <p className={`${titleClassName ? titleClassName : "text-blueGrey"} font-medium `}>{title}</p>
                </div>
            </div>
        </>
    )
}

AdminDashBoardCards.defaultProps = {
    showCurrency: true,
    showSvg: true,
    noMinHeight: false,
    onClick: () => { }
}

export default AdminDashBoardCards