import Svgs from 'Assets/svgs'
import React from 'react'
import useRoleSelectionHelper from './helper'
import RoleSelectionCard from 'Components/Elements/RoleSelectionCard'
import Button from 'Components/Elements/Button'

const RoleSelection = () => {
    const { selectedRole, setSelectedRole, handleSelectedRole, navigate } = useRoleSelectionHelper()
    return (
        <section className="flex h-[100vh] w-full ">

            <div className="grid grid-cols-1 md:grid-cols-2 w-full ">
                <div className="flex-1 flex justify-center items-center h-full overflow-auto px-2">
                    <div className="max-w-fit w-full flex flex-col space-y-8">
                        <div className="flex justify-center">
                            <Svgs.AuthLogo width={'190'} height={'100'} />
                        </div>
                        <div className='h-[28rem] bg-darkGrey rounded-3xl p-8 text-center'>
                            <div className='flex flex-col space-y-2'>
                                <h2 className="text-3xl font-semibold pt-5 text-center text-white">Select Your Role</h2>
                                <p className='text-[#B7B7B7] text-center pt-1 pb-2 text-sm '>Are You a Hotel Manager or Restaurant Owner?</p>
                            </div>
                            <div className="flex xs:flex-row flex-col gap-6 my-10">
                                <RoleSelectionCard
                                    icon={<Svgs.RestaurantIcon fill={'var(--secondary-color)'} />}
                                    active={selectedRole == 'restaurant'}
                                    name={'Restaurant'}
                                    setActiveItem={setSelectedRole}
                                />
                                <RoleSelectionCard
                                    icon={<Svgs.ConciergeUserIcon fill={'var(--secondary-color)'} />}
                                    active={selectedRole == 'concierge'}
                                    name={'Concierge'}
                                    setActiveItem={setSelectedRole}
                                />
                            </div>
                            <div className=''>
                                <Button
                                    text={'Next'}
                                    className={'w-full'}
                                    onClick={handleSelectedRole}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center gap-2 text-sm pb-6">
                            <p className='text-white'>Already have an account?</p>
                            <span className='text-secondary cursor-pointer' onClick={() => navigate('/signup')}>Login</span>
                        </div>
                    </div>
                </div>
                <div
                    className="flex-1 w-full h-full bg-cover bg-no-repeat bg-center md:block hidden"
                    style={{ backgroundImage: "url('/img/auth/roleselect.png')" }}
                >
                    {/* <img className='h-full w-full' src="/img/auth/loginlayer.png" alt="" /> */}
                </div>
            </div>
        </section>
    )
}

export default RoleSelection