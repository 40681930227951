import Svgs from 'Assets/svgs'
import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useBookingAnalytics from './helper'
import AmountCard from '../Elements/AmountCard'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import Pagination from 'Components/Pagination'
import BookingAnalytics from 'Utility/Tables/AdminAnalyticsTable/BookingAnalytics'
import BookingAnalyticsFilter from '../Elements/BookingAnalyticsFilter'
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer'
import Back from 'Components/Elements/Back'

const AdminBookingAnalytics = () => {
    const {  activeTab, setActiveTab, dropdownOptions, paginationData, currentPage, setCurrentPage, filterData, setFilterData,
        getAdminBookingAnalytics, loader, data, addFilter, setAddFilter, handleChangeFilter, filterUpdate, onClearFilter
    } = useBookingAnalytics()
    return (
        <DashboardLayout active={'analytics'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <div className='flex gap-2 justify-between items-center py-4 px-2'>
                    <Back
                        title={'Booking Analytics'}
                    />
                    <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                </div>
                <div className="py-6">
                    {loader ?
                        <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                            {[0, 1, 2, 3, 4, 5]?.map((itm) => {
                                return (
                                    <AdminAmountCardShimmer />
                                )
                            })}
                        </div>
                        :
                        <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>

                            <AmountCard
                                amount={data?.total_bookings}
                                title={'Total Bookings'}
                            />
                            <AmountCard
                                amount={data?.placed_bookings}
                                title={'Placed'}
                            />
                            <AmountCard
                                amount={data?.confirmed_bookings}
                                title={'Confirmed'}
                                subTitle={'(Not Billed)'}
                            />
                            <AmountCard
                                amount={data?.no_show_bookings}
                                title={'No Show &'}
                                subTitle={'Cancelled'}
                            />
                            <AmountCard
                                amount={data?.billed_bookings}
                                title={'Billed'}
                            />
                            <AmountCard
                                amount={data?.commission_paid_bookings}
                                title={'Commission Paid'}
                            />
                        </div>
                    }
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-lightGrey">
                                    {loader
                                        ? <BookingTableShimer columns={10} />
                                        : <BookingAnalytics data={data?.all_bookings} />
                                    }
                                    {data?.length > 0
                                        && <Pagination
                                            currentPage={currentPage}
                                            pageSize={paginationData?.per_page}
                                            totalCount={paginationData?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={paginationData?.total}
                                            currentPageCount={data?.all_bookings?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <BookingAnalyticsFilter
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={() => {
                    setAddFilter(false)
                    if (filterUpdate) {
                        getAdminBookingAnalytics()
                    }
                }}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filterData={filterData}
                setFilterData={setFilterData}
                dropdownOptions={dropdownOptions}
                handleChangeFilter={handleChangeFilter}
            />
        </DashboardLayout>
    )
}

export default AdminBookingAnalytics