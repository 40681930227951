import { getCommissionAnalytics, paymentReceive } from "Adapters/APIs/Concierge/Details"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

let initialFilterState = {
    start_date: "",
    end_date: "",
    type: "",
}
const useConceirgeCommission = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const userData = getDataFromLocalStorage('user_data')

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [currentPage, setCurrentPage] = useState(1)
    const [commissionloading, setCommissionLoading] = useState(true)
    const [openDetail, setOpenDetail] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterPopup, setFilterPopup] = useState(false)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [appliedFilter, setAppliedFilter] = useState()

    // redux states
    const state = useSelector(state => state)
    const commissions = state?.concierge?.commission_analytics
    const pagination_data = state?.concierge?.ca_pagination
    const commission_stats = state?.concierge?.commission_stats
    const recent_transfers = state?.concierge?.recent_transfers

    // on apply filter
    const onApplyFilter = () => {
        setFilterPopup(false)
        if (filterUpdate) {
            getCommission()
        }
    }

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // get Booking Analytics
    const getCommission = (noFilter) => {
        let payload = {
            page: currentPage,
            per_page: 10
        }
        if (!noFilter) {
            payload = {
                ...payload,
                page: currentPage,
                end_date: filterData?.end_date,
                start_date: filterData?.start_date,
                type: filterData?.type,
                filter: filterData?.filter == 'date' ? '' : filterData?.filter
            }
            setAppliedFilter({
                end_date: filterData?.end_date,
                start_date: filterData?.start_date,
                type: filterData?.type,
                filter: filterData?.filter == 'date' ? '' : filterData?.filter
            })
        }
        setCommissionLoading(true)
        const success = () => {
            setCommissionLoading(false)
        }
        const fail = () => {
            setCommissionLoading(false)
        }
        dispatch(getCommissionAnalytics(access_token, payload, success, fail, setCommissionLoading))
    }

    // on clear filter
    const onClearFilter = () => {
        setFilterPopup(false)
        setAppliedFilter()
        const prevFilterData = initialFilterState;
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            getCommission(true)
            setFilterData(initialFilterState)
        }
    }

    // on receve click functon
    const onRecveiveClick = (number) => {
        setLoading(true)
        const success = () => {
            getCommission()
            setLoading(false)
            setOpenDetail(false)
        }
        const fail = () => {
            setLoading(false)
        }
        dispatch(paymentReceive(access_token, { transfer_number: number }, success, fail))
    }

    useEffect(() => {
        getCommission()
    }, [currentPage])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        commissionloading, commission_stats, recent_transfers, setOpenDetail, commissions, currentPage, pagination_data, setCurrentPage,
        openDetail, userData, loading, onRecveiveClick, filterPopup, setFilterPopup, onApplyFilter, onClearFilter, setFilterData, filterData,
        handleChangeFilter, appliedFilter
    }

}

export default useConceirgeCommission