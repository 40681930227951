import { getAdminFinanceInvoicesData } from "Adapters/APIs/Admin/finance";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAdminPendingPayables = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [monthValue, setMonthValue] = useState()
    const [type, setType] = useState('unpaid')
    const [activeTab, setActiveTab] = useState('concierges')
    const [financeData, setFinanceData] = useState([])
    const [paginationData, setPaginationData] = useState()
    const [filter, setFilter] = useState('')

    // get admin finance data
    const getAdminFinance = () => {
        const date = moment(monthValue);
        const month = date.format('MM');
        const year = date.format('YYYY');
        let payload = {
            page: currentPage,
            per_page: 10,
            type: type,
            filter: filter,
            year: monthValue ? +year : "",
            month: monthValue ? +month : "",
            sub_type: activeTab
        }
        const success = (data) => {
            setFinanceData(data?.invoices?.data)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        if (filter != 'date' || (filter == 'date' && monthValue)) {
            dispatch(getAdminFinanceInvoicesData(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        getAdminFinance()
    }, [currentPage, monthValue, filter])

    useEffect(() => {
        if (filter != 'date' || (filter == 'date' && monthValue)) {
            setLoader(true)
            setCurrentPage(1)
        }
    }, [monthValue, filter])

    return {
        financeData, navigate, paginationData, loader, currentPage, setCurrentPage, monthValue, activeTab, setActiveTab, type, setType,
        setMonthValue, filter, setFilter
    }
}

export default useAdminPendingPayables