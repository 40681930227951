import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import FloatInput from 'Components/Elements/FloatInput'
import InfoIcon from 'Components/Elements/InfoIcon'
import Popup from 'Components/Elements/Popup'
import { Distance, SORT_BY_FILTER } from 'Constants/Data/Categories'
import { CATEGORY_DATA } from 'Constants/Data/TooltipData'
import StarRatings from 'Pages/Restaurants/Profile/RatingPopup/StarsRating'
import React from 'react'

const RestaurantFIlterPopup = ({ open, close, Cuisines, onChange, filterData, setFilterData, onSubmit, onClearFilter, categories }) => {
    return (
        <Popup
            open={open}
            close={close}
            heading={'Apply Filter'}
            size={'xs'}
        >
            <div className="flex flex-col gap-4">
                <Dropdown
                    info_icon={<InfoIcon
                        text={""}
                        info={<div className='flex flex-col px-2 space-y-2 max-w-80 overflow-y-auto text-start max-h-96'>
                            {CATEGORY_DATA?.map((itm, ind) => (
                                <div key={ind} className='flex flex-col space-y-1'>
                                    <h4 className='font-bold text-white'>{itm?.label}:</h4>
                                    <p className='text-blueGrey'>{itm?.value}</p>
                                </div>
                            ))}
                        </div>}
                    />}
                    title={'By Category'}
                    placeholder={'By Category'}
                    value={filterData?.category}
                    name={'category'}
                    onChange={onChange}
                    option={categories}
                />
                <Dropdown
                    title={'By Cuisine'}
                    placeholder={'By Cuisine'}
                    value={filterData?.cuisine_id}
                    name={'cuisine_id'}
                    onChange={onChange}
                    option={Cuisines}
                />
                <FloatInput
                    title={'Price per cover'}
                    placeholder={'Enter price per cover'}
                    value={filterData?.price_per_cover}
                    name={'price_per_cover'}
                    onChange={onChange}
                    required={false}
                />
                <Dropdown
                    title={'Sort By'}
                    placeholder={'Sort By'}
                    value={filterData?.sort_by}
                    name={'sort_by'}
                    onChange={onChange}
                    option={SORT_BY_FILTER}
                />
                {filterData?.sort_by == 'distance' &&
                    <Dropdown
                        title={'By Distance'}
                        placeholder={'By Distance'}
                        value={filterData?.radius}
                        name={'radius'}
                        onChange={onChange}
                        option={Distance}
                    />
                }
                <StarRatings
                    onChange={onChange}
                    name={'google_rating'}
                    className={'max-w-72'}
                    title={'By google rating'}
                    value={filterData?.google_rating}
                />
                <StarRatings
                    onChange={onChange}
                    name={'tripadvisor_rating'}
                    className={'max-w-72'}
                    title={'By trip advisor rating'}
                    value={filterData?.tripadvisor_rating}
                />

                <div className="flex gap-3">
                    <Button
                        onClick={() => {
                            onClearFilter && onClearFilter()
                            setFilterData()
                        }}
                        text={'Clear Filter'} className={'w-full'} customThemeBtn={'view-edit-btn'} />
                    <Button
                        onClick={onSubmit && onSubmit}
                        // disabled={!filterData?.distance || !filterData?.category || !filterData?.price_per_cover}
                        text={'Apply'} className={'w-full'} />
                </div>
            </div>
        </Popup>
    )
}

export default RestaurantFIlterPopup