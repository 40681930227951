import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import moment from 'moment'
import React from 'react'

const PromotonCard = ({ url, onEdit, className, restaurantName, showStatus, status, start_date, end_date, discount, description, onClick }) => {
    return (
        <div
            className={`${className} bg-darkGrey cursor-pointer w-full shadow-xl rounded-lg md:rounded-[10px] relative`}
            onClick={() => onClick && onClick()}
        >
            <Image className="w-full h-48 object-cover rounded-t-lg md:rounded-t-[10px]" src={url} alt="promotion" />
            <div className="flex py-2 px-4 gap-2 items-center justify-between">
                <div className="flex items-center text-secondary gap-2">
                    <Svgs.CalenderIcon />
                    <h1 className='text-lg text-blueGrey'>
                        {start_date && moment(start_date).format('MMM DD YYYY')}
                        {end_date && " - " + moment(end_date).format('MMM DD YYYY')}
                    </h1>
                </div>
                <h1 className='text-secondary font-semibold text-xl'>{discount && discount + '%'}</h1>
            </div>
            {restaurantName &&
                <div className="flex px-4 py-2 items-center text-secondary gap-2">
                    <Svgs.RestaurantIcon2 fill={'var(--secondary-color'} height={20} width={22} />
                    <h1 className='text-lg text-blueGrey'>
                        {restaurantName}
                    </h1>
                </div>
            }
            {description && <h1 className='px-4 py-2 text-blueGrey'>{description && description}</h1>}
            {onEdit &&
                <div className="flex absolute top-4 right-3 bg-secondary p-2 rounded-full cursor-pointer"
                    onClick={onEdit}
                >
                    <Svgs.EditIcon fill={'#fff'} />
                </div>}
            {showStatus &&
                <div className={`${status} flex capitalize absolute top-1/2 right-0 px-2 py-0.5 font-semibold rounded-l`}>
                    {status}
                </div>
            }
        </div>
    )
}

export default PromotonCard