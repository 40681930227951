import Button from 'Components/Elements/Button'
import React from 'react'

const MenuItem = ({ data, setOpenItems }) => {
    return (
        <>
            <div className="flex flex-col">
                <div className="flex justify-between items-center gap-3">
                    <h1 className='text-white text-sm'>Menu Item</h1>
                    <Button customPadding={'px-3 py-1 gap-2 !rounded-md'}
                        onClick={() => setOpenItems({
                            title: data?.title,
                            id: data?.id
                        })}
                    >
                        {/* <Svgs.PlusIcon width={'16'} height={'16'} /> */}
                        Add
                    </Button>
                </div>

                <div className={`flex flex-col my-5 ${data?.content?.length > 1 && "h-[30vh]"} overflow-y-auto`}>
                    {data?.content?.map(itm => {
                        return (
                            <div className="flex w-full"
                                onClick={() => setOpenItems({
                                    title: data?.title,
                                    id: data?.id,
                                    submenu: { ...itm?.props?.data }
                                })}
                            >
                                <div className={`overflow-hidden w-full rounded-lg max-h-screen text-wrap"`}>
                                    {itm}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default MenuItem