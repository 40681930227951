import React from 'react'

const AdminDashboardCardShimmer = ({ arrayLength, className, btnClass, className2, showSvg, customHeight, noMinHeight }) => {
    const array = arrayLength ? arrayLength : [0, 1, 2, 3, 4, 5, 6, 7]
    return (
        <>
            <div className={`${className ? className : "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"} gap-4 mb-4 animate-pulse`}>
                {array?.map((app, ind) => {
                    return <>
                        <div className={`${customHeight} bg-darkGrey rounded-lg md:rounded-[10px] ${noMinHeight ? "" : "min-h-28"} flex flex-col gap-3 items-start justify-between p-5`}>
                            {showSvg &&
                                <div className={`${btnClass} min-h-10`}>
                                    <div className={`rounded-full min-h-10 min-w-10 h-10 w-10 bg-blueGrey flex justify-center items-center`}>

                                    </div>
                                </div>
                            }
                            <div className={className2}>
                                <div className="w-20 h-4 mb-1 rounded-lg bg-blueGrey"></div>
                                <div className="w-36 h-6 rounded-lg bg-blueGrey"></div>
                            </div>
                        </div>
                    </>
                })}
            </div>
        </>
    )
}

AdminDashboardCardShimmer.defaultProps = {
    showSvg: true,
    noMinHeight: false
}

export default AdminDashboardCardShimmer