import { addCuisine } from 'Adapters/APIs/restaurants'
import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import { objectKeyConvertToArray } from 'Hooks/useObjectKeys'
import useValidations from 'Hooks/useValidations'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const AddCuisinePopup = ({ open, close, getCuisineLoader, getCuisineData }) => {
    const dispatch = useDispatch()

    const { errors, setErrors, validation } = useValidations()

    // states
    const [cuisineValue, setCuisineValue] = useState("")
    const [loading, setLoading] = useState(false)

    // handle submit data
    const handleSubmit = () => {
        const success = (data) => {
            setLoading(false)
            getCuisineData(data?.data?.id)
            setCuisineValue("")
        }
        const fail = () => {
            setLoading(false)
        }
        setErrors(validation({ cuisineValue: cuisineValue }));
        let result = validation({ cuisineValue: cuisineValue });
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)
            dispatch(addCuisine({ name: cuisineValue }, success, fail))
        }
    }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[35vw] xl:w-[30vw]'}
            customPadding={'!p-0'}
            heading={'Add Cuisine'}
        >
            <div className={`p-4 flex flex-col ${errors?.cuisineValue ? "space-y-6" : "space-y-4"}`}>
                <LoginInput
                    title={'Cuisine'}
                    placeholder={'Enter Cuisine'}
                    required={true}
                    type="text"
                    name={'cuisineValue'}
                    parentClass={'flex-1 !text-base'}
                    onChange={(e) => {
                        setCuisineValue(e?.target?.value)
                        setErrors((prev) => ({
                            ...prev,
                            cuisineValue: ""
                        }))
                    }}
                    value={cuisineValue}
                    error={errors?.cuisineValue}
                />
                <Button
                    text={"Add Cuisine"}
                    onClick={handleSubmit}
                    className="w-full !py-2.5"
                    disabled={loading || getCuisineLoader}
                    loading={loading || getCuisineLoader}
                />
            </div>
        </Popup>
    )
}

export default AddCuisinePopup