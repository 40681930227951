import React from 'react'
import useBookings from './helper';
import DashboardLayout from 'Components/DashboardLayout';
import Pagination from 'Components/Pagination';
import BookingTable from 'Utility/Tables/BookingTable';
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer';
import ConfirmBooking from 'Pages/Concierges/Bookings/ConfirmBooking';
import { BOOKING_STATUSES_WITHOUT_COMMISSION_PAID } from 'Constants/Data/BookingStatus';
import Back from 'Components/Elements/Back';
import Svgs from 'Assets/svgs';
import BookingFilter from './Elements/Filter';
import moment from 'moment';
import { convertObjectToArray } from 'Hooks/useObjectKeys';

const RestaurantBooking = () => {
    const {
        type, setType, loading, currentPage, dropdown_concierges, setCurrentPage, allBookingData, filterData, setFilterData, setBookingPopup,
        bookingDetail, setBookingDetail, bookingForm, setBookingForm, pagination_data, setErrors, requestModification, modificationLoading,
        errors, addFilter, setAddFilter, handleChangeFilter, onApplyFilter, onClearFilter, singleLoading, appliedFilter
    } = useBookings()
    const selectedConcierge = dropdown_concierges?.find(usr => usr?.id == appliedFilter?.user_id)

    return (
        <>
            <DashboardLayout active={'booking'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="pt-4 space-y-5">
                        <div className='flex gap-2 justify-between items-center py-4 px-2'>
                            <Back
                                title={'Bookings'}
                            />
                            <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                        </div>
                        <div className="border-b flex items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setType('total')} className={`${type == 'total' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Total Bookings  &nbsp;
                            </p>
                            <p onClick={() => setType('upcomming')} className={`${type == 'upcomming' ? 'activePage' : ''} cursor-pointer font-semibold text-base pb-2 flex`}>Upcoming Bookings  &nbsp;
                            </p>
                        </div>
                    </div>
                    {convertObjectToArray(appliedFilter)?.length > 0 ?
                        <div className="flex gap-5 my-3 min-w-full overflow-x-auto whitespace-nowrap px-4 py-2">
                            {appliedFilter?.status &&
                                <div className="flex gap-1 text-white capitalize"><span className='font-semibold text-secondary'>Status:</span>{appliedFilter?.status}</div>
                            }
                            {appliedFilter?.search_text &&
                                <div className="flex gap-1 text-white capitalize"><span className='font-semibold text-secondary'>Search by:</span>{appliedFilter?.search_text}</div>
                            }
                            {appliedFilter?.start_date &&
                                <div className="flex gap-1 text-white capitalize">
                                    <span className='font-semibold text-secondary'>Start Date:</span>
                                    {moment(appliedFilter?.start_date).format("DD MMMM YYYY")}
                                </div>
                            }
                            {appliedFilter?.end_date &&
                                <div className="flex gap-1 text-white capitalize">
                                    <span className='font-semibold text-secondary'>End Date:</span>
                                    {moment(appliedFilter?.end_date).format("DD MMMM YYYY")}
                                </div>
                            }
                            {appliedFilter?.user_id &&
                                <div className="flex gap-1 text-white capitalize">
                                    <span className='font-semibold text-secondary'>
                                        Concierge:
                                    </span>
                                    {selectedConcierge?.first_name + " " + selectedConcierge?.last_name}
                                </div>
                            }
                        </div> 
                        : ""}
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-lightGrey">
                                    {loading
                                        ? <BookingTableShimer />
                                        : <>
                                            <BookingTable
                                                data={allBookingData}
                                                setErrors={setErrors}
                                                setBookingForm={setBookingForm}
                                                setBookingDetail={setBookingDetail}
                                                bookingDetail={bookingDetail}
                                                bookingStatusSteps={BOOKING_STATUSES_WITHOUT_COMMISSION_PAID}
                                            />
                                            {allBookingData?.length > 0 &&
                                                <Pagination
                                                    currentPage={currentPage}
                                                    pageSize={pagination_data?.per_page}
                                                    totalCount={pagination_data?.total_pages}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                    totalDataCount={pagination_data?.total}
                                                    currentPageCount={allBookingData?.length}
                                                />
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {bookingDetail &&
                        <ConfirmBooking
                            close={setBookingDetail}
                            onEditClick={setBookingPopup}
                            data={bookingForm}
                            isRestaurant={true}
                            onReasonSubmit={requestModification}
                            btnLoading={modificationLoading}
                            errors={errors}
                            setErrors={setErrors}
                            loading={singleLoading}
                        />
                    }

                    <BookingFilter
                        open={addFilter}
                        close={setAddFilter}
                        onClearFilter={onClearFilter}
                        onApplyFilter={onApplyFilter}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        dropdownOptions={dropdown_concierges}
                        handleChangeFilter={handleChangeFilter}
                    />
                </main>
            </DashboardLayout>
        </>
    )
}

export default RestaurantBooking