import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/Elements/Checkbox'
import React from 'react'

const SubscriptionCard = ({ title, isCheckbox, onSelectedChange, selected, details }) => {
    return (
        <>
            <div className="bg-darkGrey rounded-lg p-4 border border-secondary">
                <div className="flex flex-col xs:flex-row justify-between gap-2 items-center text-blueGrey">
                    {isCheckbox &&
                        <CheckBox
                            label={''}
                            checked={selected ? selected : false}
                            name={title}
                            onChange={() => onSelectedChange(title, 'single')}
                        />}
                    <div className="flex flex-1 flex-col gap-3">
                        <span className=" font-semibold text-base">{title}</span>
                        <div className="flex items-center gap-3">
                            <div className='bg-lightGrey flex p-1 rounded-full gap-2'>
                                <Svgs.ReportIcon stroke={'var(--secondary-color)'} />
                            </div>
                            Pending
                        </div>
                    </div>
                    <div className='flex w-fit gap-2 text-primary font-semibold px-3 py-1.5 bg-secondary text-sm rounded-md'>
                        {details?.monthly_fee} {currency}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionCard