import { BASE_URL, get_my_wallet_url, recieve_commission } from "Adapters/variables";
import axios from "axios";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { toast } from "react-toastify";

// get wallet data
export const getWalletData = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_my_wallet_url}${query}`, headers);
        if (response?.status == 200) {
            success && success(response?.data)
            return
        }
    } catch (error) {
        fail && fail();
        return
    }
};

// recieve commission
export const recieveConciergeCommission = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(data)
        const response = await axios.post(`${BASE_URL}${recieve_commission}${query}`, data, headers);
        if (response?.status == 200) {
            success && success(response);
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
            return
        }
    } catch (error) {
        fail && fail();
        return
    }
};