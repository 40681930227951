import { GET_ALL_BUSINESS, GET_ALL_BUSINESS_TOPBAR } from "./ActionType";

const initialState = {
    businesses: [],
    pagination_data: {},
    businesses_topbar: [],
    pagination_data_topbar: {}
}

const RestaurantBusinessReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_BUSINESS:
            let update_data;
            if (payload?.restaurants?.length === 0) {
                update_data = [];
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                // Merge and remove duplicates based on `id`
                const newRestaurants = payload?.restaurants?.filter(
                    (newItem) => !state?.businesses?.some((existingItem) => existingItem?.id === newItem.id)
                );
                update_data = [...state?.businesses, ...newRestaurants];
            } else {
                update_data = payload?.restaurants;
            }
            return {
                ...state,
                businesses: update_data,
                pagination_data: payload?.pagination_data?.meta
            }
        case GET_ALL_BUSINESS_TOPBAR:
            let update_data_topbar;
            if (payload?.restaurants?.length === 0) {
                update_data_topbar = [];
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                // Merge and remove duplicates based on `id`
                const newRestaurantsTopbar = payload.restaurants?.filter(
                    (newItem) => !state?.businesses_topbar?.some((existingItem) => existingItem?.id === newItem?.id)
                );
                update_data_topbar = [...state?.businesses_topbar, ...newRestaurantsTopbar];
            } else {
                update_data_topbar = payload?.restaurants;
            }
            return {
                ...state,
                businesses_topbar: update_data_topbar,
                pagination_data_topbar: payload?.pagination_data?.meta
            }
        default:
            return {
                ...state,
            };
    }
}

export default RestaurantBusinessReducer;
