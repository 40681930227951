import React from 'react'
import Transaction from 'Pages/Concierges/Transaction';

const ConciergeProfileTransaction = () => {
    return (
        <>
            <Transaction active={"profile"} />
        </>
    )
}

export default ConciergeProfileTransaction