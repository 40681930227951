import Svgs from 'Assets/svgs';
import React from 'react';

const StatusSlider = ({ parentClass, steps, activeStep, customeUnActiveIcon, unActiveIcon, activeIcon }) => {
    return (
        <>
            <div className={`flex justify-between items-center relative mb-3 ${parentClass ? parentClass : ""}`}>
                {steps?.map((step, ind) => (
                    <>
                        {ind !== 0 &&
                            <div className={`w-full h-0.5 bg-gray-300 items-center cursor-pointer ${step.value <= activeStep ? "bg-secondary" : 'bg-lightGrey'}`} />
                        }
                        <div
                            key={ind}
                            className={`rounded-full transition-all duration-300 relative place-items-center z-2 grid  cursor-pointer`}
                        >
                            <div className={`absolute text-xs ${step?.name === activeStep ? "-bottom-[1.15rem]" : "-bottom-[1.3rem]"} w-max text-center text-blueGrey`}>
                                {step?.name}
                            </div>
                            <div className='text-secondary flex items-center justify-center'>
                                {step.value <= activeStep
                                    ? activeIcon ? activeIcon : <Svgs.TickIcon className='bg-secondary rounded-full' fill={'var(--primary-color)'} />
                                    : unActiveIcon ? unActiveIcon : <div className='w-5 h-5 p-1 bg-lightGrey rounded-full flex items-center justify-center'>
                                        <Svgs.CrossIcon width={9} height={9} fill={'var(--secondary-color)'} />
                                    </div>
                                }
                                {/* {step.value <= activeStep
                                    ? step?.activeIcon
                                    : customeUnActiveIcon
                                        ? customeUnActiveIcon
                                        : step?.unActiveIcon
                                } */}
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
};

export default StatusSlider;
