import { getAdminWishlistData } from "Adapters/APIs/Admin/wishlist";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

let timeOutId = undefined
const useAdminWishlist = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [activeTab, setActiveTab] = useState('not converted')
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [paginationData, setPaginationData] = useState({})
    const [wishlist, setWishlist] = useState([])
    const [wishlistDetail, setWishlistDetail] = useState()
    const [updateStaff, setUpdateStaff] = useState(false)

    // get admin wishlist data
    const getAdminWishlists = () => {
        let payload = {
            page: currentPage,
            search_text: searchText,
            type: activeTab,
            start_date: startDate,
            end_date: endDate,
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (data) => {
            let updatedData = []
            if (data?.wishlist?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...wishlist || [], ...data?.wishlist];
                } else {
                    updatedData = data?.wishlist;
                }
            }
            setWishlist(updatedData)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminWishlistData(access_token, payload, success, fail))
    }

    // get wishlist first time data every time
    const getWishlistFirstPageData = () => {
        setWishlistDetail()
        setLoader(true)
        setCurrentPage(1)
        setStartDate()
        setEndDate()
        setSearchText()
        let payload = {
            page: 1,
            type: activeTab,
        }
        const success = (data) => {
            setWishlist(data?.wishlist)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getAdminWishlistData(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getAdminWishlists()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getAdminWishlists()
            }, 500);
        }
    }, [searchText, currentPage, activeTab, startDate, endDate])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [activeTab, startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return {
        wishlist, navigate, searchText, setSearchText, paginationData, loader, secondLoader, currentPage, setCurrentPage, startDate,
        setStartDate, endDate, setEndDate, activeTab, setActiveTab, wishlistDetail, setWishlistDetail, updateStaff, setUpdateStaff,
        getWishlistFirstPageData
    }
}

export default useAdminWishlist