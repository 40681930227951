import { GET_ALL_ANNOUNCED_REWARDS, GET_ALL_UPCOMMING_REWARDS } from "./ActionType";

export const getAdminAnnouncedRewards = (data) => {
    return {
        type: GET_ALL_ANNOUNCED_REWARDS,
        payload: data
    }
};
export const getAdminUpcommingRewards = (data) => {
    return {
        type: GET_ALL_UPCOMMING_REWARDS,
        payload: data
    }
};