import { postTradeLicense, registerUser } from "Adapters/APIs/Authentication";
import { getAllBusiness, getAllCategories, getAllCuisine } from "Adapters/APIs/restaurants";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify";

const initialState = {
    first_name: '',
    email: '',
    cuisine_id: '',
    phone_code: '',
    phone_number: '',
    mobile_code: '',
    mobile_number: '',
    type: 1, // 0 Concirege && 1 Resturent
    last_name: '',
    dob: '',
    business_name: '',
    payment_method: '',
    iban_number: '',
    card_holder_name: '',
    bank_name: '',
    password: '',
    restaurant_name: '',
    address: '',
    country: '',
    categories: [],
    state: '',
    city: '',
    location_coordinates: '',
    password_confirmation: '',
    authorized_manager: '',
    terms_condition: false,
    terms_and_conditions: '',
    privacy_policy: false,
    subscription_agreement: false,
    trade_license: ""
};

const useRegister = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { role } = useParams();

    const { storeDataInSessionStorage, getDataFromSessionStorage, removeDataFromSessionStorage } = useSetDataInLocalStorage()
    const { errors, setErrors, validation, validateNumber, emailValidation, confirmPasswordValidation, passwordValidation } = useValidations()

    // states
    const [formData, setFormData] = useState(initialState);
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [showOTPScreen, setShowOTPScreen] = useState(false)
    const [addCuisine, setAddCuisine] = useState(false)
    const [getCuisineLoader, setGetCuisineLoader] = useState(true)
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)
    const [addBusinessName, setAddBusinessName] = useState(false)
    const [getBusinessNameLoader, setBusinessNameLoader] = useState(true)
    const [businesses, setBusinesses] = useState([])

    // redux states
    const state = useSelector(state => state)
    const cuisinse = state?.cuisines?.cuisines
    const categoriesData = state?.categories?.categories
    const businessData = state?.business?.businesses

    const cuisines = useMemo(() => {
        return cuisinse?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [cuisinse])

    const categories = useMemo(() => {
        return categoriesData?.map(itm => {
            return {
                label: itm?.name,
                value: itm?.id
            }
        })
    }, [categoriesData])

    useEffect(() => {
        if (!role || (role && role != 'restaurant' && role != 'concierge')) {
            navigate('/role-selection')
        }
        getCuisineData()
        getCategories()
        getBusinessData()
    }, [])

    useEffect(() => {
        let signupData = getDataFromSessionStorage("signupData");
        if (signupData) {
            setFormData(signupData)
        } else {
            setFormData(initialState)
        }
        setErrors()
        setStep(1)


    }, [role])

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'phone_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                mobile_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                phone_number: value,
                phone_code: value?.split('-')[0]?.replace('+', '')
            })
        }
        if (name == 'mobile_number') {
            setErrors((prev) => ({
                ...prev,
                [name]: '',
                phone_number: ""
            }));
            validateNumber(name, value);
            setFormData({
                ...formData,
                mobile_number: value,
                mobile_code: value?.split('-')[0]?.replace('+', '')
            })
        }

        if (name == 'email') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            emailValidation(name, value);
            setFormData({
                ...formData,
                [name]: value
            })
        }

        if (name == 'password') {
            passwordValidation(name, value, formData.password_confirmation);
            setFormData({
                ...formData,
                [name]: value
            })
        }
        if (name == 'password_confirmation') {
            confirmPasswordValidation(name, value, formData.password)
            setFormData({
                ...formData,
                [name]: value
            })
        }
        if (name == 'trade_license') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.files[0]
            })
        }
        if (name == 'privacy_policy' || name == 'terms_condition' || name == 'subscription_agreement') {
            setErrors((prev) => ({ ...prev, [name]: '' }));
            setFormData({
                ...formData,
                [name]: e?.target?.checked
            })
        }
        if (name != 'phone_number' && name != 'mobile_number' && name != 'email' && name != 'password' && name != 'password_confirmation'
            && name != 'privacy_policy' && name != 'terms_condition' && name != 'subscription_agreement' && name != 'trade_license') {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: '' }));
        }
    };

    // handle submit form
    const handleSubmit = () => {
        let requiredFields = {}
        const { first_name, email, cuisine_id,
            phone_number, mobile_code, mobile_number, last_name, dob,
            business_name, payment_method, iban_number, card_holder_name,
            bank_name, password, restaurant_name, address, country, categories,
            state, city, password_confirmation, authorized_manager,
            terms_condition, privacy_policy, subscription_agreement, trade_license,
            contact_person
        } = formData

        let payload = { ...formData }
        if (role == 'restaurant') {
            requiredFields = {
                restaurant_name,
                categories: categories?.length == 0 ? false : true,
                cuisine_id,
                // authorized_manager,
                email,
                phone_number: phone_number ? phone_number : mobile_number ? true : false,
                phone_code: phone_number ? phone_number : mobile_number ? true : false,
                mobile_code: mobile_number ? mobile_number : phone_number ? true : false,
                mobile_number: mobile_number ? mobile_number : phone_number ? true : false,
                address, state, country, city,
                password, password_confirmation, trade_license,
                terms_condition, privacy_policy,
                contact_person
            }
        }
        if (role == 'concierge') {
            if (step == 1) {
                requiredFields = {
                    dob,
                    email,
                    last_name,
                    first_name,
                    business_name,
                    mobile_code, mobile_number,
                    address, state, country, city,
                    terms_condition, privacy_policy, subscription_agreement,
                }
            } else {
                requiredFields = { password, password_confirmation, payment_method }
                if (payment_method == 'bank') {
                    requiredFields = { ...requiredFields, iban_number, card_holder_name, bank_name, }
                }
                formData.type = '0'
            }
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (step == 1) {
                if (objectKeyConvertToArray(result)?.length === 0) {
                    setStep(2)
                    return
                }
                return
            }
        }
        passwordValidation("password", password, password_confirmation);
        confirmPasswordValidation("password_confirmation", password_confirmation, password);
        setErrors((prev) => ({
            ...prev,
            ...validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, })
        }));
        let result = validation(requiredFields, "", { phone_number: phone_number ? true : mobile_number ? true : false, mobile_number: mobile_number ? true : phone_number ? true : false, });
        if (objectKeyConvertToArray(result)?.length === 0 && !errors?.password_confirmation) {
            const success = (data) => {
                if (role != 'concierge') {
                    postTradeLicenseData(data)
                }
                storeDataInSessionStorage('signupData', { ...formData, user_id: data?.id })
                // setShowOTPScreen(true)
                navigate('/otp-verification')
                setLoading(false)
            }
            const fail = (error) => {
                setLoading(false)
                if (error?.response?.data?.status == 422) {
                    if (error?.response?.data?.error?.email || error?.response?.data?.error?.mobile_number) {
                        setErrors((prev) => ({
                            ...prev,
                            'email': error?.response?.data?.error?.email ? error?.response?.data?.error?.email[0] : false,
                            'mobile_number': error?.response?.data?.error?.mobile_number ? error?.response?.data?.error?.mobile_number[0] : false,
                            'phone_number': error?.response?.data?.error?.phone_number ? error?.response?.data?.error?.phone_number[0] : false
                        }));
                        setStep(1)
                    }
                }
            }
            if (payload?.phone_number) {
                payload = {
                    ...payload,
                    phone_number: formData?.phone_number?.split('-')[1]
                }
            }
            if (role == 'restaurant') {
                payload = {
                    ...payload,
                    first_name: contact_person
                }
            }
            if (payload?.mobile_number) {
                payload = {
                    ...payload,
                    mobile_number: formData?.mobile_number?.split('-')[1]
                }
            }
            payload = {
                ...payload,
                type: role == 'restaurant' ? 1 : "0"
            }
            if (formData?.categories?.length > 0) {
                const formattedCategories = categories.reduce((acc, value, index) => {
                    acc[`categories[${index}]`] = value;
                    return acc;
                }, {});
                payload = {
                    ...payload,
                    ...formattedCategories
                }
            }
            delete payload.categories
            if (role != 'concierge') {
                if (payload?.phone_number == payload?.mobile_number) {
                    setErrors((prev) => ({
                        ...prev,
                        'mobile_number': "Mobile number and Phone number must be different"
                    }));
                    toast.error("Mobile number and Phone number must be different", { toastId: "toast" })
                } else {
                    submitFuncntion(payload, success, fail)
                }
            } else {
                submitFuncntion(payload, success, fail)
            }


        }
    };

    const submitFuncntion = (payload, success, fail) => {
        removeDataFromSessionStorage('signupData')
        setLoading(true)
        dispatch(registerUser(payload, success, fail))
    }

    // post trade license
    const postTradeLicenseData = (data) => {
        dispatch(postTradeLicense({ id: data?.restaurant?.id, trade_license: formData?.trade_license }))
    }

    // get cuisine
    const getCuisineData = (existingId) => {
        const success = () => {
            if (existingId) {
                setFormData({
                    ...formData,
                    'cuisine_id': existingId
                })
                setErrors((prev) => ({
                    ...prev,
                    'cuisine_id': '',
                }));
            }
            setAddCuisine(false)
            setGetCuisineLoader(false)
        }
        const fail = () => {
            setGetCuisineLoader(false)
        }
        setGetCuisineLoader(true)
        dispatch(getAllCuisine('', success, fail))
    }

    // get business
    const getBusinessData = () => {
        const success = (data) => {
            setAddBusinessName(false)
            setBusinessNameLoader(false)
            setBusinesses(data)
        }
        const fail = () => {
            setBusinessNameLoader(false)
        }
        setBusinessNameLoader(true)
        dispatch(getAllBusiness({ type: 'non_paginated' }, success, fail))
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    return {
        formData, setFormData, navigate, handleChange, cuisines, showOTPScreen, setShowOTPScreen, role, handleSubmit, errors, step,
        setErrors, loading, addCuisine, setAddCuisine, getCuisineLoader, getCuisineData, getCategoriesLoader, categories,
        addBusinessName, setAddBusinessName, getBusinessNameLoader, businessData, setStep, businesses

    }
}

export default useRegister