import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import AutoPlaceInput from 'Components/Elements/AutoPlaceInput'
import Button from 'Components/Elements/Button'
import Dropdown from 'Components/Elements/Dropdown'
import InfoIcon from 'Components/Elements/InfoIcon'
import LoginInput from 'Components/Elements/LoginInput'
import PasswordInput from 'Components/Elements/PasswordInput'
import PhoneInput from 'Components/Elements/PhoneInput'
import { CATEGORY_DATA } from 'Constants/Data/TooltipData'
import React from 'react'
import AddCuisinePopup from './AddCuisinePopup'
import MultiDropdown from 'Components/Elements/MultiDropdown'
import CheckBox from 'Components/Elements/Checkbox'
import FilePicker from 'Components/Elements/FilePicker'
import Back from 'Components/Elements/Back'
import Textarea from 'Components/Elements/TextArea'

const RestaurantForm = (props) => {
    const { handleChange, errors, cuisines, formData, setErrors, handleSubmit, loading, addCuisine, setAddCuisine, getCuisineLoader,
        getCuisineData, categories, addBusiness
    } = props

    return (
        <>
            <div className="flex flex-col gap-5 w-full">
                {!addBusiness &&
                    <Back title={"Sign Up"} fill={'var(--secondary-color)'} titleClassName='flex-1 mr-10 !text-4xl !font-semibold text-center !text-white' />
                }
                <div className={`grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full ${addBusiness ? "" : "mt-5"}`}>
                    <LoginInput
                        title={'Restaurant Name'}
                        value={formData?.restaurant_name}
                        error={errors?.restaurant_name}
                        onChange={handleChange}
                        name={'restaurant_name'}
                        required={true}
                        placeholder={"Enter Restaurant Name"}
                        inputPadding={"px-4 py-[9px]"}
                    />
                    <MultiDropdown
                        info_icon={<InfoIcon
                            text={""}
                            info={<div className='flex flex-col px-2 space-y-2 max-w-80 overflow-y-auto text-start max-h-96'>
                                {CATEGORY_DATA?.map((itm, ind) => (
                                    <div key={ind} className='flex flex-col space-y-1'>
                                        <h4 className='font-bold text-white'>{itm?.label}:</h4>
                                        <p className='text-blueGrey'>{itm?.value}</p>
                                    </div>
                                ))}
                            </div>}
                        />}
                        title={'Select Categories'}
                        value={formData?.categories}
                        error={errors?.categories}
                        onChange={handleChange}
                        name={'categories'}
                        required={true}
                        options={categories}
                        placeholder={"Select Categories"}
                    />
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                    <div className='flex gap-2 justify-between items-center'>
                        <div className='flex-1'>
                            <Dropdown
                                title={'Select Cuisine'}
                                value={formData?.cuisine_id}
                                error={errors?.cuisine_id}
                                onChange={handleChange}
                                option={cuisines}
                                name={'cuisine_id'}
                                required={true}
                                placeholder={"Select Cuisine"}
                            />
                        </div>
                        <div className='pt-7'>
                            <Button
                                text={<Svgs.BoldPlusIcon width={"45"} height={'40'} />}
                                onClick={() => {
                                    setAddCuisine(true)
                                }}
                                className="w-full !py-0.5 !pl-2 !pr-2.5 bg-[#282828] border-[1.5px] border-lightGrey"
                            />
                        </div>
                    </div>
                    <LoginInput
                        title={'Contact Person'}
                        value={formData?.contact_person}
                        error={errors?.contact_person}
                        onChange={handleChange}
                        name={'contact_person'}
                        required={true}
                        placeholder={"Enter Contact Person"}
                        inputPadding={"px-4 py-[9px]"}
                    />
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                    <LoginInput
                        title={'Main Point of Contact'}
                        value={formData?.authorized_manager}
                        error={errors?.authorized_manager}
                        onChange={handleChange}
                        name={'authorized_manager'}
                        required={false}
                        placeholder={"Enter Main Point of Contact"}
                        inputPadding={"px-4 py-[9px]"}
                    />
                    <LoginInput
                        title={'Email Address'}
                        value={formData?.email}
                        error={errors?.email}
                        onChange={handleChange}
                        name={'email'}
                        required={true}
                        placeholder={"Enter your Email Address"}
                        inputPadding={"px-4 py-[9px]"}
                    />
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                    <PhoneInput
                        title={'Phone Number'}
                        value={formData?.phone_number}
                        error={errors?.phone_number}
                        onChange={handleChange}
                        name={'phone_number'}
                        placeholder={"Enter your Phone Number"}
                        inputPadding={"px-4 py-[9px]"}
                    />
                    <PhoneInput
                        title={'Mobile Number'}
                        value={formData?.mobile_number}
                        error={errors?.mobile_number}
                        onChange={handleChange}
                        name={'mobile_number'}
                        placeholder={"Enter your Mobile Number"}
                        inputPadding={"px-4 py-[9px]"}
                    />
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                    <div>
                        <AutoPlaceInput
                            value={formData?.address}
                            error={errors?.address}
                            setErrors={setErrors}
                            onChange={handleChange}
                        />
                    </div>
                    {!addBusiness &&
                        <LoginInput
                            value={formData?.referral_code}
                            error={errors?.referral_code}
                            onChange={handleChange}
                            name={'referral_code'}
                            title={<div className='flex items-center'>
                                Referral Code
                                <InfoIcon
                                    text={""}
                                    info={<div className='flex flex-col px-2 space-y-2 justify-center text-center max-w-80'>
                                        <h4 className='font-bold px-4 text-white text-center'>Invite your friends to signup and earn rewards when they make bookings!</h4>
                                        <div className='flex flex-col space-y-1 items-start justify-start text-start'>
                                            <h4 className='font-bold text-white'>How it works:</h4>
                                            <p className='text-blueGrey'>Share your referral code with friends. When they signup as a concierge or a restaurant and successfully complete a booking,you'll earn {currency} per booking. You can earn up to 150 {currency} from each invitation, as rewards are limited to the first 10 successful bookings per invitee.</p>
                                        </div>
                                    </div>}
                                />
                            </div>}
                            placeholder={"Enter Referral Code"}
                            inputPadding={"px-4 py-[9px]"}
                            required={false}
                        />
                    }
                </div>
                {!addBusiness &&
                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-5 items-center w-full'>
                        <PasswordInput
                            title={'Password'}
                            value={formData?.password}
                            error={errors?.password}
                            onChange={handleChange}
                            name={'password'}
                            required={true}
                            placeholder={"Enter Password"}
                            inputPadding={"px-4 py-[9px]"}
                        />
                        <PasswordInput
                            title={'Confirm Password'}
                            value={formData?.password_confirmation}
                            error={errors?.password_confirmation}
                            onChange={handleChange}
                            name={'password_confirmation'}
                            required={true}
                            placeholder={"Enter Confirm Password"}
                            inputPadding={"px-4 py-[9px]"}
                        />

                    </div>
                }
                <FilePicker
                    required={true}
                    title="Trade License"
                    value={formData?.trade_license}
                    name={'trade_license'}
                    error={errors?.trade_license}
                    id={'trade_license'}
                    accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF"}
                    onChange={handleChange}
                    customClass={"attach-file-btn-signup"}
                    customValueClass={"border border-lightGrey"}
                />
                {/* {!addBusiness &&
                    <div className="flex">
                        <Textarea
                            title="Terms and conditions"
                            placeholder="Enter terms and conditions"
                            parent="w-full"
                            noLimit={true}
                            name="terms_and_conditions"
                            value={formData?.terms_and_conditions}
                            errorMessage={formData?.terms_and_conditions}
                            onChange={handleChange}
                            textAreaClass='resize-y'
                        />
                    </div>
                } */}
                <div className='flex flex-col space-y-2'>
                    <CheckBox
                        checked={formData?.terms_condition}
                        error={errors?.terms_condition}
                        name={'terms_condition'}
                        onChange={handleChange}
                        required={true}
                        onClickLabel1={() => {
                            window.open('https://easyrsv.com/terms-and-conditions', '_blank');
                        }}
                        label={'I agree to the '}
                        colorLabel1={'Term & Condition'}
                    />
                    <CheckBox
                        checked={formData?.privacy_policy}
                        error={errors?.privacy_policy}
                        name={'privacy_policy'}
                        onChange={handleChange}
                        required={true}
                        onClickLabel1={() => {
                            window.open('https://easyrsv.com/privacy', '_blank');
                        }}
                        label={'I agree to the '}
                        colorLabel1={'Privacy & Policy'}
                    />
                </div>
                <div className="py-4">
                    <Button
                        text={'Sign Up'}
                        onClick={handleSubmit}
                        className="w-full"
                        disabled={loading}
                        loading={loading}
                    />
                </div>
            </div>

            <AddCuisinePopup
                open={addCuisine}
                close={setAddCuisine}
                getCuisineLoader={getCuisineLoader}
                getCuisineData={getCuisineData}
            />
        </>
    )
}

export default RestaurantForm