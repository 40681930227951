import React from 'react'

const HeadingShimmerWithBackIcon = () => {
    return (
        <div className='flex gap-4 items-center py-4 lg:flex-1 animate-pulse'>
            <div className='w-11 h-11 rounded-full bg-gray-200'></div>
            <div className='bg-gray-200 h-8 w-72 rounded-md'></div>
        </div>
    )
}

export default HeadingShimmerWithBackIcon