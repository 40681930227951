import { currency } from 'Adapters/variables';
import Svgs from 'Assets/svgs';
import React from 'react';

const ContentCard = (props) => {
    const { data, index, heading, className, description, price, showDelete, onDeleteClick, discount } = props;
    return (
        <div key={index} className='my-2 relative border border-lightGrey bg-darkGrey p-4 rounded-lg shadow-md mx-0.5'>
            {discount && (
                <div className="absolute top-2 right-0 bg-secondary py-1 px-2 rounded-l-md">
                    {discount} % Off
                </div>
            )}
            <h2 className='text-secondary pt-2'>{heading}</h2>
            <p className='text-blueGrey break-words'>{description}</p>

            <div className={`${className} px-4 py-2 w-full flex items-center justify-end gap-5 rounded-md cursor-pointer`}>
                <div className='px-4 py-2 bg-secondary w-fit font-semibold text-sm text-primary flex items-center rounded-md cursor-pointer'>
                    {price} {currency}
                </div>
                {showDelete && (
                    <div
                        className='p-1.5 border-[#777] w-fit text-sm text-white flex items-center rounded-full border cursor-pointer'
                        onClick={(e) => {
                            e.stopPropagation();
                            onDeleteClick && onDeleteClick(data?.id);
                        }}
                    >
                        <Svgs.DeleteIcon stroke={'var(--secondary-color)'} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ContentCard;
