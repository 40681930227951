import React, { useState } from "react";
import Select from "react-select";

let IsError = false;
const FlagDropdown = ({
    custom_min_width,
    className,
    title,
    bgColor,
    name,
    value,
    onBlur,
    padding,
    placeholder,
    borderColor,
    options,
    onChange,
    error,
    disabled,
    required,
    noBorder,
    showTitle,
    edit,
    titleClass,
    borderRadius,
    noOptionsMessage,
    onInputChange
}) => {
    const [field_err, setFieldErr] = useState(undefined)

    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isHovered, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: noBorder
                ? 0
                : (isFocused || isHovered)
                    ? "1.5px solid #4e4e4e"
                    : "1.5px solid #4e4e4e",
            color: isFocused ? "#0e0e0e" : "#fff",
            backgroundColor: bgColor ? bgColor : '#4e4e4e',
            fontSize: "0.875rem",
            padding: padding ? padding : "0rem",
            outline: "none",
            borderRadius: borderRadius ? borderRadius : '10px 0 0 10px',
            transitionDuration: '0.2s',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#FFFFFF",
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '8px 2px'

        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                background: isDisabled
                    ? "#4e4e4e"
                    : isFocused
                        ? "#d6b560"
                        : "#4e4e4e",
                color: isFocused ? "#0e0e0e" : "#fff",
                fontSize: "0.875rem",
                textTransform: 'capitalize',
                cursor: isDisabled ? "not-allowed" : "pointer",
            };
        },
        menu: (styles) => ({
            ...styles,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            width: '100%',
            zIndex: 999,
            backgroundColor: bgColor ? bgColor : '#4e4e4e'
        }),
        input: (styles) => ({
            ...styles,
            '[type="text"]': {
                color: '#FFFFFF !important'
            }
        })
    };

    const errorStyle = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            borderRadius: borderRadius ? borderRadius : '6px',
            boxShadow: isFocused ? "none" : "none",
            border: IsError && "1.5px solid #eb3b3b",
            color: "white",
            fontSize: "0.875rem",
            padding: "0.2rem 0.2rem",
            minHeight: "0",
            backgroundColor: bgColor ? bgColor : '#1E1E1E',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "white",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                background: isDisabled
                    ? "#4e4e4e"
                    : isFocused
                        ? "#d6b560"
                        : "#4e4e4e",
                color: isFocused ? "#0e0e0e" : "#fff",
                fontSize: "0.875rem",
                cursor: isDisabled ? "not-allowed" : "pointer",
            };
        },
        menu: (styles) => ({
            ...styles,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            zIndex: 233,
            backgroundColor: bgColor ? bgColor : '#1e1e1e'
        }),
        input: (styles) => ({
            ...styles,
            '[type="text"]': {
                color: '#FFFFFF !important'
            }
        })
    };

    if (error) {
        IsError = true;
    }

    const selectedItem = options?.length > 0 ? options?.find((itm) => (itm?.code == value || itm?.value == value)) : null;

    const optimizedValue = {
        value: selectedItem || "",
        label: selectedItem ? selectedItem?.label : placeholder
    };

    return (
        <>
            <div className={`flex flex-col gap-2 flex-1 whitespace-nowrap capitalize relative ${className} ${disabled ? "cursor-not-allowed" : ""} `}>
                {title != "" && showTitle && (
                    <p className={`${titleClass} w-full text-sm flex gap-1`}>
                        {title && title}
                        {required && required ? " * " : ""}
                    </p>
                )}
                <Select
                    styles={error ? errorStyle : colourStyles}
                    name={name}
                    dark
                    menuPosition='fixed'
                    className="border-0"
                    menuPlacement="auto"
                    value={optimizedValue}
                    options={options && options.length > 0 ? options : []}
                    onChange={(e) => {
                        setFieldErr(undefined)
                        if (options.length > 0) {
                            onChange &&
                                onChange({
                                    target: {
                                        value: e.value,
                                        name: name,
                                    },
                                });
                        }
                    }}
                    menuPortalTarget={document.body}
                    isDisabled={edit ? false : options.length == 0 ? true : disabled}
                    placeholder={placeholder}
                    isOptionDisabled={(option) => option.disabled}
                    noOptionsMessage={noOptionsMessage}
                    onInputChange={onInputChange}
                    filterOption={(option, searchTerm) => {
                        // Remove "+" from search term
                        const normalizedSearchTerm = searchTerm?.replace(/\+/, '');
                        // Check if either option.code or option.value includes the normalized search term
                        return option?.data?.code.includes(normalizedSearchTerm) || option?.data?.value.includes(normalizedSearchTerm);
                    }}
                />
                {error && <span className='text-error flex items-center gap-2 text-xs'>{error}</span>}
            </div>
        </>
    );
};

FlagDropdown.defaultProps = {
    options: [
        // { value: 'no_value', label: 'Enter "options" prop' },
    ],
    name: "no_name",
    value: "no_value",
    onBlur: () => { },
    onChange: () => { },
    error: false,
    disabled: false,
    required: false,
    noBorder: false,
    showTitle: true,
};

export default FlagDropdown;