


export const DURATIONS = [
    { label: '5min', value: '5min' },
    { label: '10min', value: '10min' },
    { label: '15min', value: '15min' },
    { label: '20min', value: '20min' },
    { label: '25min', value: '25min' },
    { label: '30min', value: '30min' },
    { label: '35min', value: '35min' },
    { label: '40min', value: '40min' },
    { label: '45min', value: '45min' },
    { label: '50min', value: '50min' },
    { label: '55min', value: '55min' },
    { label: '1h', value: '1h' },
    { label: '1h 5min', value: '1h 5min' },
    { label: '1h 10min', value: '1h 10min' },
    { label: '1h 15min', value: '1h 15min' },
    { label: '1h 20min', value: '1h 20min' },
    { label: '1h 25min', value: '1h 25min' },
    { label: '1h 30min', value: '1h 30min' },
    { label: '1h 35min', value: '1h 35min' },
    { label: '1h 40min', value: '1h 40min' },
    { label: '1h 45min', value: '1h 45min' },
    { label: '1h 50min', value: '1h 50min' },
    { label: '1h 55min', value: '1h 55min' },
    { label: '2h', value: '2h' },
    { label: '2h 5min', value: '2: 5min' },
    { label: '2h 10min', value: '2h 10min' },
    { label: '2h 15min', value: '2h 15min' },
    { label: '2h 20min', value: '2h 20min' },
    { label: '2h 25min', value: '2h 25min' },
    { label: '2h 30min', value: '2h 30min' },
    { label: '2h 35min', value: '2h 35min' },
    { label: '2h 40min', value: '2h 40min' },
    { label: '2h 45min', value: '2h 45min' },
    { label: '2h 50min', value: '2h 50min' },
    { label: '2h 55min', value: '2h 55min' },
    { label: '3h', value: '3h' },
    { label: '3h 5min', value: '3: 5min' },
    { label: '3h 10min', value: '3h 10min' },
    { label: '3h 15min', value: '3h 15min' },
    { label: '3h 20min', value: '3h 20min' },
    { label: '3h 25min', value: '3h 25min' },
    { label: '3h 30min', value: '3h 30min' },
    { label: '3h 35min', value: '3h 35min' },
    { label: '3h 40min', value: '3h 40min' },
    { label: '3h 45min', value: '3h 45min' },
    { label: '3h 50min', value: '3h 50min' },
    { label: '3h 55min', value: '3h 55min' },
    { label: '4h', value: '4h ' },
    { label: '4h 5min', value: '4h 5min' },
    { label: '4h 10min', value: '4h 10min' },
    { label: '4h 15min', value: '4h 15min' },
    { label: '4h 20min', value: '4h 20min' },
    { label: '4h 25min', value: '4h 25min' },
    { label: '4h 30min', value: '4h 30min' },
    { label: '4h 35min', value: '4h 35min' },
    { label: '4h 40min', value: '4h 40min' },
    { label: '4h 45min', value: '4h 45min' },
    { label: '4h 50min', value: '4h 50min' },
    { label: '4h 55min', value: '4h 55min' },
    { label: '5h', value: '5h' },
    { label: '5h 5min', value: '5h 5min' },
    { label: '5h 10min', value: '5h 10min' },
    { label: '5h 15min', value: '5h 15min' },
    { label: '5h 20min', value: '5h 20min' },
    { label: '5h 25min', value: '5h 25min' },
    { label: '5h 30min', value: '5h 30min' },
    { label: '5h 35min', value: '5h 35min' },
    { label: '5h 40min', value: '5h 40min' },
    { label: '5h 45min', value: '5h 45min' },
    { label: '5h 50min', value: '5h 50min' },
    { label: '5h 55min', value: '5h 55min' },
    { label: '6h', value: '6h' },
    { label: '6h 5min', value: '6h 5min' },
    { label: '6h 10min', value: '6h 10min' },
    { label: '6h 15min', value: '6h 15min' },
    { label: '6h 20min', value: '6h 20min' },
    { label: '6h 25min', value: '6h 25min' },
    { label: '6h 30min', value: '6h 30min' },
    { label: '6h 35min', value: '6h 35min' },
    { label: '6h 40min', value: '6h 40min' },
    { label: '6h 45min', value: '6h 45min' },
    { label: '6h 50min', value: '6h 50min' },
    { label: '6h 55min', value: '6h 55min' },
    { label: '7h', value: '7h' },
    { label: '7h 5min', value: '7h 5min' },
    { label: '7h 10min', value: '7h 10min' },
    { label: '7h 15min', value: '7h 15min' },
    { label: '7h 20min', value: '7h 20min' },
    { label: '7h 25min', value: '7h 25min' },
    { label: '7h 30min', value: '7h 30min' },
    { label: '7h 35min', value: '7h 35min' },
    { label: '7h 40min', value: '7h 40min' },
    { label: '7h 45min', value: '7h 45min' },
    { label: '7h 50min', value: '7h 50min' },
    { label: '7h 55min', value: '7h 55min' },
    { label: '8h', value: '8h' },
    { label: '8h 5min', value: '8h 5min' },
    { label: '8h 10min', value: '8h 10min' },
    { label: '8h 15min', value: '8h 15min' },
    { label: '8h 20min', value: '8h 20min' },
    { label: '8h 25min', value: '8h 25min' },
    { label: '8h 30min', value: '8h 30min' },
    { label: '8h 35min', value: '8h 35min' },
    { label: '8h 40min', value: '8h 40min' },
    { label: '8h 45min', value: '8h 45min' },
    { label: '8h 50min', value: '8h 50min' },
    { label: '8h 55min: 8h 55min' },
    { label: '9h', value: '9h' },
    { label: '9h 5min', value: '9h 5min' },
    { label: '9h 10Mmin', value: '9h 10min' },
    { label: '9h 15Mmin', value: '9h 15min' },
    { label: '9h 20min', value: '9h 20min' },
    { label: '9h 25min', value: '9h 25min' },
    { label: '9h 30min', value: '9h 30min' },
    { label: '9h 35min', value: '9h 35min' },
    { label: '9h 40min', value: '9h 40min' },
    { label: '9h 45min', value: '9h 45min' },
    { label: '9h 50min', value: '9h 50min' },
    { label: '9h 55min', value: '9h 55min' },
    { label: '10h', value: '10h' },
    { label: '10h 5min', value: '10h 5min' },
    { label: '10h 10Amin', value: '10h 10min' },
    { label: '10h 15min', value: '10h 15min' },
    { label: '10h 20min', value: '10h 20min' },
    { label: '10h 25min', value: '10h 25min' },
    { label: '10h 30min', value: '10h 30min' },
    { label: '10h 35min', value: '10h 35min' },
    { label: '10h 40min', value: '10h 40min' },
    { label: '10h 45min', value: '10h 45min' },
    { label: '10h 50min', value: '10h 50min' },
    { label: '10h 55min', value: '10h 55min' },
    { label: '11h', value: '11h' },
    { label: '11h 5min', value: '11h 5min' },
    { label: '11h 10min', value: '11h 10min' },
    { label: '11h 20min', value: '11h 20min' },
    { label: '11h 15min', value: '11h 15min' },
    { label: '11h 25min', value: '11h 25min' },
    { label: '11h 30min', value: '11h 30min' },
    { label: '11h 35min', value: '11h 35min' },
    { label: '11h 40min', value: '11h 40min' },
    { label: '11h 45min', value: '11h 45min' },
    { label: '11h 50min', value: '11h 50min' },
    { label: '11h 55min', value: '11h 55min' },
    { label: '12h', value: '12h' },
]


export const OPENING_TIME = [
    { label: '12:00 AM', value: '00:00:00' },
    { label: '12:30 AM', value: '00:30:00' },
    { label: '01:00 AM', value: '01:00:00' },
    { label: '01:30 AM', value: '01:30:00' },
    { label: '02:00 AM', value: '02:00:00' },
    { label: '02:30 AM', value: '02:30:00' },
    { label: '03:00 AM', value: '03:00:00' },
    { label: '03:30 AM', value: '03:30:00' },
    { label: '04:00 AM', value: '04:00:00' },
    { label: '04:30 AM', value: '04:30:00' },
    { label: '05:00 AM', value: '05:00:00' },
    { label: '05:30 AM', value: '05:30:00' },
    { label: '06:00 AM', value: '06:00:00' },
    { label: '06:30 AM', value: '06:30:00' },
    { label: '07:00 AM', value: '07:00:00' },
    { label: '07:30 AM', value: '07:30:00' },
    { label: '08:00 AM', value: '08:00:00' },
    { label: '08:30 AM', value: '08:30:00' },
    { label: '09:00 AM', value: '09:00:00' },
    { label: '09:30 AM', value: '09:30:00' },
    { label: '10:00 AM', value: '10:00:00' },
    { label: '10:30 AM', value: '10:30:00' },
    { label: '11:00 AM', value: '11:00:00' },
    { label: '11:30 AM', value: '11:30:00' },
    { label: '12:00 PM', value: '12:00:00' },
    { label: '12:30 PM', value: '12:30:00' },
    { label: '01:00 PM', value: '13:00:00' },
    { label: '01:30 PM', value: '13:30:00' },
    { label: '02:00 PM', value: '14:00:00' },
    { label: '02:30 PM', value: '14:30:00' },
    { label: '03:00 PM', value: '15:00:00' },
    { label: '03:30 PM', value: '15:30:00' },
    { label: '04:00 PM', value: '16:00:00' },
    { label: '04:30 PM', value: '16:30:00' },
    { label: '05:00 PM', value: '17:00:00' },
    { label: '05:30 PM', value: '17:30:00' },
    { label: '06:00 PM', value: '18:00:00' },
    { label: '06:30 PM', value: '18:30:00' },
    { label: '07:00 PM', value: '19:00:00' },
    { label: '07:30 PM', value: '19:30:00' },
    { label: '08:00 PM', value: '20:00:00' },
    { label: '08:30 PM', value: '20:30:00' },
    { label: '09:00 PM', value: '21:00:00' },
    { label: '09:30 PM', value: '21:30:00' },
    { label: '10:00 PM', value: '22:00:00' },
    { label: '10:30 PM', value: '22:30:00' },
    { label: '11:00 PM', value: '23:00:00' },
    { label: '11:30 PM', value: '23:30:00' },
]

export const BUSINESS_TIME = [
    { label: '12:00 AM', value: '00:00' },
    { label: '12:30 AM', value: '00:30' },
    { label: '01:00 AM', value: '01:00' },
    { label: '01:30 AM', value: '01:30' },
    { label: '02:00 AM', value: '02:00' },
    { label: '02:30 AM', value: '02:30' },
    { label: '03:00 AM', value: '03:00' },
    { label: '03:30 AM', value: '03:30' },
    { label: '04:00 AM', value: '04:00' },
    { label: '04:30 AM', value: '04:30' },
    { label: '05:00 AM', value: '05:00' },
    { label: '05:30 AM', value: '05:30' },
    { label: '06:00 AM', value: '06:00' },
    { label: '06:30 AM', value: '06:30' },
    { label: '07:00 AM', value: '07:00' },
    { label: '07:30 AM', value: '07:30' },
    { label: '08:00 AM', value: '08:00' },
    { label: '08:30 AM', value: '08:30' },
    { label: '09:00 AM', value: '09:00' },
    { label: '09:30 AM', value: '09:30' },
    { label: '10:00 AM', value: '10:00' },
    { label: '10:30 AM', value: '10:30' },
    { label: '11:00 AM', value: '11:00' },
    { label: '11:30 AM', value: '11:30' },
    { label: '12:00 PM', value: '12:00' },
    { label: '12:30 PM', value: '12:30' },
    { label: '01:00 PM', value: '13:00' },
    { label: '01:30 PM', value: '13:30' },
    { label: '02:00 PM', value: '14:00' },
    { label: '02:30 PM', value: '14:30' },
    { label: '03:00 PM', value: '15:00' },
    { label: '03:30 PM', value: '15:30' },
    { label: '04:00 PM', value: '16:00' },
    { label: '04:30 PM', value: '16:30' },
    { label: '05:00 PM', value: '17:00' },
    { label: '05:30 PM', value: '17:30' },
    { label: '06:00 PM', value: '18:00' },
    { label: '06:30 PM', value: '18:30' },
    { label: '07:00 PM', value: '19:00' },
    { label: '07:30 PM', value: '19:30' },
    { label: '08:00 PM', value: '20:00' },
    { label: '08:30 PM', value: '20:30' },
    { label: '09:00 PM', value: '21:00' },
    { label: '09:30 PM', value: '21:30' },
    { label: '10:00 PM', value: '22:00' },
    { label: '10:30 PM', value: '22:30' },
    { label: '11:00 PM', value: '23:00' },
    { label: '11:30 PM', value: '23:30' },
];


export const CLOSING_TIME = [...OPENING_TIME]