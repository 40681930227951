import { GET_ADMIN_DASHBOARD_DATA, GET_CONCIERGE_RESTAURANT_STATS_DATA, GET_DASHBOARD_CONCIERGE, GET_DASHBOARD_RESTAURANT, GET_DROPDOWN_CONCIERGES_DATA, GET_DROPDOWN_RESTAURANT_DATA, GET_UNAPPROVED_CONCIERGE_RESTAURANT_DATA } from "./ActionType";

const initialState = {
    dashbaord_data: {},
    dropdown_concierges: [],
    dropdown_restaurants: [],
    concierge_restaurant_stats: {},
    restaurants: [],
    unapproved_concierge_restaurant: [],
    unapproved_concierge_restaurant_pagination: {}
}

const AdminDashboardReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ADMIN_DASHBOARD_DATA:
            return {
                ...state,
                dashbaord_data: payload,
            }
        case GET_DROPDOWN_CONCIERGES_DATA:
            return {
                ...state,
                dropdown_concierges: payload,
            }
        case GET_DROPDOWN_RESTAURANT_DATA:
            return {
                ...state,
                dropdown_restaurants: payload,
            }
        case GET_CONCIERGE_RESTAURANT_STATS_DATA:
            return {
                ...state,
                concierge_restaurant_stats: payload,
            }
        case GET_UNAPPROVED_CONCIERGE_RESTAURANT_DATA:
            let updatedArray;
            if (payload?.restaurants?.length == 0) {
                updatedArray = []
            } else {
                if (payload?.pagination_data?.meta?.current_page !== 1) {
                    updatedArray = [...state?.unapproved_concierge_restaurant, ...payload?.array];
                } else {
                    updatedArray = payload?.array;
                }
            }
            return {
                ...state,
                unapproved_concierge_restaurant: updatedArray,
                unapproved_concierge_restaurant_pagination: payload?.pagination_data?.meta
            }
        case GET_DASHBOARD_RESTAURANT:
            return {
                ...state,
                restaurants: payload,
            }
        case GET_DASHBOARD_CONCIERGE:
            return {
                ...state,
                concierges: payload,
            }
        default:
            return {
                ...state,
            };
    }
}

export default AdminDashboardReducer