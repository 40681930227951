import { GET_USER_DETAILS, GET_USER_NOTIFICATIONS } from "./ActionType";

const initialState = {
    user: {},
    notifications: [],
    notification_pagination: {},
    unread_count: 0,
}

const UserReducers = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_DETAILS:
            return {
                ...state,
                user: payload,
            }
        case GET_USER_NOTIFICATIONS:
            let notifactionData;
            if (payload?.notifications?.length == 0) {
                notifactionData = []
            } else {
                if (payload?.pagination_data?.meta?.current_page !== 1) {
                    notifactionData = [...state?.notifications, ...payload?.notifications];
                } else {
                    notifactionData = payload?.notifications;
                }
            }
            return {
                ...state,
                notifications: notifactionData,
                notification_pagination: payload?.pagination_data?.meta,
                unread_count: payload?.unread_count,
                user_unread_count: payload?.user_unread_count
            }

        default:
            return {
                ...state,
            };
    }
}

export default UserReducers