import React from 'react'
import NotFound from 'Utility/NotFound'

const AdminStatisticsTable = ({ data, type }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white bg-lightGrey capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                {type == 'concierge' ? "Concierge name" : "Restaurant name"}
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total bookings for this {type == 'concierge' ? "concierge" : "restaurant"}
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total commissions generated to platform
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total concierge commissions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map((itm, index) => {
                                return (
                                    <>
                                        <tr className={`bg-darkGrey border-b border-lightGrey cursor-pointer text-blueGrey`}>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {type == 'concierge' ? `${itm?.first_name} ${itm?.last_name}` : itm?.name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.reservations_count}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.reservations_sum_commission}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.reservations_sum_manager_earning}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {data?.length == 0 &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default AdminStatisticsTable