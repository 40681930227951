import Svgs from 'Assets/svgs'
import React from 'react'

const ComingSoon = () => {
    return (
        <div className="p-4 lg:ml-72 md:ml-64 flex flex-col items-center justify-center h-screen">
            <Svgs.ComingSoonSvg width={300} height={300} />
            <h1 className="text-5xl font-bold text-white">Coming Soon</h1>
        </div>
    )
}

export default ComingSoon