import React from 'react';
import DummyImage from "../../../../Assets/images/error2.png"
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function Slider({ data }) {
    return (
        <>
            <div>
                {data?.length > 0 ?
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={1}
                        autoplay={true}
                    >
                        {data?.map((itm, index) => (
                            <SwiperSlide key={index}>
                                <div className='h-[200px]'>
                                    <img className='h-full w-full object-cover rounded-lg' src={itm?.url} alt={index} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper> :
                    <Swiper
                        modules={[Autoplay]}
                        autoplay={true}
                        slidesPerView={1}
                    >
                        <SwiperSlide >
                            <div className='h-[200px]'>
                                <img className='h-full w-full object-cover rounded-lg' src={DummyImage} alt="dummy" />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                }
            </div>
        </>
    );
}