import { currency } from 'Adapters/variables'
import StatusSlider from 'Components/Elements/StatusSlider'
import VerticalStatusSlider from 'Components/Elements/VerticalStatusSlider'
import { BOOKING_STATUSES_WITH_ICONS } from 'Constants/Data/BookingStatus'
import moment from 'moment'
import React from 'react'

const ConciergePayBookingCard = ({ name, pax, number, date, onClick, bookingStatusSteps, status, amount, hideStatus, selected }) => {
    const handleActiveStatus = (status) => {
        switch (status.toLowerCase()) {
            case 'placed':
                return 1
            case 'confirmed':
                return 2
            case 'billed':
                return 3
            case 'commission paid':
                return 4
            default:
                return 1
        }
    }
    return (
        <>
            <div className={`bg-darkGrey restaurant-card-shadow rounded-xl p-4 w-full flex xs:flex-row flex-col justify-between sm:items-center gap-4 mb-3 cursor-pointer ${selected ? "border border-secondary" : ""}`}
                onClick={() => onClick && onClick()}
            >
                <div className='flex flex-col space-y-2'>
                    <h1 className='font-semibold text-white'>{name}</h1>
                    <span className="bg-lightGrey px-3 py-1 h-6 capitalize w-fit min-w-6 rounded-md text-secondary text-sm font-normal flex justify-center items-center">
                        Total Guest - {pax}
                    </span>
                    <p className='text-sm text-secondary'>ID - {number}</p>
                    <p className='text-blueGrey text-sm'>{moment(date)?.format('YYYY-MM-DD • hh:mm A')}</p>
                </div>
                {amount &&
                    <h1 className='flex self-start font-semibold text-secondary'>
                        {amount} {amount && currency}
                    </h1>
                }

                {!hideStatus &&
                    <>
                        <div className='pr-20 xs:block hidden'>
                            <VerticalStatusSlider
                                activeStep={handleActiveStatus(status)}
                                customNonActiveColor={'bg-[#FCE8A2]'}
                                parentClass={"pr-8"}
                                steps={bookingStatusSteps ? bookingStatusSteps : BOOKING_STATUSES_WITH_ICONS} />
                        </div>
                        <div className='xs:hidden block'>
                            <StatusSlider
                                activeStep={handleActiveStatus(status)}
                                customNonActiveColor={'bg-[#FCE8A2]'}
                                parentClass={"pr-8"}
                                steps={bookingStatusSteps ? bookingStatusSteps : BOOKING_STATUSES_WITH_ICONS} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default ConciergePayBookingCard