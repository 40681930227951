import Button from 'Components/Elements/Button'
import DateInput from 'Components/Elements/DateInput'
import Dropdown from 'Components/Elements/Dropdown'
import Popup from 'Components/Elements/Popup'
import { DIRECTIONS, EARNING_SORT } from 'Constants/Data/Statuses'
import React, { useEffect, useState } from 'react'

const ConciergeRestaurantAnalyticsFilter = ({ open, close, onClearFilter, onApplyFilter, filterData, setFilterData, handleChangeFilter}) => {
    // states
    const [startDate, setStartDate] = useState(filterData?.start_date)
    const [endDate, setEndDate] = useState(filterData?.end_date)
    useEffect(() => {
        if (startDate) {
            setEndDate()
            setFilterData((prev) => ({
                ...prev,
                start_date: startDate,
                end_date: ''
            }))
        }
    }, [startDate])

    useEffect(() => {
        setFilterData((prev) => ({
            ...prev,
            end_date: endDate
        }))
    }, [endDate])

        // handle on clear filters
        const handleOnClearFilters = () => {
            onClearFilter && onClearFilter()
            setStartDate()
            setEndDate()
        }

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Filter by'}
        >
            <div className='flex flex-col space-y-4'>
                <Dropdown
                    title={'Sort By'}
                    placeholder={'Sort By'}
                    name={'filter'}
                    onChange={handleChangeFilter}
                    value={filterData?.filter}
                    inputClass={"min-w-[180px]"}
                    option={EARNING_SORT}
                />
                <Dropdown
                    title={'Direction'}
                    placeholder={'Direction'}
                    name={'direction'}
                    onChange={handleChangeFilter}
                    value={filterData?.direction}
                    inputClass={"min-w-[180px]"}
                    option={DIRECTIONS}
                />
                <DateInput
                    title={'Select Start Date'}
                    name={'startDate'}
                    value={startDate}
                    onChange={(e) => { setStartDate(e.target.value) }}
                    placeholder={'Select Start Date'}
                    inputPadding={"px-4 py-[9px]"}
                    inputClass={"flex-1"}
                    parentClass={"flex-1"}
                />
                <DateInput
                    title={'Select End Date'}
                    name={'endDate'}
                    value={endDate}
                    onChange={(e) => { setEndDate(e.target.value) }}
                    placeholder={'Select End Date'}
                    inputPadding={"px-4 py-[9px]"}
                    inputClass={"flex-1"}
                    parentClass={"flex-1"}
                    min={startDate}
                />
                <div className="flex items-center justify-between gap-2 mt-4">
                    <Button text={'Clear'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={handleOnClearFilters} />
                    <Button text={'Apply'} className={'w-full'} onClick={onApplyFilter && onApplyFilter} />
                </div>
            </div>
        </Popup>
    )
}

export default ConciergeRestaurantAnalyticsFilter