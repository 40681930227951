import DashboardLayout from 'Components/DashboardLayout'
import Dropdown from 'Components/Elements/Dropdown'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import React from 'react'
import RequestCard from 'Components/RequestCard'
import Popup from 'Components/Elements/Popup'
import useAdminSupportRequest from './helper'
import SupportRequestCardShimmer from 'Utility/Shimmers/SupportRequestCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import { SupportRequestStatus } from 'Constants/Data/Categories'
import NotFound from 'Utility/NotFound'
import Back from 'Components/Elements/Back'

const AdminSupportRequests = () => {
    const {
        navigate, currentPage, setCurrentPage, endDate, setEndDate, startDate, setStartDate, openDetail, setOpenDetail, support_requests,
        pagination, loading, loadingSingle, getSingleRequest, supportDetail, commentLoading, addThreadComment, loadingThread, setStatus, status,
        updateStatusLoader, updateSupportRequestStatusValue, updateStatusValue, setUpdateStatusValue,
    } = useAdminSupportRequest()
    return (
        <>
            <DashboardLayout active={'SupportRequest'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                    <Back
                        title={'Support Requests'}
                    />
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="flex w-full gap-3 items-end lg:items-center justify-end">
                                <div className="flex">
                                    <Dropdown
                                        title={''}
                                        placeholder={'Filter by'}
                                        option={SupportRequestStatus}
                                        value={status}
                                        onChange={(e) => setStatus(e?.target?.value)}
                                    />
                                </div>
                                <DateRangePicker
                                    startDate={startDate}
                                    className="!max-w-none"
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg">
                                    {loading
                                        ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                                return <SupportRequestCardShimmer />
                                            })}
                                        </div>
                                        :
                                        support_requests?.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={support_requests?.length ? support_requests?.length : 10} //This is important field to render the next data
                                                next={() => {
                                                    setCurrentPage(currentPage + 1)
                                                }}
                                                hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                                loader={(pagination?.current_page !== pagination?.total_pages)
                                                    && <div className="flex justify-center items-center my-4">
                                                        <PreloaderSm />
                                                    </div>
                                                }
                                            >
                                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                                                    {support_requests?.map(itm => {
                                                        return <RequestCard
                                                            onCardClick={() => {
                                                                getSingleRequest(itm?.id)
                                                                setOpenDetail()
                                                            }}
                                                            id={itm?.id}
                                                            number={itm?.number}
                                                            status={itm?.status}
                                                            title={itm?.title}
                                                            category={itm?.type}
                                                            date={itm?.created_at}
                                                            user={itm?.user}
                                                            sameStatus={true}
                                                        />
                                                    })}
                                                </div>
                                            </InfiniteScroll>
                                            : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Popup
                        open={openDetail}
                        close={() => {
                            setUpdateStatusValue()
                            setOpenDetail(false)
                        }}
                        heading={'Request Detail'}
                        size={'md'}
                    >
                        {loadingSingle
                            ? <SupportRequestCardShimmer
                                isDetail={true}
                            />
                            : <RequestCard
                                id={supportDetail?.id}
                                number={supportDetail?.number}
                                status={supportDetail?.status}
                                title={supportDetail?.title}
                                category={supportDetail?.type}
                                image={supportDetail?.image}
                                date={supportDetail?.created_at}
                                user={supportDetail?.user}
                                isDetail={true}
                                sameStatus={true}
                                note={supportDetail?.notes}
                                threads={supportDetail?.thread}
                                btnLoading={commentLoading}
                                onSubmit={addThreadComment}
                                loadingThread={loadingThread}
                                updateStatus={true}
                                updateStatusValue={updateStatusValue}
                                setUpdateStatusValue={setUpdateStatusValue}
                                onClickUpdateStatus={updateSupportRequestStatusValue}
                                updateStatusLoader={updateStatusLoader}
                            />}
                    </Popup>
                </main>
            </DashboardLayout>
        </>
    )
}

export default AdminSupportRequests