
export const PERMISSIONS = [
    { label: 'Transaction & Payments', value: "Transaction & Payments" },
    { label: 'Rewards', value: "Rewards" },
    { label: 'Promotions Boosting Management', value: "Promotions Boosting Management" },
    { label: 'Promotion Request', value: "Promotion Request" },
    { label: 'Finance', value: "Finance" },
    { label: 'Wishlist', value: "Wishlist" },
    { label: 'Staff', value: "Staff" },
    { label: 'Analytics', value: "Analytics" },
    { label: 'Subscription', value: "Subscription" },
    { label: 'Top Performers', value: "Top Performers" },
    { label: 'Support Requests', value: "Support Requests" },
    { label: 'Settings', value: "Settings" }
]