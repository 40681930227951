import Svgs from "Assets/svgs"

export const Categories = [
    { "label": "Beach Club", "value": "Beach Club", icon: <Svgs.BeachClub /> },
    { "label": "Fast Casual", "value": "Fast Casual", icon: <Svgs.FastCasual /> },
    { "label": "Fine dining", "value": "Fine dining", icon: <Svgs.FineDinning /> },
    { "label": "Premium Casual", "value": "Premium Casual", icon: <Svgs.PremiumCasual /> },
    { "label": "Night Club", "value": "Fast Food", icon: <Svgs.NightClub /> },
    { "label": "Bar/Lounge", "value": "Bar/Lounge", icon: <Svgs.BarLounge /> },
]

export const SORT_BY_FILTER = [
    { "label": "By Alphabetically", "value": "name" },
    { "label": "By Distance", "value": "distance" },
    { "label": "By Recent Joining", "value": "joining_date" }
]

export const Distance = [
    { "label": "5 KM", "value": "5" },
    { "label": "10 KM", "value": "10" },
    { "label": "20 KM", "value": "20" },
    { "label": "30 KM", "value": "30" },
    { "label": "40 KM", "value": "40" },
    { "label": "50 KM", "value": "50" }
]

export const SupportRequestsCategories = [
    { "label": "Select Category", "value": "" },
    { "label": "Complaints", "value": "complaints" },
    { "label": "Suggesstions", "value": "suggesstions" },
    { "label": "Technical Support", "value": "technical support" },
    { "label": "Billing and Payments", "value": "billing and payments" },
    { "label": "General Inquiry", "value": "general inquiry" },
    { "label": "Feedback", "value": "feedback" },
    { "label": "Request Restaurant", "value": "request restaurant" },
    { "label": "Other", "value": "other" },
]

export const SupportRequestStatus = [
    { "label": "All Requests", "value": "" },
    { "label": "Pending", "value": "Pending" },
    { "label": "In progress", "value": "In progress" },
    { "label": "Resolved", "value": "Resolved" },
]

export const SupportRequestUpdateStatus = [
    { "label": "Pending", "value": "pending" },
    { "label": "In progress", "value": "in progress" },
    { "label": "Resolved", "value": "resolved" },
]


export const BookingAnalyticsStatus = [
    { "label": "All Bookings", "value": "" },
    { "label": "Placed", "value": "placed" },
    { "label": "Confirmed", "value": "confirmed" },
    { "label": "Billed", "value": "billed" },
    { "label": "No Show", "value": "no show" },
    { "label": "Cancelled", "value": "cancelled" },
]
export const BookingAnalyticsTimePeriod = [
    { "label": "Select Time Period", value: "" },
    { "label": "Today", value: "today" },
    { "label": "Yesterday", value: "yesterday" },
    { "label": "This week", value: "this_week" },
    { "label": "Last week", value: "last_week" },
    { "label": "This month", value: "this_month" },
    { "label": "Last month", value: "last_month" },
    { "label": "This year", value: "this_year" },
    { "label": "Last Year", value: "last_year" },
    { "label": "Select Custom Date", value: "date" }
]

export const InvoiceReportTimePeriod = [
    { "label": "Select Time Period", value: "" },
    { "label": "This month", value: "this_month" },
    { "label": "Last month", value: "last_month" },
    { "label": "This year", value: "this_year" },
    { "label": "Select Custom Date", value: "date" }
]

export const PAYMENT_METHODS = [
    {
        method_type: "cash",
        label: "Cash",
        name: 'cash',
        icon: <Svgs.CashIcon fill={'var(--secondary-color)'} />
    },
    {
        method_type: "bank",
        label: "Bank Transfer",
        name: 'bank',
        icon: <Svgs.CardIcon fill={'var(--secondary-color)'} />
    },
]

export const PaidUnpaidStatus = [
    { "label": "All", value: "" },
    { "label": "Paid", value: "paid" },
    { "label": "Unpaid", value: "unpaid" },
]

export const InvoicePaymentStatus = [
    { "label": "All", value: "" },
    { "label": "Paid", value: "paid" },
    { "label": "Partially Paid", value: "partially paid" },
    { "label": "Unpaid", value: "unpaid" },
]