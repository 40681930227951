import Svgs from 'Assets/svgs';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

const FilePicker = ({
    id,
    title,
    name,
    value,
    accept,
    error,
    required,
    onChange,
    customClass,
    mb_file_size,
    multiple,
    imgWidth,
    imgHeight
}) => {
    const [image, setImage] = useState(undefined)
    // Create a ref to access the file input element
    const fileInputRef = useRef(null);

    const handleDelete = () => {
        // Manually set the file input value to an empty string
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        // Call onChange with an empty value
        onChange && onChange({
            target: {
                name: name ? name : 'file',
                value: '',
                files: []
            }
        });
    };

    return (
        <>
            <div className="flex relative my-2 flex-col gap-2">
                {title &&
                    <div className={`text-sm col-span-2 text-blueGrey flex items-center gap-1`}>
                        {title}
                        {required && <span className="leading-[1] text-red-600"> *</span>}
                    </div>
                }
                <input
                    ref={fileInputRef} // Attach the ref to the input element
                    hidden
                    type="file"
                    multiple={multiple ? true : false}
                    // onChange={onChange && onChange}
                    onChange={(e) => {
                        if (multiple) {
                            for (let fl in e.target.files) {
                                let file = e.target.files[fl]
                                let kb_file_size = file?.size
                                let file_size_mb = (kb_file_size / 1000) / 1000
                                if (file_size_mb > mb_file_size) {
                                    toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "toast" })
                                    return
                                }
                            }
                        }
                        else {
                            let kb_file_size = e.target.files[0]?.size
                            let file_size_mb = (kb_file_size / 1000) / 1000
                            if (file_size_mb > mb_file_size) {
                                toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "toast" })
                                return
                            }
                        }

                        const file = e.target.files[0];
                        const fileExtension = file?.name?.split('.').pop();
                        const validFileTypes = accept ? accept.split(',') : ['*'];

                        if (!accept ||
                            validFileTypes.includes(file.type) || // Check MIME type (like image/png)
                            validFileTypes.includes(`.${fileExtension}`) || // Check extension (like .png)
                            (accept ? accept : '*')
                                ?.split(',')
                                ?.map(format => {
                                    if (format.startsWith('image/')) {
                                        return format.replace('image/', '').trim();
                                    } else if (format.startsWith('application/')) {
                                        return format.replace('application/', '').trim();
                                    }
                                    return format.trim();
                                })
                                .includes(fileExtension)
                        ) {
                            if (imgWidth && imgHeight) {
                                const reader = new FileReader();
                                reader.onload = (event) => {
                                    const img = new Image();
                                    img.src = event.target.result;
                                    img.onload = () => {
                                        const width = img.width;
                                        const height = img.height;
                                        if (width <= imgWidth && height <= imgHeight) {
                                            onChange && onChange(e)
                                            if (!multiple) {
                                                let url = URL.createObjectURL(e.target.files[0])
                                                setImage(url)
                                            }
                                        } else {
                                            toast.info(`Reduce image resolution to ${imgWidth} x ${imgHeight} or lower`, { toastId: "toast" });
                                            return;
                                        }
                                    };
                                };

                                reader.readAsDataURL(file);
                            } else {
                                onChange && onChange(e)
                                if (!multiple) {
                                    let url = URL.createObjectURL(e.target.files[0])
                                    setImage(url)
                                }
                            }
                        } else {
                            toast.info(`Only ${accept ? accept : 'image/*'} formats are supported.`, { toastId: "toast" });
                            return
                        }
                    }}
                    id={id ? id : "edit"}
                    name={name ? name : 'file'}
                    accept={accept ? accept : 'image/*'}
                    autoComplete="off"
                />
                <label
                    htmlFor={id ? id : "edit"}
                    className={`${error && 'border-red-500 text-red-500'} ${customClass ? customClass : "attach-file-btn"} gap-3 cursor-pointer`}
                >
                    <div>
                        <Svgs.AttachmentIcon
                            fill={error ? '#eb3b3b' : 'var(--secondary-color)'}
                        />
                    </div>
                    Attach File
                </label>
                {error && (
                    <span className={"text-error flex items-center gap-1 text-xs absolute top-[103%] w-full justify-end"}>
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </span>
                )}
            </div>
            {value?.name &&
                <div className={`flex gap-3 bg-darkGrey text-blueGrey items-center p-2 rounded-xl shadow justify-between ${customClass ? customClass : "attach-file-btn"}`}>
                    <div className="flex items-center gap-2 line-clamp-1">
                        <div><Svgs.FileIcon /></div>
                        {value?.name}
                    </div>

                    <div
                        className='cursor-pointer p-2 rounded-full'
                        onClick={handleDelete} // Use the delete handler function
                    >
                        <Svgs.CrossIcon fill={'#fff'} />
                    </div>
                </div>
            }
        </>
    );
};

FilePicker.defaultProps = {
    required: true
};

export default FilePicker;
