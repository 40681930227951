import React from 'react'
import Shimmer from './Shimmer'

const ConciergeWishlistShimmer = () => {
    return (
        <Shimmer>
            <div className='flex flex-col gap-2 bg-darkGrey rounded-lg p-4'>
                <div class="bg-gray-300 mb-1 h-52 w-full rounded"></div>
                <div class="bg-gray-300 mb-1 h-5 w-24 rounded"></div>
                <div class="bg-gray-300 mb-1 h-5 w-36 rounded"></div>
                <div class="bg-gray-300 h-5 w-32 rounded"></div>
                <div class="bg-gray-300 h-5 w-24 rounded"></div>
                <div class="bg-gray-300 h-5 w-56 rounded"></div>
                <div class="bg-gray-300 h-5 w-56 rounded"></div>
            </div>
        </Shimmer>
    )
}

export default ConciergeWishlistShimmer