import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import { openInvoiceInNewTab } from 'Constants/Functions/openInvoiceInNewTab'
import moment from 'moment'
import React from 'react'

const CommissionCard = ({ amount, name, date, number, proof, activeTab, isRecieved, onRecieveClick, updateLoader }) => {
    return (
        <>
            <div className={` bg-darkGrey rounded-lg md:rounded-[10px] min-h-20 flex flex-col items-center justify-between p-5`}>
                <h4 className={`font-semibold text-2xl text-secondary`}>{amount} {currency}</h4>
                <p className="text-blueGrey">Total Earned</p>
            </div>
            <div className='my-4 rounded-lg border border-secondary bg-darkGrey p-4 flex justify-between items-center'>
                <div className='flex flex-col space-y-1'>
                    <h1 className='font-semibold text-white'>{name}</h1>
                    <div className='flex gap-2 text-lightGrey items-center text-sm text-blueGrey'>
                        {moment(date).format('DD-MM-YYYY •')}
                        <span className="bg-lightGrey px-4 py-1 w-fit min-w-6 rounded-md text-secondary text-sm font-normal flex justify-center items-center">
                            ID: {number}
                        </span>
                    </div>
                </div>
                <div className='px-4 py-2 bg-secondary text-primary font-semibold max-h-[30px] flex gap-2 items-center rounded-md cursor-pointer text-sm'>
                    {amount} {currency}
                </div>
            </div>
            <div className='flex flex-col space-y-2'>
                <h1 className='text-blueGrey'>Amount transfer proof</h1>
                <Image className="w-full h-52 object-cover border border-darkGrey rounded-lg" src={proof} showDummy={true} />
                {activeTab == 'ourEarnings' ?
                    <div className={`${!isRecieved ? "flex justify-between items-center gap-4" : "w-full"}`}>
                        {!isRecieved &&
                            <Button
                                customThemeBtn={'view-edit-btn'}
                                text={'Recieve'}
                                className={'w-full'}
                                onClick={onRecieveClick}
                                loading={updateLoader}
                                disabled={updateLoader}
                            />
                        }
                        <Button
                            text={'View Invoice'}
                            className={'w-full'}
                            onClick={() => { openInvoiceInNewTab('payment', number) }}
                        />
                    </div>
                    :
                    <Button
                        text={'View Reciept'}
                        onClick={() => { openInvoiceInNewTab('reciept', number) }}
                    />
                }
            </div>
        </>
    )
}

export default CommissionCard