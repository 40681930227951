import { getAdminSingleConciergeTransactionOrEarningDetail, updateEarningPaymentRecieveStatus } from "Adapters/APIs/Admin/transaction&payments";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useTransactionDetail = ({ number, activeTab, successCallback, close }) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState()
    const [updateLoader, setUpdateLoader] = useState(false)

    // get admin single concierge detail data
    const getDetail = () => {
        let payload = {
            number: number,
            activeTab: activeTab
        }
        setLoader(true)
        const success = (data) => {
            if (data?.concierge_earning) {
                setData(data?.concierge_earning)
            } else {
                setData(data?.admin_earning)
            }
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleConciergeTransactionOrEarningDetail(access_token, payload, success, fail))
    }

    // update earning recieve status
    const updateEarningRecievStatus = () => {
        let payload = {
            transfer_number: number
        }
        const success = () => {
            setUpdateLoader(false)
            close && close()
            successCallback && successCallback()
        }
        const fail = () => {
            setUpdateLoader(false)
        }
        setUpdateLoader(true)
        dispatch(updateEarningPaymentRecieveStatus(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (number) {
            getDetail()
        }
    }, [number])

    return {
        loader, data, updateLoader, updateEarningRecievStatus
    }
}

export default useTransactionDetail