import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminPromotions from './helper'
import NotFound from 'Utility/NotFound'
import Button from 'Components/Elements/Button'
import AdminPromotionCard from 'Components/AdminPromotionCard'
import AdminCreateEditPromotions from './CreateEditPromotions'
import AdminPromotionCardShimmer from 'Utility/Shimmers/AdminPromotionCardShimmer'
import Back from 'Components/Elements/Back'

const AdminPromotions = () => {
    const { navigate, loader, data, addEditPromotion, setAddEditPromotion, editData, setEditData, getAdminPromotionData, updateOrAddPromotionData
    } = useAdminPromotions()
    return (
        <DashboardLayout active={'promotion'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 justify-between">
                    <Back
                        title={'Promotions'} />
                    <div className="flex justify-end my-2 gap-4">
                        <Button
                            text={'Create Promotion'}
                            onClick={() => { setAddEditPromotion(true) }}
                        />
                    </div>
                </div>
                <div className="py-6">
                    <div className="lg:col-span-8 space-y-5">
                        <div className="space-y-3">
                            {loader
                                ? <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 justify-between items-center">
                                    {[0, 1, 2, 3, 4]?.map(itm => {
                                        return <AdminPromotionCardShimmer />
                                    })}
                                </div>
                                :
                                data?.length > 0 ?
                                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-4 justify-between items-center'>
                                        {data?.map((itm, index) => {
                                            return <AdminPromotionCard
                                                index={index}
                                                id={itm?.id}
                                                name={itm?.name}
                                                duration={`${itm?.duration} Days`}
                                                status={itm?.status == 1 ? true : false}
                                                type={itm?.type}
                                                description={itm?.description}
                                                price={itm?.price}
                                                onCardClick={() => {
                                                    setEditData(itm)
                                                    setAddEditPromotion(true)
                                                }}
                                                onEditStatus={() => {
                                                    updateOrAddPromotionData(itm)
                                                }}
                                            />
                                        })}
                                    </div>
                                    : <NotFound />}
                        </div>
                    </div>
                </div>
            </main>

            {addEditPromotion &&
                <AdminCreateEditPromotions
                    open={addEditPromotion}
                    close={() => {
                        setEditData()
                        setAddEditPromotion(false)
                    }}
                    editData={editData}
                    successCallBack={getAdminPromotionData}
                />}
        </DashboardLayout>
    )
}

export default AdminPromotions