import { openDB } from 'idb';

const DB_NAME = 'HotelConcierge';
const DB_VERSION = 1;
const STORE_NAME = 'settings';

export async function saveDefaultRoute(defaultRoute) {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
  await db.put(STORE_NAME, defaultRoute, 'defaultRoute');
}

export async function getDefaultRoute() {
  const db = await openDB(DB_NAME, DB_VERSION);
  return db.get(STORE_NAME, 'defaultRoute');
}
