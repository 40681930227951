import Svgs from 'Assets/svgs';
import React from 'react';

const StarRatings = ({ className, totalStars = 5, value = 0, name, title, onChange }) => {
    const handleClick = (index, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const clickPosition = event.clientX - rect.left;
        const width = rect.width;
        const isHalf = clickPosition < width / 2; // Check if click was in the first half

        const newValue = isHalf ? index + 0.5 : index + 1;

        onChange && onChange({
            target: {
                name: name ? name : "rating",
                value: newValue
            }
        });
    };

    return (
        <div className="flex flex-col gap-2">
            <p className="text-blueGrey text-sm">{title}</p>
            <div className={`${className} flex w-full justify-between`}>
                {[...Array(totalStars)].map((_, index) => {
                    const isFullStar = index + 1 <= Math.floor(value); // Full star for whole part of value
                    const isHalfStar = !isFullStar && value > index && value < index + 1; // Half star for decimal part

                    return (
                        <span
                            key={index}
                            onClick={(e) => handleClick(index, e)}
                            className="text-[5rem] cursor-pointer"
                        >
                            {isFullStar ? (
                                <Svgs.StarFill />
                            ) : isHalfStar ? (
                                <Svgs.StarHalf />
                            ) : (
                                <Svgs.StarEmpty />
                            )}
                        </span>
                    );
                })}
            </div>
        </div>
    );
};

export default StarRatings;
