import React from 'react'

const SingleAdminConciergeDetailShimmer = () => {
    return (
        <div className="pt-4 space-y-5 animate-pulse">
            <div className='flex justify-between items-center'>
                <div className='flex gap-2 items-center'>
                    <div className="flex items-center justify-center h-[4rem] w-[4rem] overflow-hidden rounded-full bg-gray-200 ">
                    </div>
                    <div className="h-6 w-60 bg-gray-300 rounded-md"></div>
                </div>
                <div className="h-6 justify-end w-[36px] bg-gray-300 rounded-full"></div>
            </div>
            <div className='grid sm:grid-cols-2 grid-cols-1 justify-between items-center gap-4'>
                <div className='flex flex-col space-y-2'>
                    <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                    <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                    <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                    <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                    <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                    <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
                <div className='flex flex-col space-y-2'>
                    <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
                    <div className="h-6 w-3/4 bg-gray-200 rounded-md"></div>
                </div>
            </div>
            <div className='h-32 bg-gray-200 rounded'></div>
        </div>
    )
}

export default SingleAdminConciergeDetailShimmer