import React from 'react'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer';
import DashboardLayout from 'Components/DashboardLayout';
import Pagination from 'Components/Pagination';
import useAdminTransactionPayments from './helper';
import AdminTransactionTable from 'Utility/Tables/AdminTransactionTable';
import Svgs from 'Assets/svgs';
import TransactionDetail from './TransactionDetail';
import AdminAmountCardShimmer from 'Utility/Shimmers/AdminAmountCardShimmer';
import AmountCard from '../Analytics/Elements/AmountCard';
import Filters from './Elements/Filters';
import Back from 'Components/Elements/Back';

const AdminTransactionPayments = () => {
    const { data, navigate, paginationData, loader, currentPage, setCurrentPage, activeTab, setActiveTab, dropdownOptions, selectedItmDetail,
        setSelectedItemDetail, getDataForFirstTime, transactionsData, addFilter, setAddFilter, filterData, setFilterData, onClearFilter,
        onApplyFilter, handleChangeFilter
    } = useAdminTransactionPayments()

    return (
        <>
            <DashboardLayout active={'transaction'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className='flex gap-2 justify-between items-center pb-4 px-2'>
                        <Back
                            title={'Transactions & Payments'}
                        />
                        <div className='cursor-pointer' onClick={() => { setAddFilter(true) }}><Svgs.Filter2 width={40} height={40} /></div>
                    </div>
                    <div className="!m-0">
                        <div className="border-b mt-2">
                            <div className="flex items-center gap-6">
                                <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "conciergesPayment" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                    onClick={() => {
                                        setActiveTab("conciergesPayment");
                                    }}
                                >
                                    <h2>Concierges Payments</h2>
                                </div>
                                <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "ourEarnings" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                                    onClick={() => {
                                        setActiveTab("ourEarnings");
                                    }}
                                >
                                    <h2>
                                        Our Earnings
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="pt-6">
                            {loader ?
                                <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                    {[0, 1, 2]?.map((itm) => {
                                        return (
                                            <AdminAmountCardShimmer />
                                        )
                                    })}
                                </div>
                                :
                                <div className='grid md:grid-cols-3 grid-cols-2 gap-4'>
                                    <AmountCard
                                        amount={transactionsData?.total_bill_value}
                                        title={'Total bill value'}
                                        showCurrency
                                    />
                                    <AmountCard
                                        amount={transactionsData?.total_commission}
                                        title={'Total platform commission'}
                                        showCurrency
                                    />
                                    <AmountCard
                                        amount={transactionsData?.total_manager_earning}
                                        title={'Total concierges commission'}
                                        showCurrency
                                    />
                                </div>
                            }
                        </div>
                        <div className="py-6">
                            <div className="lg:col-span-8 space-y-5">

                                <div className="space-y-3">
                                    <div className="relative sm:rounded-lg border border-lightGrey">
                                        {loader
                                            ? <BookingTableShimer />
                                            : <AdminTransactionTable data={data} onRowCick={(data) => { setSelectedItemDetail(data) }} />
                                        }
                                        {data?.length > 0
                                            && <Pagination
                                                currentPage={currentPage}
                                                pageSize={paginationData?.per_page}
                                                totalCount={paginationData?.total_pages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                                totalDataCount={paginationData?.total}
                                                currentPageCount={data?.length}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>

            <Filters
                open={addFilter}
                close={setAddFilter}
                onClearFilter={onClearFilter}
                onApplyFilter={onApplyFilter}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filterData={filterData}
                setFilterData={setFilterData}
                dropdownOptions={dropdownOptions}
                handleChangeFilter={handleChangeFilter}
            />

            <TransactionDetail
                open={selectedItmDetail}
                close={setSelectedItemDetail}
                number={selectedItmDetail}
                activeTab={activeTab}
                successCallback={getDataForFirstTime}
            />
        </>
    )
}

export default AdminTransactionPayments