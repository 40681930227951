import { getAllPromotion, getDashboardDataAPI } from "Adapters/APIs/Concierge/Details"
import { getAllCategories, getAllRestaurents } from "Adapters/APIs/restaurants"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getAdminSingleRestaurantsData } from "../../../Redux/Admin/Restaurants/Action";

const useDashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')


    // states
    const [loading, setLoading] = useState(true)
    const [resturantLoading, setResturantLoading] = useState(true)
    const [promotionloading, setPromotionLoading] = useState(true)
    const [createBookingPopup, setCreateBookingPopup] = useState()
    const [openRestaurantPopup, setOpenRestaurantPopup] = useState(false)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [getCategoriesLoader, setGetCategoriesLoader] = useState(true)

    // redux states
    const state = useSelector(state => state)
    const dashboardData = state?.concierge?.dashbaord_data
    const restaurants = state?.retaurant?.restaurants
    const promotions = state?.concierge?.promotions
    const categoriesData = state?.categories?.categories

    // get dashboard cards data
    const dashboardCardData = () => {
        let payload = {
            start_date: startDate ? startDate : "",
            end_date: endDate ? endDate : ""
        }
        setLoading(true)
        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        dispatch(getDashboardDataAPI(access_token, payload, success, fail, setLoading))
    }

    // get all restaurants
    const getAllRestaurantsData = () => {
        let payload = {
            sort_by: "name",
            sort_order: "asc"
        }
        setResturantLoading(true)
        const success = () => {
            setResturantLoading(false)
        }
        const fail = () => {
            setResturantLoading(false)
        }
        dispatch(getAllRestaurents(access_token, payload, success, fail, setResturantLoading))
    }

    // get categories
    const getCategories = () => {
        const success = () => {
            setGetCategoriesLoader(false)
        }
        const fail = () => {
            setGetCategoriesLoader(false)
        }
        setGetCategoriesLoader(true)
        dispatch(getAllCategories('', success, fail))
    }

    // store restaurant 
    const storeRestaurant = (data) => {
        navigate(`/concierge/restaurant/${data?.id}`)
        dispatch(getAdminSingleRestaurantsData({
            retaurant: data
        }))
    }

    useEffect(() => {
        dashboardCardData()
    }, [startDate, endDate])


    useEffect(() => {
        setLoading(true)
        setResturantLoading(true)
        setPromotionLoading(true)
        getAllRestaurantsData()
        getCategories()
        // dispatch(getAllPromotion(access_token, '', '', '', setPromotionLoading))
    }, [])

    return {
        dashboardData, restaurants, promotions, navigate, openRestaurantPopup, setOpenRestaurantPopup, loading, resturantLoading,
        promotionloading, createBookingPopup, setCreateBookingPopup, startDate, setStartDate, endDate, setEndDate, storeRestaurant,
        storeDataInLocalStorage, getCategoriesLoader, categoriesData
    }

}

export default useDashboard