import React from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import DummyImage from "../../../../Assets/images/error2.png"

export default function RestaurantSlider({ data }) {
    return (
        <>
            {data?.length > 0 ?
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    autoplay={true}
                    breakpoints={{
                        1200: {
                            slidesPerView: 4,
                        },
                        1028: {
                            slidesPerView: 3,
                        },
                        320: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {data?.map((itm, index) => (
                        <SwiperSlide key={index}>
                            <div className='h-[120px] '>
                                <img className='h-full w-full object-cover rounded-lg' src={itm?.url} alt={index} />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper> :
                <Swiper
                    slidesPerView={1}
                >
                    <SwiperSlide >
                        <div className='h-[120px] '>
                            <img className='h-full w-full object-cover rounded-lg' src={DummyImage} alt="dummy" />
                            {/* <img className='h-full w-full object-contain bg-[#e6f7f3] rounded-lg' src={DummyImage} alt="dummy" /> */}
                        </div>
                    </SwiperSlide>
                </Swiper>
            }
        </>
    );
}