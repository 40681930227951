import { GET_ALL_BUSINESS, GET_ALL_BUSINESS_TOPBAR } from "./ActionType";

export const getAllBusinessData = (data) => ({
    type: GET_ALL_BUSINESS,
    payload: data
});

export const getAllBusinessDataTopbar = (data) => ({
    type: GET_ALL_BUSINESS_TOPBAR,
    payload: data
});