import { deleteAccount } from 'Adapters/APIs/Authentication'
import useLogoutHelper from 'Hooks/useLogoutHelper'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

const useProfile = (onSuccessCallback) => {
    const { logoutUser } = useLogoutHelper()

    const [searchParams, setSearchParams] = useSearchParams();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const user = getDataFromLocalStorage('user_data')
    const access_token = getDataFromLocalStorage('access_token')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [isEditProfile, setIsEditProfile] = useState(false)
    const [activeTab, setActiveTab] = useState(searchParams.get('activeTab') || 'profile')
    const [deleteProfile, setDeleteProfile] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)

    // Delete Account
    const handleDeleteAccount = () => {
        const onSuccessCallback = () => {
            setDeleteProfile(false)
            setDeleteLoader(false)
        }
        const success = (data) => {
            logoutUser(onSuccessCallback)
        }
        const fail = () => {
            setDeleteLoader(false)
        }
        setDeleteLoader(true)
        dispatch(deleteAccount(access_token, {}, success, fail))
    };

    useEffect(() => {
        // Update URL when activeTab changes
        if (activeTab && window.location.pathname == "/concierge/profile") {
            setSearchParams({ activeTab });
        }
    }, [activeTab]);


    return {
        user, navigate, isEditProfile, setIsEditProfile, activeTab, setActiveTab, deleteProfile, setDeleteProfile, deleteLoader,
        handleDeleteAccount
    }
}

export default useProfile