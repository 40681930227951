import moment from 'moment'
import React from 'react'

const NotificationCard = ({ id, type, data, ids, title, message, created_at, navigate_url, read_at, icon }) => {
    return (
        <>
            <div
                onClick={() => { navigate_url(id, type, ids, data) }}
                className={`flex !mx-0 cursor-pointer gap-3 !items-start justify-between shadow-md rounded-md pl-2 pr-4 py-1.5 border transition-all text-blueGrey border-lightGrey 
                ${read_at == null ? "bg-darkGrey" : "bg-primary"}`}
            >
                <div className="!mt-2 !m-0 text-'var(--secondary-color)" >
                    {icon}
                </div>
                <div className="flex flex-col gap-2 p-2 w-full">
                    <h1 className="font-bold cursor-pointer text-sm">
                        {title}
                    </h1>
                    <p className="font-semibold cursor-pointer text-sm">
                        {message}
                    </p>
                    <div className="flex justify-between w-full">
                        <p className="font-normal text-blurGrey cursor-pointer text-xs">
                            {moment(created_at)?.format("LT")}
                        </p>
                        <p className="font-normal text-blurGrey cursor-pointer text-xs">
                            {moment(created_at)?.format("MM/DD/YYYY")}
                        </p>
                    </div>
                </div>
            </div>

        </>
    )
}

export default NotificationCard