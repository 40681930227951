import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useAdminPromotionRequest from './helper'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import SupportRequestCardShimmer from 'Utility/Shimmers/SupportRequestCardShimmer'
import InfiniteScroll from 'react-infinite-scroll-component'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import NotFound from 'Utility/NotFound'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import AdminDashBoardCards from '../MainDashbaord/Elements/AdminDashBoardCards'
import PromotionCard from './Elements/PromotionCard'
import moment from 'moment'
import DetailPromotionrequest from './DetailPromotionrequest'
import Back from 'Components/Elements/Back'

const AdminPromotionRequest = () => {
    const { data, navigate, paginationData, loader, secondLoader, currentPage, setCurrentPage, startDate, setStartDate, endDate, setEndDate,
        activeTab, setActiveTab, totalearning, dataDetail, setDataDetail, callDataForFirstTime } = useAdminPromotionRequest()
    return (
        <DashboardLayout active={'PromotionRequest'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                <div className='flex justify-between items-center gap-4'>
                    <Back
                        title={'Promotion Request'}
                    />
                    <DateRangePicker
                        startDate={startDate}
                        className="!max-w-none"
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        maxDate={new Date()}
                    />
                </div>
                <div className='my-4'>
                    {loader ?
                        <AdminDashboardCardShimmer className={"grid grid-cols-1 sm:grid-cols-2 gap-4"} arrayLength={[0]} showSvg={false} noMinHeight={true} /> :
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <AdminDashBoardCards title={'Total Earned'} bgColor={'bg-[#E0F5F0] cursor-pointer'} amount={totalearning} showSvg={false} noMinHeight={true} />
                        </div>
                    }
                </div>
                <div className="border-b my-5">
                    <div className="flex items-center gap-6">
                        <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "approved" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                            onClick={() => {
                                setActiveTab("approved");
                            }}
                        >
                            <h2>Approved</h2>
                        </div>
                        <div className={`cursor-pointer pb-3 border-b-[2px] ${activeTab == "unapproved" ? "font-semibold text-secondary border-secondary" : "border-transparent text-blueGrey"}`}
                            onClick={() => {
                                setActiveTab("unapproved");
                            }}
                        >
                            <h2>
                                Pending
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="!m-0">
                    {loader
                        ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 py-4">
                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                return <SupportRequestCardShimmer />
                            })}
                        </div>
                        :
                        data?.length > 0 ?
                            <InfiniteScroll
                                dataLength={data?.length ? data?.length : 10} //This is important field to render the next data
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }}
                                hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                loader={secondLoader
                                    ? <div className="flex justify-center items-center my-4">
                                        <PreloaderSm />
                                    </div> : ""
                                }
                            >
                                <div className="!m-0 grid grid-cols-1 sm:grid-cols-2 gap-5">
                                    {data?.map(itm => {
                                        return <PromotionCard
                                            onCardClick={() => { setDataDetail(itm) }}
                                            name={itm?.restaurant?.name}
                                            title={itm?.package?.name}
                                            amount={itm?.package?.price}
                                            featuredDate={`${moment(itm?.featured_from).format('DD MMM YYYY')} - ${moment(itm?.featured_to).format('DD MMM YYYY')}`}
                                            bought_at={moment(itm?.created_at).format('DD MMM YYYY')}
                                            description={itm?.package?.description}
                                            approved={itm?.is_approved == 1 ? true : false}
                                        />
                                    })}
                                </div>
                            </InfiniteScroll>
                            : <NotFound />
                    }
                </div>
            </main>
            {dataDetail &&
                <DetailPromotionrequest
                    open={dataDetail}
                    close={setDataDetail}
                    data={dataDetail}
                    successCallback={callDataForFirstTime}
                    id={dataDetail?.package?.id}
                />}
        </DashboardLayout>
    )
}

export default AdminPromotionRequest