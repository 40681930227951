import React, { useEffect, useState } from 'react';
import 'swiper/css';
import DummyImage from "../../../../Assets/images/error2.png"
import Svgs from 'Assets/svgs';
import { convertImgToUrl } from 'Hooks/useObjectKeys';
import Image from 'Components/Elements/Image';
import ImageShimmer from 'Utility/Shimmers/ImageShimmer';

export default function EditRestaurantSlider({ data, onRemove, hideRemove }) {
    const [loading, setLoading] = useState(true); // State to manage the loader

    useEffect(() => {
        // Set a timeout for 3 seconds to simulate loading
        const timer = setTimeout(() => {
            setLoading(false); // Hide loader after 3 seconds
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timeout when component unmounts
    }, []);

    return (
        <>
            <div className="flex gap-4 justify-start w-full overflow-auto py-2 pr-4">
                {loading ?
                    [0, 1, 2, 3]?.map(itm => {
                        return <ImageShimmer customHeight={'h-[120px] w-[200px]'} />
                    })
                    : data?.length > 0
                        ? data?.map((itm, index) => {
                            return (<div className='h-[120px] w-[200px]  relative cursor-pointer'>
                                <Image className='h-full w-full  object-cover rounded-lg' src={itm?.url || convertImgToUrl(itm)} alt={index} />
                                {!hideRemove &&
                                    <div className="absolute -top-2 -right-2 border  rounded-full p-2 bg-[#FFE8E1] border-[#FFE8E1]"
                                        onClick={() => { onRemove && onRemove(itm?.id, index) }}
                                    >
                                        <Svgs.CrossIcon fill={'#FF5722'} />
                                    </div>
                                }
                            </div>)
                        })
                        :
                        <div className='h-[120px] w-[200px]'>
                            <img className='h-full w-full object-cover rounded-lg' src={DummyImage} alt="dummy" />
                        </div>
                }
            </div>
        </>
    );
}