import Popup from 'Components/Elements/Popup'
import React from 'react'
import PromotionCard from '../Elements/PromotionCard'
import moment from 'moment'
import useDetailPromotionRequest from './helper'

const DetailPromotionRequest = ({ open, close, data, id, successCallback }) => {
    const { updateLoader, updatePendingPromotionRequestStatus } = useDetailPromotionRequest({ id, successCallback, close })
    return (
        <Popup
            open={open}
            close={close}
            heading={"Transaction Request"}
            customSizeStyle={'sm:w-[40vw] md:w-[50vw] lg:w-[40vw]'}
        >
            <PromotionCard
                name={data?.restaurant?.name}
                title={data?.package?.name}
                amount={data?.package?.price}
                featuredDate={`${moment(data?.featured_from).format('DD MMM YYYY')} - ${moment(data?.featured_to).format('DD MMM YYYY')}`}
                bought_at={moment(data?.created_at).format('DD MMM YYYY')}
                description={data?.package?.description}
                approved={data?.is_approved == 1 ? true : false}
                showDetail={true}
                imageSrc={data?.restaurant?.images?.length > 0 ? data?.restaurant?.images[0]?.url : ""}
                proof={data?.proof}
                number={data?.number}
                confirmLoader={updateLoader}
                onConfirmClick={updatePendingPromotionRequestStatus}
            />
        </Popup>
    )
}

export default DetailPromotionRequest