import React, { useEffect, useState } from 'react'
import SideBar from '../Sidebar'
import TopBar from '../TopBar'

const DashboardLayout = ({ children, active, showTopbarSearch }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        // This will scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
        document.querySelector(".topscroll").scrollIntoView();
    }, []);

    return (
        <>
            <div class="antialiased bg-primary">
                <TopBar openSidebar={toggleSidebar} showTopbarSearch={showTopbarSearch} />
                <SideBar openSidebar={isSidebarOpen} active={active} />
                <div className='topscroll'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default DashboardLayout