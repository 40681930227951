import { GET_ALL_BUSINESS } from "./ActionType";

const initialState = {
    businesses: [],

}

const BusinessReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_BUSINESS:
            return {
                ...state,
                businesses: payload,
            }
        default:
            return {
                ...state,
            };
    }
}

export default BusinessReducer