import NotFound from 'Utility/NotFound'
import moment from 'moment'
import React from 'react'

const CommissionAnalyticTable = ({ data }) => {
    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead className="text-sm text-white border-b border-table bg-primary capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Booking Date
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Restaurant
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Guest Name
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Total Bill
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Guest
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                My Earning
                            </th>
                            <th scope="col" class="px-6 py-3 whitespace-nowrap">
                                Payment Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.length > 0
                                ? data?.map(itm => {
                                    return (
                                        <tr className={`text-blueGrey cursor-pointer text-blueGrey border-b border-table bg-primary`}>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.number}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {moment(itm?.date).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.restaurant?.name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.guest_name}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.amount}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.total_pax}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                {itm?.manager_earning}
                                            </td>
                                            <td class="px-6 py-3 whitespace-nowrap">
                                                <div className='border border-blueGrey w-fit px-3 py-1 rounded-2xl'>
                                                    {itm?.concierges_paid}
                                                </div>
                                            </td>
                                        </tr>)
                                })
                                : <tr>
                                    <td colSpan={7}>
                                        <NotFound />
                                    </td>
                                </tr>
                        }

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CommissionAnalyticTable