import Image from 'Components/Elements/Image'
import React from 'react'

const RestaurantFilterCircleCard = ({ icon, text, onClick, key, loading }) => {
    return (
        loading ?
            <div className='flex flex-col gap-2 justify-center items-center cursor-pointer animate-pulse' key={key}>
                <div className='border border-[#3F3F3F] bg-gray-500 p-6 rounded-full'>
                </div>
                <div className='h-4 w-20 rounded-md bg-gray-500'></div>
            </div>
            :
            <div className='flex flex-col gap-2 justify-center items-center cursor-pointer w-16 text-center' key={key} onClick={onClick && onClick}>
                <div className='w-14 h-14 border border-[#3F3F3F] p-3 rounded-full'>
                    <Image
                        src={icon}
                        className={'rounded-full w-full h-full object-cover'}
                        customLoaderHeight={"!rounded-full w-full h-full"}
                    />
                </div>
                <h4 className='text-sm text-blueGrey'>{text}</h4>
            </div>
    )
}

export default RestaurantFilterCircleCard