import React from 'react'

const RoleSelectionCard = ({ icon, name, active, setActiveItem }) => {
    return (
        <>
            <div
                onClick={() => setActiveItem && setActiveItem(name?.toLowerCase())}
                className={`flex rounded-[16px] items-center justify-center cursor-pointer border-2 ${active ? "border-secondary" :'border-lightGrey'} bg-lightGrey py-6  px-10`}
            >
                <div className="flex flex-col gap-4 items-center">
                    <div>{icon}</div>
                    <div className='text-lg text-[#B7B7B7]'>{name}</div>
                </div>
            </div>
        </>
    )
}

export default RoleSelectionCard