import { GET_ALL_MENU_CATEGORIES } from "./ActionType";

const initialState = {
    main_manu_category: [],
    beverage_manu_category: [],
    desert_manu_category: []
}

const MenuCategoryReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_MENU_CATEGORIES:
            return {
                ...state,
                main_manu_category: payload?.categories?.filter(itm => itm?.type == 'main_menu'),
                beverage_manu_category: payload?.categories?.filter(itm => itm?.type == 'beverage_menu'),
                desert_manu_category: payload?.categories?.filter(itm => itm?.type == 'dessert_menu')
            }
        default:
            return {
                ...state,
            };
    }
}

export default MenuCategoryReducer