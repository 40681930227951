import { BASE_URL, get_admin_concierges_payments, get_admin_earnings, get_single_conierge_transaction_detail, get_single_earning_detail, update_single_earning_recieve_status } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import axios from "axios";
import { toast } from "react-toastify";

// get admin concierge payments and admin earnings
export const getAdminConciergePaymentsAndEarnings = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    let endUrl = payload?.type == 'conciergesPayment' ? get_admin_concierges_payments : get_admin_earnings
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${endUrl}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// get admin single concierge transaction detail / single earning detail
export const getAdminSingleConciergeTransactionOrEarningDetail = (access_token, payload, success, fail) => async (dispatch) => {
    let endUrl = payload?.activeTab == 'conciergesPayment' ? get_single_conierge_transaction_detail : get_single_earning_detail
    delete payload.activeTab
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${endUrl}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// update our earning payment recieve
export const updateEarningPaymentRecieveStatus = (access_token, payload, success, fail) => async (dispatch) => {
    const formData = convertDataToFormData(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_single_earning_recieve_status}`, formData, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}