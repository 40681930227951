import Cookies from "js-cookie"

// import React from "react";
export const logoutfunction = () => {
    // Store userLocation temporarily
    const userLocation = localStorage.getItem('userLocation');

    localStorage.removeItem('user_data')
    localStorage.removeItem('access_token')
    localStorage.clear()

    // Set userLocation back in localStorage
    if (userLocation) {
        localStorage.setItem('userLocation', userLocation);
    }


    // Remove all cookies
    const allCookies = Cookies.get(); // Get all cookies
    Object.keys(allCookies).forEach((cookieName) => {
        Cookies.remove(cookieName); // Remove each cookie
    });
    window.location.href = "/login"
}
