// Redux/Cuisines/Actions.js
import { GET_ALL_BOOKINGS, GET_BOOKING_ANALYTICS } from "./ActionType";

export const getAllBookings = (data) => ({
    type: GET_ALL_BOOKINGS,
    payload: data
});
export const getBookingAnalyticsAction = (data) => ({
    type: GET_BOOKING_ANALYTICS,
    payload: data
});