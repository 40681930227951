import { GET_ADMIN_CONCIERGES, GET_ADMIN_SINGLE_CONCIERGE } from "./ActionType";

const initialState = {
    concierges: [],
    pagination_data: {},
    singleConcierge: {}
}

const AdminConcierges = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ADMIN_CONCIERGES:
            let updatedConcierges;
            if (payload?.concierges?.length == 0) {
                updatedConcierges = []
            } else {
                if (payload?.pagination_data?.meta?.current_page !== 1) {
                    updatedConcierges = [...state?.concierges, ...payload?.concierges];
                } else {
                    updatedConcierges = payload?.concierges;
                }
            }
            return {
                ...state,
                concierges: updatedConcierges,
                pagination_data: payload?.pagination_data?.meta
            }
        case GET_ADMIN_SINGLE_CONCIERGE:
            return {
                ...state,
                singleConcierge: payload?.user,
            }
        default:
            return {
                ...state,
            };
    }
}

export default AdminConcierges