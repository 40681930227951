import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import MainRoutes from './Routes';
import NoInternetPage from 'Components/NoInternetPage';
import useAppHelper from 'useAppHelper';
import NotificationHandler from 'NotificationHandler';

function App() {
  const { isInternetWorking, isOnline, screenWidth } = useAppHelper()

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        theme='colored'
        style={{
          width: screenWidth >= 629 ? "500" : screenWidth >= 481 ? '95%' : "auto"
        }}
      />
      {(isOnline && isInternetWorking) ? <MainRoutes /> : <NoInternetPage />}
      {/* <NotificationPrompt /> */}
      {/* <NotificationHandler /> */}
    </>
  );
}

export default App;