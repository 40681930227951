import { currency } from 'Adapters/variables'
import Button from 'Components/Elements/Button'
import FloatInput from 'Components/Elements/FloatInput'
import LoginInput from 'Components/Elements/LoginInput'
import Textarea from 'Components/Elements/TextArea'
import React from 'react'

const AddMenuItem = ({ errors, onChange, mainMenu, loading, formData, onSubmit }) => {
    return (
        <>
            <div className="flex flex-col space-y-4">
                <LoginInput
                    title='Menu Name ddd'
                    placeholder='Enter Menu Name'
                    value={mainMenu?.title}
                    readOnly={true}
                    disabled={true}
                />
                <hr className='' />
                <LoginInput
                    title='Menu Item'
                    placeholder='Enter menu item name'
                    name="name"
                    value={formData?.name}
                    onChange={onChange}
                    error={errors?.name}
                    required={true}
                />

                <FloatInput
                    title='Price'
                    placeholder='Enter menu price'
                    name="price"
                    value={formData?.price}
                    onChange={onChange}
                    error={errors?.price}
                    leftIcon={currency}
                    required={true}
                    iconStyle={"py-4"}
                />

                <FloatInput
                    iconStyle={"py-4 px-6"}
                    title='Discount'
                    placeholder='Enter Discount'
                    name="discount"
                    value={formData?.discount}
                    required={false}
                    onChange={onChange}
                    leftIcon={'%'}
                />

                <Textarea
                    title='Description'
                    placeholder='Enter Description'
                    name="description"
                    required={true}
                    value={formData?.description}
                    onChange={onChange}
                    error={errors?.description}
                />

                <Button title={formData?.id ? 'Save' : 'Add'} onClick={onSubmit} loading={loading} />
            </div>
        </>
    )
}

export default AddMenuItem