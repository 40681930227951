import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import Pagination from 'Components/Pagination'
import BookingTableShimer from 'Utility/Shimmers/BookingTableShimer'
import ConciergeBookingAnalyticsTable from 'Utility/Tables/ConciergeBookingAnalyticsTable'
import useBookingAnalytics from './helper'
import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import Back from 'Components/Elements/Back'
import BookingAnalyticsFilter from 'Pages/Admin/Analytics/Elements/BookingAnalyticsFilter'
import { RESTAURANT_BOOKING_STATUSES } from 'Constants/Data/Statuses'
import Svgs from 'Assets/svgs'

const BookingAnalytics = () => {
    const { setFilterData, filterData, restaurants, filterPopup, setFilterPopup, handleChangeFilter, loading, booking, currentPage,
        pagination_data, setCurrentPage, total_bill_amount, total_manager_earning, onApplyFilter, onClearFilter
    } = useBookingAnalytics()
    return (
        <>
            <DashboardLayout active={'bookingAnalytics'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen max-h-screen overflow-y-auto">
                    <div className="space-y-4">
                        <div className='flex gap-2 justify-between items-center py-4 px-2'>
                            <Back title={'Booking Analytics'} />
                            <div className='cursor-pointer' onClick={() => { setFilterPopup(true) }}>
                                <Svgs.Filter2 width={40} height={40} />
                            </div>
                        </div>
                        {loading
                            ?
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                                <AdminDashboardCardShimmer
                                    showCurrency={false} showSvg={false} noMinHeight={true} arrayLength={[1]}
                                    className='w-full'
                                    className2={"flex gap-1 flex-col-reverse items-center w-full"}
                                />
                                <AdminDashboardCardShimmer
                                    showCurrency={false} showSvg={false} noMinHeight={true} arrayLength={[1]}
                                    className='w-full'
                                    className2={"flex gap-1 flex-col-reverse items-center w-full"}
                                />
                                <AdminDashboardCardShimmer
                                    showCurrency={false} showSvg={false} noMinHeight={true} arrayLength={[1]}
                                    className='w-full'
                                    className2={"flex gap-1 flex-col-reverse items-center w-full"}
                                />
                            </div>
                            : <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4 mb-4">
                                <AdminDashBoardCards
                                    className={"flex flex-col-reverse items-center w-full"}
                                    title={'Total Bill Value'}
                                    bgColor={'bg-[#E0F5F0] cursor-pointer'}
                                    amount={total_bill_amount} showCurrency={false}
                                    showSvg={false} noMinHeight={true}
                                />
                                <AdminDashBoardCards
                                    className={"flex flex-col-reverse items-center w-full"}
                                    title={'Total Earning'}
                                    bgColor={'bg-[#E0F5F0] cursor-pointer'}
                                    amount={total_manager_earning} showCurrency={false}
                                    showSvg={false} noMinHeight={true}
                                />
                                <AdminDashBoardCards
                                    className={"flex flex-col-reverse items-center w-full"}
                                    title={'Total Recieved Amount'}
                                    bgColor={'bg-[#E0F5F0] cursor-pointer'}
                                    amount={total_manager_earning} showCurrency={false}
                                    showSvg={false} noMinHeight={true}
                                />
                            </div>
                        }
                    </div>
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-table">
                                    {loading
                                        ? <BookingTableShimer columns={8} />
                                        : <ConciergeBookingAnalyticsTable
                                            data={booking}
                                        />
                                    }
                                    {booking?.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            pageSize={pagination_data?.per_page}
                                            totalCount={pagination_data?.total_pages}
                                            onPageChange={(page) => setCurrentPage(page)}
                                            totalDataCount={pagination_data?.total}
                                            currentPageCount={booking?.length}
                                            showing={pagination_data?.showing}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>


                <BookingAnalyticsFilter
                    bookingStatuses={RESTAURANT_BOOKING_STATUSES}
                    open={filterPopup}
                    close={setFilterPopup}
                    onClearFilter={onClearFilter}
                    onApplyFilter={onApplyFilter}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    dropdownOptions={restaurants}
                    activeTab={'restaurant'}
                    hideSelection={true}
                    handleChangeFilter={handleChangeFilter}
                />

            </DashboardLayout>
        </>
    )
}

export default BookingAnalytics