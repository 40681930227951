import { useEffect, useState } from "react"

const useSingleRestaurantRatingPupup = (ratingData) => {
    const [currentTab, setCurrentTab] = useState('tripadvisor')
    const [activeTabData, setActiveTabData] = useState(undefined)

    useEffect(() => {
        const matchedData = ratingData?.find(itm => itm?.type == currentTab)
        if (matchedData) {
            setActiveTabData(matchedData)
        } else {
            setActiveTabData(undefined)
        }
    }, [currentTab])

    return {
        currentTab, setCurrentTab, activeTabData
    }
}

export default useSingleRestaurantRatingPupup