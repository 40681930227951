import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import React from 'react'
import useAdminCreateEditStaff from './helper'
import Dropdown from 'Components/Elements/Dropdown'
import PhoneInput from 'Components/Elements/PhoneInput'
import MultiDropdown from 'Components/Elements/MultiDropdown'
import { PERMISSIONS } from 'Constants/Data/Permissions'

const AdminCreateEditStaff = ({ open, close, editData, successCallBack, staffRoles }) => {
    const { data, loader, updateOrAddStaffData, handleChange, errors, handlePermissionChange
    } = useAdminCreateEditStaff({ editData, close, successCallBack, staffRoles })
    return (
        <Popup
            open={open}
            close={close}
            heading={editData ? "Edit Staff" : "Add Staff"}
            customSizeStyle={'sm:w-[40vw] md:w-[50vw] lg:w-[40vw]'}
        >
            <div className="flex flex-col space-y-5">
                <div>
                    <Dropdown
                        required={true}
                        title={'Select Role'}
                        placeholder={'Select user role'}
                        value={data?.role_id}
                        name={'role_id'}
                        onChange={handleChange}
                        option={staffRoles?.map(itm => ({
                            label: itm?.name,
                            value: itm?.id
                        }))}
                        error={errors?.role_id}
                    />
                </div>
                <LoginInput
                    required={true}
                    title={'First Name'}
                    placeholder={'Enter first Name'}
                    value={data?.first_name}
                    name={'first_name'}
                    onChange={handleChange}
                    error={errors?.first_name}
                />
                <LoginInput
                    required={true}
                    title={'Last Name'}
                    placeholder={'Enter last Name'}
                    value={data?.last_name}
                    name={'last_name'}
                    onChange={handleChange}
                    error={errors?.last_name}
                />
                <LoginInput
                    required={true}
                    title={'Email'}
                    type={'email'}
                    placeholder={'Enter email'}
                    value={data?.email}
                    name={'email'}
                    onChange={handleChange}
                    error={errors?.email}
                    disabled={editData?.email}
                />
                <PhoneInput
                    value={data?.number}
                    error={errors?.number}
                    name={'number'}
                    onChange={handleChange}
                    required={true}
                    title={'Mobile Number'}
                    placeholder={'Enter mobile number'}
                />
                <div>
                    <MultiDropdown
                        required={true}
                        title={'Permissions'}
                        placeholder={'Select permissions'}
                        value={data?.permissions}
                        name={'permissions'}
                        onChange={handlePermissionChange}
                        options={PERMISSIONS}
                        error={errors?.permissions}
                    />
                </div>
                <div className='pt-2'>
                    <Button
                        className={'w-full'}
                        title={editData ? "Save" : "Add"}
                        loading={loader}
                        disabled={loader}
                        onClick={updateOrAddStaffData}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default AdminCreateEditStaff