import Svgs from 'Assets/svgs';
import React from 'react'

const StarsRating = ({ rating, maximumRating, starSize }) => {
    const updatedRating = rating ? rating : 0
    const maxRating = maximumRating ? maximumRating : 5;
    const filledStars = Array.from({ length: +updatedRating }, (_, index) => index + 1);
    const emptyStars = Array.from({ length: maxRating - +updatedRating }, (_, index) => index + +updatedRating + 1);
    const hasHalfStar = rating % 1 >= 0.5;
    
    return (
        <div className='flex gap-4 items-center justify-center'>
            {filledStars?.map((_, index) => (
                <Svgs.StarFill width={starSize} height={starSize} />
            ))}
            {hasHalfStar && (
                <Svgs.StarHalf width={starSize} height={starSize} />
            )}
            {emptyStars?.map((_, index) => (
                <Svgs.StarEmpty width={starSize} height={starSize} />
            ))}
        </div>
    )
}

export default StarsRating