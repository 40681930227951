export const DIAL_CODES = [
    { "Code": "AF", "label": "+93", "value": "93" },
    { "Code": "AL", "label": "+355", "value": "355" },
    { "Code": "DZ", "label": "+213", "value": "213" },
    { "Code": "AD", "label": "+376", "value": "376" },
    { "Code": "AO", "label": "+244", "value": "244" },
    { "Code": "AR", "label": "+54", "value": "54" },
    { "Code": "AM", "label": "+374", "value": "374" },
    { "Code": "AW", "label": "+297", "value": "297" },
    { "Code": "AU", "label": "+61", "value": "61" },
    { "Code": "AT", "label": "+43", "value": "43" },
    { "Code": "AZ", "label": "+994", "value": "994" },
    { "Code": "BH", "label": "+973", "value": "973" },
    { "Code": "BD", "label": "+880", "value": "880" },
    { "Code": "BY", "label": "+375", "value": "375" },
    { "Code": "BE", "label": "+32", "value": "32" },
    { "Code": "BZ", "label": "+501", "value": "501" },
    { "Code": "BJ", "label": "+229", "value": "229" },
    { "Code": "BT", "label": "+975", "value": "975" },
    { "Code": "VE", "label": "+58", "value": "58" },
    { "Code": "BO", "label": "+591", "value": "591" },
    { "Code": "BQ", "label": "+599", "value": "599" },
    { "Code": "BA", "label": "+387", "value": "387" },
    { "Code": "BW", "label": "+267", "value": "267" },
    { "Code": "BR", "label": "+55", "value": "55" },
    { "Code": "IO", "label": "+246", "value": "246" },
    { "Code": "BN", "label": "+673", "value": "673" },
    { "Code": "BG", "label": "+359", "value": "359" },
    { "Code": "BF", "label": "+226", "value": "226" },
    { "Code": "BI", "label": "+257", "value": "257" },
    { "Code": "CV", "label": "+238", "value": "238" },
    { "Code": "KH", "label": "+855", "value": "855" },
    { "Code": "CM", "label": "+237", "value": "237" },
    { "Code": "CA", "label": "+1", "value": "1" },
    { "Code": "CF", "label": "+236", "value": "236" },
    { "Code": "TD", "label": "+235", "value": "235" },
    { "Code": "CL", "label": "+56", "value": "56" },
    { "Code": "CN", "label": "+86", "value": "86" },
    { "Code": "CX", "label": "+61", "value": "61" },
    { "Code": "CC", "label": "+61", "value": "61" },
    { "Code": "CO", "label": "+57", "value": "57" },
    { "Code": "KM", "label": "+269", "value": "269" },
    { "Code": "CG", "label": "+242", "value": "242" },
    { "Code": "CD", "label": "+243", "value": "243" },
    { "Code": "CK", "label": "+682", "value": "682" },
    { "Code": "CR", "label": "+506", "value": "506" },
    { "Code": "CI", "label": "+225", "value": "225" },
    { "Code": "HR", "label": "+385", "value": "385" },
    { "Code": "CU", "label": "+53", "value": "53" },
    { "Code": "CW", "label": "+599", "value": "599" },
    { "Code": "CY", "label": "+357", "value": "357" },
    { "Code": "CZ", "label": "+420", "value": "420" },
    { "Code": "TL", "label": "+670", "value": "670" },
    { "Code": "DK", "label": "+45", "value": "45" },
    { "Code": "DJ", "label": "+253", "value": "253" },
    { "Code": "EC", "label": "+593", "value": "593" },
    { "Code": "EG", "label": "+20", "value": "20" },
    { "Code": "SV", "label": "+503", "value": "503" },
    { "Code": "GQ", "label": "+240", "value": "240" },
    { "Code": "ER", "label": "+291", "value": "291" },
    { "Code": "EE", "label": "+372", "value": "372" },
    { "Code": "ET", "label": "+251", "value": "251" },
    { "Code": "FK", "label": "+500", "value": "500" },
    { "Code": "FO", "label": "+298", "value": "298" },
    { "Code": "FJ", "label": "+679", "value": "679" },
    { "Code": "FI", "label": "+358", "value": "358" },
    { "Code": "FR", "label": "+33", "value": "33" },
    { "Code": "GF", "label": "+594", "value": "594" },
    { "Code": "PF", "label": "+689", "value": "689" },
    { "Code": "GA", "label": "+241", "value": "241" },
    { "Code": "GM", "label": "+220", "value": "220" },
    { "Code": "GE", "label": "+995", "value": "995" },
    { "Code": "DE", "label": "+49", "value": "49" },
    { "Code": "GH", "label": "+233", "value": "233" },
    { "Code": "GI", "label": "+350", "value": "350" },
    { "Code": "GR", "label": "+30", "value": "30" },
    { "Code": "GL", "label": "+299", "value": "299" },
    { "Code": "GP", "label": "+590", "value": "590" },
    { "Code": "GT", "label": "+502", "value": "502" },
    { "Code": "GN", "label": "+224", "value": "224" },
    { "Code": "GW", "label": "+245", "value": "245" },
    { "Code": "GY", "label": "+592", "value": "592" },
    { "Code": "HT", "label": "+509", "value": "509" },
    { "Code": "HM", "label": "+ ", "value": " " },
    { "Code": "HN", "label": "+504", "value": "504" },
    { "Code": "HK", "label": "+852", "value": "852" },
    { "Code": "HU", "label": "+36", "value": "36" },
    { "Code": "IS", "label": "+354", "value": "354" },
    { "Code": "IN", "label": "+91", "value": "91" },
    { "Code": "ID", "label": "+62", "value": "62" },
    { "Code": "IR", "label": "+98", "value": "98" },
    { "Code": "IQ", "label": "+964", "value": "964" },
    { "Code": "IE", "label": "+353", "value": "353" },
    { "Code": "IL", "label": "+972", "value": "972" },
    { "Code": "IT", "label": "+39", "value": "39" },
    { "Code": "SJ", "label": "+47", "value": "47" },
    { "Code": "JP", "label": "+81", "value": "81" },
    { "Code": "JO", "label": "+962", "value": "962" },
    { "Code": "KZ", "label": "+7", "value": "7" },
    { "Code": "KE", "label": "+254", "value": "254" },
    { "Code": "KI", "label": "+686", "value": "686" },
    { "Code": "KR", "label": "+82", "value": "82" },
    { "Code": "KW", "label": "+965", "value": "965" },
    { "Code": "KG", "label": "+996", "value": "996" },
    { "Code": "LA", "label": "+856", "value": "856" },
    { "Code": "LV", "label": "+371", "value": "371" },
    { "Code": "LB", "label": "+961", "value": "961" },
    { "Code": "LS", "label": "+266", "value": "266" },
    { "Code": "LR", "label": "+231", "value": "231" },
    { "Code": "LY", "label": "+218", "value": "218" },
    { "Code": "LI", "label": "+423", "value": "423" },
    { "Code": "LT", "label": "+370", "value": "370" },
    { "Code": "LU", "label": "+352", "value": "352" },
    { "Code": "MO", "label": "+853", "value": "853" },
    { "Code": "MK", "label": "+389", "value": "389" },
    { "Code": "MG", "label": "+261", "value": "261" },
    { "Code": "MW", "label": "+265", "value": "265" },
    { "Code": "MY", "label": "+60", "value": "60" },
    { "Code": "MV", "label": "+960", "value": "960" },
    { "Code": "ML", "label": "+223", "value": "223" },
    { "Code": "MT", "label": "+356", "value": "356" },
    { "Code": "MH", "label": "+692", "value": "692" },
    { "Code": "MQ", "label": "+596", "value": "596" },
    { "Code": "MR", "label": "+222", "value": "222" },
    { "Code": "MU", "label": "+230", "value": "230" },
    { "Code": "YT", "label": "+262", "value": "262" },
    { "Code": "MX", "label": "+52", "value": "52" },
    { "Code": "FM", "label": "+691", "value": "691" },
    { "Code": "MD", "label": "+373", "value": "373" },
    { "Code": "MC", "label": "+377", "value": "377" },
    { "Code": "MN", "label": "+976", "value": "976" },
    { "Code": "ME", "label": "+382", "value": "382" },
    { "Code": "MA", "label": "+212", "value": "212" },
    { "Code": "MZ", "label": "+258", "value": "258" },
    { "Code": "MM", "label": "+95", "value": "95" },
    { "Code": "NA", "label": "+264", "value": "264" },
    { "Code": "NR", "label": "+674", "value": "674" },
    { "Code": "NP", "label": "+977", "value": "977" },
    { "Code": "NL", "label": "+31", "value": "31" },
    { "Code": "NC", "label": "+687", "value": "687" },
    { "Code": "NZ", "label": "+64", "value": "64" },
    { "Code": "NI", "label": "+505", "value": "505" },
    { "Code": "NE", "label": "+227", "value": "227" },
    { "Code": "NG", "label": "+234", "value": "234" },
    { "Code": "NU", "label": "+683", "value": "683" },
    { "Code": "NF", "label": "+672", "value": "672" },
    { "Code": "KP", "label": "+850", "value": "850" },
    { "Code": "NO", "label": "+47", "value": "47" },
    { "Code": "OM", "label": "+968", "value": "968" },
    { "Code": "PK", "label": "+92", "value": "92" },
    { "Code": "PW", "label": "+680", "value": "680" },
    { "Code": "PS", "label": "+970", "value": "970" },
    { "Code": "PA", "label": "+507", "value": "507" },
    { "Code": "PG", "label": "+675", "value": "675" },
    { "Code": "PY", "label": "+595", "value": "595" },
    { "Code": "PE", "label": "+51", "value": "51" },
    { "Code": "PH", "label": "+63", "value": "63" },
    { "Code": "PN", "label": "+870", "value": "870" },
    { "Code": "PL", "label": "+48", "value": "48" },
    { "Code": "PT", "label": "+351", "value": "351" },
    { "Code": "QA", "label": "+974", "value": "974" },
    { "Code": "RE", "label": "+262", "value": "262" },
    { "Code": "RO", "label": "+40", "value": "40" },
    { "Code": "RU", "label": "+7", "value": "7" },
    { "Code": "RW", "label": "+250", "value": "250" },
    { "Code": "BL", "label": "+590", "value": "590" },
    { "Code": "SH", "label": "+290", "value": "290" },
    { "Code": "MF", "label": "+590", "value": "590" },
    { "Code": "PM", "label": "+508", "value": "508" },
    { "Code": "WS", "label": "+685", "value": "685" },
    { "Code": "SM", "label": "+378", "value": "378" },
    { "Code": "ST", "label": "+239", "value": "239" },
    { "Code": "SA", "label": "+966", "value": "966" },
    { "Code": "SN", "label": "+221", "value": "221" },
    { "Code": "RS", "label": "+381", "value": "381" },
    { "Code": "SC", "label": "+248", "value": "248" },
    { "Code": "SL", "label": "+232", "value": "232" },
    { "Code": "SG", "label": "+65", "value": "65" },
    { "Code": "SX", "label": "+599", "value": "599" },
    { "Code": "SK", "label": "+421", "value": "421" },
    { "Code": "SI", "label": "+386", "value": "386" },
    { "Code": "SB", "label": "+677", "value": "677" },
    { "Code": "SO", "label": "+252", "value": "252" },
    { "Code": "ZA", "label": "+27", "value": "27" },
    { "Code": "SS", "label": "+211", "value": "211" },
    { "Code": "ES", "label": "+34", "value": "34" },
    { "Code": "LK", "label": "+94", "value": "94" },
    { "Code": "SD", "label": "+249", "value": "249" },
    { "Code": "SR", "label": "+597", "value": "597" },
    { "Code": "SJ", "label": "+47", "value": "47" },
    { "Code": "SZ", "label": "+268", "value": "268" },
    { "Code": "SE", "label": "+46", "value": "46" },
    { "Code": "CH", "label": "+41", "value": "41" },
    { "Code": "SY", "label": "+963", "value": "963" },
    { "Code": "TW", "label": "+886", "value": "886" },
    { "Code": "TJ", "label": "+992", "value": "992" },
    { "Code": "TZ", "label": "+255", "value": "255" },
    { "Code": "TH", "label": "+66", "value": "66" },
    { "Code": "TG", "label": "+228", "value": "228" },
    { "Code": "TK", "label": "+690", "value": "690" },
    { "Code": "TO", "label": "+676", "value": "676" },
    { "Code": "TN", "label": "+216", "value": "216" },
    { "Code": "TR", "label": "+90", "value": "90" },
    { "Code": "TM", "label": "+993", "value": "993" },
    { "Code": "TV", "label": "+688", "value": "688" },
    { "Code": "UM", "label": "+1", "value": "1" },
    { "Code": "UG", "label": "+256", "value": "256" },
    { "Code": "UA", "label": "+380", "value": "380" },
    { "Code": "AE", "label": "+971", "value": "971" },
    { "Code": "GB", "label": "+44", "value": "44" },
    { "Code": "US", "label": "+1", "value": "1" },
    { "Code": "UY", "label": "+598", "value": "598" },
    { "Code": "UZ", "label": "+998", "value": "998" },
    { "Code": "VU", "label": "+678", "value": "678" },
    { "Code": "VA", "label": "+379", "value": "379" },
    { "Code": "VE", "label": "+58", "value": "58" },
    { "Code": "VN", "label": "+84", "value": "84" },
    { "Code": "WF", "label": "+681", "value": "681" },
    { "Code": "EH", "label": "+212", "value": "212" },
    { "Code": "YE", "label": "+967", "value": "967" },
    { "Code": "ZM", "label": "+260", "value": "260" },
    { "Code": "ZW", "label": "+263", "value": "263" }
]
