import { GET_ALL_SLOTS, GET_BOOSTING_HISTORY } from "./ActionType";

const initialState = {
    slots: [],
    boostingHistory: [],
    boostingHistory_paginationData: {}
}

const RestaurantProfileBoostingReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_SLOTS:
            return {
                ...state,
                slots: payload,
            }

        case GET_BOOSTING_HISTORY:
            let update_data;
            if (payload?.slot_bookings?.length === 0) {
                update_data = [];
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                // Merge and remove duplicates based on `id`
                const newBookingHistory = payload.slot_bookings?.filter(
                    (newItem) => !state?.boostingHistory?.some((existingItem) => existingItem?.id === newItem?.id)
                );
                update_data = [...state?.boostingHistory, ...newBookingHistory];
            } else {
                update_data = payload?.slot_bookings;
            }
            return {
                ...state,
                boostingHistory: update_data,
                boostingHistory_paginationData: payload?.pagination_data?.meta
            }
        default:
            return {
                ...state,
            };
    }
}

export default RestaurantProfileBoostingReducer;

