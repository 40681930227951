import { announceAdminReward, getAdminTopPerformers, updateOrAddAdminReward } from "Adapters/APIs/Admin/rewards";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useAdminAnnounceReward = ({ announceReward, close, successCallBack }) => {
    const dispatch = useDispatch()
    
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [secondLoader, setSecondLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState()
    const [topPerformers, setTopPerformers] = useState([])

    // get top performers data
    const getTopPerformersData = () => {
        const success = (data) => {
            let updatedData = []
            if (data?.users?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...topPerformers || [], ...data?.users];
                } else {
                    updatedData = data?.users;
                }
            }
            setTopPerformers(updatedData)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        let date = moment(`${announceReward?.year}-${announceReward?.month}`, 'YYYY-MM')
        // Get the start of the month
        const startOfMonth = date.startOf('month').format('YYYY-MM-DD');
        // Get the end of the month
        const endOfMonth = date.endOf('month').format('YYYY-MM-DD');
        const payload = {
            page: currentPage,
            start_date: startOfMonth,
            end_date: endOfMonth
        }
        dispatch(getAdminTopPerformers(access_token, payload, success, fail))
    }


    // announe reward
    const announeRewardApi = () => {
        let date = moment(`${announceReward?.year}-${announceReward?.month}`, 'YYYY-MM')
        // Get the start of the month
        const startOfMonth = date.startOf('month').format('YYYY-MM-DD');
        // Get the end of the month
        const endOfMonth = date.endOf('month').format('YYYY-MM-DD');
        let payload = {
            start_date: startOfMonth,
            end_date: endOfMonth,
        }
        const success = () => {
            setLoading(false)
            successCallBack && successCallBack()
            close && close(false)
        }
        const fail = () => {
            setLoading(false)
        }
        setLoading(true)
        dispatch(announceAdminReward(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (announceReward) {
            getTopPerformersData()
        }
    }, [announceReward])

    return {
        loader, secondLoader, paginationData, topPerformers, currentPage, setCurrentPage, announeRewardApi, loading
    }
}

export default useAdminAnnounceReward