import { getResaurantFinancialsDetails } from "Adapters/APIs/Restaurant/Payment";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect } from "react";
import { useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useFinancials = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const [loading, setLoading] = useState(true)
    const [details, setDetails] = useState()
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();

    const getFinancialDetials = () => {
        const success = (data) => {
            setDetails(data)
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        const payload = {
            end_date: endDate,
            start_date: startDate,
        }
        setLoading(true)
        dispatch(getResaurantFinancialsDetails(access_token, payload, success, fail))
    }

    useEffect(() => {
        getFinancialDetials()
    }, [endDate, startDate])

    return {
        endDate, setEndDate, startDate, setStartDate, loading, details, navigate
    }

}
export default useFinancials