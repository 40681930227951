import React, { useEffect, useState } from 'react';
import { currency } from 'Adapters/variables';
import Svgs from 'Assets/svgs';
import ToggleWithText from 'Components/Elements/ToggleWithText';

const colors = ['#1D8D70', '#1D9AB6', '#B76F2D'];

const getColorByIndex = (index) => {
    return colors[index % colors.length];
};

const AdminPromotionCard = ({ index, name, duration, hideToggle, status, type, description, price, onCardClick, onEditStatus }) => {
    const [active, setActive] = useState(status);
    const color = getColorByIndex(index);

    useEffect(() => {
        setActive(status);
    }, [status]);

    // handle update status
    const handleUpdateStatus = () => {
        setActive(active === 1 ? false : true);
        onEditStatus && onEditStatus();
    };

    return (
        <div
            className="relative shadow-md rounded-lg p-6 pt-0 h-full min-h-40 cursor-pointer bg-darkGrey border-t-[10px] border-secondary"
            // style={{ borderTop: `10px solid ${color}` }}
            onClick={onCardClick && onCardClick}
        >
            <div className="absolute h-28 w-20 promotion-clipy flex flex-col items-center justify-start pt-2 space-y-0.5 bg-secondary"
            // -mt-1
            // style={{ backgroundColor: color }}
            >
                <Svgs.Percentage />
                <h1 className="text-white">{currency}</h1>
                <h1 className="text-white">{price}</h1>
            </div>
            <div className="ml-24 mt-7 flex flex-col space-y-1">
                <div className="flex gap-4 justify-between items-center">
                    <h1 className="font-semibold text-white">{name}</h1>
                    {!hideToggle &&
                        <ToggleWithText
                            checked={active}
                            onChange={handleUpdateStatus}
                        />
                    }
                </div>
                <div className="flex gap-2 items-center">
                    <Svgs.CalendarGreen fill={'var(--secondary-color)'} />
                    <p className="text-blueGrey text-sm">{duration}</p>
                </div>
                <div className="font-semibold text-sm text-secondary">Category: <span className="text-blueGrey font-normal capitalize">{type}</span></div>
                <p className="text-blueGrey text-sm">{description}</p>
            </div>
        </div>
    );
};

export default AdminPromotionCard;
