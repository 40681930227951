import Svgs from "Assets/svgs"

const ConiergesSidebarRoutes = (active) => {
    const conciergeDashBoard = [
        {
            url: '/concierge/dashboard',
            heading: 'Dashboard',
            active: active == 'Dashboard',
            icon: <Svgs.DashbaordIcon />
        },
        {
            url: '/concierge/booking',
            heading: 'Bookings',
            active: active == 'booking',
            icon: <Svgs.BookingIcon />
        },
        {
            url: '/concierge/chat',
            heading: 'Chat',
            active: active == 'chat',
            icon: <Svgs.ChatIcon />
        },
        {
            url: '/concierge/my-wallet',
            heading: 'My Wallet',
            active: active == 'wallet',
            icon: <Svgs.CommissionIcon />
        },
        {
            url: '/concierge/reward',
            heading: 'Rewards',
            active: active == 'reward',
            icon: <Svgs.RewardIcon />
        },
        {
            url: '/concierge/support-request',
            heading: 'Support Requests',
            active: active == 'support',
            icon: <Svgs.TransactionIcon />
        },
        {
            url: '/concierge/booking-analytics',
            heading: 'Booking Analytics',
            active: active == 'bookingAnalytics',
            icon: <Svgs.TransactionIcon />
        },
        {
            url: '/concierge/wishlist',
            heading: 'Wishlist',
            active: active == 'wishlist',
            icon: <Svgs.WishlistIcon fill={active == "wishlist" ? "var(--primary-color)" : ''} />
        },
        {
            url: '/concierge/invite-friend',
            heading: 'Invite Friend',
            active: active == 'InviteFriend',
            icon: <Svgs.InviteFriend fill={active == "InviteFriend" ? "var(--primary-color)" : 'var(--blueGrey-color)'} />
        },
        {
            url: '/concierge/profile',
            heading: 'Profile',
            active: active == 'profile',
            icon: <Svgs.ProfileIcon />
        },
    ]

    return conciergeDashBoard
}

export default ConiergesSidebarRoutes