import React from 'react'

const AdminAmountCardShimmer = () => {
    return (
        <div className={`bg-darkGrey rounded-lg md:rounded-[10px] min-h-20 flex flex-col gap-3 items-center justify-between p-5`}>
            <div className="w-24 h-6 mb-1 rounded-lg bg-blueGrey"></div>
            <div className="w-20 h-4 rounded-lg bg-blueGrey"></div>
        </div>
    )
}

export default AdminAmountCardShimmer