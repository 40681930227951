import PreloaderSm from 'Components/Elements/PreloaderSm'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import useSingleRestaurantFeedback from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import NotFound from 'Utility/NotFound'
import AdminSingleRestaurantFeedbackCardShimmer from 'Utility/Shimmers/AdminSingleRestaurantFeedbackCardShimmer'
import FeedbackCard from 'Pages/Admin/Restaurants/SingleRestaurant/Feedback/FeedbackCard'
import Back from 'Components/Elements/Back'

const RestaurantFeedback = ({ className }) => {
    const { navigate, feedbackData, paginationData, loader, secondLoader, currentPage, setCurrentPage } = useSingleRestaurantFeedback()
    return (
        <DashboardLayout active={'review'}>
            <main className={`p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen `}>
                <Back
                    loader={loader}
                    title={`Reviews (${paginationData?.total})`}
                />
                <div className="!m-0">
                    {loader ?
                        <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4 p-2">
                            {[0, 1, 2, 3, 4].map((index) => (
                                <AdminSingleRestaurantFeedbackCardShimmer showImages={true} key={index} />
                            ))}
                        </div>
                        :
                        feedbackData?.length > 0 ?
                            <InfiniteScroll
                                dataLength={feedbackData?.length ? feedbackData?.length : 10} //This is important field to render the next data
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }}
                                hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                loader={
                                    secondLoader ?
                                        <div className="flex-1 justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div>
                                        : ""
                                }
                                height={'40rem'}
                            >
                                <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4 p-2">
                                    {feedbackData?.map((itm, index) => {
                                        return <FeedbackCard
                                            data={itm}
                                            index={index}
                                            created_at={moment(itm?.created_at).format('DD-MM-YYYY')}
                                            name={itm?.booking?.guest_name}
                                            rating={itm?.rating}
                                            description={itm?.feedback}
                                            food={itm?.food}
                                            ambiance={itm?.ambiance}
                                            quality={itm?.quality}
                                            service={itm?.service}
                                            valueForMoney={itm?.value_for_money}
                                        />
                                    })}
                                </div>
                            </InfiniteScroll>
                            : <NotFound />
                    }
                </div>
            </main>
        </DashboardLayout>
    )
}

export default RestaurantFeedback