import React from 'react'
import TopBar from 'Components/TopBar'
import EditConciergeProfile from 'Pages/Concierges/Profile/EditProfile'

const EditRejectedConcierge = () => {
    return (
        <>
            <TopBar showFullLength={true} hideNotificationIcon={true} showLogo={true} />
            <main className="p-4 h-auto pt-10 min-h-screen ">
                <EditConciergeProfile hideUpdateEmail={true} hideBackBtn={true} showRejectionReasons={true} />
            </main>
        </>
    )
}

export default EditRejectedConcierge