import { GET_ALL_RESTAURANTS, GET_BUSINESS_HOURS } from "./ActionType";

export const getAllRestuarants = (data) => {
    return {
        type: GET_ALL_RESTAURANTS,
        payload: data
    }
};

export const getBusinessHours = (data) => {
    return {
        type: GET_BUSINESS_HOURS,
        payload: data
    }
};