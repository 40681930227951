import { getRestaurantChat } from 'Adapters/APIs/Chat'
import ChatCard from 'Components/ChatCard'
import DashboardLayout from 'Components/DashboardLayout'
import LoginInput from 'Components/Elements/LoginInput'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import NotFound from 'Utility/NotFound'
import ChatCardShimmer from 'Utility/Shimmers/ChatCardShimmer'
import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
let timeOutId = undefined

const RestaurantChat = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const chat = state?.chat?.chat
    const pagination = state?.chat?.chat_pagination
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const [loading, setLoading] = useState(true)
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const access_token = getDataFromLocalStorage('access_token')


    const getChat = () => {
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        if (currentPage == 1)
            setLoading(true)
        dispatch(getRestaurantChat(access_token, { page: currentPage, search_text: searchText }, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            getChat()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getChat()
            }, 500);
        }
    }, [searchText, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return (
        <>
            <DashboardLayout active={'chat'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="pt-4 space-y-5 flex justify-between items-center">
                        <h2 className="font-bold text-2xl">Chat</h2>
                        <LoginInput
                            name={'searchText'}
                            value={searchText}
                            onChange={(e) => { setSearchText(e.target.value) }}
                            placeholder={'Search concierge'}
                            inputPadding={"px-4 py-[9px]"}
                            inputClass={"flex-1"}
                            parentClass={"xs:w-fit w-full"}
                        />
                    </div>
                    <div className="py-6">
                        <div className="lg:col-span-8 space-y-5">
                            <div className="space-y-3">
                                <div className="relative sm:rounded-lg border border-[#ECECEC] p-5">
                                    {loading
                                        ? <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                                            {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                                return <ChatCardShimmer />
                                            })}
                                        </div>
                                        :
                                        chat?.length > 0 ?
                                            <InfiniteScroll
                                                dataLength={chat?.length ? chat?.length : 10} //This is important field to render the next data
                                                next={() => {
                                                    setCurrentPage(currentPage + 1)
                                                    // getAdminRestaurants()
                                                }}
                                                hasMore={pagination && pagination?.current_page !== pagination?.total_pages}
                                                loader={(pagination?.current_page !== pagination?.total_pages)
                                                    && <div className="flex justify-center items-center my-4">
                                                        <PreloaderSm />
                                                    </div>
                                                }
                                                height={'40rem'}
                                            >
                                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                                                    {chat?.map(itm => {
                                                        return <ChatCard
                                                            key={itm?.id}
                                                            profile={itm?.profile_picture}
                                                            user_name={itm?.business_name}
                                                            restaurant_name={itm?.first_name + ' ' + itm?.last_name}
                                                            phone_code={(itm?.wa_code == 'null' || !itm?.wa_code) ? itm?.mobile_code : itm?.wa_code}
                                                            phoneNumber={itm?.phone_number && itm?.phone_number != 'null' ?
                                                                `+${itm?.phone_code} ${itm?.phone_number}` :
                                                                itm?.mobile_number && itm?.mobile_number != 'null' ?
                                                                    `+${itm?.mobile_code} ${itm?.mobile_number}` : itm?.wa_number && itm?.wa_number != 'null' ?
                                                                        `+${itm?.wa_code} ${itm?.wa_number}` : "----"}
                                                        />
                                                    })}
                                                </div>
                                            </InfiniteScroll>
                                            : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>

        </>
    )
}
export default RestaurantChat