import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import moment from 'moment'
import Image from 'Components/Elements/Image'
import LoginInput from 'Components/Elements/LoginInput'
import useAdminSingleConcierge from './helper'
import SingleAdminConciergeDetailShimmer from 'Utility/Shimmers/SingleAdminConciergeDetailShimmer'
import Popup from 'Components/Elements/Popup'
import Textarea from 'Components/Elements/TextArea'
import Back from 'Components/Elements/Back'

const AdminUnapprovedConcierge = () => {
    const { concierge, navigate, loader, rejectPopup, setRejectPopup, rejectLoader, rejectReason, setRejectReason, handleRejectSubmission,
        commissionPercentage, setCommissionPercentage, updateConciergeCommissionData, updateCommissionLoader, errors, setErrors
    } = useAdminSingleConcierge()

    const { id, first_name, last_name, business_name, email, mobile_code, mobile_number, dob, created_at, profile_picture, payment_method,
        rejection_thread, payment_details,
    } = concierge
    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    {loader ?
                        <SingleAdminConciergeDetailShimmer />
                        :
                        <div className="pt-4 space-y-5">
                            <div className='flex justify-between items-center'>
                                <div className='flex gap-2 items-center'>
                                    <Back />
                                    {/* <Svgs.BackIcon className={"cursor-pointer"} onClick={() => { navigate(-1) }} /> */}
                                    {profile_picture ?
                                        <div className="flex items-center justify-center h-[4rem] w-[4rem] overflow-hidden rounded-full bg-gray-200 ">
                                            <Image src={profile_picture} className="w-full h-full object-cover" />
                                        </div>
                                        :
                                        <Svgs.UserIcon2 width={'56'} height={'56'} />}

                                    <h2 className="font-semibold md:text-2xl sm:text-lg text-md text-white">{`${first_name ? first_name : ""} ${last_name ? last_name : ""}`}</h2>
                                </div>
                                <div>
                                    <Button
                                        customPadding={'!p-2'}
                                        title={'Edit Profile'}
                                        onClick={() => { navigate(`/admin/edit-concierge/${id}`) }}
                                    />
                                </div>
                            </div>
                            <div className='grid sm:grid-cols-2 grid-cols-1 justify-between gap-2'>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Business Name</span>
                                    <span className="font-extralight text-blueGrey">{business_name}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Email Address</span>
                                    <span className="font-extralight text-blueGrey">{email}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Mobile Number</span>
                                    <span className="font-extralight text-blueGrey">{`+${mobile_code}-${mobile_number}`}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Date of Birth</span>
                                    <span className="font-extralight text-blueGrey">{moment(dob).format("DD-MM-YYYY")}</span>
                                </div>
                                <div className='flex flex-col space-y-2'>
                                    <span className='text-white'>Joining Date</span>
                                    <span className="font-extralight text-blueGrey">{moment(created_at).format("DD-MM-YYYY")}</span>
                                </div>

                            </div>
                            {rejection_thread?.length > 0 &&
                                <div className="flex flex-col gap-3 ">
                                    <h1 className='text-base text-white font-semibold'>Rejection Reason</h1>
                                    {rejection_thread?.map((itm, ind) => {
                                        return (
                                            <div className='flex justify-between p-2 gap-4 bg-darkGrey rounded-lg'>
                                                <h1 className='text-sm text-secondary'>{itm?.message}</h1>
                                                <h1 className='text-sm text-blueGrey'>{moment(itm?.created_at).format('DD MMM YYYY')}</h1>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <hr className='border-lightGrey' />
                            <div className='flex flex-col space-y-2'>
                                <span className='text-white'>Payment Preference</span>
                                <span className="font-extralight text-blueGrey capitalize">{payment_method}</span>
                            </div>
                            {payment_details &&
                                <div className='grid sm:grid-cols-2 grid-cols-1 justify-between gap-2'>
                                    <div className='flex flex-col space-y-2'>
                                        <span className='text-white'>Bank Name</span>
                                        <span className="font-extralight text-blueGrey">{payment_details?.bank_name}</span>
                                    </div>
                                    <div className='flex flex-col space-y-2'>
                                        <span className='text-white'>Account Title</span>
                                        <span className="font-extralight text-blueGrey">{payment_details?.card_holder_name}</span>
                                    </div>
                                    <div className='flex flex-col space-y-2'>
                                        <span className='text-white'>Account IBAN</span>
                                        <span className="font-extralight text-blueGrey">{payment_details?.iban_number}</span>
                                    </div>
                                </div>}
                            <div className={`bg-darkGrey rounded-lg lg:w-[50%] flex flex-col space-y-2 items-start justify-between p-5`}>
                                <LoginInput
                                    title="Commission Percentage"
                                    leftIcon={'%'}
                                    parentClass={'w-full'}
                                    value={commissionPercentage}
                                    name={"commissionPercentage"}
                                    onChange={(e) => {
                                        setCommissionPercentage(e.target.value)
                                        setErrors((prev) => ({
                                            ...prev,
                                            commissionPercentage: ''
                                        }))
                                    }}
                                    placeholder={'Enter commission percentage'}
                                    onlyNumericAllowed={true}
                                    error={errors?.commissionPercentage}
                                    inputClass={'bg-lightGrey'}
                                    iconStyle={'border-blueGrey min-w-16'}
                                />
                                <div className='grid xs:grid-cols-2 grid-cols-1 gap-4 text items-center w-full pt-3'>
                                    <Button
                                        text={'Reject Submission'}
                                        className={"w-full !font-normal"}
                                        customPadding={"px-5 py-2"}
                                        onClick={() => {
                                            setRejectPopup(true)
                                        }}
                                    />
                                    <Button
                                        text={'Accept Submission'}
                                        className={"w-full !font-normal"}
                                        customPadding={"px-5 py-2"}
                                        loading={updateCommissionLoader}
                                        disabled={updateCommissionLoader}
                                        onClick={updateConciergeCommissionData}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </main>

                <Popup
                    open={rejectPopup}
                    close={setRejectPopup}
                    customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                    heading={'Provide reason for rejection'}
                >
                    <div className='flex flex-col space-y-4'>
                        <Textarea
                            title={'Reason'}
                            value={rejectReason}
                            error={errors?.rejectReason}
                            name={'rejectReason'}
                            placeholder={"Provide a brief reason"}
                            required={true}
                            onChange={(e) => {
                                setRejectReason(e.target.value);
                                setErrors((prev) => ({
                                    ...prev,
                                    rejectReason: ''
                                }))
                            }}
                        />
                        <Button text={'Submit'} className={'w-full'} onClick={handleRejectSubmission} loading={rejectLoader} disabled={rejectLoader} />
                    </div>
                </Popup>
            </DashboardLayout>
        </>
    )
}

export default AdminUnapprovedConcierge