import React from 'react'
import useAdminDashboard from './helper'
import DashboardLayout from 'Components/DashboardLayout'
import RestaurentCard from 'Pages/Admin/MainDashbaord/Elements/RestaurantCard'
import Svgs from 'Assets/svgs'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import AdminDashBoardCards from './Elements/AdminDashBoardCards'
import AdminRestaurantCardShimmer from 'Utility/Shimmers/AdminRestaurantCardShimmer'

const MainDashBoard = () => {
    const {
        DasboardStats, cardDataLoader, startDate, setStartDate, endDate, setEndDate, allRestaurants, navigate, restaurantLoader, allConcierges,
        conciergeLoader
    } = useAdminDashboard()

    return (
        <>
            <DashboardLayout active={'Dashboard'}>
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                    <div className="pt-4 space-y-5">
                        <div className='flex justify-between items-center gap-2'>
                            <h2 className="font-semibold md:text-2xl sm:text-lg text-md text-white">Dashboard Admin</h2>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                maxDate={new Date()}
                                className={'!w-full'}
                            />
                        </div>
                        {cardDataLoader ?
                            <AdminDashboardCardShimmer /> :
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-4">
                                <AdminDashBoardCards title={'Concierges Statistics'} btnColor={'!bg-[#29BCB8]'} svgIcon={<Svgs.ConciergeStats />} onClick={() => { navigate('/admin/concierge-statistics') }} />
                                <AdminDashBoardCards title={'Restaurant Statistics'} btnColor={'!bg-[#7A92B9]'} svgIcon={<Svgs.RestaurantStats />} onClick={() => { navigate('/admin/restaurant-statistics') }} />
                                <AdminDashBoardCards title={'Total Concierges'} amount={DasboardStats?.total_conceirges} showCurrency={false} btnColor={'!bg-[#D4A059]'} svgIcon={<Svgs.GroupUserIcon />} onClick={() => { navigate('/admin/concierges') }} />
                                <AdminDashBoardCards title={'Unapproved Concierges'} amount={DasboardStats?.un_approved_conceirges} showCurrency={false} btnColor={'!bg-[#7BC1D8]'} svgIcon={<Svgs.UnapprovedConcierges />} onClick={() => { navigate('/admin/unapproved-concierges') }} />
                                <AdminDashBoardCards title={'Total Restaurant'} amount={DasboardStats?.total_restaurants} showCurrency={false} btnColor={'!bg-[#1D8D70]'} svgIcon={<Svgs.Building />} onClick={() => { navigate('/admin/restaurants') }} />
                                <AdminDashBoardCards title={'Unapproved Restaurant'} amount={DasboardStats?.un_approved_restaurants} showCurrency={false} btnColor={'!bg-[#D2B95D]'} svgIcon={<Svgs.UnapprovedRestaurant />} onClick={() => { navigate('/admin/unapproved-restaurants') }} />
                                <AdminDashBoardCards title={'Pending Recievables'} amount={DasboardStats?.pending_receivable} btnColor={'!bg-[#D4A059]'} svgIcon={<Svgs.PendingPayment />} onClick={() => { navigate('/admin/pending-recievables') }} />
                                <AdminDashBoardCards title={'Pending Payables'} amount={DasboardStats?.pending_payable} btnColor={'!bg-[#D4A059]'} svgIcon={<Svgs.PendingPayment />} onClick={() => { navigate('/admin/pending-payables') }} />
                            </div>
                        }
                    </div>
                    <div className="py-6 grid grid-cols-1 lg:grid-cols-12 gap-5">
                        <div className="lg:col-span-6 space-y-5">
                            <div className="flex items-end justify-between">
                                <h2 className="font-semibold text-2xl text-white">Restaurants</h2>
                                <button className="rounded-lg md:rounded-[10px] border border-secondary text-secondary text-xs font-semibold py-2 px-6" onClick={() => { navigate('/admin/restaurants') }}>View All</button>
                            </div>
                            <div className="space-y-3 grid grid-cols-1 gap-4">
                                {restaurantLoader ?
                                    (
                                        [0, 1, 2, 3, 4].map((index) => (
                                            <AdminRestaurantCardShimmer showImages={true} key={index} />
                                        ))
                                    )
                                    : allRestaurants?.map((itm, index) => {
                                        return <RestaurentCard
                                            showImages={true}
                                            data={itm}
                                            images={itm?.images}
                                            index={index}
                                            name={itm?.name}
                                            phoneNumber={`${itm?.mobile_code}-${itm?.mobile_number}`}
                                            address={itm?.address}
                                            totalBookings={itm?.reservations}
                                            totalEarned={itm?.total_earned}
                                            totalDues={itm?.due_payments}
                                            deleted={itm?.user?.is_deleted == 1 ? true : false}
                                            active={itm?.is_active == 1 ? true : false}
                                            viewEditProfileClick={() => { navigate(`/admin/restaurant/${itm?.id}`) }}
                                            viewBookingClick={() => { navigate(`/admin/restaurant-bookings/${itm?.id}`) }}
                                        />
                                    })}
                            </div>
                        </div>
                        <div className="lg:col-span-6 space-y-5">
                            <div className="flex items-end justify-between">
                                <h2 className="font-semibold text-2xl text-white">Concierges</h2>
                                <button className="rounded-lg md:rounded-[10px] border border-secondary text-secondary text-xs font-semibold py-2 px-6" onClick={() => { navigate('/admin/concierges') }}>View All</button>
                            </div>
                            <div className="space-y-3 grid grid-cols-1 gap-4">
                                {conciergeLoader ?
                                    (
                                        [0, 1, 2, 3, 4].map((index) => (
                                            <AdminRestaurantCardShimmer key={index} />
                                        ))
                                    )
                                    : allConcierges?.map((itm, index) => {
                                        return <RestaurentCard
                                            data={itm}
                                            index={index}
                                            conciergeImage={itm?.profile_picture}
                                            name={`${itm?.first_name} ${itm?.last_name}`}
                                            phoneNumber={`${itm?.mobile_code}-${itm?.mobile_number}`}
                                            address={itm?.address}
                                            totalBookings={itm?.reservations}
                                            totalEarned={itm?.total_earned}
                                            totalDues={itm?.due_payments}
                                            deleted={itm?.is_deleted == 1 ? true : false}
                                            active={itm?.is_active == 1 ? true : false}
                                            viewEditProfileClick={() => { navigate(`/admin/concierge/${itm?.id}`) }}
                                            viewBookingClick={() => { navigate(`/admin/concierge-bookings/${itm?.id}`) }}
                                        />
                                    })}
                            </div>
                        </div>
                    </div>
                </main>
            </DashboardLayout>
        </>
    )
}

export default MainDashBoard