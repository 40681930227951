import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Popup from 'Components/Elements/Popup'
import SignaturePad from 'Components/Elements/SignaturePad'
import { error_message } from 'Constants/Functions/Variables'
import React from 'react'

const TermAndSignaturePopup = ({ open, close, termStep, setTermStep, signatureValue, setSignatureValue, handleSubmitSigntureData,
    signatureLoading, errors }) => {
    return (
        <Popup
            open={open}
            close={close}
            size={'lg'}
            header={false}
            customHeight={termStep == 1 ? "min-h-[80vh]" : ""}
            popupHeight={" "}
        >
            {termStep == 1 &&
                <div className='min-h-[80vh] !flex !flex-col'>
                    <iframe
                        src={'https://easyrsv.com/terms-and-conditions'}
                        title="Popup Content"
                        className="w-full h-[80vh] border-none overflow-y-auto bg-white"
                    />
                    <div className="flex items-center justify-end gap-4 mt-4">
                        <Button text={'Accept'} className={'w-fit'} onClick={() => {
                            setTermStep(termStep + 1)
                        }} />
                    </div>
                </div>
            }
            {termStep == 2 &&
                <div className="flex flex-col space-y-2">
                    <h3 className='font-semibold text-white'>Add your digital signature</h3>
                    <div className='relative'>
                        <SignaturePad value={signatureValue} setValue={setSignatureValue} />
                        {errors?.signatureValue &&
                            <p className="text-error flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                                <Svgs.I fill="#eb3b3b" /> {error_message}
                            </p>
                        }
                    </div>
                    <div className={`flex items-center justify-end gap-4 ${errors?.signatureValue ? "pt-8" : "mt-4"}`}>
                        <Button text={'Submit'} className={'w-fit'}
                            loading={signatureLoading}
                            disabled={signatureLoading || !signatureValue}
                            onClick={() => {
                                handleSubmitSigntureData()
                            }} />
                    </div>
                </div>
            }
        </Popup>
    )
}

export default TermAndSignaturePopup