import { Navigate, Route, Routes } from "react-router-dom";
import React from 'react';
import useRouteHelper from "./helper";
import EditRejectedRestaurant from "Pages/Restaurants/RejectedRestaurantProfile";
import EditRejectedConcierge from "Pages/Restaurants/RejectedConciergeProfile";

const MainRoutes = () => {
    const { conciergeRoutes, restaurantRoutes, adminRoutes, authRoutes, access_token, defaultRoute } = useRouteHelper();

    return (
        <Routes>
            {/* Public routes */}
            {!access_token && authRoutes?.map(({ path, element }, index) => (
                <Route path={path} element={element} key={index} />
            ))}
            {/* Private routes */}
            {access_token &&
                <>
                    {defaultRoute == '/concierge/dashboard' && conciergeRoutes?.map(({ path, element }, index) => (
                        <Route path={path} element={element} key={index} />
                    ))}
                    {defaultRoute == "/restaurant/dashboard" && restaurantRoutes?.map(({ path, element }, index) => (
                        <Route path={path} element={element} key={index} />
                    ))}
                    {defaultRoute == "/concierge/edit-profile" && <Route path="/concierge/edit-profile" element={<EditRejectedConcierge />} />}
                    {defaultRoute == "/restaurant/edit-profile" && <Route path="/restaurant/edit-profile" element={<EditRejectedRestaurant />} />}
                    {defaultRoute == '/admin/dashboard' && adminRoutes?.map(({ path, element }, index) => (
                        <Route path={path} element={element} key={index} />
                    ))}
                </>
            }

            {!access_token ?
                <Route path="*" element={<Navigate to={'/login'} />} />
                :
                <Route path="*" element={<Navigate to={defaultRoute} />} />
            }
        </Routes>
    );
};

export default MainRoutes;
