import React from 'react'

const MobileVerifyInput = ({ name, value, onChange, onBackSpace, inp_ref, onEnter }) => {
    const HandleKeyPress = (e) => {
        if (e.key.toLowerCase() == "enter") {
            //   onEnterSubmit();
        }
        const regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/);

        return !e.key.match(regex) && e.preventDefault();
    };
    return (
        <div className={`${value ? "border-[#4E4E4E]" : ""} !text-[#B7B7B7] border-2 border-[#4E4E4E] rounded-lg h-[3rem] w-[3rem] !bg-[#4E4E4E] overflow-hidden`}>
            <input
                ref={inp_ref}
                type="number"
                name={name}
                value={value}
                onChange={onChange}
                placeholder='-'
                className='h-full w-full outline-none p-3 text-center numberAddInp text-md bg-[#4E4E4E] !text-[#B7B7B7]'
                onKeyDown={(e) => {
                    HandleKeyPress(e)
                    if (e.key.toLowerCase() == 'backspace') {
                        onBackSpace()
                    }
                    if (e.key.toLowerCase() == 'enter') {
                        onEnter()
                    }
                }}
                autoComplete="off"
            />
        </div>
    )
}

MobileVerifyInput.defaultProps = {
    onBackSpace: () => { }
}

export default MobileVerifyInput