import { BASE_URL, add_promotion_url, boost_promotion_package_url, buy_promotion_package_url, get_active_packages, update_promotion_url } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertObjectToForm } from "Constants/Functions/convertDataToFormData";
import axios from "axios";
import { toast } from "react-toastify";

export const addRestaurantPromotion = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertObjectToForm(payload)
        const response = await axios.post(`${BASE_URL}${add_promotion_url}`, formData, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const updateRestaurantPromotion = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertObjectToForm(payload)
        const response = await axios.post(`${BASE_URL}${update_promotion_url}/${payload?.id}`, formData, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message)
            success && success(data)
            setLoading && setLoading(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}

export const getActivePackages = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_active_packages}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const buyPromotionPackage = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const formData = convertObjectToForm(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${buy_promotion_package_url}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, {toastId: 'toast'})
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const boostPromotionPackage = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const formData = convertObjectToForm(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${boost_promotion_package_url}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, {toastId: 'toast'})
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

