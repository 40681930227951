import React from 'react'

const SupportRequestCardShimmer = ({ isDetail }) => {
    return (
        <div className='flex flex-col gap-4'>
            <div className={`flex flex-col bg-darkGrey rounded-xl p-3 sm:p-5 gap-3 shadow-xl animate-pulse`}>
                <div className="flex justify-between items-center">
                    <div className="font-semibold mb-2 bg-gray-300 h-6 w-24 rounded"></div>
                    <div className="flex items-center gap-1 px-4 py-1.5 rounded-lg  bg-gray-300 h-8 w-20"></div>
                </div>
                <p className="text-gray-700 font-semibold text-lg  bg-gray-300 h-8 w-full rounded"></p>
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                    <div className="flex gap-1 items-center">
                        <div className=" bg-gray-300 h-6 w-8 rounded"></div>
                        {isDetail && <span className=" bg-gray-300 h-6 w-24 rounded"></span>}
                        <span className=" bg-gray-300 h-6 w-32 rounded"></span>
                    </div>
                    <div className="flex gap-1 items-center">
                        <div className=" bg-gray-300 h-6 w-8 rounded"></div>
                        {isDetail && <span className=" bg-gray-300 h-6 w-24 rounded"></span>}
                        <span className=" bg-gray-300 h-6 w-32 rounded"></span>
                    </div>
                </div>
                {isDetail ?
                    <div className='flex flex-col gap-4 mt-2'>
                        <div className='flex flex-col gap-2'>
                            <div className='font-semibold  bg-gray-300 h-6 w-32 rounded'></div>
                            <div className=' bg-gray-300 h-40 w-full rounded-lg'></div>
                            <div className='flex gap-2 bg-[#E7F8F4] items-center mt-2 p-3 rounded-xl shadow justify-between'>
                                <div className=" bg-gray-300 h-6 w-32 rounded"></div>
                                <div className=" bg-gray-300 h-6 w-24 rounded"></div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div className='font-semibold  bg-gray-300 h-6 w-32 rounded'></div>
                            <p className=" bg-gray-300 h-6 w-full rounded"></p>
                        </div>
                    </div>
                    : <>
                        <hr />
                        <div className="flex gap-2 items-center">
                            <div className=" bg-gray-300 h-6 w-32 rounded"></div>
                            <div className=" bg-gray-300 h-6 w-24 rounded"></div>
                        </div>
                    </>
                }
            </div>
            {isDetail && <div className="flex flex-col gap-4 animate-pulse">
                <div className='font-semibold  bg-gray-300 h-6 w-32 rounded'></div>
                <div className="flex flex-col gap-3">
                    <div className='flex flex-col text-sm gap-1 p-3 max-w-40 rounded-b shadow justify-between bg-[#E7F8F4] rounded-tr self-start '>
                        <p className=" bg-gray-300 h-6 w-full rounded"></p>
                        <p className=' bg-gray-300 h-4 w-24 rounded'></p>
                    </div>
                    <div className='flex flex-col text-sm gap-1 p-3 max-w-40 rounded-b shadow justify-between text-white bg-[#1D8D70] rounded-tl self-end '>
                        <p className=" bg-gray-300 h-6 w-full rounded"></p>
                        <p className=' bg-gray-300 h-4 w-24 rounded'></p>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <div className='font-semibold  bg-gray-300 h-6 w-32 rounded'></div>
                    <div className=" bg-gray-300 h-24 w-full rounded"></div>
                </div>
                <div className=' bg-gray-300 h-10 w-32 rounded p-3'></div>
            </div>
            }
        </div>

    )
}

export default SupportRequestCardShimmer