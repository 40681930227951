import { BASE_URL, get_booking_stats_url, get_due_payments_url, get_restaurant_fincancials_url, get_restaurant_payments_url, get_subscription_payments_url, get_subscription_url, pay_subscription_url, pay_super_admin_url } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertObjectToForm } from "Constants/Functions/convertDataToFormData";
import axios from "axios";
import { toast } from "react-toastify";

export const getRestaurantPayments = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_restaurant_payments_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const getDuePaymentDetails = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_due_payments_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const getBookingDetails = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_booking_stats_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const paySubScriptionPayment = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const formData = convertObjectToForm(payload)
        const response = await axios.post(`${BASE_URL}${pay_subscription_url}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail(error)
        return error;
    }
}
export const getResaurantSubScription = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_subscription_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const getSubScriptionDetails = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_subscription_payments_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}
export const payToSuperAdmin = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append('paid_proof', payload.paid_proof);
        formData.append('paid_amount', payload.paid_amount);
        payload?.reservationIds?.forEach(item => {
            formData.append('reservation_id[]', item);
        });
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${pay_super_admin_url}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

export const getResaurantFinancialsDetails = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_restaurant_fincancials_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}