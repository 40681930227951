import React from 'react'
import Popup from 'Components/Elements/Popup'
import NotFound from 'Utility/NotFound'

const DownloadMenuPopup = ({ open, close, data }) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Pdf Menu'}
        >
            <div className="flex flex-col gap-4 py-4 px-2 text-white w-full items-start justify-start divide-y divide-lightGrey">
                {data?.url &&
                    <div className="flex flex-col space-y-2 w-full last:border-0 cursor-pointer" onClick={() => { window.open(data?.url, '_blank') }}>
                        <div>Main Menu</div>
                    </div>
                }
                {data?.beverage &&
                    <div className="flex flex-col space-y-2 w-full last:border-0 cursor-pointer pt-4" onClick={() => { window.open(data?.beverage, '_blank') }}>
                        <div>Beverage Menu</div>
                    </div>
                }
                {data?.dessert &&
                    <div className="flex flex-col space-y-2 w-full last:border-0 cursor-pointer pt-4" onClick={() => { window.open(data?.dessert, '_blank') }}>
                        <div>Dessert Menu</div>
                    </div>
                }
                {(!data?.url && !data?.beverage && !data?.dessert) &&
                    <NotFound />
                }
            </div>
        </Popup>
    )
}

export default DownloadMenuPopup