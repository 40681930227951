import { BASE_URL, bill_booking_url, booking_report_url, cancel_booking_url, confirm_booking_url, get_all_reservations_url, get_restaurant_booking_url, get_single_reservation, modification_booking_url, noShow_booking_url, } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getAllBookings, getBookingAnalyticsAction } from "../../../Redux/Concierge/Bookings/Actions";
import axios from "axios";
import { toast } from "react-toastify";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";

export const getRestaurantBookings = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const quer = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_restaurant_booking_url}${quer}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getAllBookings(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}
export const getSingleReservation = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_single_reservation}${payload}`, headers);
        console.log(response, "response")
        if (response?.status == 200) {
            const data = response?.data?.reservation
            success && success(data)
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}
export const getAllReservations = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_all_reservations_url}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.promotions
            // dispatch(getAllPromotion(data))
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}
export const getBookingAnalytics = (access_token, payload, success, fail, setLoading) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${booking_report_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            setLoading && setLoading(false)
            dispatch(getBookingAnalyticsAction(data))
        }
    } catch (error) {
        setLoading && setLoading(false)
        fail && fail()
        return error;
    }
}
export const RequestBookingmodification = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${modification_booking_url}`, payload, headers);
        if (response?.status == 200) {
            const data = response?.data
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const CancelRequestBooking = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${cancel_booking_url}/${payload?.number}`, payload, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const confirmRequestBooking = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${confirm_booking_url}/${payload?.number}`, {}, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const billBookingRequest = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }

        const formData = convertDataToFormData(payload)
        const response = await axios.post(`${BASE_URL}${bill_booking_url}`, formData, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}
export const noShowRequestBooking = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${noShow_booking_url}/${payload?.number}`, {}, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success()
        }
    } catch (error) {
        toast.error(error?.response?.data?.message || apiErrorMessage, { toastId: 'toast' })
        fail && fail()
        return error;
    }
}