import { updateOrAddStaffMember } from "Adapters/APIs/Admin/staff";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const useAdminCreateEditStaff = ({ editData, close, successCallBack, staffRoles }) => {
    const dispatch = useDispatch()

    const { setErrors, errors, validation, emailValidation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState({
        role_id: "",
        number: "",
        first_name: "",
        last_name: "",
        email: "",
        status: true,
        permissions: []
    })

    // handle permissions change
    const handlePermissionChange = (selected) => {
        const selectedValues = selected ? selected?.map(option => option.value) : [];
        setData((prev) => ({
            ...prev,
            permissions: selectedValues
        }))
        setErrors((prev) => ({ ...prev, permissions: '' }));
    }

    // handle onChange function
    const handleChange = (event) => {
        const { name, value } = event.target
        if (name == 'email') {
            emailValidation(name, value);
        }
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({ ...prev, [name]: '' }));
    }


    // update or add staff data
    const updateOrAddStaffData = () => {
        let payload = {
            id: editData?.id,
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
            mobile_code: data?.number?.split('-')[0],
            mobile_number: data?.number?.split('-')[1],
            role_id: data?.role_id,
            status: data?.status ? 1 : 0,
            permissions: data?.permissions
        }
        const success = () => {
            setLoader(false)
            successCallBack && successCallBack()
            close && close(false)
        }
        const fail = () => {
            setLoader(false)
        }
        let requiredData = {
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
            number: data?.number?.split('-')[1],
            permissions: data?.permissions?.length > 0 ? true : false,
            role_id: data?.role_id
        }
        setErrors(validation(requiredData));
        let result = validation(requiredData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoader(true)
            dispatch(updateOrAddStaffMember(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        if (editData) {
            let role
            if (editData?.is_manager == '1') {
                role = staffRoles?.find(itm => itm?.name == 'Manager')?.id
            } else {
                role = staffRoles?.find(itm => itm?.name == 'Staff')?.id
            }
            setData({
                role_id: role,
                number: `${editData?.mobile_code}-${editData?.mobile_number}`,
                first_name: editData?.first_name,
                last_name: editData?.last_name,
                email: editData?.email,
                status: editData?.is_active,
                permissions: editData?.staff_data?.permissions
            })
        }
    }, [editData])

    return {
        data, loader, updateOrAddStaffData, handleChange, setData, errors, handlePermissionChange
    }
}

export default useAdminCreateEditStaff