import Svgs from 'Assets/svgs'
import Popup from 'Components/Elements/Popup'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotFound from 'Utility/NotFound'

const BusinessPopup = ({ open, close, loader, allBusinesses, currentPage, setCurrentPage, secondLoader, pagination_data }) => {
    return (
        <Popup
            open={open}
            close={close}
            // customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            size={'md'}
            heading={'My Business'}
        >
            <div className='my-2'>
                <div className="!m-0 py-6">
                    {loader ?
                        <div className="!m-0 grid grid-cols-1 gap-4">
                            {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                                <div className='bg-gray-500 rounded-md animate-pulse h-24 w-full'></div>
                            ))}
                        </div>
                        :
                        allBusinesses?.length > 0 ?
                            <InfiniteScroll
                                dataLength={allBusinesses?.length ? allBusinesses?.length : 10} //This is important field to render the next data
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }}
                                hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                loader={secondLoader
                                    ? <div className="flex justify-center items-center my-4">
                                        <PreloaderSm />
                                    </div> : ""
                                }
                            >
                                <div className="!m-0 grid grid-cols-1 gap-4">
                                    {allBusinesses?.map((itm, index) => {
                                        return <div className='flex justify-between gap-4 p-4 bg-darkGrey rounded-md'>
                                            <div className='flex flex-col space-y-3' key={index}>
                                                <h2 className='text-white'>{itm?.name}</h2>
                                                <div className='flex gap-2 items-center'>
                                                    <Svgs.LocationIcon stroke={'var(--secondary-color)'} />
                                                    <p className='text-blueGrey break-words'>{itm?.address}</p>
                                                </div>
                                            </div>
                                            <div className='flex flex-col space-y-1 items-end mb-2'>
                                                {itm?.is_approved ?
                                                    <span className={` !text-black p-2 cursor-pointer w-fit rounded-full text-sm font-normal flex justify-center items-center`}>
                                                        <Svgs.EditGoldIcon />
                                                    </span>
                                                    : ""
                                                }
                                                <span className={`${itm?.is_approved == 1 || itm?.is_approved == 0 ? 'bg-secondary !text-black' : 'rejected'} px-3 py-2 font-semibold w-fit rounded-lg text-sm flex justify-center items-center`}>
                                                    {itm?.is_approved == 1 ? 'Active' : itm?.is_approved == 2 ? 'Rejected' : "Pending"}
                                                </span>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </InfiniteScroll>
                            : <NotFound />
                    }
                </div>
            </div>
        </Popup>
    )
}

export default BusinessPopup