import React from 'react'
import DashboardLayout from 'Components/DashboardLayout'
import Svgs from 'Assets/svgs'
import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import useSubscriptionPayments from './helper'
import { currency, subscription_invoice_url } from 'Adapters/variables'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import ViewAllBtn from 'Components/Elements/ViewAllBtn'
import PaymentHistory from '../Payment/PaymentHistory'
import SubscriptionCard from 'Components/SubscriptionCard'
import PendingPayments from './PendingPayments'
import ComingSoon from 'Components/Elements/ComingSoon'

const SubscriptionPayment = () => {
    // const { loading, details, user_data, paySubscription, setPaySubscription, selectedMonths, onSelectedChange, setPayClick, errors,
    //     total_price_no_vat, total_vat, total_price_with_vat, payClick, paidProof, onProofChangeHandler, payLoader, onSubmitPayment
    // } = useSubscriptionPayments()
    return (
        <>
            <DashboardLayout active={'subscription-payments'}>
            <ComingSoon />
                {/* <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                    <div className="flex items-center gap-3 justify-between">
                        <Back
                            title={'Subscription Payments'}
                        />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        {loading
                            ? <>
                                <AdminDashboardCardShimmer
                                    showCurrency={false}
                                    showSvg={false}
                                    arrayLength={[1]}
                                    customHeight={'items-center w-full'}
                                    className2={'flex flex-col py-4 items-center gap-1 justify-center !h-full'}
                                    className={'flex flex-col items-center justify-center !w-full !mb-0'}
                                />
                                <AdminDashboardCardShimmer
                                    showCurrency={false}
                                    showSvg={false}
                                    arrayLength={[1]}
                                    customHeight={'items-center w-full'}
                                    className2={'flex flex-col py-4 items-center gap-1 justify-center !h-full'}
                                    className={'flex flex-col items-center justify-center !w-full !mb-0'}
                                />
                            </>
                            : <>
                                <AdminDashBoardCards
                                    noMinHeight={true}
                                    arrayLength={[1]}
                                    title={'Total Paid'}
                                    amount={details?.total_paid}
                                    btnColor={'bg-secondary'}
                                    className={'flex flex-col items-center justify-center w-full py-5'}
                                    btnClass={'flex w-full justify-end'}
                                    svgIcon={<Svgs.WalletIcon fill={'var(--primary-color)'} />}
                                />
                                <AdminDashBoardCards
                                    noMinHeight={true}
                                    arrayLength={[1]}
                                    title={'Due Fee'}
                                    btnColor={'bg-secondary'}
                                    amount={details?.due_payments}
                                    className={'flex flex-col items-center justify-center w-full py-5'}
                                    btnClass={'flex w-full justify-end'}
                                    svgIcon={<Svgs.WalletIcon fill={'var(--primary-color)'} />}
                                />
                            </>
                        }
                    </div>

                    <h1 className='text-2xl font-semibold py-5 text-white'>Total bill value:</h1>
                    <div className={`!w-full bg-darkGrey relative rounded-lg md:rounded-[10px] flex items-center justify-between mb-8 p-5`}>
                        <div className="flex flex-col gap-5 w-full">
                            <div className="flex flex-col gap-3 py-3 items-center justify-between h-full w-full">
                                <div className="flex flex-col w-full max-w-xl gap-3">
                                    <div className="flex  items-center justify-between">
                                        <h1 className='font-medium text-blueGrey'>Due Payment Details:</h1>
                                        {!loading && details?.overall_pending_days !== 0
                                            && <Button className={'!bg-transparent gap-3 items-center !border border-error text-error !p-2'}>
                                                <Svgs.ClockIcon stroke={"var(--error-color)"} />
                                                Due since {details?.overall_pending_days} days
                                            </Button>
                                        }
                                    </div>
                                    <div className="flex p-4 justify-between items-center bg-lightGrey rounded-lg">
                                        <h1 className='text-blueGrey'>Total payable</h1>
                                        {loading
                                            ? <div className='h-5 w-10 rounded bg-gray-200 animate-pulse' />
                                            : <b className='text-secondary'>{details?.due_payments} {currency}</b>
                                        }
                                    </div>
                                    {details?.due_months_date?.length > 0 &&
                                        <>
                                            {details?.due_months_date?.map(itm => {
                                                return (<>
                                                    <SubscriptionCard title={itm} details={details} />
                                                </>)
                                            })}
                                            <div className="grid grid-cols-2 gap-4">
                                                <ViewAllBtn title={'View Details'}
                                                    onClick={() => setPaySubscription(true)}
                                                />
                                                <Button
                                                    title="View Invoice"
                                                    onClick={() => {
                                                        window.open(`${subscription_invoice_url}?restaurant_id=${user_data?.restaurant?.id}`)
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {details?.paid_subscriptions?.length > 0 &&
                        <PaymentHistory
                            noPagination={true}
                            data={details?.paid_subscriptions}
                            title="Subscription Payments"
                            subscription={true}
                        />
                    }
                    <PendingPayments
                        total_price_no_vat={total_price_no_vat}
                        total_vat={total_vat}
                        total_price_with_vat={total_price_with_vat}
                        payClick={payClick}
                        paidProof={paidProof}
                        setPayClick={setPayClick}
                        onProofChangeHandler={onProofChangeHandler}
                        payLoader={payLoader}
                        onSubmitPayment={onSubmitPayment}
                        details={details}
                        selectedMonths={selectedMonths}
                        onSelectedChange={onSelectedChange}
                        open={paySubscription}
                        errors={errors}
                        close={setPaySubscription}
                        removeHeading={true}
                    />
                </main> */}
            </DashboardLayout>
        </>
    )
}

export default SubscriptionPayment