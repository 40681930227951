import React from 'react'
import Button from 'Components/Elements/Button'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'

const FinancialsCards = ({ title1, hideSeprater, title2, btnText, amount1, amount2, heading, headingAmount, currency, onAllClick }) => {
    return (
        <>
            <div
                className={`bg-darkGrey cursor-pointer relative rounded-lg md:rounded-[10px] flex flex-col border border-secondary items-center justify-between overflow-hidden`}
                onClick={onAllClick && onAllClick}
            >
                <div className='flex flex-col border-b text-blueGrey items-center w-full p-3 mx-auto gap-1'>
                    <h4 className="font-semibold text-lg text-lightGrey">{headingAmount} {headingAmount && currency}</h4>
                    <p className="text-base font-semibold text-center text-blueGrey">{heading}</p>
                </div>
                <div className="flex flex-col relative gap-3 items-center justify-between h-full w-full pt-8 pb-14 px-5">

                    <div className="flex justify-between w-full text-blueGrey gap-2">
                        <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                            <h4 className="font-semibold text-xl text-secondary">{amount1} {currency}</h4>
                            <p className="text-base text-center">{title1}</p>
                        </div>
                        {!hideSeprater && <div className='bg-lightGrey w-0.5' />}
                        {(title2 || amount2) &&
                            <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                                <h4 className="font-semibold text-xl text-secondary">{amount2} {currency}</h4>
                                <p className="text-base text-center">{title2}</p>
                            </div>
                        }
                    </div>

                    <Button
                        onClick={onAllClick && onAllClick}
                        className={'absolute bottom-0 right-0 !rounded-md !py-2 !rounded-tr-none !rounded-bl-none'}
                        title={btnText ? btnText : "View all"}
                    />
                </div>
            </div>
        </>
    )
}

export default FinancialsCards