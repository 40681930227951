export const DummySpecificConciergeRestaurantData = {
    monthly_data: [
      { month: "January", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "February", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "March", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "April", total_bookings: 37, total_commission: "9240.00", total_manager_earning: 3620 },
      { month: "May", total_bookings: 56, total_commission: "2977.00", total_manager_earning: 852.85 },
      { month: "June", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "July", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "August", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "September", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "October", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "November", total_bookings: 0, total_commission: 0, total_manager_earning: 0 },
      { month: "December", total_bookings: 1, total_commission: 0, total_manager_earning: 0 }
    ]
  };