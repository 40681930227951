// Redux/Cuisines/Actions.js

import { GET_CONCEIRGE_CHAT, GET_RESTAURANT_CHAT } from "./ActionType";

export const getConceirgeChat = (data) => ({
    type: GET_CONCEIRGE_CHAT,
    payload: data
});
export const getRestaurantChats = (data) => ({
    type: GET_RESTAURANT_CHAT,
    payload: data
});