import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Image from 'Components/Elements/Image'
import moment from 'moment'
import React from 'react'

const RewardCard = ({ image, text, date, winner, isAnnounced, className, onClick, allowEditAndAnnounce, onEditClick, onAnnounceClick, showLeftImageData }) => {
    return (
        <>
            <div className={`${className} grid grid-cols-1 ${isAnnounced ? 'sm:grid-cols-2' : "sm:grid-cols-1"} w-full shadow-xl rounded-lg md:rounded-[10px]`} onClick={onClick && onClick}>
                {showLeftImageData &&
                    <div className="relative flex flex-col w-full rounded-lg">
                        <Image className={"w-full h-52 object-cover rounded-lg md:rounded-t-[10px]"} src={image} alt="promo" showDummy={true} />
                        <div className="absolute bottom-2 left-2 flex p-2 gap-2 items-center justify-between">
                            <div className="flex p-2 items-center bg-darkGrey gap-2 text-primary rounded-lg">
                                <Svgs.CalenderIcon stroke={'var(--blueGrey-color)'} />
                                <h1 className='text-md font-semibold text-blueGrey'>
                                    {text ? text : moment(date).format('MMMM YYYY')}
                                </h1>
                            </div>
                        </div>
                        {allowEditAndAnnounce &&
                            <div className='absolute top-2 right-2 flex gap-2'>
                                <div className='cursor-pointer border-2 border-secondary bg-secondary rounded-full' onClick={onEditClick && onEditClick}>
                                    <Svgs.FillEditIcon stroke={'var(--primary-color)'} fill={'var(--secondary-color)'} />
                                </div>
                                <div className='cursor-pointer border-2 border-secondary bg-secondary rounded-full' onClick={onAnnounceClick && onAnnounceClick}>
                                    <Svgs.AnnouncmentIcon stroke={'var(--primary-color)'} fill={'var(--secondary-color)'} />
                                </div>
                            </div>
                        }
                    </div>
                }
                {isAnnounced &&
                    <div className={`flex flex-col w-full rounded-lg p-4 gap-2 justify-between text-blueGrey`}>
                        <h1 className='font-semibold text-white'> {winner?.first_name} {winner?.last_name} </h1>
                        <h1 className='text-secondary'> {winner?.business_name} </h1>
                        <h1 className='flex gap-3 items-center'>
                            <div><Svgs.PhoneIcon stroke={'var(--secondary-color)'} /></div>
                            +{winner?.mobile_code} {winner?.mobile_number}
                        </h1>
                        <h1 className='flex gap-3 items-center'>
                            <div><Svgs.MailIcon stroke={'var(--secondary-color)'} /></div>
                            {winner?.email}
                        </h1>
                        <hr />
                        <h1>Total Booking: {winner?.reservations_count} </h1>
                        <h1>Total Revenue: {winner?.reservations_sum_commission} {currency}</h1>
                        <h1>Total Commissions: {winner?.reservations_sum_manager_earning} {currency}</h1>
                    </div>
                }
            </div>
        </>
    )
}

RewardCard.defaultProps = {
    showLeftImageData: true
}

export default RewardCard