import Button from 'Components/Elements/Button'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import OTPScreen from 'OnBaording/OTPVerification/OTPScreen'
import React, { useEffect, useState } from 'react'

const ChangeEmailPopup = ({ open, email, close, onSuccess, btnLoading, errors, emailValidation, setIsEdit }) => {
    const [step, setStep] = useState(1)
    const [newEmail, setNewEmail] = useState()
    return (
        <>
            <Popup
                open={open}
                isBack={step == 2 || step == 4 ? true : false}
                removeHeading={step != 1 ? false : true}
                heading={(step == 2 || step == 4) ? 'Update Email' : step == 3 ? "New Email" : ""}
                removeClose={step == 1 ? true : false}
                close={() => {
                    close(false)
                    setStep(1)
                }}
                onBackClick={() => {
                    step == 2
                        ? setStep(1)
                        : step == 4 && setStep(3)
                }}
                size={'xs'}
            >
                <div className="flex">
                    {step == 1
                        // Confirmation msg for OTP request
                        ? <div className="flex flex-col justify-center gap-5 w-full ">
                            <h1 className='font-semibold text-center text-white'>Are you sure want to update this email?</h1>
                            <div className="flex justify-between gap-3">
                                <Button
                                    customThemeBtn={'view-edit-btn'}
                                    customPadding={'px-5 w-full py-3'}
                                    onClick={() => close(false)}
                                >
                                    No
                                </Button>
                                <Button
                                    customThemeBtn={'theme-btn'}
                                    customPadding={'px-5 w-full py-3'}
                                    onClick={() => {
                                        onSuccess('existing', false, setStep)
                                    }}
                                    loading={btnLoading}
                                >
                                    Yes
                                </Button>

                            </div>
                        </div>
                        : step == 2
                            // Verify OTP
                            ? <OTPScreen
                                isReset={true}
                                setEmailStep={setStep}
                                email={email}
                                isUpdate={true}
                                onResendClick={() => { onSuccess('existing', false, setStep) }}
                            />
                            : step == 3
                                // Add new email
                                ? <div className="flex flex-col gap-6 w-full">
                                    <LoginInput
                                        title={'New Email Address'}
                                        placeholder={'Enter new email address'}
                                        value={newEmail}
                                        error={errors?.newEmail}
                                        onChange={(e) => {
                                            setNewEmail(e?.target?.value)
                                            emailValidation('newEmail', e?.target.value)
                                        }}
                                    />
                                    <Button
                                        customThemeBtn={'theme-btn'}
                                        customPadding={'px-5 w-full py-3'}
                                        onClick={() => {
                                            onSuccess('new', newEmail, setStep)
                                        }}
                                        loading={btnLoading}
                                    >
                                        Submit
                                    </Button>
                                </div>
                                : step == 4
                                    ? <OTPScreen
                                        isReset={true}
                                        setEmailStep={setStep}
                                        email={newEmail}
                                        isNew={true}
                                        isUpdate={true}
                                        onResendClick={() => {
                                            close(false)
                                            setIsEdit && setIsEdit()
                                        }}
                                    />
                                    : ''
                    }
                </div>
            </Popup >
        </>
    )
}

export default ChangeEmailPopup