import { BASE_URL, add_staff_member, get_staff_data, get_staff_roles_data, update_concierge_status, update_staff_member, update_wishlist_staff } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import axios from "axios";
import { toast } from "react-toastify";

// get admin staff members data
export const getStaffData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_staff_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// update staff toogle status
export const updateStaffToogleStatus = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_concierge_status}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}

// get staff roles data
export const getStaffRoles = (access_token, payload, success, fail) => async (dispatch) => {
    const query = payload ? buildQueryString(payload) : ''
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_staff_roles_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.roles
            success && success(data)
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail()
        return error;
    }
}

// update pr add new staff member
export const updateOrAddStaffMember = (access_token, payload, success, fail) => async (dispatch) => {
    let endUrl = payload?.id ? update_staff_member : add_staff_member
    let editId = payload?.id ? payload?.id : ""
    const formData = new FormData();
    if (payload?.id) {
        formData.append('id', payload.id);
    }
    formData.append('first_name', payload.first_name);
    formData.append('last_name', payload.last_name);
    formData.append('email', payload.email);
    formData.append('mobile_code', payload?.mobile_code);
    formData.append('mobile_number', payload?.mobile_number);
    formData.append('role_id', payload.role_id);
    formData.append('status', payload.status);
    payload?.permissions?.forEach(item => {
        formData.append('permissions[]', item);
    });
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${endUrl}${editId}`, formData, headers);
        if (response?.status == 201 || response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, { toastId: 'toast' })
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            toast.error(error?.response?.data?.message, { toastId: 'toast' })
        } else {
            toast.error(apiErrorMessage, { toastId: 'toast' })
        }
        fail && fail()
        return error;
    }
}