import DashboardLayout from 'Components/DashboardLayout'
import React from 'react'
import useUnapprovedConciergeRestaurant from './helper'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotFound from 'Utility/NotFound'
import Card from './Elements/Card'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import Svgs from 'Assets/svgs'
import UnapprovedCardShimmer from 'Utility/Shimmers/UnapprovedCardShimmer'
import HeadingShimmerWithBackIcon from 'Utility/Shimmers/HeadingShimmerWithBackIcon'
import Back from 'Components/Elements/Back'

const UnapprovedConciergeRestaurant = () => {
    const { loader, unapproved_concierge_restaurant, paginationData, currentPage, setCurrentPage, secondLoader, type, navigate
    } = useUnapprovedConciergeRestaurant()
    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen">
                {!type
                    ?
                    <HeadingShimmerWithBackIcon /> :
                    <Back
                        title={`Unapproved ${type == 'concierge' ? 'Concierges' : 'Restaurants'}`}
                    />
                }
                <div className="space-y-3">
                    <div className="relative">
                        {loader
                            ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 p-4">
                                {[0, 1, 2, 3, 4, 5]?.map(itm => {
                                    return <UnapprovedCardShimmer />
                                })}
                            </div>
                            :
                            unapproved_concierge_restaurant?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={unapproved_concierge_restaurant?.length ? unapproved_concierge_restaurant?.length : 10} //This is important field to render the next data
                                    next={() => {
                                        setCurrentPage(currentPage + 1)
                                    }}
                                    hasMore={paginationData && paginationData?.current_page !== paginationData?.total_pages}
                                    loader={secondLoader
                                        ? <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div> : ""
                                    }
                                >
                                    <div className="grid grid-cols-1 sm:grid-cols-2 py-4 gap-5">
                                        {unapproved_concierge_restaurant?.map((itm, ind) => {
                                            return <Card
                                                index={ind}
                                                showImage={type == 'concierge' ? true : false}
                                                type={type}
                                                conciergeImage={itm?.profile_picture}
                                                name={type == 'concierge' ? `${itm?.first_name} ${itm?.last_name}` : itm?.name}
                                                status={itm?.user ? itm?.user?.rejection_messages?.length > 0 ? 2 : 0 : itm?.is_approved}
                                                phoneNumber={`+${itm?.mobile_code}-${itm?.mobile_number}`}
                                                businessName={itm?.business_name ? itm?.business_name : itm?.address}
                                                created_at={itm?.created_at}
                                                onCardClick={() => {
                                                    if (type == 'concierge') {
                                                        navigate(`/admin/unapproved-concierge/${itm?.id}`)
                                                    } else {
                                                        navigate(`/admin/unapproved-restaurant/${itm?.id}`)
                                                    }
                                                }}
                                            />
                                        })}
                                    </div>
                                </InfiniteScroll>
                                : <NotFound />
                        }
                    </div>
                </div>
            </main>

        </DashboardLayout>
    )
}

export default UnapprovedConciergeRestaurant