import React from 'react'

const PromotionCardShimmer = () => {
  return (
    <>
      <div className="w-full shadow-xl rounded-lg md:rounded-[10px] animate-pulse bg-darkGrey">
        <div className='h-48 w-full rounded-t-lg md:rounded-t-[10px] bg-gray-500'></div>
        <div className="p-2 w-full flex justify-between">
          <div className="flex rounded-lg h-9 w-3/5 bg-gray-500 "></div>
          <div className="flex rounded-lg h-9 w-1/5 bg-gray-500 "></div>
        </div>
      </div>
    </>
  )
}

export default PromotionCardShimmer