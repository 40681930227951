import Back from 'Components/Elements/Back'
import React from 'react'

const BookingDetailShimmer = ({ close }) => {
    return (
        <>
            <div id="bookings_sidebar" className="translate-x-0 transition-all fixed top-0 left-0 z-[100000] w-full max-h-screen h-screen bg-[#ffffff]/15">
                <div className="h-full">
                    <div className="relative p-4 w-full max-h-full">
                        <div className="fixed h-screen top-0 right-0 xs:max-w-md max-w-[320px] bg-primary shadow ">
                            <div className="h-full w-full">
                                <div className="flex px-4 md:px-5">
                                    <Back title={''} onClick={() => { close(false) }} />
                                </div>
                                <div id="step1" className="pt-2 pb-6 xs:w-[26rem] w-[320px] gap-4 flex flex-col overflow-y-auto max-h-[90vh] px-4 md:px-5 animate-pulse">
                                    <div className='flex flex-col space-y-2 items-center'>
                                        <div className='h-64 py-24 w-full bg-gray-500 rounded-md'></div>
                                        <div className='flex gap-2 items-center w-full'>
                                            {[0, 1, 2, 3]?.map(itm => {
                                                return <div className='h-20 w-[25%] bg-gray-500 rounded-md'></div>
                                            })}
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-3 items-center w-full">
                                            <div className='h-12 w-24 bg-gray-500 rounded-md'></div>
                                            <div className='h-12 w-24 bg-gray-500 rounded-md'></div>
                                        </div>
                                        <div className={`bg-gray-500 h-12 w-44 border min-w-fit rounded-full capitalize px-4 py-2 text-xs border-transparent font-bold flex justify-center items-center gap-1`}></div>
                                    </div>

                                    {/* Restaurant Detail */}
                                    <div>
                                        <div className='bg-gray-500 rounded-md h-12 w-36'></div>
                                    </div>
                                    <div className="pt-2">
                                        <ul className="space-y-2">
                                            <li className='bg-gray-500 rounded-md h-4 w-44'></li>
                                            <li className='bg-gray-500 rounded-md h-4 w-36'></li>
                                            <li className='bg-gray-500 rounded-md h-4 w-52'></li>
                                        </ul>
                                    </div>

                                    {/* Booking Persion Detail */}
                                    <div className="grid grid-cols-2 gap-5 text-sm">
                                        <div className='flex flex-col space-y-2'>
                                            <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                            <div className="bg-gray-500 rounded-md h-6 w-32"></div>
                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                            <div className="bg-gray-500 rounded-md h-6 w-32"></div>
                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                            <div className="bg-gray-500 rounded-md h-6 w-32"></div>
                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                            <div className="bg-gray-500 rounded-md h-6 w-32"></div>
                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                            <div className="bg-gray-500 rounded-md h-6 w-32"></div>
                                        </div>
                                        <div className='flex flex-col space-y-2'>
                                            <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                            <div className="bg-gray-500 rounded-md h-6 w-32"></div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col space-y-2'>
                                        <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                        <div className="bg-gray-500 rounded-md h-24 w-full"></div>
                                    </div>
                                    <div className="flex flex-col space-y-2 mt-5">
                                        <div className="bg-gray-500 rounded-md h-6 w-28"></div>
                                        <div className="bg-gray-500 rounded-md h-24 w-full"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BookingDetailShimmer