import { GET_ALL_CUISINES } from "./ActionType";

const initialState = {
    cuisines: [],

}

const CuisinesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_CUISINES:
            return {
                ...state,
                cuisines: payload,
            }
        default:
            return {
                ...state,
            };
    }
}

export default CuisinesReducer