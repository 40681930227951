import { applyMiddleware, legacy_createStore as createStore,compose } from 'redux'
import { thunk } from 'redux-thunk'

import Reducers from './rootReducer';

export default function configureStore() {
    const middlewareEnhancer = applyMiddleware(thunk)

    const store = createStore(Reducers, compose(middlewareEnhancer))
    return store;
}