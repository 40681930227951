// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SpecificAnalyticsLineChart = ({ data, animate }) => {
  const months = data?.monthly_data?.map(item => item.month);
  const totalBookings = data?.monthly_data?.map(item => item.total_bookings);
  const totalCommission = data?.monthly_data?.map(item => parseFloat(item.total_commission) || 0);
  let totalEarning;
  if (data?.monthly_data?.find(itm => itm?.total_manager_earning)) {
    totalEarning = data?.monthly_data?.map(item => item.total_manager_earning);
  } else {
    totalEarning = data?.monthly_data?.map(item => item.total_restaurant_earning);
  }

  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Total Commission',
        data: totalCommission,
        borderColor: animate ? "#e8e8e8" : 'rgba(153, 102, 255, 1)',
        backgroundColor: animate ? "#e8e8e8" : 'rgba(153, 102, 255, 0.2)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y',
      },
      {
        label: 'Total Manager Earning',
        data: totalEarning,
        borderColor: animate ? "#e8e8e8" : 'rgba(255, 159, 64, 1)',
        backgroundColor: animate ? "#e8e8e8" : 'rgba(255, 159, 64, 0.2)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y',
      },
      {
        label: 'Total Bookings',
        data: totalBookings,
        borderColor: animate ? "#e8e8e8" : 'rgba(75, 192, 192, 1)',
        backgroundColor: animate ? "#e8e8e8" : 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 10,
          color: '#B7B7B7' // Text color for legend
        },
      },
      backgroundColorPlugin: {
        id: 'backgroundColorPlugin',
        beforeDraw: (chart) => {
          const ctx = chart.ctx;
          ctx.save();
          ctx.fillStyle = '#282828'; // Set the background color
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        position: 'left',
        title: {
          display: true,
          text: 'Total Commission & Manager Earnings',
          color: '#B7B7B7', // Text color for Y axis title
        },
        ticks: {
          color: '#B7B7B7', // Text color for Y axis labels
        },
        grid: {
          color: '#B7B7B7' // Axis line color
        },
      },
      y1: {
        beginAtZero: true,
        position: 'right',
        title: {
          display: true,
          text: 'Total Bookings',
          color: '#B7B7B7', // Text color for Y1 axis title
        },
        ticks: {
          color: '#B7B7B7', // Text color for Y1 axis labels
        },
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: '#B7B7B7' // Axis line color
        },
      },
      x: {
        ticks: {
          color: '#B7B7B7', // Text color for X axis labels
        },
        grid: {
          color: '#B7B7B7' // Axis line color
        }
      }
    },
  };

  return <div className='rounded-lg border-[3px] bg-darkGrey border-lightGrey'>
    <Line data={chartData} options={options} plugins={[options.plugins.backgroundColorPlugin]} />
  </div>
};

export default SpecificAnalyticsLineChart;
