import { getDropdownConciergesAPI } from "Adapters/APIs/Admin/dashboard"
import { getInvoiceReportAPI, sendInvoceByEmailAPI } from "Adapters/APIs/InvoiceReport"
import { objectKeyConvertToArray } from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

let initialFilterState = {
    monthValue: "",
    type: "",
    filter: ""
}
const useBookingAnalytics = () => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')
    const { errors, setErrors, validation, emailValidation } = useValidations()


    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [data, setData] = useState([])
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [onDownloadClick, setOnDownloadClick] = useState(false)
    const [downloadFilter, setDownloadFilter] = useState(moment())
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [share, setShare] = useState()
    const [shareEmail, setShareEmail] = useState('')
    const [shareLoading, setShareLoading] = useState(false)

    // share email change handler
    const onShareChangeHandler = (e) => {
        const { name, value } = e.target
        setShareEmail(value)
        emailValidation(name, value)
    }

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            getInvoiceReport()
        }
    }

    // onDOwnloadClick
    const DownloadClick = () => {
        const date = moment(filterData?.monthValue);
        const month = date.format('MM');
        const year = date.format('YYYY');
        const payload = {
            export: 'csv',
            month: +month,
            year: +year,
            restaurant_id: user_data?.restaurant?.id,
        }
        const success = (res) => {
            setOnDownloadClick(false)
            setDownloadLoading(false)
            toast.success(res.message, { toastId: 'toast' })
            window.open(res.url)
        }
        const fail = () => {
            setDownloadLoading(false)
        }
        setDownloadLoading(true)
        dispatch(getInvoiceReportAPI(access_token, payload, success, fail))
    }

    // send invoice to specific email
    const SendInvoiceByEmail = () => {
        const success = () => {
            setShare()
            setShareEmail(false)
            setShareLoading(false)
        }

        const fail = () => {
            setShareLoading(false)
        }

        const payload = {
            invoice_id: share,
            email: shareEmail
        }

        const error = validation({ email: shareEmail })
        setErrors(error)
        if (objectKeyConvertToArray(error)?.length == 0) {
            setShareLoading(true)
            dispatch(sendInvoceByEmailAPI(access_token, payload, success, fail))
        }
    }
    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            getInvoiceReport(true)
            setFilterData(initialFilterState)
        }
    }

    // get booking analytics data
    const getInvoiceReport = (noFilter) => {
        let month;
        let year;

        if (filterData?.monthValue) {
            const date = moment(filterData?.monthValue);
            month = date.format('MM');
            year = date.format('YYYY');
        }
        let payload = {
            per_page: 10,
            page: currentPage,
            restaurant_id: user_data?.restaurant?.id,
        }
        if (!noFilter) {

            payload = {
                ...payload,
                month: month ? +month : '',
                year: year ? +year : '',
                ...filterData
            }
            delete payload?.monthValue
        }
        setLoader(true)
        const success = (res) => {
            setData(res?.invoices?.data)
            setPaginationData(res?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getInvoiceReportAPI(access_token, payload, success, fail))
    }

    useEffect(() => {
        getInvoiceReport()
    }, [currentPage])

    useEffect(() => {
        // get dropdown data for concierges and restaurants
        dispatch(getDropdownConciergesAPI())
        // dispatch(getDropdownRestaurantAPI())
    }, [])

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        paginationData, currentPage, setCurrentPage, filterData, setFilterData, DownloadClick, loader, data, addFilter, setAddFilter, handleChangeFilter,
        onClearFilter, onApplyFilter, onDownloadClick, setOnDownloadClick, downloadFilter, setDownloadFilter, downloadLoading,
        share, setShare, shareEmail, setShareEmail, shareLoading, setShareLoading, onShareChangeHandler, errors, SendInvoiceByEmail
    }
}

export default useBookingAnalytics