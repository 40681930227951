import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import ConceirgeCards from 'Components/ConceirgeCards'
import DashboardLayout from 'Components/DashboardLayout'
import DateRangePicker from 'Components/Elements/ReactDateRange'
import AdminDashBoardCards from 'Pages/Admin/MainDashbaord/Elements/AdminDashBoardCards'
import React from 'react'
import useFinancials from './helper'
import AdminDashboardCardShimmer from 'Utility/Shimmers/AdminDashboardCardShimmer'
import ConceirgeCardShimmer from 'Utility/Shimmers/ConceirgeCardShimmer'
import Back from 'Components/Elements/Back'
import ComingSoon from 'Components/Elements/ComingSoon'
import FinancialsCards from 'Components/FinancialsCards'
import FinancialCardShimmer from 'Utility/Shimmers/FinancialCardShimmer'

const RestaurantFinancials = () => {
    const { endDate, setEndDate, startDate, setStartDate, loading, details, navigate } = useFinancials()
    return (
        <>
            <DashboardLayout active={'financials'}>
                {/* <ComingSoon /> */}
                <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen overflow-y-auto">
                    <div className="flex flex-col 2sm:flex-row items-start my-4 2sm:my-0 2sm:items-center gap-3 justify-between">
                        <Back title={'Financials'} />
                        <div className="flex w-full flex-col 2sm:flex-row gap-4 items-starty 2sm:items-center justify-end">
                            {loading
                                ? <div className="h-7 rounded bg-gray-200 w-20" />
                                : <div className="flex text-blueGrey">
                                    {!startDate && "Year "}
                                    {details?.period}
                                </div>
                            }
                            <DateRangePicker
                                className="!max-w-none"
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-6">
                            {loading
                                ? <div className="grid 2sm:grid-cols-2 gap-5">
                                    <AdminDashboardCardShimmer
                                        showCurrency={false}
                                        showSvg={false}
                                        arrayLength={[1]}
                                        customHeight={'items-center w-full !h-44'}
                                        className2={'flex flex-col-reverse py-4 items-center gap-1 justify-center !h-full'}
                                        className={'flex flex-col-reverse items-center justify-center !w-full !mb-0'}
                                    />
                                    <AdminDashboardCardShimmer
                                        showCurrency={false}
                                        showSvg={false}
                                        arrayLength={[1]}
                                        customHeight={'items-center w-full !h-44'}
                                        className2={'flex flex-col-reverse py-4 items-center gap-1 justify-center !h-full'}
                                        className={'flex flex-col-reverse items-center justify-center !w-full !mb-0'}
                                    />
                                </div>
                                : <div className="grid 2sm:grid-cols-2 gap-5">
                                    <AdminDashBoardCards
                                        noMinHeight={true}
                                        arrayLength={[1]}
                                        btnClass={'flex w-full justify-end'}
                                        title={'Total Sale'}
                                        amount={details?.total_earned}
                                        showCurrency={true}
                                        // onClick={() => {
                                        //     navigate('/restaurant/subscription-payments')
                                        // }}
                                        className={'flex flex-col items-center justify-center w-full py-5'}
                                        btnColor={'bg-secondary'}
                                        bgColor={'bg-secondary cursor-pointer'}
                                        svgIcon={<Svgs.WalletIcon fill={'var(--darkGrey-color)'} />}
                                    />
                                    <AdminDashBoardCards
                                        noMinHeight={true}
                                        arrayLength={[1]}
                                        btnClass={'flex w-full justify-end'}
                                        title={'Platform Commission'}
                                        amount={details?.total_paid}
                                        showCurrency={true}
                                        // onClick={() => {
                                        //     navigate('/restaurant/subscription-payments')
                                        // }}
                                        className={'flex flex-col items-center justify-center w-full py-5'}
                                        btnColor={'bg-secondary'}
                                        bgColor={'bg-secondary cursor-pointer'}
                                        svgIcon={<Svgs.WalletIcon fill={'var(--darkGrey-color)'} />}
                                    />
                                </div>
                            }
                        </div>
                        {loading
                            ? <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                                <FinancialCardShimmer
                                    showHeading={true}
                                    hideIcon={true}
                                    customHeight={'h-60'}
                                />
                            </div>
                            : <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                                <FinancialsCards
                                    title1={'Total Paid to Admin'}
                                    hideBtn={true}
                                    title2={'Total Due'}
                                    heading={'Commission Payments'}
                                    amount1={details?.total_paid_to_admin}
                                    amount2={details?.total_payable}
                                    currency={currency}
                                    btnText={'View Details'}
                                    onAllClick={() => { navigate('/restaurant/payment-history') }}
                                    themeBtn={'view-edit-btn bg-transparent !text-[#1D8D70] font-semibold mt-3'}
                                    hideSeprater={true}
                                    bgColor={'border !w-full'}
                                />
                                <FinancialsCards
                                    title1={'Total Paid'}
                                    hideBtn={true}
                                    title2={'Total Due'}
                                    heading={'Total subscription payments'}
                                    amount1={details?.total_paid_subscription}
                                    amount2={details?.total_due_subscription}
                                    currency={currency}
                                    btnText={'View Details'}
                                    onAllClick={() => { navigate('/restaurant/subscription-payments') }}
                                    themeBtn={'view-edit-btn bg-transparent !text-[#1D8D70] font-semibold mt-3'}
                                    hideSeprater={true}
                                    bgColor={'border !w-full'}
                                />
                                <FinancialsCards
                                    title1={'Total paid'}
                                    hideBtn={true}
                                    heading={'Total boost payments'}
                                    amount1={details?.total_boost_paid}
                                    currency={currency}
                                    btnText={'View Details'}
                                    themeBtn={'view-edit-btn bg-transparent !text-[#1D8D70] font-semibold mt-3'}
                                    onAllClick={() => { navigate('/restaurant/boost-payments') }}
                                    hideSeprater={true}
                                    bgColor={'border !w-full'}
                                />
                            </div>
                        }
                    </div>
                </main>
            </DashboardLayout>
        </>
    )
}

export default RestaurantFinancials