import LabelAvatar from 'Components/Elements/LabelAvatar'
import React from 'react'

const SubmittedRequestCard = ({ first_name, last_name, date, talk_person, status, interest_level }) => {
    return (
        <div className='flex gap-2 border-b border-white pb-2 mb-2'>
            <LabelAvatar name={first_name} />
            <div className='flex flex-col space-y-2 flex-1 w-full'>
                <h2 className='font-semibold text-white'>{`${first_name} ${last_name}`}</h2>
                <div className='flex justify-between gap-2 w-full'>
                    <div className='flex flex-col space-y-1'>
                        <h2 className='text-white'>Visited Date</h2>
                        <h2 className='text-blueGrey'>{date}</h2>
                    </div>
                    <div className='flex flex-col space-y-1 text-end'>
                        <h2 className='text-white'>Talk Person</h2>
                        <h2 className='text-blueGrey capitalize'>{talk_person}</h2>
                    </div>
                </div>
                <div className='flex justify-between gap-2'>
                    <div className='flex flex-col space-y-1'>
                        <h2 className='text-white'>Status</h2>
                        <h2 className='text-blueGrey capitalize'>{status}</h2>
                    </div>
                    <div className='flex flex-col space-y-1 text-end'>
                        <h2 className='text-white'>Interest Level</h2>
                        <h2 className='text-blueGrey capitalize'>{interest_level}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubmittedRequestCard