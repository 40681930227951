import { GET_ALL_CATEGORIES } from "./ActionType";

const initialState = {
    categories: [],

}

const CategoriesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_CATEGORIES:
            return {
                ...state,
                categories: payload,
            }
        default:
            return {
                ...state,
            };
    }
}

export default CategoriesReducer