import React from 'react';

const BookingTableShimer = ({ columns }) => {
    let renderColumnsLength = columns ? columns : 5
    const renderColumns = (width, className) => {
        return (
            <>
                {[...Array(renderColumnsLength)].map((_, index) => (
                    <div
                        key={index}
                        className={`h-8 ${width} my-2 rounded-lg bg-gray-200 ${className}`}
                    ></div>
                ))}
            </>
        );
    };

    const renderRowColumns = (width, className) => {
        return (
            <>
                {[...Array(renderColumnsLength)].map((_, index) => (
                    <div
                        key={index}
                        className={`h-9 ${width} rounded-lg bg-gray-200 ${className}`}
                    ></div>
                ))}
            </>
        );
    };

    return (
        <>
            <div className="overflow-x-auto animate-pulse rounded-lg">
                <div className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <div className="flex gap-4 px-5 justify-evenly text-sm text-blueGrey bg-primary border-b border-table capitalize ">
                        {renderColumns('w-36', '')}
                    </div>
                    <div className="flex flex-col">
                        {[0, 1, 2, 3, 4, 5]?.map((itm) => {
                            return (
                                <div key={itm}
                                    className="flex py-2 px-5 justify-evenly bg-primary text-sm text-blueGrey gap-4 border-b border-table"
                                >
                                    {renderRowColumns('w-36', '')}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookingTableShimer;
