import axios from "axios";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { BASE_URL, change_password_url, delete_account_url, generate_test_notifications, get_all_notification_url, get_login_user_data, get_my_wallet_url, login_url, post_user_signature, read_all_notification_url, read_single_notification_url, register_url, remove_fcm_token_url, resend_otp_url, reset_passwrod_url, update_email_otp_url, update_fcm_tokens_url, update_profile_url, upload_trade_license, verify_email_otp_url, verify_otp_url, verify_otp_url_forgot_password } from "Adapters/variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAllNotificationsAction } from "../../Redux/User/Actions";
import { convertDataToFormData, convertObjectToForm } from "Constants/Functions/convertDataToFormData";
import Cookies from "js-cookie";


// Function to show a single error message
const showSingleErrorMessage = (error) => {
    if (error && typeof error === 'object') {
        // Get the first key in the error object
        const firstKey = Object.keys(error)[0];

        if (firstKey && Array.isArray(error[firstKey]) && error[firstKey].length > 0) {
            // Get the first message for the first key
            const message = error[firstKey][0];

            // Show the error message
            toast.error(message, {
                toastId: 'toast' // Using a single toastId to ensure only one toast is shown
            });
        } else {
            // Handle case where error[firstKey] is not an array or is empty
            console.warn('No valid error message found.');
        }
    } else {
        // Handle case where error is not an object or is undefined
        console.warn('Error object is invalid.');
    }
};

// signup new user
export const registerUser = (data, success, fail,) => async (dispatch) => {
    try {
        let form_data = convertDataToFormData(data)
        const response = await axios.post(`${BASE_URL}${register_url}`, form_data);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response?.data?.user);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
            if (response?.data?.status == 404) {
                toast.error(response?.data?.message, {
                    toastId: 'toast'
                })
                // const error = convertToObject(response?.data?.data)
                // setErrors(error)
                fail && fail();
                return
            }
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 422) {
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
        }
        fail && fail()
        return error;
    }
}

// post trade license
export const postTradeLicense = (data, success, fail,) => async (dispatch) => {
    try {
        const form_data = convertObjectToForm(data)
        const response = await axios.post(`${BASE_URL}${upload_trade_license}${data?.id}`, form_data);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                return
            }
        }
    } catch (error) {
        fail && fail()
        return error;
    }
};

// update user data
export const updateUser = (access_token, data, success, fail,) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const form_data = convertObjectToForm(data)
        const response = await axios.post(`${BASE_URL}${update_profile_url}`, form_data, headers);
        if (response?.status == 200) {
            Cookies.set('getUserProfileData', true)
            if (response?.data?.status == 200) {
                success && success(response?.data?.user);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
            if (response?.data?.status == 404) {
                toast.error(response?.data?.message, {
                    toastId: 'toast'
                })
                fail && fail();
                return
            }
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 403) {
            toast.error(error?.response?.data?.message, {
                toastId: 'toast'
            })
            fail && fail(error)
        } if (error?.response?.data?.status == 422) {
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
            fail && fail(error)
        }
        return error;
    }
};

// login api function
export const userLogin = (data, success, fail) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}${login_url}`, data);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response?.data);
                // if (response?.data?.user?.restaurant && (response?.data?.user?.restaurant?.terms_accepted === 0 && response?.data?.user?.restaurant?.signature)) {
                //     return
                // } else {
                    toast.success(response?.data?.message, {
                        toastId: 'toast'
                    })
                    return
                // }
            }
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.status == 404 && error?.response?.data?.error != "User doesn't exists with this account.") {
            toast.success(error?.response?.data?.error, { toastId: 'toast' })
        } else if (error?.response?.status == 401) {
            toast.error(error?.response?.data?.error, { toastId: 'toast' })
        } else if (error?.response?.status == 405) {
            // toast.success(error?.response?.data?.error, { toastId: 'toast' })
        } else {
            toast.error(error?.response?.data?.error || apiErrorMessage, {
                toastId: 'toast'
            })
        }
        fail && fail(error);
        return
    }
};

// post user signature data
export const postSignatureData = (access_token, data, success, fail,) => async (dispatch) => {
    const headers = {
        headers: {
            'authorization': `Bearer ${access_token}`
        }
    }
    try {
        const form_data = convertObjectToForm(data)
        const response = await axios.post(`${BASE_URL}${post_user_signature}`, form_data, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                return
            }
        }
    } catch (error) {
        fail && fail()
        return error;
    }
};

// resend OTP
export const resendOTP = (data, success, fail) => async (dispatch) => {
    try {
        // const response = await axios.post(`${BASE_URL}${verify_otp_url}`, data);
        const response = await axios.post(`${BASE_URL}${resend_otp_url}`, data);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success();
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage)
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// OTP verification
export const OtpVerification = (data, success, fail) => async (dispatch) => {
    let endUrl = data?.isReset ? verify_otp_url_forgot_password : verify_otp_url
    try {
        const response = await axios.post(`${BASE_URL}${endUrl}`, data);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage)
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
        if (error?.response?.data?.status == 403) {
            toast.success(error?.response?.data?.message, { toastId: 'toast' })
            fail && fail(error);
            return
        }
    }
};

// reset user password
export const resetPassword = (data, success, fail) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}${reset_passwrod_url}`, data);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage)
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }

};

// new user password
export const newPassword = (data, success, fail) => async (dispatch) => {
    try {
        const response = await axios.post(`${BASE_URL}${change_password_url}`, data);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage)
            fail && fail();
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }

};

// update email with
export const updateEmailRequest = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_email_otp_url}`, data, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage)
            fail && fail();
        }
    } catch (error) {
        toast.error(error?.response?.data?.error || error?.response?.data?.message || apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail();
        return

    }

};
// verify OTP
export const verifyUpdateEmailOTP = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${verify_email_otp_url}`, data, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// Update FCM token
export const UpdateFCMToken = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token ? access_token : JSON.parse(localStorage.getItem("access_token"))}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_fcm_tokens_url}`, data, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// get login user data
export const getLoginUserData = (access_token, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token ? access_token : JSON.parse(localStorage.getItem('access_token'))}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_login_user_data}`, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response?.data);
                return
            }
        } else {
            fail && fail();
        }
    } catch (error) {
        fail && fail(error);
        return
    }
};

// Generate test notifications
export const generateTestNotifications = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${generate_test_notifications}?user_id=${data}`, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// Remove Token when user logouts
export const removeFCMToken = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${remove_fcm_token_url}`, data, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// Get All Notifications
export const getAllNotifications = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const query = buildQueryString(data)
        const response = await axios.get(`${BASE_URL}${get_all_notification_url}${query}`, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                dispatch(getAllNotificationsAction(response?.data))
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
        fail && fail();
        return
    }
};

// Read Single Notification
export const readSingleNotification = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${read_single_notification_url}/${data}`, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
    }
};

// read all notifications
export const readAllNotifications = (access_token, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${read_all_notification_url}?mark_all_as_read=1`, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
        fail && fail();
        return
    }
};

// make external nottification count zero
export const makeExternalNotificationCountZero = (access_token, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${read_all_notification_url}`, headers);

        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response)
                return
            }
        }
    } catch (error) {
        if (error?.response?.data?.status == 404) {
            toast.error(error?.response?.data?.error, {
                toastId: 'toast'
            })
            fail && fail();
            return
        }
        fail && fail();
        return
    }
};

// delete user account
export const deleteAccount = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${delete_account_url}`, data, headers);
        if (response?.status == 200) {
            if (response?.data?.status == 200) {
                success && success(response);
                toast.success(response?.data?.message, {
                    toastId: 'toast'
                })
                return
            }
        } else {
            toast.error(apiErrorMessage)
            fail && fail();
        }
    } catch (error) {
        toast.error(error?.response?.data?.error, {
            toastId: 'toast'
        })
        fail && fail();
        return
    }
};