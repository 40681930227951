import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Functions/Variables';
import React, { useState, useEffect } from 'react';

const Textarea = ({ parent, readOnly, textAreaClass, disabled, required, errorMessage, limit, value, onChange, contentAboveInput, noLimit, ...props }) => {
    const parsedPlaceholder = props?.isCapitalized ? props?.placeholder : props?.placeholder && props?.placeholder.charAt(0).toUpperCase() + props?.placeholder.slice(1).toLowerCase();

    const [count, setCount] = useState(0);
    const [text, setText] = useState(value || ''); // Internal state for the text

    useEffect(() => {
        // Set count and text based on whether noLimit is true
        if (noLimit) {
            setText(value || '');
        } else {
            setCount(value ? value?.slice(0, +limit)?.length : 0);
            setText(value ? value?.slice(0, +limit) : ''); // Update internal state when external value changes
        }
    }, [value, limit, noLimit]);

    const handleChange = (event) => {
        const newText = event.target.value;

        // If noLimit is true, don't restrict the text length
        if (noLimit || newText?.length <= limit) {
            setText(newText);
            setCount(newText?.length);

            const updatedEvent = {
                ...event,
                target: { ...event.target, value: newText, name: props.name },
            };
            onChange && onChange(updatedEvent); // Propagate the change to the parent component
        } else {
            // Trim the text to fit within the limit if noLimit is false
            const trimmedText = newText?.slice(0, limit);
            setText(trimmedText);
            setCount(trimmedText.length);

            const updatedEvent = {
                ...event,
                target: { ...event.target, value: trimmedText, name: props.name },
            };
            onChange && onChange(updatedEvent); // Propagate the change to the parent component
        }
    };

    return (
        <div className={`flex flex-col gap-2 relative ${parent}`}>
            {props?.title && (
                <div className={`${props?.title === '' ? 'hidden' : ''} text-sm text-blueGrey flex items-center gap-1`}>
                    <div>{props?.title}</div> {required && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            <div className='relative'>
                {contentAboveInput && (
                    <div className='absolute top-0 w-full'>
                        {contentAboveInput}
                    </div>
                )}
                <textarea
                    disabled={disabled}
                    value={text}
                    readOnly={readOnly ? true : false}
                    onChange={handleChange}
                    name={props?.name}
                    rows={props?.rows || 10}
                    className={`${textAreaClass} w-full bg-darkGrey text-sm text-white resize-none rounded-md border border-lightGrey outline-none p-3 ${contentAboveInput && 'md:pt-8 pt-10'} ${(props?.error || errorMessage) && ' border-error'}`}
                    placeholder={parsedPlaceholder}
                    autoComplete="off"
                />
                {
                    (!noLimit && props?.counter && !readOnly) && <div className='absolute bottom-2 text-xs right-2 text-white'>{count}/{limit}</div>
                }
            </div>
            {(props?.error) && typeof props?.error === "string" ? (
                <span className='text-error -mt-2 flex justify-end items-center gap-2 text-xs'>
                    <Svgs.I fill="#eb3b3b" /> {props?.error}
                </span>
            ) : (typeof props?.error === "boolean" && props?.error === true) ? (
                <span className='text-error flex items-center gap-2 text-xs'>
                    <Svgs.I fill="#eb3b3b" /> {error_message}
                </span>
            ) : null}
        </div>
    );
};

Textarea.defaultProps = {
    counter: true,
    limit: '150',
    rows: '5'
}

export default Textarea;
