import { newPassword } from "Adapters/APIs/Authentication";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"

const initialState = { password: '', password_confirmation: '' }

const useResetPassword = ({ step, setStep, isReset }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    // states
    const [done, setDone] = useState(false)
    const [resetPassword, setResetPassword] = useState(initialState);
    const [loader, setLoader] = useState(false)

    const { onBlurHandler, setErrors, passwordValidation, confirmPasswordValidation, validation, errors } = useValidations();
    const { getDataFromSessionStorage } = useSetDataInLocalStorage()

    useEffect(() => {
        let forgotEmail = getDataFromSessionStorage("forgotEmail");
        if (!forgotEmail) {
            // if there is isReset then don't navigate to previous screen
            if (!isReset)
                navigate(-1)
        }
    }, []);

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target
        switch (name) {
            case 'password':
                passwordValidation(name, value, resetPassword.password_confirmation, 'password_confirmation');
                setResetPassword({
                    ...resetPassword,
                    [name]: value
                })
                break;
            case 'password_confirmation':
                confirmPasswordValidation(name, value, resetPassword.password)
                setResetPassword({
                    ...resetPassword,
                    [name]: value
                })
                break;
            default:
                setErrors((prev) => ({ ...prev, [name]: "" }));
                setResetPassword({
                    ...resetPassword,
                    [name]: value
                })
        }
    }

    // submit data
    const changePasswordWithEmail = () => {
        let fail = () => {
            setLoader(false)
        }
        let success = () => {
            setLoader(false)
            // setDone(true)
            navigate("/login")
            setResetPassword(initialState);
            sessionStorage.removeItem('forgotEmail')
        }

        let payload = {
            email: getDataFromSessionStorage("forgotEmail"),
            password: resetPassword?.password,
            password_confirmation: resetPassword?.password_confirmation,
            type: "email"
        }
        setErrors(validation({ password: resetPassword?.password, password_confirmation: resetPassword?.password_confirmation }));
        let result = validation({ password: resetPassword?.password, password_confirmation: resetPassword?.password_confirmation });
        passwordValidation("password", resetPassword?.password, resetPassword?.password_confirmation, 'password_confirmation');
        confirmPasswordValidation("password_confirmation", resetPassword?.password_confirmation, resetPassword?.password);
        if (objectKeyConvertToArray(result)?.length === 0 && resetPassword?.password == resetPassword?.password_confirmation) {
            setLoader(true)
            dispatch(newPassword(payload, success, fail))
        }
    }

    return {
        done, setDone, navigate, resetPassword, onBlurHandler, setErrors, errors, handleChange, changePasswordWithEmail, loader
    }
}

export default useResetPassword;