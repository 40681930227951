import Svgs from 'Assets/svgs'
import Button from 'Components/Elements/Button'
import Image from 'Components/Elements/Image'
import Popup from 'Components/Elements/Popup'
import moment from 'moment'
import React from 'react'

const WishlistDetailPopup = ({ wishlistDetail, setWishlistDetail, onClickUpdateStaff }) => {
    return (
        <Popup
            open={wishlistDetail}
            close={setWishlistDetail}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Wishlist Detail'}
        >
            <div className="max-w-sm mx-auto bg-darkGrey shadow-lg rounded-lg overflow-hidden">
                <div className="relative">
                    <Image
                        src={wishlistDetail?.image}
                        className="w-full h-52 object-cover"
                        alt={'wishlist detail image'}
                        showDummy={true}
                    />
                    <div className="absolute inset-x-0 bottom-0 h-6 bg-darkGrey rounded-t-full"></div>
                </div>
                <div className="px-4 pb-4">
                    <div class="font-semibold text-secondary mb-1">ID: {wishlistDetail?.number}</div>
                    <p class="font-semibold text-lg mb-1 text-white">{wishlistDetail?.name}</p>
                    <div className="flex flex-1 flex-col space-y-2">
                        {wishlistDetail?.address &&
                            <div className="flex items-start gap-2 text-sm">
                                <span><Svgs.LocationIcon stroke={'var(--secondary-color)'} /></span>
                                <span className='extralight text-blueGrey'>{wishlistDetail?.address}</span>
                            </div>
                        }
                        <div className="flex gap-2 items-center text-sm">
                            <span><Svgs.CalendarGreen fill={'var(--secondary-color)'} width={14} height={18} /></span>
                            <span className="font-extralight text-blueGrey">{moment(wishlistDetail?.created_at).format('MMM, DD YYYY')}</span>
                        </div>
                        {(wishlistDetail?.phone_code && wishlistDetail?.phone_number) &&
                            <div className="flex gap-2 items-center text-sm">
                                <span><Svgs.PhoneIcon stroke={'var(--secondary-color)'} width={14} height={18} /></span>
                                <span className="font-extralight text-blueGrey">{`${wishlistDetail?.phone_code} - ${wishlistDetail?.phone_number}`}</span>
                            </div>
                        }
                        <Button
                            text={'Update Staff'}
                            className={'w-full'}
                            onClick={onClickUpdateStaff && onClickUpdateStaff}
                        />
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default WishlistDetailPopup