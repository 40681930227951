import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LoginInput from './LoginInput';

const AutoPlaceInput = ({ onChange, value, error, setErrors, name }) => {
    const [address, setAddress] = useState(value || ""); // Initialize with the outer value

    // Sync the state with the value prop only when the prop changes, but not during typing
    useEffect(() => {
        if (value !== address) {
            setAddress(value);
        }
    }, [value]);

    const handleAddressSuggestion = async (value) => {
        const result = await geocodeByAddress(value);
        let address = {
            target: {
                name: name ? name : 'address',
                value: value
            }
        };
        setAddress(value);
        onChange(address); // Update parent

        if (result[0]?.address_components) {
            const location_cords = await getLatLng(result[0]);
            const latLngString = `${location_cords?.lat},${location_cords?.lng}`;
            const addressComponents = result[0].address_components;

            const hasCity = addressComponents.find(component => component.types.includes('locality') || component.types.includes('administrative_area_level_3'));
            const hasState = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
            const hasCountry = addressComponents.find(component => component.types.includes('country'));

            if (!hasCity?.long_name) {
                setErrors((prev) => ({ ...prev, address: "Selected location doesn't have any city" }));
            }
            if (!hasState?.long_name) {
                setErrors((prev) => ({ ...prev, address: "Selected location doesn't have any state" }));
            }
            if (!hasCountry?.long_name) {
                setErrors((prev) => ({ ...prev, address: "Selected location doesn't have any country" }));
            }
            onChange({
                target: {
                    name: 'city',
                    value: hasCity?.short_name
                }
            });
            onChange({
                target: {
                    name: 'state',
                    value: hasState?.long_name
                }
            });
            onChange({
                target: {
                    name: 'country',
                    value: hasCountry?.long_name
                }
            });
            onChange({
                target: {
                    name: 'location_coordinates',
                    value: latLngString
                }
            });
        }
    };

    const handleAddressChange = (newAddress) => {
        setAddress(newAddress);
        // Trigger parent's onChange with empty address if cleared
        if (!newAddress) {
            onChange({ target: { name: name ? name : 'address', value: '' } });
        }
    };

    return (
        <div className="relative">
            <PlacesAutocomplete
                value={address}
                onChange={handleAddressChange}
                onSelect={handleAddressSuggestion}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                }) => (
                    <div className="relative">
                        <LoginInput
                            {...getInputProps()}
                            required={true}
                            title={'Address'}
                            value={address} // Keep in sync with internal state
                            error={error}
                            placeholder={"Enter Address"}
                        />
                        {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container absolute border border-lightGrey rounded-md mt-2 w-full bg-darkGrey z-10">
                                {loading &&
                                    <div className="rounded-md p-2 text-xs text-blueGrey bg-darkGrey">
                                        Loading...
                                    </div>
                                }
                                {suggestions.map((suggestion) => {
                                    const className = `${suggestion.active ? "bg-secondary text-primary" : "bg-darkGrey text-blueGrey"} p-2 text-xs cursor-pointer`;
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
};

export default AutoPlaceInput;
