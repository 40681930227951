import { useState, useEffect } from "react"

export default function useCustomLazyLoading(src) {
    const [sourceLoaded, setSourceLoaded] = useState(null)

    useEffect(() => {
        if (src) {
            const img = new Image()
            img.src = src
            img.onload = () => setSourceLoaded(src)
            img.onerror = () => {
                setSourceLoaded(true)
            }
        } else {
            setSourceLoaded(true)
        }
    }, [src])

    return sourceLoaded
}