import React from 'react'
import Shimmer from './Shimmer'

const ConceirgeCardShimmer = ({ title2, title3, showViewDetail, customPadding, showAmount1, showAmount2, showAmount3 }) => {
    return (
        <Shimmer>
            <div className={`relative bg-darkGrey p-4 rounded-[10px] cursor-pointer`}>
                {showViewDetail &&
                    <div className='absolute right-0 top-0 bg-gray-500 py-[18px] px-[45px] rounded-bl-md rounded-tr-md text-sm font-bold'>
                    </div>
                }
                <div className={`flex justify-between w-full text-blueGrey gap-4 ${customPadding ? customPadding : "pt-10 px-2 py-6"}`}>
                    <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                        <div className="bg-gray-500 h-4 xs:w-28 w-20 rounded-md"></div>
                        {showAmount1 &&
                            <div className="bg-gray-500 h-4 w-16 rounded-md"></div>
                        }
                    </div>
                    {(title2) &&
                        <>
                            <div className='bg-gray-500 w-0.5' />
                            <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                                <div className="bg-gray-500 h-4 xs:w-28 w-20 rounded-md"></div>
                                {showAmount2 &&
                                    <div className="bg-gray-500 h-4 w-16 rounded-md"></div>
                                }
                            </div>
                        </>
                    }
                    {(title3) &&
                        <>
                            <div className='bg-gray-500 w-0.5' />
                            <div className='flex flex-col items-center w-11/12 mx-auto gap-1'>
                                <div className="bg-gray-500 h-4 xs:w-28 w-20 rounded-md"></div>
                                {showAmount3 &&
                                    <div className="bg-gray-500 h-4 w-16 rounded-md"></div>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </Shimmer>
    )
}

ConceirgeCardShimmer.defaultProps = {
    showViewDetail: true,
    showAmount1: true,
    showAmount2: true,
    showAmount3: true
}

export default ConceirgeCardShimmer