import React from 'react'
import { useNavigate } from 'react-router-dom'

const AnalyticsDashboardCard = ({  title, description, icon, link }) => {
    const navigate = useNavigate()
    return (
        <div className="bg-darkGrey restaurant-card-shadow rounded-xl p-6 w-full flex gap-2 items-center cursor-pointer" onClick={() => { navigate(`/admin/analytics/${link}`) }}>
            {icon && icon}
            <div className='flex-1 flex flex-col gap-2'>
                <h1 className='font-medium text-blueGrey'>{title}</h1>
                <p className='text-sm text-blueGrey'>{description ? description : ""}</p>
            </div>
        </div>
    )
}

export default AnalyticsDashboardCard