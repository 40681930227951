import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useValidations from "Hooks/useValidations";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { resetPassword } from "Adapters/APIs/Authentication";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
export const useForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // states
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const { storeDataInSessionStorage, } = useSetDataInLocalStorage();

  const {
    errors,
    setErrors,
    onBlurHandler,
    onFocusHandler,
    validatePhoneNumber,
    emailValidation,
    validation
  } = useValidations();

  // handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'email') {
      setErrors((prev) => ({ ...prev, [name]: '' }));
      emailValidation(name, value);
      setEmail(value)
    }
  };

  // handle email submit for recovery
  const onEmailSubmit = () => {
    if (!errors.email) {
      let successCallBack = (response) => {
        if (email)
          storeDataInSessionStorage("forgotEmail", email);

        if (response?.status === 204) {
          setLoading(false);
          toast.error(response?.data?.message, { toastId: "toast" });
        }
        if (response?.status === 200) {
          setLoading(false);
          // navigate("/forgot-password/otp");
          setStep(step + 1);
        }
      };
      let payload = {
        type: 'email',
        email: email
      };
      let fail = () => {
        setLoading(false);
      };
      setErrors(validation({ email: email }));
      let result = validation({ email: email });
      if (objectKeyConvertToArray(result)?.length === 0) {
        setLoading(true);
      dispatch(resetPassword(payload, successCallBack, fail));
      }
    }
  };

  return {
    errors,
    navigate,
    handleChange,
    onEmailSubmit,
    onBlurHandler,
    onFocusHandler,
    validatePhoneNumber,
    loading,
    step,
    email, setEmail,
    setStep,
  };
};
