import { getAdminSpecificConciergeRestaurantAnalyticsData } from "Adapters/APIs/Admin/analytics"
import { getDropdownConciergesAPI, getDropdownRestaurantAPI } from "Adapters/APIs/Admin/dashboard"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

let initialFilterState = {
    dropdownValue: "",
    start_date: "",
    end_date: ""
}
const useAdminSpecificConciergeRestaurantAnalytics = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const dropdown_concierges = state?.adminDashboard?.dropdown_concierges
    const dropdown_restaurants = state?.adminDashboard?.dropdown_restaurants

    const prevFilterDataRef = useRef(initialFilterState);

    // states
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const [addFilter, setAddFilter] = useState(false)
    const [filterData, setFilterData] = useState(initialFilterState)
    const [filterUpdate, setFilterUpdate] = useState(false)
    const [type, setType] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])

    // handle change filter 
    const handleChangeFilter = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // on clear filter
    const onClearFilter = () => {
        setAddFilter(false)
        const prevFilterData = initialFilterState;
        // Function to compare the current state with the previous state
        const hasStateUpdated = (prev, current) => {
            return Object.keys(prev).some(key => prev[key] !== current[key]);
        };
        if (hasStateUpdated(prevFilterData, filterData)) {
            prevFilterDataRef.current = initialFilterState
            getAnalyticsData(true)
            setFilterData(initialFilterState)
        }
    }

    // on apply filter
    const onApplyFilter = () => {
        setAddFilter(false)
        if (filterUpdate) {
            getAnalyticsData()
        }
    }

    // get booking analytics data
    const getAnalyticsData = (noFilter) => {
        if (type) {
            let payload = {
                type: type,
            }
            if (!noFilter) {
                let dropdownDataValue
                if (type == 'concierge') {
                    dropdownDataValue = {
                        user_id: filterData?.dropdownValue
                    }
                } else {
                    dropdownDataValue = {
                        restaurant_id: filterData?.dropdownValue
                    }
                }
                payload = {
                    ...payload,
                    ...filterData,
                    ...dropdownDataValue
                }
            }
            setLoader(true)
            const success = (res) => {
                setData(res)
                setLoader(false)
            }
            const fail = () => {
                setLoader(false)
            }
            dispatch(getAdminSpecificConciergeRestaurantAnalyticsData(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        // get dropdown data for concierges and restaurants
        dispatch(getDropdownConciergesAPI())
        dispatch(getDropdownRestaurantAPI())
    }, [])

    useEffect(() => {
        if (type == "concierge") {
            setDropdownOptions(dropdown_concierges)
        } else {
            setDropdownOptions(dropdown_restaurants)
        }
    }, [type, dropdown_concierges, dropdown_restaurants])

    useEffect(() => {
        setLoader(true)
        getAnalyticsData()
    }, [type])

    useEffect(() => {
        const url = location.pathname; // Get the current pathname
        if (url.endsWith('specific-concierge-analytics')) {
            setType('concierge');
        } else {
            setType('restaurant');
        }
    }, [location]);

    useEffect(() => {
        const prevData = prevFilterDataRef.current;
        // Check if the object structure or values have changed
        const hasObjectChanged = (prev, current) => {
            // Get all keys from both objects
            const prevKeys = Object.keys(prev);
            const currentKeys = Object.keys(current);
            // Check if the number of keys has changed (new key added or removed)
            if (prevKeys.length !== currentKeys.length) {
                return true;
            }
            // Check if any key or value has changed
            for (const key of currentKeys) {
                if (!prev.hasOwnProperty(key) || prev[key] !== current[key]) {
                    return true; // New key added or value updated
                }
            }
            return false;
        };
        if (hasObjectChanged(prevData, filterData)) {
            setFilterUpdate(true)
        } else {
            setFilterUpdate(false)
        }
        // Update the ref with the current object for the next render
        prevFilterDataRef.current = filterData;
    }, [filterData]);

    return {
        navigate, filterData, setFilterData, loader, data, addFilter, setAddFilter, handleChangeFilter, onClearFilter, type, onApplyFilter,
        dropdownOptions
    }
}

export default useAdminSpecificConciergeRestaurantAnalytics