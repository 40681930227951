import { GET_CONCEIRGE_CHAT, GET_RESTAURANT_CHAT } from "./ActionType";

const initialState = {
    chat: [],
    chat_pagination: {},
}

const ChatReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CONCEIRGE_CHAT:
            let update_chat;
            if (payload?.restaurants?.length == 0) {
                update_chat = [];
            } else if (payload?.pagination_data?.meta?.current_page !== 1) {
                update_chat = [...state?.chat, ...payload?.restaurants];
            } else {
                update_chat = payload?.restaurants;
            }
            return {
                ...state,
                chat: update_chat,
                chat_pagination: payload?.pagination_data?.meta
            }
        case GET_RESTAURANT_CHAT:
            let restaurant_chat;
            if (payload?.pagination_data?.meta?.current_page !== 1) {
                restaurant_chat = [...state?.chat, ...payload?.concierges];
            } else {
                restaurant_chat = payload?.concierges;
            }
            return {
                ...state,
                chat: restaurant_chat,
                chat_pagination: payload?.pagination_data?.meta
            }
        default:
            return {
                ...state,
            };
    }
}

export default ChatReducer