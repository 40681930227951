import { GET_ADMIN_RESTAURANTS, GET_ADMIN_RESTAURANTS_FEATURED, GET_ADMIN_SINGLE_RESTAURANT } from "./ActionType";

export const getAdminRestaurantsData = (data) => {
    return {
        type: GET_ADMIN_RESTAURANTS,
        payload: data
    }
};

export const getAdminRestaurantsFeaturedData = (data) => {
    return {
        type: GET_ADMIN_RESTAURANTS_FEATURED,
        payload: data
    }
};

export const getAdminSingleRestaurantsData = (data) => {
    return {
        type: GET_ADMIN_SINGLE_RESTAURANT,
        payload: data
    }
};
