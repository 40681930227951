import Popup from 'Components/Elements/Popup'
import React from 'react'
import useSingleRestaurantRatingPupup from './helper'
import Svgs from 'Assets/svgs'
import ActiveCard from './ActiveCard'
import StarsRating from './StarsRating'
import LoginInput from 'Components/Elements/LoginInput'
import { copyToClipBoard } from 'Constants/Functions/CopyToClipBoard'

const SingleRestaurantRatingPupup = ({ open, close, ratingData }) => {
    const { currentTab, setCurrentTab, activeTabData } = useSingleRestaurantRatingPupup(ratingData)

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            removeHeading={true}
        >
            <div className='flex gap-2 items-center justify-between'>
                <ActiveCard
                    heading={'TripAdvisor'}
                    svgIcon={<Svgs.TripAdvisor />}
                    active={currentTab == 'tripadvisor'}
                    onClick={() => { setCurrentTab('tripadvisor') }}
                />
                <ActiveCard
                    heading={'Google'}
                    svgIcon={<Svgs.GoogleIcon />}
                    active={currentTab == 'google'}
                    onClick={() => { setCurrentTab('google') }}
                />
            </div>
            {activeTabData ?
                <>
                    <h1 className='text-white py-4 text-md'>Rating</h1>
                    <StarsRating rating={activeTabData?.rating} />
                    <h1 className='text-white py-4 text-md'>Review Link</h1>
                    <div className='flex gap-2'>
                        <LoginInput parentClass={'flex-1'} value={activeTabData?.link ? activeTabData?.link : ""} disabled={true} title={''} placeholder={'Enter link here'} />
                        <div className='px-4 py-2 bg-secondary text-primary font-semibold flex gap-2 items-center rounded-md cursor-pointer' onClick={() => { copyToClipBoard(activeTabData?.link, 'Link Copied!') }}>
                            Share Link
                        </div>
                        {/* <Button title={'Share Link'} customPadding={"px-5 py-2"} onClick={() => { copyToClipBoard(activeTabData?.link, 'Link Copied!') }} /> */}
                    </div>
                </>
                : <p className='text-white text-lg text-center py-4'>No record found</p>}
        </Popup>
    )
}

export default SingleRestaurantRatingPupup