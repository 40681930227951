import { apiErrorMessage } from "Constants/Data/Errors";
import axios from "axios";
import { toast } from "react-toastify";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getAdminRestaurantsData, getAdminRestaurantsFeaturedData, getAdminSingleRestaurantsData } from "../../../Redux/Admin/Restaurants/Action";
import { BASE_URL, get_profile_by_id, get_restaurant_url, get_bookings_by_id, get_single_restaurant_feedback_data, get_single_restaurant_menu_data, get_single_restaurant_menu_pdf_data, update_restaurant_status, update_restaurant_subscription_commission } from "Adapters/variables";

// get restaurant api data
export const getAdminRestaurantApiData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_restaurant_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            if (payload?.is_featured) {
                success && success(data)
                dispatch(getAdminRestaurantsFeaturedData(data))
            } else {
                dispatch(getAdminRestaurantsData(data))
                success && success(false)
            }
        } else {
            fail && fail(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail(false)
        return error;
    }
}

// get admin single restaurant api data
export const getAdminSingleRestaurantApiData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_profile_by_id}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            dispatch(getAdminSingleRestaurantsData(data))
            success && success(false)
        } else {
            fail && fail(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail(false)
        return error;
    }
}

// update restaurant toogle status
export const updateRestaurantToogleStatus = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_restaurant_status}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
        }
    } catch (error) {
        toast.error(apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail()
        return error;
    }
}

// update restaurant subscription and comission
export const updateRestaurantSubscriptionAndCommission = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${update_restaurant_subscription_commission}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
        }
    } catch (error) {
        toast.error(apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail()
        return error;
    }
}

// get admin single restaurant feedback data
export const getAdminSingleRestaurantFeedbackData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_single_restaurant_feedback_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail(error)
        return error;
    }
}

// get admin single restaurant menu data
export const getAdminSingleRestaurantMenuData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_single_restaurant_menu_data}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        fail && fail(error)
        if (error?.response?.status == 404) {
            return
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
        }
        return error;
    }
}

// get admin single restaurant menu pdf data
export const getAdminSingleRestaurantMenuPdfData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_single_restaurant_menu_pdf_data}${query}`, headers);
        console.log(response)
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        }
    } catch (error) {
        if (error?.response?.status == 404) {
            fail && fail(error)
            return
        } else {
            toast.error(apiErrorMessage, {
                toastId: 'toast'
            })
        }
        fail && fail(error)
        return error;
    }
}

// get admin single restaurant bookings data
export const getAdminSingleRestaurantBookingsData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_bookings_by_id}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage, {
            toastId: 'toast'
        })
        fail && fail(error)
        return error;
    }
}

