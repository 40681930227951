import { GET_ADMIN_DASHBOARD_DATA, GET_CONCIERGE_RESTAURANT_STATS_DATA, GET_DASHBOARD_CONCIERGE, GET_DASHBOARD_RESTAURANT, GET_DROPDOWN_CONCIERGES_DATA, GET_DROPDOWN_RESTAURANT_DATA, GET_UNAPPROVED_CONCIERGE_RESTAURANT_DATA } from "./ActionType";

export const getAdminDashboardData = (data) => {
    return {
        type: GET_ADMIN_DASHBOARD_DATA,
        payload: data
    }
};

export const getDropdownConciergesData = (data) => {
    return {
        type: GET_DROPDOWN_CONCIERGES_DATA,
        payload: data
    }
};

export const getDropdownRestaurantData = (data) => {
    return {
        type: GET_DROPDOWN_RESTAURANT_DATA,
        payload: data
    }
};

export const getConiergeRestaurantStatsData = (data) => {
    return {
        type: GET_CONCIERGE_RESTAURANT_STATS_DATA,
        payload: data
    }
};

export const getUnapprovedConiergeRestaurantData = (data) => {
    return {
        type: GET_UNAPPROVED_CONCIERGE_RESTAURANT_DATA,
        payload: data
    }
};


export const getDashboardRestaurantData = (data) => {
    return {
        type: GET_DASHBOARD_RESTAURANT,
        payload: data
    }
};


export const getDashboardConciergeData = (data) => {
    return {
        type: GET_DASHBOARD_CONCIERGE,
        payload: data
    }
};
