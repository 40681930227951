import { BASE_URL, edit_profile_by_admin, get_admin_dashboard_detail_url, get_concierge_restaurant_stats_url, get_concierges_url, get_dropdown_concierges_url, get_dropdown_restaurant_url, get_restaurant_url, get_unapproved_concierge, get_unapproved_restaurant, reject_submission } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getAdminDashboardData, getConiergeRestaurantStatsData, getDashboardConciergeData, getDashboardRestaurantData, getDropdownConciergesData, getDropdownRestaurantData, getUnapprovedConiergeRestaurantData } from "../../../Redux/Admin/Dashboard/Action";
import axios from "axios";
import { toast } from "react-toastify";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { convertObjectToForm } from "Constants/Functions/convertDataToFormData";

// Function to show a single error message
const showSingleErrorMessage = (error) => {
    if (error && typeof error === 'object') {
        // Get the first key in the error object
        const firstKey = Object.keys(error)[0];

        if (firstKey && Array.isArray(error[firstKey]) && error[firstKey].length > 0) {
            // Get the first message for the first key
            const message = error[firstKey][0];

            // Show the error message
            toast.error(message, {
                toastId: 'toast' // Using a single toastId to ensure only one toast is shown
            });
        } else {
            // Handle case where error[firstKey] is not an array or is empty
            console.warn('No valid error message found.');
        }
    } else {
        // Handle case where error is not an object or is undefined
        console.warn('Error object is invalid.');
    }
};

// get dashboard cards data
export const getAdminDashboardDataAPI = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_admin_dashboard_detail_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getAdminDashboardData(data))
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get dropdown concierges data
export const getDropdownConciergesAPI = (access_token, payload, success, fail) => async (dispatch) => {
    const query = payload ? buildQueryString(payload) : ''
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_dropdown_concierges_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.concierges
            success && success()
            dispatch(getDropdownConciergesData(data))
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get dropdown restaurant data
export const getDropdownRestaurantAPI = (access_token, payload, success, fail) => async (dispatch) => {
    const query = payload ? buildQueryString(payload) : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_dropdown_restaurant_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.restaurants
            success && success()
            dispatch(getDropdownRestaurantData(data))
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get conierge or restaurant stats data
export const getConciergeRestaurantStatsAPI = (access_token, payload, success, fail) => async (dispatch) => {
    const query = payload ? buildQueryString(payload) : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_concierge_restaurant_stats_url}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            dispatch(getConiergeRestaurantStatsData(data))
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get unapproved conierge or restaurant stats data
export const getUnApprovedRestaurantConciergeData = (access_token, payload, success, fail) => async (dispatch) => {
    let endUrl = payload?.type == 'concierge' ? get_unapproved_concierge : get_unapproved_restaurant
    const query = payload ? buildQueryString(payload) : ""
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${endUrl}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success()
            let updatedData = {
                array: payload?.type == 'concierge' ? data?.concierges : data?.restaurants,
                pagination_data: data?.pagination_data
            }
            dispatch(getUnapprovedConiergeRestaurantData(updatedData))
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}

// get restaurant api data
export const getRestaurantApiData = (access_token, setLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_restaurant_url}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.restaurants
            dispatch(getDashboardRestaurantData(data))
            setLoader && setLoader(false)
        } else {
            setLoader && setLoader(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoader && setLoader(false)
        return error;
    }
}

// get concierge api data
export const getConciergeApiData = (access_token, setLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_concierges_url}`, headers);
        if (response?.status == 200) {
            const data = response?.data?.concierges
            dispatch(getDashboardConciergeData(data))
            setLoader && setLoader(false)
        } else {
            setLoader && setLoader(false)
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        setLoader && setLoader(false)
        return error;
    }
}


// update unapproved restaurant data
export const updateUnapprovedRestaurant = (access_token, data, success, fail,) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const form_data = convertObjectToForm(data)
        const response = await axios.post(`${BASE_URL}${edit_profile_by_admin}`, form_data, headers);
        if (response?.status == 200) {
            toast.success(response?.data?.message, { toastId: 'toast' })
            success && success(response?.data?.user);
        }
    } catch (error) {
        console.log(error)
        if (error?.response?.data?.status == 422) {
            console.log("inside 422")
            showSingleErrorMessage(error?.response?.data?.error)
            fail && fail(error)
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" })
            fail && fail(error)
        }
        return error;
    }
};

// update restaurant reject submission
export const updateRestaurantRejectReason = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.post(`${BASE_URL}${reject_submission}`, payload, headers);
        if (response?.status == 200) {
            success && success()
            toast.success(response?.data?.message, {
                toastId: 'toast'
            })
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
        return error;
    }
}