import { BASE_URL, get_booking_analytics, get_concierge_restaurant_analytics, get_restaurant_booking_analytics, get_specific_concierge_restaurant_analytics } from "Adapters/variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import axios from "axios";
import { toast } from "react-toastify";

// get admin booking analytics reports data
export const getAdminBookingAnalyticsReport = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_booking_analytics}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// get admin booking analytics reports data
export const getRestaurantBookingAnalyticsReport = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_restaurant_booking_analytics}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// get admin concierges/restaurant analytics reports data
export const getAdminConciergeRestaurantAnalyticsData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_concierge_restaurant_analytics}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}

// get admin specific concierges/restaurant analytics reports data
export const getAdminSpecificConciergeRestaurantAnalyticsData = (access_token, payload, success, fail) => async (dispatch) => {
    const query = buildQueryString(payload)
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${get_specific_concierge_restaurant_analytics}${query}`, headers);
        if (response?.status == 200) {
            const data = response?.data
            success && success(data)
        } else {
            fail && fail(response)
        }
    } catch (error) {
        toast.error(apiErrorMessage)
        fail && fail(error)
        return error;
    }
}