import { GET_ADMIN_RESTAURANTS, GET_ADMIN_RESTAURANTS_FEATURED, GET_ADMIN_SINGLE_RESTAURANT } from "./ActionType";

const initialState = {
    restaurants: [],
    pagination_data: {},
    featured_restaurants: [],
    featured_pagination_data: {},
    singleRestaurant: {}
}

const AdminRestaurants = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ADMIN_RESTAURANTS:
            let updatedRestaurants;
            if (payload?.restaurants?.length == 0) {
                updatedRestaurants = []
            } else if (payload?.pagination_data) {
                if (payload?.pagination_data?.meta?.current_page !== 1) {
                    updatedRestaurants = [...state?.restaurants, ...payload?.restaurants];
                } else {
                    updatedRestaurants = payload?.restaurants;
                }
            } else {
                updatedRestaurants = payload?.restaurants;
            }
            return {
                ...state,
                restaurants: updatedRestaurants,
                pagination_data: payload?.pagination_data?.meta
            }

        case GET_ADMIN_RESTAURANTS_FEATURED:
            let updatedRestaurantsFeatured;
            if (payload?.restaurants?.length == 0) {
                updatedRestaurantsFeatured = []
            } else if (payload?.pagination_data) {
                if (payload?.pagination_data?.meta?.current_page !== 1) {
                    updatedRestaurantsFeatured = [...state?.restaurants, ...payload?.restaurants];
                } else {
                    updatedRestaurantsFeatured = payload?.restaurants;
                }
            } else {
                updatedRestaurantsFeatured = payload?.restaurants;
            }
            return {
                ...state,
                featured_restaurants: updatedRestaurantsFeatured,
                featured_pagination_data: payload?.pagination_data?.meta
            }

        case GET_ADMIN_SINGLE_RESTAURANT:
            return {
                ...state,
                singleRestaurant: payload?.retaurant,
                singleRestaurantPromotions: payload?.promotions
            }
        default:
            return {
                ...state,
            };
    }
}

export default AdminRestaurants