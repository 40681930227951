import React from 'react'

const RewardsShimmer = ({ isAnnounced }) => {
    return (
        <>
            <div className={`grid grid-cols-1 ${isAnnounced ? 'sm:grid-cols-2' : "sm:grid-cols-1"} w-full shadow-xl rounded-lg md:rounded-[10px] animate-pulse`}>
                <div className="flex flex-col w-full gap-2 p-2  border-4 rounded-lg border-secondary">
                    <div className="bg-gray-500 w-full h-52 rounded-lg" alt="promo" />
                    <div className="flex p-2 h-10 w-32 items-center bg-gray-500 rounded-lg"></div>
                </div>
                {isAnnounced &&
                    <div className="flex flex-col w-full rounded-lg p-4 gap-2 justify-between ">
                        <div className='h-5 w-3/5 rounded-lg bg-gray-500'></div>
                        <div className='h-5 w-2/5 rounded-lg bg-gray-500'></div>
                        <div className='h-5 w-4/5 rounded-lg bg-gray-500'></div>
                        <div className='h-5 w-4/5 rounded-lg bg-gray-500'></div>
                        <hr />
                        <div className='h-5 w-2/5 rounded-lg bg-gray-500'></div>
                        <div className='h-5 w-3/5 rounded-lg bg-gray-500'></div>
                        <div className='h-5 w-4/5 rounded-lg bg-gray-500'></div>
                    </div>
                }
            </div>
        </>
    )
}

export default RewardsShimmer