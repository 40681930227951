import React from 'react'

const UserCardShimmer = () => {
    return (
        <div className="flex flex-col w-full rounded-lg p-4 gap-2 justify-between bg-darkGrey">
            <div className='h-5 w-3/5 rounded-lg bg-gray-200'></div>
            <div className='h-5 w-2/5 rounded-lg bg-gray-200'></div>
            <div className='h-5 w-4/5 rounded-lg bg-gray-200'></div>
            <div className='h-5 w-4/5 rounded-lg bg-gray-200'></div>
            <hr className='border-lightGrey' />
            <div className='h-5 w-2/5 rounded-lg bg-gray-200'></div>
            <div className='h-5 w-3/5 rounded-lg bg-gray-200'></div>
            <div className='h-5 w-4/5 rounded-lg bg-gray-200'></div>
        </div>
    )
}

export default UserCardShimmer