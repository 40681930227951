import React from 'react'

const ActiveCard = ({ active, onClick, heading, svgIcon }) => {
    return (
       <div
            className={`border-2  ${active ? "border-secondary" : "border-lightGrey"} text-blueGrey gap-3 rounded-xl md:rounded-[15px] py-5 px-3 flex flex-1 justify-center items-center cursor-pointer activeRate`}
            onClick={onClick && onClick}
        >
            {svgIcon}
            <p className='font-semibold text-sm'>{heading}</p>
        </div>
    )
}

export default ActiveCard