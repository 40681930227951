import React from 'react'
import StarsRating from '../SingleRestaurantRatingPupup/StarsRating'

const FeedbackCard = (props) => {
    const {
        data, index, name, created_at, rating, description, food, ambiance, quality, service, valueForMoney
    } = props

    return (
        <div className="bg-darkGrey rounded-xl w-full" key={index}>
            <div className='p-4'>
                <div className='flex justify-between items-center gap-2'>
                    <h1 className='font-semibold text-white'>{name}</h1>
                    <StarsRating rating={rating} starSize={20} />
                </div>
                <h1 className='text-secondary text-sm pb-1'>{created_at}</h1>
                <h1 className='text-blueGrey text-sm'>{description}</h1>
            </div>
            <hr className='border-[1.5px] border-lightGrey' />
            <div className='p-4'>
                <div className='grid grid-cols-2 gap-2'>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-blueGrey text-sm'>Food:</h1>
                        <h1 className='text-sm font-semibold text-white'>{food}</h1>
                    </div>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-blueGrey text-sm'>Ambiance:</h1>
                        <h1 className='text-sm font-semibold text-white'>{ambiance}</h1>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-2'>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-blueGrey text-sm'>Quality:</h1>
                        <h1 className='text-sm font-semibold text-white'>{quality}</h1>
                    </div>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-blueGrey text-sm'>Service:</h1>
                        <h1 className='text-sm font-semibold text-white'>{service}</h1>
                    </div>
                </div>
                <div className='flex items-center gap-2'>
                    <h1 className='text-blueGrey text-sm'>Value for money:</h1>
                    <h1 className='text-sm font-semibold text-white'>{valueForMoney}</h1>
                </div>
            </div>
        </div>
    )
}

export default FeedbackCard