import DashboardLayout from 'Components/DashboardLayout'
import Back from 'Components/Elements/Back'
import React from 'react'
import useProfileBoostingHistory from './helper'
import NotFound from 'Utility/NotFound'
import PreloaderSm from 'Components/Elements/PreloaderSm'
import InfiniteScroll from 'react-infinite-scroll-component'
import BoostingHistoryCard from './Elements/BoostingHistoryCard'
import BoostingHistoryDetailPopup from './Elements/BoostingHistoryDetailPopup'
import ComingSoon from 'Components/Elements/ComingSoon'

const ProfileBoostingHistory = () => {
    // const { loader, boostingHistory, pagination_data, currentPage, setCurrentPage, secondLoader, type, setType, slotId, setSlotId,
    //     getDetailLoader, slotDetailData } = useProfileBoostingHistory()
    return (
        <DashboardLayout active={'boosting-history'}>
            <ComingSoon />
            {/* <main className="relative p-4 lg:ml-72 md:ml-64 pt-20 min-h-screen overflow-y-auto">
                <div className="flex items-center gap-3 w-full justify-between">
                    <div className="pt-4 space-y-5 w-full">
                        <div className="flex xs:flex-row flex-col justify-between xs:items-center">
                            <Back title={'Boosting History'} />
                        </div>
                        <div className="border-b flex w-full items-baseline gap-5 text-blueGrey">
                            <p onClick={() => setType('approved')} className={`${type == 'approved' ? 'activePage' : ''} cursor-pointer font-semibold sm:text-base text-sm pb-2 flex`}>Approved  &nbsp;
                            </p>
                            <p onClick={() => setType('unapproved')} className={`${type == 'unapproved' ? 'activePage' : ''} cursor-pointer font-semibold sm:text-base text-sm pb-2 flex`}>Unapproved &nbsp;
                            </p>
                        </div>
                    </div>
                </div>
                <div className='py-6'>
                    <div className="!m-0">
                        {loader ?
                            <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => (
                                    <div className='bg-gray-500 animate-pulse rounded-md h-44'></div>
                                ))}
                            </div>
                            :
                            boostingHistory?.length > 0 ?
                                <InfiniteScroll
                                    dataLength={boostingHistory?.length ? boostingHistory?.length : 10} //This is important field to render the next data
                                    next={() => {
                                        setCurrentPage(currentPage + 1)
                                    }}
                                    hasMore={pagination_data && pagination_data?.current_page !== pagination_data?.total_pages}
                                    loader={secondLoader
                                        ? <div className="flex justify-center items-center my-4">
                                            <PreloaderSm />
                                        </div> : ""
                                    }
                                >
                                    <div className="!m-0 grid lg:grid-cols-2 grid-cols-1 gap-4">
                                        {boostingHistory?.map((itm, index) => {
                                            return <BoostingHistoryCard
                                                onClick={() => {
                                                    setSlotId(itm?.id)
                                                }}
                                                index={index}
                                                slotName={itm?.slot_id ? itm?.slot?.name : "Universal slot"}
                                                amount={itm?.total_amount}
                                                start_date={itm?.start_date}
                                                end_date={itm?.end_date}
                                                status={itm?.is_approved}
                                            />
                                        })}
                                    </div>
                                </InfiniteScroll>
                                : <NotFound />
                        }
                    </div>
                </div>
            </main>

            <BoostingHistoryDetailPopup
                open={slotId}
                close={setSlotId}
                data={slotDetailData}
                loader={getDetailLoader}
            /> */}
        </DashboardLayout>
    )
}

export default ProfileBoostingHistory