import React from 'react'

const ChatCardShimmer = () => {
    return (
        <>
            <div className="border border-lightGrey flex items-center justify-between px-2 xl:px-4 animate-pulse py-2 xl:py-5 rounded-lg md:rounded-[10px]">
                <div className="h-24 !w-24 rounded-full bg-gray-500" />
                <div className="flex sm:flex-col xl:flex-row items-start xl:items-center gap-2 xl:gap-0 2xl:gap-2 flex-1">
                    <div className="flex flex-col gap-2 2xl:gap-4 items-start w-full px-2 2xl:px-4">
                        <div>
                            <div className='h-6 w-36 my-2 rounded-lg bg-gray-500'></div>
                            <div className='h-5 w-28 my-2 rounded-lg bg-gray-500'></div>
                        </div>
                        <div className='h-5 w-36 my-2 rounded-lg bg-gray-500'></div>
                    </div>
                    <div className="flex flex-col sm:flex-row xl:flex-col gap-4 sm:gap-2 xl:gap-4 w-10 2xl:w-14 xl:px-0 px-2">
                        <div className="h-8 w-8 rounded-full bg-gray-500" />
                        <div className="h-8 w-8 rounded-full bg-gray-500" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatCardShimmer