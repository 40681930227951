import { getAdminSingleRestaurantMenuPdfData } from "Adapters/APIs/Admin/restaurant";
import { deleteMenuCategory, deleteMenuItem, deletePDFCategory, getMenuCategory } from "Adapters/APIs/Restaurant/menu";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useRestaurantMenu = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // redux states
    const state = useSelector(state => state)
    const main_manu_category = state?.manu?.main_manu_category
    const beverage_manu_category = state?.manu?.beverage_manu_category
    const desert_manu_category = state?.manu?.desert_manu_category

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const user_data = getDataFromLocalStorage('user_data')

    // states
    const [loader, setLoader] = useState(true)
    const [type, setType] = useState('main')
    const [addPopup, setAddPopup] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pdfMenu, setPdfMenu] = useState()
    const [pdfMenuLoader, setPdfMenuLLoader] = useState(true)
    const [pDFDeleteLoader, setPDFDeleteLoader] = useState(false)
    const [pdfData, setPdfData] = useState("")
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    // get menu  data
    const getMenu = (noLoader) => {
        !noLoader && setLoader(true)
        const success = (data) => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getMenuCategory(access_token, { restaurant_id: user_data?.restaurant?.id }, success, fail))
    }

    // delete pdf category
    const deletePDF = () => {
        const success = () => {
            setLoader(true)
            getPDFMenu()
            getMenu()
            setPDFDeleteLoader(false)
            setDeleteConfirmation(false)
        }
        const fail = () => {
            setPDFDeleteLoader(false)

        }
        let data = {
            id: pdfMenu?.id, category: type == 'desert' ? 'dessert' : type
        }
        setPDFDeleteLoader(true)
        dispatch(deletePDFCategory(access_token, data, success, fail))
    }


    // get pdf menu data
    const getPDFMenu = () => {
        let payload = { restaurant_id: user_data?.restaurant?.id }
        const success = (res) => {
            setPdfMenu(res?.pdf_menu)
            setPdfMenuLLoader(false)
        }
        const fail = () => {
            setPdfMenuLLoader(false)
        }
        setPdfMenuLLoader(true)
        dispatch(getAdminSingleRestaurantMenuPdfData(access_token, payload, success, fail))
    }

    // delete menu
    const deleteMainMenu = () => {
        const success = () => {
            onSuccess()
            setDeletePopup(false)
            setLoading(false)
            setAddPopup(false)
        }
        const fail = () => { setLoading(false) }
        setLoading(true)

        deletePopup?.type
            ? dispatch(deleteMenuItem(access_token, deletePopup?.id, success, fail))
            : dispatch(deleteMenuCategory(access_token, deletePopup?.id, success, fail))
    }

    // on success callback
    const onSuccess = () => {
        getPDFMenu()
        getMenu()
    }

    const manu_category = useMemo(() => {
        return type == 'main' ? main_manu_category : type == 'beverage' ? beverage_manu_category : desert_manu_category
    }, [main_manu_category, beverage_manu_category, type, desert_manu_category])

    useEffect(() => {
        setLoader(true)
        onSuccess()
    }, [type])

    useEffect(() => {
        if (pdfMenu?.url || pdfMenu?.beverage || pdfMenu?.dessert) {
            setPdfData(type == 'main' ? pdfMenu?.url : type == 'beverage' ? pdfMenu?.beverage : pdfMenu?.dessert)
        }else{
            setPdfData()
        }
    }, [type, pdfMenu])

    return {
        navigate, loader, type, setType, onSuccess, addPopup, setAddPopup, manu_category, deletePopup, setDeletePopup, deleteMainMenu, loading,
        pdfMenu, pdfMenuLoader, deletePDF, pDFDeleteLoader, pdfData, deleteConfirmation, setDeleteConfirmation
    }
}

export default useRestaurantMenu