import { getUnApprovedRestaurantConciergeData } from "Adapters/APIs/Admin/dashboard";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const useUnapprovedConciergeRestaurant = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const unapproved_concierge_restaurant = state?.adminDashboard?.unapproved_concierge_restaurant
    const paginationData = state?.adminDashboard?.unapproved_concierge_restaurant_pagination

    // states
    const [loader, setLoader] = useState(true)
    const [type, setType] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [secondLoader, setSecondLoader] = useState(false)
    const [editData, setEditData] = useState()

    // get unapproved concierge restaurant data
    const getUnapprovedCOnciergeRestaurant = () => {
        let payload = {
            type: type,
            page: currentPage,
            per_page: 10,
        }
        if (currentPage == '1') {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getUnApprovedRestaurantConciergeData(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (type) {
            getUnapprovedCOnciergeRestaurant()
        }
    }, [type, currentPage])

    useEffect(() => {
        const url = location.pathname; // Get the current pathname
        if (url.endsWith('unapproved-concierges')) {
            setType('concierge');
        } else {
            setType('restaurant');
        }
    }, [location]);

    return {
        loader, unapproved_concierge_restaurant, paginationData, currentPage, setCurrentPage, secondLoader, type, navigate, editData, setEditData
    }
}

export default useUnapprovedConciergeRestaurant