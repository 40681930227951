import Svgs from 'Assets/svgs'
import React from 'react'

const ActivityLogCard = ({ first_name, last_name, email, date, assignedToName, assignedToEmail }) => {
    return (
        <div className='bg-darkGrey p-4 rounded-lg mb-2'>
            <div className='flex gap-2 pb-3'>
                <Svgs.UserIcon2 width={'40'} height={'40'} />
                <div className='flex justify-between gap-4 flex-1 px-2'>
                    <div className='flex flex-col'>
                        <h2 className='text-white text-lg'>{`${first_name} ${last_name}`}</h2>
                        <p className='text-blueGrey'>{email}</p>
                    </div>
                    <p className='text-sm text-secondary text-end pt-1'>{date}</p>
                </div>
            </div>
            <div className='flex justify-center items-center text-center mt-2 border-t border-lightGrey'>
                <div className='px-2.5 py-0.5 rounded-bl-xl rounded-br-xl text-white bg-lightGrey w-fit'>
                    Assigned To
                </div>
            </div>
            <div className=''>
                <div className='flex flex-col space-y-1'>
                    <div className='flex gap-2 text-blueGrey'>Name: <span className='text-white'>{assignedToName}</span></div>
                    <div className='flex gap-2 text-blueGrey'>Email: <span className='text-white'>{assignedToEmail}</span></div>
                </div>
            </div>
        </div>
    )
}

export default ActivityLogCard