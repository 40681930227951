import { currency } from 'Adapters/variables'
import Svgs from 'Assets/svgs'
import Popup from 'Components/Elements/Popup'
import moment from 'moment'
import React from 'react'

const WinnerDetailPopup = ({ open, close, data }) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            customPadding={'!p-0'}
            heading={'Winner Details'}
        >
            <div className={`p-6`}>
                <div className=' bg-[#e8e5c5] rounded-lg w-full py-5 flex flex-col space-y-2 justify-center items-center'>
                    <div className='relative'>
                        <Svgs.RewardDateShape />
                        <p className='font-semibold text-lg text-white absolute inset-0 flex items-center justify-center pb-3'>
                            {moment(data?.date).format('MMMM YYYY')}
                        </p>
                    </div>
                    <img src='/img/reward/RewardImg.png' className='w-[250px] object-cover' alt='reward winner' />
                    <div className='flex flex-col space-y-2 items-center'>
                        <h3 className='text-3xl font-bold'>Congratulations!</h3>
                        <p className='text-customGreen font-medium'>{data?.winner?.user?.first_name ? data?.winner?.user?.first_name : ""} {data?.winner?.user?.last_name ? data?.winner?.user?.last_name : ""} <span className='text-black'>for winning</span> {data?.prize}</p>
                        <div className=''>Total {data?.type == "booking_count" ? "Bookings" : "Revenue"}: <span className='bg-customGreen text-white px-2 py-1 rounded-[5px]'>{data?.type == "booking_count" ? data?.winner?.user?.reservations_count : `${data?.winner?.user?.reservations_sum_commission} ${currency}`}</span></div>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default WinnerDetailPopup