import React from 'react'
import ConciergeCommission from 'Pages/Concierges/Commission'

const ConciergeProfileCommissionAnalytics = () => {
    return (
        <>
            <ConciergeCommission active={"profile"} fromWallet={true} />
        </>
    )
}

export default ConciergeProfileCommissionAnalytics