import { GET_ADMIN_CONCIERGES, GET_ADMIN_SINGLE_CONCIERGE } from "./ActionType";

export const getAdminConciergesData = (data) => {
    return {
        type: GET_ADMIN_CONCIERGES,
        payload: data
    }
};

export const getAdminSingleConciergesData = (data) => {
    return {
        type: GET_ADMIN_SINGLE_CONCIERGE,
        payload: data
    }
};

