import Svgs from 'Assets/svgs'
import moment from 'moment'
import React from 'react'
import NotFound from 'Utility/NotFound'

const AdminTransactionTable = ({ data, onRowCick }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right min-w-[600px]">
                    <thead class="text-sm text-white bg-lightGrey capitalize">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Date
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 &&
                            data?.map(itm => {
                                return (
                                    <>
                                        <tr className="bg-darkGrey border-b border-lightGrey cursor-pointer text-blueGrey"
                                            onClick={() => {
                                                onRowCick && onRowCick(itm?.number)
                                            }}
                                        >
                                            <td class="px-6 py-3">
                                                {itm?.number}
                                            </td>
                                            <td class="px-6 py-3">
                                                {itm?.restaurant ? itm?.restaurant?.name : `${itm?.user?.first_name} ${itm?.user?.last_name}`}
                                            </td>
                                            <td class="px-6 py-3">
                                                {moment(itm?.updated_at).format('DD-MM-YYYY')}
                                            </td>
                                            <td class="px-6 py-3">
                                                <button className={`${itm?.is_received ? 'approve' : 'not-approved'} flex items-center gap-1 px-4 py-1.5 rounded-lg `}>
                                                    {
                                                        itm?.is_received
                                                            ? <>
                                                                <Svgs.Tick stroke={"white"} />
                                                                Confirmed
                                                            </>
                                                            : <>
                                                                Not Confirmed
                                                            </>
                                                    }
                                                </button>
                                            </td>
                                            <td class="px-6 py-3">
                                                {itm?.amount}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                    </tbody>
                </table>
                {!data &&
                    <NotFound />
                }
            </div>
        </>
    )
}

export default AdminTransactionTable