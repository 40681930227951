import { getStaffData, getStaffRoles, updateStaffToogleStatus } from "Adapters/APIs/Admin/staff";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

let timeOutId = undefined
const useAdminStaff = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [secondLoader, setSecondLoader] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [staffData, setStaffData] = useState([])
    const [staffDetail, setStaffDetail] = useState()
    const [addEditStaff, setAddEditStaff] = useState(false)
    const [confirmToogle, setConfirmToogle] = useState()
    const [statusLoader, setStatusLoader] = useState(false)
    const [staffRoles, setStaffRoles] = useState([])

    // get admin staff roles data
    const getAdminStaffRolesData = () => {
        let payload = {}
        const success = (data) => {
            setStaffRoles(data)
        }
        const fail = () => {
        }
        dispatch(getStaffRoles(access_token, payload, success, fail))
    }

    // get admin staff data data
    const getAdminStaffMembers = () => {
        let payload = {
            page: currentPage,
            search_text: searchText,
        }
        if (currentPage == 1) {
            setLoader(true)
        } else {
            setSecondLoader(true)
        }
        const success = (data) => {
            let updatedData = []
            if (data?.members?.length == 0) {
                updatedData = []
            } else {
                if (data?.pagination_data?.meta?.current_page !== 1) {
                    updatedData = [...staffData || [], ...data?.members];
                } else {
                    updatedData = data?.members;
                }
            }
            setStaffData(updatedData)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getStaffData(access_token, payload, success, fail))
    }

    // get staff first time data every time
    const getStaffFirstTimeData = () => {
        setStaffDetail()
        setLoader(true)
        setCurrentPage(1)
        setSearchText()
        let payload = {
            page: 1,
        }
        const success = (data) => {
            setStaffData(data?.members)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
            setSecondLoader(false)
        }
        const fail = () => {
            setLoader(false)
            setSecondLoader(false)
        }
        dispatch(getStaffData(access_token, payload, success, fail))
    }

    // update staff status
    const updateStaffStatus = () => {
        setStatusLoader(true)
        let payload = {
            user_id: confirmToogle?.id
        }
        const success = () => {
            setConfirmToogle()
            getStaffFirstTimeData()
            setStatusLoader(false)
        }
        const fail = () => {
            setStatusLoader(false)
        }
        dispatch(updateStaffToogleStatus(access_token, payload, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getAdminStaffMembers()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getAdminStaffMembers()
            }, 500);
        }
    }, [searchText, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    useEffect(() => {
        getAdminStaffRolesData()
    }, [])

    return {
        staffData, navigate, searchText, setSearchText, paginationData, loader, secondLoader, currentPage, setCurrentPage,
        staffDetail, setStaffDetail, addEditStaff, setAddEditStaff, getStaffFirstTimeData, confirmToogle, setConfirmToogle,
        statusLoader, setStatusLoader, updateStaffStatus, staffRoles
    }
}

export default useAdminStaff