import { getAdminSingleRestaurantMenuData, getAdminSingleRestaurantMenuPdfData } from "Adapters/APIs/Admin/restaurant";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ContentCard from "./ContentCard";

const useSingleRestaurantMenu = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { restuarant_id } = useParams();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [activeTab, setActiveTab] = useState('MainMenu')
    const [loader, setLoader] = useState(true)
    const [pdfMenuLoader, setPdfMenuLLoader] = useState(true)
    const [menuData, setMenuData] = useState([])
    const [allMenuData, setAllMenuData] = useState([])
    const [pdfMenudata, setPdfMenuData] = useState({})
    const [menuPopup, setMenuPopup] = useState(false)

    // get restaurant menu
    const getRestaurantMenuData = () => {
        let payload = {
            restaurant_id: restuarant_id,
        }
        setLoader(true)
        const success = (res) => {
            setAllMenuData(res?.categories)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleRestaurantMenuData(access_token, payload, success, fail))
    }

    // get restaurant menu pdf
    const getRestaurantMenuPDFData = () => {
        let payload = {
            restaurant_id: restuarant_id,
        }
        setPdfMenuLLoader(true)
        const success = (res) => {
            setPdfMenuData(res?.pdf_menu)
            setPdfMenuLLoader(false)
        }
        const fail = () => {
            setPdfMenuLLoader(false)
        }
        dispatch(getAdminSingleRestaurantMenuPdfData(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoader(true)
        setPdfMenuLLoader(true)
        getRestaurantMenuData()
        getRestaurantMenuPDFData()
    }, [])

    useEffect(() => {
        if (allMenuData?.length > 0) {
            let type = activeTab == 'MainMenu' ? 'main_menu' : activeTab == 'beverage_menu' ? 'beverage_menu' : 'dessert_menu'
            const filteredData = allMenuData?.filter(itm => itm?.type == type)?.map((itm, ind) => ({
                title: itm?.name,
                content: itm?.items?.map((obj, ind) => <ContentCard
                    data={obj}
                    index={ind}
                    heading={obj?.name}
                    description={obj?.description}
                    price={obj?.price}
                    discount={obj?.discount}
                />
                )
            }))
            setMenuData(filteredData)
        } else {
            setMenuData([])
        }
    }, [allMenuData, activeTab])

    return {
        menuData, loader, navigate, activeTab, setActiveTab, pdfMenuLoader, pdfMenudata, menuPopup, setMenuPopup
    }
}

export default useSingleRestaurantMenu