import DashboardLayout from 'Components/DashboardLayout'
import EditRestaurantProfile from 'Pages/Restaurants/Profile/EditProfile'
import React from 'react'
import useAdminUnapprovedRestaurant from './helper'

const EditRestaurantByAdmin = () => {
    const { restaurant, navigate, loader } = useAdminUnapprovedRestaurant()

    return (
        <DashboardLayout active={'Dashboard'}>
            <main className="p-4 lg:ml-72 md:ml-64 h-auto pt-20 min-h-screen ">
                <EditRestaurantProfile
                    loader={loader}
                    editData={restaurant}
                    successCallback={() => { navigate(`/admin/unapproved-restaurant/${restaurant?.id}`) }}
                    hideUpdateEmail={true}
                />
            </main>
        </DashboardLayout>
    )
}

export default EditRestaurantByAdmin