import { Howl } from 'howler';

// Define sounds for different scenarios
const sounds = {
  reservationAlert: new Howl({
    src: ['/audio/bellsound.mp3'],
    volume: 0.5,
  }),
  // error: new Howl({
  //   src: ['path-to-error-sound.mp3'],
  //   volume: 0.5,
  // }),
  // notification: new Howl({
  //   src: ['path-to-notification-sound.mp3'],
  //   volume: 0.5,
  // }),
};

// Function to play sound
export const playSound = (scenario) => {
  if (sounds[scenario]) {
    sounds[scenario].play();
  }
};
