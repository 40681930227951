import { GET_ALL_RESTAURANTS, GET_BUSINESS_HOURS } from "./ActionType";

const initialState = {
    restaurants: [],
    business_hours: [],
    business_hours_state_updated: false
}

const RestaurantReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_ALL_RESTAURANTS:
            return {
                ...state,
                restaurants: payload,
            }
        case GET_BUSINESS_HOURS:
            return {
                ...state,
                business_hours: payload,
                business_hours_state_updated: true
            }
        default:
            return {
                ...state,
            };
    }
}

export default RestaurantReducer