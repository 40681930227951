import { getAdminFinanceInvoicesData } from "Adapters/APIs/Admin/finance";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAdminPendingRecievables = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // states
    const [loader, setLoader] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [financeData, setFinanceData] = useState([])
    const [paginationData, setPaginationData] = useState()

    // get admin finance data
    const getAdminPendingRecievables = () => {
        let payload = {
            page: currentPage,
            per_page: 10,
            type: 'unpaid',
            sub_type: 'restaurants'
        }
        const success = (data) => {
            setFinanceData(data?.invoices?.data)
            setPaginationData(data?.pagination_data?.meta)
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminFinanceInvoicesData(access_token, payload, success, fail))
    }

    useEffect(() => {
        getAdminPendingRecievables()
    }, [currentPage])

    return {
        financeData, navigate, paginationData, loader, currentPage, setCurrentPage
    }
}

export default useAdminPendingRecievables