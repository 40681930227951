import Popup from 'Components/Elements/Popup'
import { WEEKDAYS } from 'Constants/Data/Months';
import React from 'react'

const RestaurantBusinessHour = ({ open, close, data }) => {

    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            heading={'Time Hours'}
        >
            <div className='flex flex-col space-y-3'>
                {/* Map over the days of the week */}
                {WEEKDAYS?.map((day) => {
                    // Find the data for the current day
                    const dayData = data?.find((d) => d?.day_of_week === day);

                    return (
                        <div
                            key={day}
                            className="flex gap-2 items-center justify-between"
                        >
                            <h1 className="text-blueGrey font-semibold text-lg">{day}</h1>
                            <div className="flex justify-center items-center text-blueGrey">
                                {dayData
                                    ? `${dayData?.opening_time} - ${dayData?.closing_time}`
                                    : 'Closed'}
                            </div>
                        </div>
                    );
                })}
            </div>
        </Popup>
    )
}

export default RestaurantBusinessHour