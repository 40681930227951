import React from 'react'
import useEditProfile from './helper'
import Button from 'Components/Elements/Button'
import PasswordInput from 'Components/Elements/PasswordInput'

const UpdatePassword = ({ setActiveTab }) => {
    const { formData, errors, handleChange, handleSubmit, loading } = useEditProfile(setActiveTab)
    return (
        <>
            <div className="py-6 w-full">
                <div className='flex flex-col gap-4' >
                    <div className="flex gap-3 items-center">
                        <h4 className="text-2xl font-semibold text-blueGrey">Change Password</h4>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-5">
                        <PasswordInput
                            title={'Current Password'}
                            value={formData?.current_password}
                            error={errors?.current_password}
                            onChange={handleChange}
                            name={'current_password'}
                            required={true}
                            placeholder={"Enter current password"}
                        />
                        <PasswordInput
                            title={'New Password'}
                            value={formData?.password}
                            error={errors?.password}
                            onChange={handleChange}
                            name={'password'}
                            required={true}
                            placeholder={"Enter new password"}
                        />
                        <PasswordInput
                            title={'Confirm New Password'}
                            value={formData?.new_password_confirmation}
                            error={errors?.new_password_confirmation}
                            onChange={handleChange}
                            name={'new_password_confirmation'}
                            required={true}
                            placeholder={"Enter confirm new password"}
                        />
                    </div>
                    <div className="flex justify-end">
                        <Button
                            loading={loading}
                            onClick={handleSubmit}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default UpdatePassword