// SignaturePad.js
import React, { useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignaturePad = ({ value, setValue }) => {
    const signatureRef = useRef(null);

    // Load existing signature when component mounts or value changes
    useEffect(() => {
        if (signatureRef.current && value) {
            signatureRef.current.fromDataURL(value);
        }
    }, [value]);

    const handleClear = () => {
        signatureRef.current.clear();
        setValue(''); // Clear the value in the parent component
    };

    const handleSave = () => {
        if (signatureRef.current) {
            const signatureData = signatureRef.current.toDataURL('image/png');
            setValue(signatureData); // Update the value in the parent component
        }
    };

    return (
        <div className="">
            <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{ className: 'w-full h-64 border border-gray-300 bg-white' }}
                onEnd={handleSave} // Optionally trigger save on end of drawing
            />
        </div>
    );
};

export default SignaturePad;
