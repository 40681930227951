import { getAdminSingleConciergeApiData, updateConciergeCommission, updateConciergeToogleStatus } from "Adapters/APIs/Admin/concierge";
import { objectKeyConvertToArray } from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const useAdminSingleConcierge = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user_id } = useParams();

    const { setErrors, errors, validation } = useValidations()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const concierge = state?.adminConcierges?.singleConcierge

    // states
    const [loader, setLoader] = useState(true)
    const [toogleStatus, setToogleStatus] = useState(false)
    const [confirmToogle, setConfirmToogle] = useState(false)
    const [statusLoader, setStatusLoader] = useState(false)
    const [updateCommissionLoader, setUpdateCommissionLoader] = useState(false)
    const [commissionPercentage, setCommissionPercentage] = useState('')

    // get single concierge
    const getSingleConciergeData = () => {
        let payload = {
            user_id: user_id
        }
        setLoader(true)
        const success = () => {
            setLoader(false)
        }
        const fail = () => {
            setLoader(false)
        }
        dispatch(getAdminSingleConciergeApiData(access_token, payload, success, fail))
    }

    // update concierge status
    const changeConciergeStatus = () => {
        setStatusLoader(true)
        let payload = {
            user_id: concierge?.id
        }
        const success = () => {
            setToogleStatus(!toogleStatus)
            setStatusLoader(false)
            setLoader(true)
            setConfirmToogle(false)
            getSingleConciergeData()
        }
        const fail = () => {
            setStatusLoader(false)
        }
        dispatch(updateConciergeToogleStatus(access_token, payload, success, fail))
    }

    // update concierge commission data
    const updateConciergeCommissionData = () => {
        let payload = {
            user_id: concierge?.id,
            commission_percentage: commissionPercentage
        }
        const success = () => {
            setLoader(true)
            setUpdateCommissionLoader(false)
            getSingleConciergeData()
        }
        const fail = () => {
            setUpdateCommissionLoader(false)
        }
        let requiredFields = { commissionPercentage: commissionPercentage }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setUpdateCommissionLoader(true)
            dispatch(updateConciergeCommission(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        setCommissionPercentage(Math.floor(concierge?.commission_percentage))
    }, [concierge])

    useEffect(() => {
        setToogleStatus(concierge?.is_active == 1 ? true : false)
    }, [concierge?.is_active])

    useEffect(() => {
        getSingleConciergeData()
    }, [])

    return {
        concierge, navigate, loader, toogleStatus, setToogleStatus, changeConciergeStatus, confirmToogle, setConfirmToogle, statusLoader,
        commissionPercentage, setCommissionPercentage, updateConciergeCommissionData, updateCommissionLoader, errors, setErrors
    }
}

export default useAdminSingleConcierge