import Svgs from "Assets/svgs"

export const BOOKING_STATUSES = [
    { label: 'Placed', value: "placed" },
    { label: 'Confirmed', value: "confirmed" },
    { label: 'Billed', value: "billed" },
    { label: 'Commission Paid', value: "commission paid" },
    { label: 'No Show', value: "no show" },
]

export const BOOKING_STATUSES_WITH_ICONS = [
    { name: 'Placed', value: 1, activeIcon: <Svgs.PlacedIcon />, unActiveIcon: <Svgs.PlacedIcon /> },
    { name: 'Confirmed', value: 2, activeIcon: <Svgs.ConfirmedFillIcon />, unActiveIcon: <Svgs.ConfirmedIcon /> },
    { name: 'Billed', value: 3, activeIcon: <Svgs.BilledFillIcon />, unActiveIcon: <Svgs.BilledIcon /> },
    { name: 'Commission Paid', value: 4, activeIcon: <Svgs.CommissionPaidFillIcon />, unActiveIcon: <Svgs.CommissionPaidIcon /> }
]

export const BOOKING_STATUSES_WITHOUT_COMMISSION_PAID = [{ name: 'Placed', value: 1, activeIcon: <Svgs.PlacedIcon />, unActiveIcon: <Svgs.PlacedIcon /> },
{ name: 'Confirmed', value: 2, activeIcon: <Svgs.ConfirmedFillIcon />, unActiveIcon: <Svgs.ConfirmedIcon /> },
{ name: 'Billed', value: 3, activeIcon: <Svgs.BilledFillIcon />, unActiveIcon: <Svgs.BilledIcon /> }
]