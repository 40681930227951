import React from 'react'

const AdminPromotionCardShimmer = () => {
    return (
        <div className="relative bg-gray-200 shadow-md border-t-[10px] border-gray-400 rounded-lg p-6 pt-0 h-full min-h-40 animate-pulse">
            <div className='absolute bg-gray-400 h-28 w-20 -mt-1 promotion-clipy flex flex-col items-center justify-start pt-2 space-y-0.5'>
            </div>
            <div className='ml-24 mt-7 flex flex-col space-y-1'>
                <div className='flex gap-4 justify-between items-center'>
                    <div className='h-5 bg-gray-300 rounded-lg w-28'></div>
                    <div className="h-6 justify-end w-[36px] bg-gray-300 rounded-full"></div>
                </div>
                <div className='h-5 bg-gray-300 rounded-lg w-20'></div>
                <div className='h-5 bg-gray-300 rounded-lg w-36'></div>
                <div className='h-5 bg-gray-300 rounded-lg w-full flex flex-wrap'></div>
            </div>
        </div>
    )
}

export default AdminPromotionCardShimmer