import { BASE_URL, generate_description } from "Adapters/variables";
import axios from "axios";

// Generate description
export const generateDescription = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const headers = {
            headers: {
                'authorization': `Bearer ${access_token}`
            }
        }
        const response = await axios.get(`${BASE_URL}${generate_description}?restaurant_id=${data}`, headers);
        if (response?.status == 200) {
                success && success(response?.data)
                return
        }
    } catch (error) {
        fail && fail();
        return
    }
};