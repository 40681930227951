import { currency } from 'Adapters/variables'
import Back from 'Components/Elements/Back'
import Button from 'Components/Elements/Button'
import CheckBox from 'Components/Elements/Checkbox'
import FilePicker from 'Components/Elements/FilePicker'
import LoginInput from 'Components/Elements/LoginInput'
import Popup from 'Components/Elements/Popup'
import SubscriptionCard from 'Components/SubscriptionCard'
import React from 'react'
import { toast } from 'react-toastify'

const PendingPayments = ({ open, close, errors, details, selectedMonths, onSelectedChange, total_price_no_vat, total_vat, setPayClick, total_price_with_vat, payClick, paidProof, onProofChangeHandler, payLoader, onSubmitPayment }) => {
    return (
        <>

            {open &&
                <Popup
                    open={open}
                    close={close}
                    removeHeading={true}
                >
                    <div className={`flex w-full flex-col space-y-8`}>
                        <div className="w-full flex items-center justify-between">
                            <Back title={"All Monthly Fee"} noPadding={true} onClick={close && close} />
                            <CheckBox
                                name={'select-all'}
                                label={'Select All'}
                                onChange={() => onSelectedChange('', 'all')}
                                onClickLabel2={() => onSelectedChange('', 'all')}
                                parentClass={'!flex-row-reverse'}
                                checked={selectedMonths?.length == details?.due_months_date?.length}
                            />
                        </div>
                        {details?.due_months_date?.length > 0 &&
                            <>
                                {details?.due_months_date?.map(itm => {
                                    const selected = selectedMonths?.find(months => months == itm)
                                    return (<>
                                        <SubscriptionCard title={itm} details={details} isCheckbox={true} selected={selected} onSelectedChange={onSelectedChange} />
                                    </>)
                                })}
                            </>
                        }
                        {total_price_with_vat
                            &&
                            <Button
                                title={total_price_with_vat == 0 ? 'Pay' : `Pay ${total_price_with_vat} ${currency} ( ${total_price_no_vat} + ${total_vat} VAT)`}
                                onClick={() => {
                                    selectedMonths?.length > 0
                                        ? setPayClick(true)
                                        : toast.error("Atleast select atleast 1 month in order to pay!", { toastId: 'toast' })
                                }}
                            />
                        }
                    </div>
                </Popup>
            }

            <Popup
                open={payClick}
                close={setPayClick}
                customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
                heading={'Proceed to payment'}
            >
                <div className="flex flex-col gap-4">
                    <FilePicker
                        required={true}
                        title="Attach transfer proof"
                        value={paidProof}
                        name={'paidProof'}
                        error={errors?.paidProof}
                        id={'paidProof'}
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.pdf,.PDF"}
                        onChange={onProofChangeHandler}
                    />
                    <LoginInput
                        title={'Payable Commission'}
                        required={false}
                        value={total_price_with_vat}
                        disabled={true}
                        leftIcon={currency}
                    />
                    <Button className={'mt-2'}
                        text={"Submit"}
                        loading={payLoader}
                        disabled={payLoader}
                        onClick={onSubmitPayment}
                    />
                </div>
            </Popup>
        </>

    )
}

export default PendingPayments