import { getConceirgeWishlist, toggleWishList } from "Adapters/APIs/Concierge/Wishlist";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let timeOutId = undefined
const useSupportRequest = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    // redux states
    const state = useSelector(state => state)
    const wishlist = state?.concierge?.wishlists
    const pagination = state?.concierge?.wishlist_pagination

    // states
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [createReq, setCreateReq] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [status, setStatus] = useState('')
    const [activeTab, setActiveTab] = useState('not converted')
    const [searchText, setSearchText] = useState()
    const [updateForm, setUpdateForm] = useState()

    // get Wishlist record
    const getWishlist = (isToggle) => {
        !isToggle && setLoading(true)

        const success = () => {
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        const payload = {
            type: activeTab,
            page: currentPage,
            end_date: endDate,
            start_date: startDate,
            search_text: searchText,
        }
        dispatch(getConceirgeWishlist(access_token, payload, success, fail,))
    }

    // Toggle Wishlist
    const onToggleClick = (id) => {
        const success = (data) => {
            getWishlist('toggle')
        }
        const fail = () => {
        }
        dispatch(toggleWishList(access_token, id, success, fail))
    }

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText 
            getWishlist()
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getWishlist()
            }, 500);
        }
    }, [currentPage, endDate, activeTab, searchText, startDate])

    useEffect(() => {
        setLoading(true)
        setCurrentPage(1)
    }, [activeTab, startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    return {
        currentPage, setCurrentPage, endDate, setEndDate, startDate, setStartDate, createReq, setCreateReq, openDetail, setOpenDetail,
        wishlist, pagination, loading, getWishlist, onToggleClick, setStatus, status, activeTab, setActiveTab, searchText, setSearchText,
        updateForm, setUpdateForm, navigate
    }
}

export default useSupportRequest