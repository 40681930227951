import Svgs from 'Assets/svgs';
import React from 'react'


import TooltipLite from 'react-tooltip-lite';


const InfoIcon = ({ text, info }) => {
    return (
        <>
            <div className='flex items-center gap-1'>
                {text}
                <a data-tip={info} className='cursor-pointer'></a>
                <TooltipLite zIndex={'123213213'} content={info} tagName="div">
                    <Svgs.I className='cursor-pointer' />
                </TooltipLite>
            </div>
        </>

    )
}

InfoIcon.defaultProps = {
    text: 'Enter "Text" Prop',
    info: 'Enter "Info" Prop',
}

export default InfoIcon