import Button from 'Components/Elements/Button'
import Popup from 'Components/Elements/Popup'
import React from 'react'

const ConfirmationPopup = ({ open, close, status, onYesClick, yesLoader, title, removeHeader }) => {
    return (
        <Popup
            open={open}
            close={close}
            customSizeStyle={'sm:w-[40vw] lg:w-[30vw]'}
            removeHeading={true}
            header={!removeHeader}
        >
            <h1 className='text-center py-5 text-lg font-semibold text-blueGrey'>{title ? title : `Are you sure you want to ${status == 1 ? 'de-activate' : 'activate'} this?`}</h1>
            <div className="flex items-center justify-between gap-4 mt-4">
                <Button text={'No'} className={'w-full'} customThemeBtn={'view-edit-btn'} onClick={() => {
                    const rootElement = document.getElementById('root');
                    rootElement?.classList.remove('overflow-hidden')
                    close(false);
                }} />
                <Button text={'Yes'} className={'w-full'} onClick={onYesClick} loading={yesLoader} disabled={yesLoader} />
            </div>
        </Popup>
    )
}

export default ConfirmationPopup